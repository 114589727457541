import EmptyImageWithArrow from '@/assets/images/illustration/emptyState.svg';
const SurvivalSurveysEmptyState = ({ description }: { description: string }) => {
  return (
    <div className="rounded-lg h-full  flex-col flex flex-1 items-center justify-center dark:bg-darkblack-500 relative">
      <img src={EmptyImageWithArrow} alt="" className="h-[150px]" />
      <p className="text-center text-base dark:text-bgray-200">{description}</p>
    </div>
  );
};
export default SurvivalSurveysEmptyState;
