import PrimaryOutlineButton from '@/component/buttons/PrimaryOutlineButton';
import { Chip } from '@mui/material';
import LogoCompany from './LogoCompany';
import InfosCompany from './InfosCompany';
import { companyProfileStore, userStore } from '@/store';
import { AddCompanyModal } from '@/pages/private/master/companies/companies-list/modal-add-company';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const HeaderCompanyProfile = observer(() => {
  const { modalStates, companyData, handleModal } = companyProfileStore;
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="w-full mb-6 rounded-lg bg-white px-[24px] py-[20px] dark:bg-darkblack-600 shadow-lg">
      <div className="w-full py-5 items-center justify-between flex">
        <div className="flex space-x-5 items-center">
          <p className="text-lg font-semibold dark:text-gray-200">
            {tCompanyProfile('header.infoOfCompany')}
          </p>
          {!companyData.active && companyData.id && (
            <Chip
              label={tCompanyProfile('header.chipInactive')}
              color={'error'}
              size="small"
            />
          )}
        </div>
        {(userStore.user.keyUser || userStore.user.master) && (
          <PrimaryOutlineButton
            text={tCommon('actions.edit')}
            onClick={() => handleModal({ ...modalStates, updateCompany: true })}
          />
        )}
        {(userStore.user.keyUser || userStore.user.master) && (
          <AddCompanyModal
            isActive={modalStates.updateCompany}
            handleClose={() => handleModal({ ...modalStates, updateCompany: false })}
            company={companyData}
          />
        )}
      </div>
      <div className="w-full lg:flex lg:space-x-5">
        <LogoCompany />
        <InfosCompany />
      </div>
    </div>
  );
});

export default HeaderCompanyProfile;
