import { paramType } from '../services-params-types';
import { checkPermission } from '@/utils/helpers/validations';
import { companyProfileStore } from '@/store';
import { translationI18n } from '@/i18n';

export type servicesParamsType = {
  title: string;
  service: string;
  description: string;
  index?: boolean;
  hide?: boolean;
  params: {
    param: paramType;
    title: string;
  }[];
};
const servicesParams = (): servicesParamsType[] => {
  const { isMyCompany } = companyProfileStore;

  return [
    {
      title: translationI18n(
        'companyProfile:body.screens.params.items.survivalSurveys.title',
      ),
      service: 'survivalSurveys',
      description: translationI18n(
        'companyProfile:body.screens.params.items.survivalSurveys.description',
      ),
      index: true,
      hide:
        isMyCompany &&
        !checkPermission({
          permission: 'hasone',
          service: 'survivalSurveys',
        }),
      params: [
        {
          param: 'survivalSurveysParams' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.paramsEvaluation.name',
          ),
        },
        {
          param: 'survivalSurveysTime' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.name',
          ),
        },
        {
          param: 'survivalSurveysCause' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.causes.name',
          ),
        },
      ],
    },
    {
      title: translationI18n(
        'companyProfile:body.screens.params.items.plantedArea.title',
      ),
      service: 'plantedArea',
      description: translationI18n(
        'companyProfile:body.screens.params.items.plantedArea.description',
      ),
      index: true,
      hide:
        isMyCompany &&
        !checkPermission({
          permission: 'hasone',
          service: 'plantedArea',
        }),
      params: [
        {
          param: 'plantedAreaParams' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.params.name',
          ),
        },
        {
          param: 'plantedAreaReason' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.reasons.name',
          ),
        },
        {
          param: 'plantedAreaGeneticMaterial' as paramType,
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.name',
          ),
        },
      ],
    },
  ].filter((x) => !x.hide);
};
export default servicesParams;
