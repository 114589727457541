import {
  ReboleiraPhotosInterface,
  SurvivalSurveysEvaluationPhotoInterface,
} from '@/pages/private/quality/silviculture/survival-surveys/quality-survival-surveys-types';
import { survivalSurveysEvaluationsStore } from '@/store';
import Colors from '@/utils/colors';
import { handleDownloadImageByUrl } from '@/utils/downloadImage';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiSolidDownload, BiX } from 'react-icons/bi';
import { ScaleLoader } from 'react-spinners';

type ActiveImageType = {
  imageUrl: string;
  id: number;
  observation: string | null;
  uuid: string;
};

type ModalGalleryProps = {
  activeImage: ActiveImageType;
  handleActiveImage: (
    image: ReboleiraPhotosInterface | SurvivalSurveysEvaluationPhotoInterface,
  ) => void;
  closeModal: () => void;
  images: ActiveImageType[];
  isOpen: boolean;
};

const ModalGallery = ({
  activeImage,
  closeModal,
  images,
  handleActiveImage,
  isOpen,
}: ModalGalleryProps) => {
  const { evaluationDetails } = survivalSurveysEvaluationsStore;
  const [loadingDownloadImage, setLoadingDownloadImage] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  async function downloadImage() {
    setLoadingDownloadImage(true);
    try {
      await handleDownloadImageByUrl(
        activeImage.imageUrl,
        `${evaluationDetails.forestRegistry.fazenda} - ${evaluationDetails.forestRegistry.talhao} - ${activeImage.uuid.split('-')[0]}`,
      );
      setLoadingDownloadImage(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingDownloadImage(false);
    }
  }
  const nextImage = () => {
    const currentIndex = images.findIndex((img) => img.id === activeImage.id);
    const nextIndex = (currentIndex + 1) % images.length;
    handleActiveImage(images[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = images.findIndex((img) => img.id === activeImage.id);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    handleActiveImage(images[prevIndex]);
  };
  useMemo(() => {
    if (activeImage.imageUrl) {
      setLoadingImage(true);
    }
  }, [activeImage]);
  if (!isOpen) {
    return;
  }

  return (
    <div className="fixed inset-0 p-12 z-50 flex items-center justify-center bg-black dark:bg-darkblack-600  bg-opacity-75 dark:bg-opacity-75">
      {loadingImage && (
        <div className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center flex bg-black bg-opacity-50 z-30">
          <ScaleLoader
            color={Colors.primary[200]}
            height={150}
            width={12}
            speedMultiplier={0.7}
          />
        </div>
      )}
      <button
        onClick={downloadImage}
        className={` absolute right-24 top-4 w-[42px] h-[42px] text-primary-200 z-50`}
      >
        {loadingDownloadImage ? (
          <ScaleLoader color={Colors.primary[200]} />
        ) : (
          <BiSolidDownload
            fontSize={42}
            className="hover:text-white hover:stroke-primary-200"
          />
        )}
      </button>
      <button
        onClick={closeModal}
        className={` absolute right-4 top-4 text-primary-200 z-50`}
      >
        <BiX fontSize={42} className="hover:text-white hover:stroke-primary-200" />
      </button>
      <div className="relative">
        <div className="flex items-center justify-center relative w-screen h-[80vh]">
          <img
            className={`max-h-[80vh] max-w-[80%] z-20`}
            src={activeImage.imageUrl}
            alt={activeImage.observation}
            onLoad={() => setLoadingImage(false)}
          />
          <button
            onClick={(e) => {
              e.stopPropagation();
              prevImage();
            }}
            className={` ${images.length === 1 ? 'hidden' : ''} absolute left-4 md:left-16 top-1/2 z-50 text-primary-200`}
          >
            <BiChevronLeft
              fontSize={64}
              className="hover:text-white hover:stroke-primary-200"
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              nextImage();
            }}
            className={` ${images.length === 1 ? 'hidden' : ''} z-50 absolute right-4 md:right-16 top-1/2 text-primary-200`}
          >
            <BiChevronRight
              fontSize={64}
              className="hover:text-white hover:stroke-primary-200"
            />
          </button>
        </div>
        <div className="max-h-8 min-h-8">
          <p className="text-base text-white text-center">{activeImage.observation}</p>
        </div>
        <div
          className={`${images.length === 1 ? 'hidden' : ''} flex mt-4 space-x-2 justify-center`}
        >
          {images.map((image) => (
            <img
              key={image.id}
              loading="lazy"
              onClick={(e) => {
                e.stopPropagation();
                handleActiveImage(image);
              }}
              className={`w-[50px] h-[50px] cursor-pointer ${image.id === activeImage.id ? 'border-success-200 border-2' : ''}`}
              src={image.imageUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(ModalGallery);
