/** @type {import('tailwindcss').Config} */
import plugin from 'tailwindcss/plugin';
import fromPlugin from '@tailwindcss/forms';
export default {
  content: ['./index.html', './src/**/*', './src/component/**/*'],
  darkMode: 'class',
  theme: {
    extend: {
      fontFamily: {
        urbanist: ['Urbanist', 'sans-serif'],
        poppins: ['Poppins', 'sans-serif'],
      },
      width: {
        66: '66%',
        88: '88%',
        70: '70%',
      },
      fontSize: {
        xs: '10px',
        sm: '11px',
        base: '13px',
        lg: '14px',
        xl: '16px',
        '2xl': '18px',
        '3xl': '22px',
        '4xl': '26px',
        '5xl': '32px',
      },
      colors: {
        darkblack: {
          300: '#747681',
          400: '#2A313C',
          500: '#23262B',
          600: '#1D1E24',
          700: '#151515',
        },
        primary: {
          50: '#E9F4E9',
          100: '#B8DFC2',
          200: '#50AE77',
          300: '#377E63',
          400: '#255341',
        },
        success: {
          100: '#CCE7D7',
          200: '#22C55E',
          300: '#17A948',
        },
        warning: {
          50: '#FAE7C0',
          200: '#f5c25b',
          300: '#EAB308',
          400: '#BB8A06',
          500: '#FF8C00',
        },

        error: {
          50: '#F5C4C6',
          100: '#FF7171',
          200: '#FF4747',
          300: '#C12126',
        },
        bgray: {
          50: '#FAFAFA',
          100: '#F7FAFC',
          200: '#EDF2F7',
          300: '#E2E8F0',
          400: '#CBD5E0',
          500: '#A0AEC0',
          600: '#718096',
          700: '#4A5568',
          800: '#2D3748',
          900: '#1A202C',
        },
        gray: {
          50: '#eef3ff',
          100: '#e0e5f0',
          200: '#BDC1CA',
          300: '#9095A1',
          400: '#4f5054',
          500: '#1f1f20',
        },
        grayOpacity: {
          50: '#eef3ff65',
          100: '#e0e5f065',
          200: '#BDC1CA65',
          300: '#9095A165',
          400: '#4f505465',
          500: '#1f1f2065',
        },
        primaryOpacity: {
          50: '#E9F4E920',
          100: '#B8DFC220',
          200: '#50AE7720',
          300: '#377E6320',
          400: '#25534120',
        },
        successOpacity: {
          100: '#CCE7D720',
          200: '#22C55E20',
          300: '#17A94820',
          400: '#16A34A20',
        },
        errorOpacity: {
          50: '#F5C4C620',
          100: '#FF717120',
          200: '#FF474720',
          300: '#C1212620',
        },
        warningOpacity: {
          50: '#FAE7C020',
          200: '#f5c25b20',
          300: '#EAB30820',
        },
        purple: '#94196d',
        purpleOpacity: '#94196d20',
        whiteOpacity: '#ffffff90',
        blackOpacity: '#000000aa',
      },
      backgroundImage: {
        'bgc-dark': "url('./src/assets/images/background/comming-soon-dark.svg')",
        'bgc-light': "url('./src/assets/images/background/coming-soon-bg.svg')",
        'notfound-dark': "url('./src/assets/images/background/404-dark.jpg')",
        'notfound-light': "url('./src/assets/images/background/404-bg.png')",
      },
      lineHeight: {
        'extra-loose': '44.8px',
        'big-loose': '140%',
        130: '130%',
        150: '150%',
        160: '160%',
        175: '175%',
        180: '180%',
        200: '200%',
        220: '220%',
      },
      letterSpacing: {
        tight: '-0.96px',
        40: '-0.4px',
      },
      borderRadius: {
        20: '20px',
      },
    },
  },
  plugins: [
    fromPlugin,
    plugin(function ({ addVariant }) {
      addVariant('current', '&.active');
    }),
  ],
};
