import InputPassword from '@/component/forms/InputPassword';
import ButtonSubmit from '@/component/forms/button-submit';
import BtnClose from '@/component/buttons/BtnClose';
import HeadingLogo from './header-logo';
import { PasswordResetModalControllerType } from '../../../signin-types';
import { useTranslation } from 'react-i18next';

const NewPass = ({ passwordResetModalControllers }: PasswordResetModalControllerType) => {
  const { onClose, validationNewPassword, loadingNewPassword } =
    passwordResetModalControllers;
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    validationNewPassword;
  const tSignin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="step-content step-3">
      {/* Step 3 Content Here */}
      <div className="relative  transform overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 p-8 text-left transition-all">
        <div className="absolute top-0 right-0 pr-5 pt-5">
          <BtnClose close={onClose} />
        </div>
        <div>
          <HeadingLogo />
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-gray-200 mb-3">
            {tSignin('resetPasswordModal.newPassword.title')}
          </h3>
          <p className="text-base font-medium text-bgray-600 dark:text-darkblack-300 mb-7">
            {tSignin('resetPasswordModal.newPassword.subtitle')}
          </p>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <InputPassword
              placeholder={tSignin('resetPasswordModal.newPassword.newPassword')}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              onBlur={handleBlur('newPassword')}
              error={!!(errors.newPassword && touched.newPassword)}
              errorMessage={errors.newPassword}
            />
            <InputPassword
              placeholder={tSignin('resetPasswordModal.newPassword.newPasswordConfirm')}
              value={values.newPasswordConfirm}
              onChange={handleChange('newPasswordConfirm')}
              onBlur={handleBlur('newPasswordConfirm')}
              error={!!(errors.newPasswordConfirm && touched.newPasswordConfirm)}
              errorMessage={errors.newPasswordConfirm}
            />
            <ButtonSubmit
              wFull
              title={tCommon('actions.save')}
              loading={loadingNewPassword}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPass;
