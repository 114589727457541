import React, { useEffect } from 'react';
import { MapContainer, Polygon, useMap } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import NoMapIcon from '@/assets/images/icons/noMapIcon';
import Theme from '../../../../../../tailwind.config';
const { colors } = Theme.theme.extend;

interface FitBoundsPolygonProps {
  positions: L.LatLngExpression[];
}

const FitBoundsPolygon: React.FC<FitBoundsPolygonProps> = ({ positions }) => {
  const map = useMap();

  useEffect(() => {
    if (positions && positions.length > 0) {
      map.fitBounds(positions);
    }
  }, [map, positions]);

  return null;
};

interface MapComponentProps {
  polygonPositions: { coordinates: LatLngExpression[] }[];
}

const MiniMapComponent = ({ polygonPositions }: MapComponentProps) => {
  if (polygonPositions.length === 0) {
    return (
      <div
        style={{
          height: '50px',
          width: '50px',
          zIndex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          borderRadius: '10px',
          backgroundColor: colors.bgray[300],
        }}
      >
        <NoMapIcon />
      </div>
    );
  }

  // Converter { lat: number; lng: number }[] para L.LatLngExpression[]
  const convertPositions = (
    positions: { lat: number; lng: number }[],
  ): L.LatLngExpression[] => {
    return positions.map((position) => [position.lat, position.lng]);
  };

  // Combinar todas as coordenadas dos polígonos em um único array
  const allCoordinates: L.LatLngExpression[] = [];
  polygonPositions.forEach((polygon) => {
    const converted = convertPositions(polygon.coordinates);
    allCoordinates.push(...converted);
  });

  return (
    <MapContainer
      center={[
        polygonPositions[0].coordinates[0].lat,
        polygonPositions[0].coordinates[0].lng,
      ]}
      zoom={13}
      zoomAnimation={false}
      scrollWheelZoom={false}
      zoomControl={false}
      style={{
        height: '50px',
        width: '50px',
        borderRadius: '10px',
        backgroundColor: colors.bgray[300],
        zIndex: 1,
      }}
      attributionControl={false}
      dragging={false}
      doubleClickZoom={false}
    >
      {polygonPositions.map((polygon, index) => (
        <Polygon
          key={'minimap' + index}
          positions={convertPositions(polygon.coordinates)}
          color="red"
          className="z-0"
        />
      ))}
      <FitBoundsPolygon positions={allCoordinates} />
    </MapContainer>
  );
};

export default MiniMapComponent;
