import { observer } from 'mobx-react';
import { dashboardSurvivalSurveysStore, userStore } from '@/store';
import { checkPermission, checkPermissionCompany } from '@/utils/helpers/validations';
import Map from './map';
import CausesGraph from './causes-graph';
import HeaderExport from './header-export';
import EmptyState from '@/component/EmptyState';
import { useMemo } from 'react';
import SurvivalChart from './survival-chart';
import { SurvivalSurveysFilters } from './filters';
import { CardsInfo } from './card-infos';
import { useTranslation } from 'react-i18next';

const CardSurvivalSurveys = observer(() => {
  const {
    survivalSurveysGroup,
    filterSurvivalSurveys,
    summary,
    getSuvivalSurveysSummary,
    getSuvivalSurveysDataCause,
  } = dashboardSurvivalSurveysStore;
  const tHome = useTranslation('home').t;
  const hasPermission =
    checkPermissionCompany({
      companyData: userStore.companyActive,
      service: 'survivalSurveys',
    }) && checkPermission({ permission: 'permissionRead', service: 'survivalSurveys' });
  useMemo(() => {
    if (hasPermission) {
      getSuvivalSurveysSummary();
      getSuvivalSurveysDataCause();
    }
  }, [filterSurvivalSurveys, userStore.companyActive.id, hasPermission]);

  if (!hasPermission) {
    return null;
  }
  return (
    <div className="rounded-lg">
      <SurvivalSurveysFilters />
      {summary.evaluationsInfo.length > 0 ? (
        <div className="space-y-3" id="card_graph">
          <CardsInfo />
          <HeaderExport />
          <div className="lg:flex space-x-0 lg:space-x-3 space-y-3 lg:space-y-0">
            <div className="flex-1">
              <SurvivalChart
                showSlider={true}
                showButton={true}
                survivalSurveysGroup={survivalSurveysGroup}
              />
            </div>
          </div>
          <div className="lg:flex space-x-0 lg:space-x-3 space-y-3 lg:space-y-0">
            <div className="flex-[0.5] relative">
              <SurvivalChart survivalSurveysGroup={'time'} />
            </div>
            <div className="flex-1  ">
              <CausesGraph />
            </div>
          </div>
          <Map />
        </div>
      ) : (
        <div className="shadow-lg rounded-lg">
          <EmptyState
            size="lg"
            title={tHome('survivalSurveys.emptyData.title')}
            description={tHome('survivalSurveys.emptyData.description')}
          />
        </div>
      )}
    </div>
  );
});

export default CardSurvivalSurveys;
