import ProtoTypes from 'prop-types';
import { useState } from 'react';
import User from './User';
// import NotificationPopup from './NotificationPopup';
import ProfilePopup from './ProfilePopup';
import ModeToggler from './ModeToggler';
import LanguageSelect from './LanguageSelect';
import { Skeleton } from '@mui/material';
import { IoChevronBackSharp } from 'react-icons/io5';
import tailwindConfig from '../../../tailwind.config';
import { observer } from 'mobx-react';
interface HeaderOneProps {
  handleSidebar: () => void;
  title: string | null;
  goBack: () => void;
  showGoBack: boolean;
  subTitle: string | null;
}
interface PopUpsTypes {
  notification?: boolean;
  profile?: boolean;
  store?: boolean;
  massage?: boolean;
}
const HeaderOne = ({
  handleSidebar,
  title,
  subTitle,
  goBack,
  showGoBack,
}: HeaderOneProps) => {
  const [popup, setPopup] = useState<PopUpsTypes>({
    notification: false,
    profile: false,
    store: false,
    massage: false,
  });
  const handlePopup = (name: 'notification' | 'profile' | 'massage' | 'store') => {
    setPopup({ [name]: popup?.[name] ? false : true });
  };

  return (
    <header className="header-wrapper z-20 w-full shadow-lg">
      <div className="relative flex h-[80px] w-full items-center justify-between bg-white px-6 dark:bg-darkblack-600 2xl:px-[60px]">
        <button
          aria-label="none"
          onClick={handleSidebar}
          title="Ctrl+b"
          type="button"
          className="drawer-btn absolute left-0 top-auto rotate-180 transform"
        >
          <span>
            <svg
              width="16"
              height="40"
              viewBox="0 0 16 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 10C0 4.47715 4.47715 0 10 0H16V40H10C4.47715 40 0 35.5228 0 30V10Z"
                fill="#50AE77"
              />
              <path
                d="M10 15L6 20.0049L10 25.0098"
                stroke="#ffffff"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
        {/* page-title */}
        <div className="flex w-full flex-1">
          {showGoBack && (
            <button className="pr-2 py-2" onClick={goBack}>
              <IoChevronBackSharp
                size={30}
                color={tailwindConfig.theme.extend.colors.primary[200]}
              />
            </button>
          )}
          <div className="flex-col flex-1">
            {title ? (
              <h3 className="text-xl font-bold text-bgray-900 dark:text-bgray-50 lg:text-2xl lg:leading-[36.4px]">
                {title}
              </h3>
            ) : (
              <Skeleton className="dark:bg-darkblack-400" width={400} height={50} />
            )}
            {title && subTitle ? (
              <p className="text-xs font-medium text-bgray-600 dark:text-bgray-50 lg:text-sm lg:leading-[25.2px]">
                {subTitle}
              </p>
            ) : (
              <Skeleton className="dark:bg-darkblack-400" width={200} height={20} />
            )}
          </div>
        </div>

        {/* quick access */}

        <div className="quick-access-wrapper relative">
          <div className="flex items-center space-x-4 lg:space-x-6">
            <div className="hidden items-center space-x-5 sm:flex">
              <div
                onClick={() => setPopup({ ...popup, profile: false })}
                id="noti-outside"
                style={{ zIndex: 98 }}
                className={`fixed left-0 top-0  h-full w-full ${
                  popup.profile ? 'block' : 'hidden'
                }`}
              ></div>
              <ModeToggler />
              <LanguageSelect />
            </div>
            <div className="hidden h-[48px] w-[1px] bg-bgray-300 dark:bg-darkblack-400 sm:block"></div>
            {/* author */}
            <User showProfile={handlePopup} />
          </div>
          {/* notification ,message, store */}

          <ProfilePopup active={popup?.profile} handlePopup={handlePopup} />
        </div>
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  handleSidebar: ProtoTypes.func,
};

export default observer(HeaderOne);
