import { observer } from 'mobx-react';
import ForestRegistryBody from './forest-registry-body';
import { useContext, useEffect } from 'react';
import { TitleContext } from '@/component/layout';
import ForestRegistryModals from './forest-registry-modals';
import ForestRegistryHeader from './forest-registry-header';
import ForestRegistryFilters from './forest-registry-filters';
import { translationI18n } from '@/i18n';
import { userStore } from '@/store';

const ForestRegistry = () => {
  document.title = translationI18n('forestRegistry:document-title');
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const language = localStorage.getItem('language');
  useEffect(() => {
    setTitle(translationI18n('forestRegistry:title'));
    setSubTitle(translationI18n('forestRegistry:subtitle'));
  }, [language, userStore.user.language]);

  return (
    <div className="w-full min-h-full px-2 py-2 md:px-4 md:py-4 dark:bg-darkblack-700 pb-14 md:pb-14">
      <ForestRegistryHeader />
      <ForestRegistryFilters />
      <ForestRegistryBody />
      <ForestRegistryModals />
    </div>
  );
};
export default observer(ForestRegistry);
