import { IconType } from 'react-icons';

interface PrimaryOutlineButtonProps {
  icon?: IconType;
  text: string;
  onClick: () => void;
  wFull?: boolean;
}
const PrimaryOutlineButton = ({
  icon: Icon,
  text,
  onClick = () => {},
  wFull = false,
}: PrimaryOutlineButtonProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`flex px ${
        wFull ? 'w-full' : ''
      } gap-2 items-center border border-primary-200 hover:bg-primary-300  h-[40px] transition-all hover:text-white text-success-200 py-2 px-[30px] font-medium rounded-lg  text-sm `}
    >
      {Icon !== undefined ? <Icon size={25} /> : null}
      {text}
    </button>
  );
};

export default PrimaryOutlineButton;
