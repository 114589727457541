import { get, post, put } from '@/utils/helpers/api_helper';
import {
  AddCompanyDataType,
  AddCompanyResponseType,
  GetCompaniesDataType,
  GetCompaniesResponseType,
} from './master-companies-types';
import * as url from '@/utils/helpers/url_helper';

export const getCompanies = async (
  data: GetCompaniesDataType,
): Promise<GetCompaniesResponseType> => {
  const result = (await get(url.GET_COMPANYS, {
    params: data,
  })) as GetCompaniesResponseType;
  return result;
};

export const saveCompany = async (
  data: AddCompanyDataType,
): Promise<AddCompanyResponseType> => {
  const resultSave = (await post(url.POST_COMPANY, data)) as AddCompanyResponseType;
  return resultSave;
};
export const updateCompany = async (
  data: Partial<AddCompanyDataType>,
): Promise<AddCompanyResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await put(
    url.POST_COMPANY + '/' + data.id,
    data,
  )) as AddCompanyResponseType;
  return resultSave;
};
export const changeStatusCompany = async (
  data: Partial<AddCompanyDataType>,
): Promise<AddCompanyResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await put(
    url.PUT_CHANGE_STATUS + '/' + data.id,
  )) as AddCompanyResponseType;
  return resultSave;
};
