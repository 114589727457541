import Table from '@/component/Table';
import ModalAddSurvivalSurveysTime from './modal-add-survival-surveys-time';
import ModalActions from '@/component/modals/modal-actions';
import { SurvivalSurveysTimesTableControllerType } from './survival-surveys-times-types';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { IoAdd } from 'react-icons/io5';
import { translationI18n } from '@/i18n';
import { useTranslation } from 'react-i18next';
import EmptyState from '@/component/EmptyState';

const SurvivalSurveysTimesTable = ({
  controller,
}: {
  controller: SurvivalSurveysTimesTableControllerType;
}) => {
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  const {
    columns,
    page,
    size,
    totalPages,
    times,
    loadingTimes,
    order,
    setOrder,
    setPage,
    setSize,
    options,
    validation,
    modalsState,
    setModalsState,
    onDelete,
    canInsert,
  } = controller;
  // if (
  //   (!loading && survivalSurveys.evaluations.length === 0) ||
  //   Number(filtersStatus.filter((option) => option.value === filterStatus)[0]?.value) ===
  //     0
  // ) {
  //   return (
  //     <EmptyState
  //       title={tSurvivalSurveys('survivalSurveysList.table.emptyState.title')}
  //       description={tSurvivalSurveys('survivalSurveysList.table.emptyState.description')}
  //     />
  //   );
  // }

  return (
    <div className="bg-white w-[100%] h-[100%] px-4 pt-5 mb-[40px]  rounded-lg dark:bg-darkblack-600 shadow-lg">
      <div className="flex justify-end">
        {canInsert && (
          <PrimaryButton
            icon={IoAdd}
            text={translationI18n('common:actions.add')}
            onClick={() => setModalsState({ ...modalsState, add: true, time: undefined })}
            loading={false}
          />
        )}
      </div>
      {!loadingTimes && times.length === 0 ? (
        <EmptyState
          title={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.emptyState.title',
          )}
          description={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.emptyState.description',
          )}
        />
      ) : (
        <Table
          options={options}
          showSearch={false}
          columns={columns}
          page={page}
          size={size}
          totalPages={totalPages}
          data={times}
          setPage={setPage}
          setSize={setSize}
          loading={loadingTimes}
          order={order}
          setOrder={setOrder}
        />
      )}
      <ModalAddSurvivalSurveysTime
        validation={validation}
        setModalState={setModalsState}
        modalState={modalsState}
      />
      <ModalActions
        isActive={modalsState.delete}
        handleClose={() =>
          setModalsState({ ...modalsState, delete: false, time: undefined })
        }
        handleSubmit={onDelete}
        variant={'delete'}
        title={tCompanyProfile(
          'body.screens.params.items.survivalSurveys.params.timeEvaluation.deleteModal.title',
        )}
        description={tCompanyProfile(
          'body.screens.params.items.survivalSurveys.params.timeEvaluation.deleteModal.description',
        )}
        submitText={tCommon('actions.delete')}
        cancelText={tCommon('actions.cancel')}
        loading={modalsState.loading}
      />
    </div>
  );
};
export default SurvivalSurveysTimesTable;
