import React, { useRef, useState, useEffect } from 'react';
import EChartComponent from '@/component/Echart'; // Verifique o caminho correto do componente Echart
import * as echarts from 'echarts';
import { formatNumber } from '@/utils/helpers/formaters'; // Verifique o caminho correto do formatador
import Colors from '@/utils/colors'; // Verifique o caminho correto das cores
import { Skeleton } from '@mui/material';
import { dashboardForestRegistryStore, userStore } from '@/store'; // Verifique o caminho correto da store
import { observer } from 'mobx-react';
import SurvivalSurveysEmptyState from '@/pages/private/quality/silviculture/survival-surveys/list/header-results/myApprovals/EmptyState';
import _ from 'lodash';
import { monthsEnumSummary, monthsNum } from '@/utils/enums/month';
import { translationI18n } from '@/i18n';
import { useTranslation } from 'react-i18next';

interface MonthData {
  month: number;
  area: number;
}

interface YearData {
  year: number;
  totalArea: number;
  months: MonthData[];
}

const PlantedArea: React.FC = observer(() => {
  const {
    forestRegistryPlantedArea,
    loading,
    filterForestRegistry: filter,
  } = dashboardForestRegistryStore;
  const { forestReistryPlantedArea: loadingPlantedArea } = loading;

  const chartRef = useRef<HTMLDivElement>(null);
  const { theme } = userStore;
  const isDark = theme === 'dark';
  const [currentLevel, setCurrentLevel] = useState<'year' | 'month'>('year');
  const [currentYear, setCurrentYear] = useState<number | null>(null);

  // Agrupa os dados por ano e mês
  const groupedData: { [year: number]: YearData } = forestRegistryPlantedArea.reduce(
    (
      acc: { [year: number]: YearData },
      item: { month: number; year: number; area: number },
    ) => {
      if (!acc[item.year]) {
        acc[item.year] = { year: item.year, totalArea: 0, months: [] };
      }
      acc[item.year].totalArea += item.area;
      acc[item.year].months.push({ month: item.month, area: item.area });
      return acc;
    },
    {},
  );

  const years = Object.values(groupedData);

  // Opções de configuração inicial
  const getOptionConfig = (
    data: YearData[] | MonthData[],
    level: 'year' | 'month',
  ): echarts.EChartsOption => {
    return {
      legend: {
        top: 15,
        textStyle: {
          color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
          fontSize: 10,
        },
        show: false,
        data: [translationI18n('home:forestRegistry.plantedAreaChart.plantedArea')],
      },
      tooltip: {
        trigger: 'axis',
        valueFormatter: (value) => formatNumber(value, 2) + ' ha',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
      },
      xAxis: {
        type: 'category',
        data:
          level === 'year'
            ? data.map((item) => (item as YearData).year)
            : monthsNum.map((item) => monthsEnumSummary()[item]),
        axisLine: {
          lineStyle: { color: isDark ? Colors.bgray[200] : Colors.darkblack[600] },
        },
        axisLabel: {
          show: true,
          fontSize: 10,
          color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
        },
      },
      axisPointer: {
        type: 'shadow',
      },
      yAxis: [
        {
          nameLocation: 'middle',
          nameGap: 5,
          show: false,
          type: 'value',
          name: translationI18n('home:forestRegistry.plantedAreaChart.plantedArea'),
          axisLine: {
            show: false,
          },
          interval: 50,
          axisLabel: {
            show: false,
          },
        },
      ],
      series: [
        {
          type: 'bar',
          name: translationI18n('home:forestRegistry.plantedAreaChart.plantedArea'),
          color: Colors.primary[200],
          label: {
            show: true,
            rotate: 90,
            padding: 8,
            fontSize: 12,
            align: 'left',
            color: theme === 'dark' ? 'white' : 'black',
            position: 'insideEnd',
            formatter: (value) =>
              value.data && Number(value.data) > 0
                ? `${formatNumber(value.data, 0)} ha`
                : '',
          },
          data:
            level === 'year'
              ? data.map((item) => (item as YearData).totalArea)
              : monthsNum.map(
                  (monthNum) =>
                    data.find((item) => (item as MonthData).month === monthNum)?.area ||
                    0,
                ),
        },
      ],
      animation: false,
      height: '110',
      darkMode: isDark,
    };
  };

  const handleDrilldown = (params: { name: any }) => {
    if (currentLevel === 'year') {
      setCurrentLevel('month');
      setCurrentYear(Number(params.name));
    }
  };

  const handleBack = () => {
    setCurrentLevel('year');
    setCurrentYear(null);
  };

  const optionConfig: echarts.EChartsOption =
    currentLevel === 'year'
      ? getOptionConfig(years, 'year')
      : getOptionConfig(
          _.sortBy(groupedData[currentYear as number]?.months, 'month'),
          'month',
        );

  useEffect(() => {
    handleBack();
  }, [filter]);
  const tHome = useTranslation('home').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="rounded-lg bg-white py-3 gap-3 items-center flex-col h-full flex dark:bg-darkblack-500 lg:flex-[0.66] shadow-lg">
      <div className="flex justify-between items-center w-full px-3">
        <p className="dark:text-white font-bold">
          {`${tHome('forestRegistry.plantedAreaChart.plantedBy')} ${currentLevel === 'year' ? tHome('forestRegistry.plantedAreaChart.year') : tHome('forestRegistry.plantedAreaChart.monthOf')} ${currentLevel === 'month' ? String(currentYear) : ''}`}
        </p>
        {currentLevel === 'month' && (
          <button
            className="text-sm bg-bgray-200 rounded px-3 py-1 flex items-center gap-1"
            onClick={handleBack}
          >
            {tCommon('actions.back')}
          </button>
        )}
      </div>
      <div className="w-full h-[190px] flex-col relative">
        {loadingPlantedArea ? (
          <div className="h-full px-5 py-2">
            <Skeleton
              variant="rectangular"
              className="rounded dark:bg-darkblack-400"
              height={'190px'}
              width={'100%'}
            />
          </div>
        ) : forestRegistryPlantedArea.length === 0 ? (
          <SurvivalSurveysEmptyState description={tHome('forestRegistry.emptyChart')} />
        ) : (
          <EChartComponent
            chartRef={chartRef}
            handleClick={handleDrilldown}
            option={optionConfig}
            style={{
              width: '100%',
              zIndex: 0,
              height: '100%',
            }}
          />
        )}
      </div>
    </div>
  );
});

export default PlantedArea;
