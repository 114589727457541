import { observer } from 'mobx-react';
import { PlantedAreaNoteInterface } from '../../../../planted-area-types';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '@/utils/helpers/formaters';

export const PlantedAreaModalEditHeader = observer(
  ({ note }: { note: PlantedAreaNoteInterface }) => {
    const tPlantedArea = useTranslation('plantedArea').t;
    const tCommon = useTranslation('common').t;

    const Item = ({
      value,
      label,
      percentage,
    }: {
      value: number;
      label: string;
      percentage: number;
    }) => (
      <div>
        <div className="font-semibold text-2xl dark:text-white">
          {`${formatNumber(value, 2)}${tCommon('ha')}`}
        </div>
        <div className="text-base text-primary-200">{label}</div>
        <div className="text-base dark:text-white">{formatNumber(percentage, 1)}%</div>
      </div>
    );
    const plantedArea =
      Number(note.forestRegistry.plantedArea) - (note?.error ? 0 : Number(note?.area));
    const avaliableArea = Number(note?.forestRegistry.area) - plantedArea;
    return (
      <div className="bg-bgray-200 dark:bg-darkblack-500 text-center p-1 rounded-lg">
        <div className="text-xl font-semibold dark:text-white">
          {tPlantedArea('listNotes.modals.modalEdit.infos.title')}
        </div>
        <div className="grid grid-cols-3">
          <Item
            value={note?.forestRegistry.area}
            label={tPlantedArea('listNotes.modals.modalEdit.infos.total')}
            percentage={100}
          />
          <Item
            value={plantedArea}
            label={tPlantedArea('listNotes.modals.modalEdit.infos.planted')}
            percentage={(plantedArea / Number(note?.forestRegistry.area)) * 100}
          />
          <Item
            value={avaliableArea}
            label={tPlantedArea('listNotes.modals.modalEdit.infos.avaliable')}
            percentage={(avaliableArea / Number(note?.forestRegistry.area)) * 100}
          />
        </div>
      </div>
    );
  },
);
