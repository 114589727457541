import servicesParamsController from './master-params-controller';
import EmptyStateServicesParams from './contents/empty-state';
import ParamsComponent from './items';
import ParamsTable from './contents';
import { observer } from 'mobx-react';

const ParamsPage = () => {
  const { activeParam, setActiveParam } = servicesParamsController();
  return (
    <div className="w-full min-h-full px-2 py-2  md:px-4 md:py-4 dark:bg-darkblack-700">
      <div className="w-full lg:flex lg:space-x-5 mt-5 pb-14">
        <div className="bg-white lg:w-[30%] px-4 py-3  rounded-lg dark:bg-darkblack-600 shadow-lg">
          <ParamsComponent activeParam={activeParam} setActiveParam={setActiveParam} />
        </div>
        <div className="lg:w-[70%] lg:mt-0 mt-5 space-y-5 shadow-lg rounded-lg">
          {activeParam !== '' ? (
            <ParamsTable activeParam={activeParam} />
          ) : (
            <EmptyStateServicesParams />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ParamsPage);
