import maplibregl, { LngLatLike } from 'maplibre-gl';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MapStateType } from './map-types';
import { dashboardSurvivalSurveysStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { createPolygons } from './create-geojson';
import Colors from '@/utils/colors';
import LegendeMap from '@/pages/private/quality/silviculture/survival-surveys/details/body/evaluation-results/details-map/details-map-legend';
import SelectEvaluationTime from './map-select-evaluation-time';
import EmptyState from '@/component/EmptyState';
import * as turf from '@turf/turf';
import { styleMapLibre } from '@/utils/geo';
import { useTranslation } from 'react-i18next';

const Map = observer(() => {
  const {
    survivalSurveysDataMap,
    loading: loadings,
    getSuvivalSurveysDataMap,
    filterSurvivalSurveys,
    survivalSurveyEvaluationMapFilter,
  } = dashboardSurvivalSurveysStore;
  const tHome = useTranslation('home').t;
  const { filtersMap: loading } = loadings;
  const mapContainerRef = useRef(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const [mapState, setMapState] = useState<MapStateType>({
    center: [-52.842663, -20.504497],
    zoom: 20,
    loaded: false,
  });

  const survivalTarget = survivalSurveysDataMap[0]?.survivalTarget || 0;
  useMemo(() => {
    if (survivalSurveyEvaluationMapFilter !== 0) {
      getSuvivalSurveysDataMap();
    }
  }, [
    filterSurvivalSurveys,
    userStore.companyActive.id,
    survivalSurveyEvaluationMapFilter,
  ]);

  useEffect(() => {
    if (mapContainerRef.current && survivalSurveysDataMap.length) {
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [mapContainerRef.current, survivalSurveysDataMap]);

  function addLayers(map: maplibregl.Map) {
    map.addSource('forestRegistry-source', {
      type: 'geojson',
      data: createPolygons(survivalSurveysDataMap),
    });
    map.addLayer({
      source: 'forestRegistry-source',
      paint: {
        'line-color': 'white',
        'line-width': 2,
      },
      id: 'forestRegistry-line',
      type: 'line',
    });
    map.addLayer({
      source: {
        type: 'geojson',
        data: turf.featureCollection(
          survivalSurveysDataMap.flatMap((data) => data.interpolated),
        ),
      },
      paint: {
        'fill-color': [
          'step',
          ['get', 'value'],
          Colors.error[300],
          (survivalTarget - 12) / 100,
          Colors.error[300],
          (survivalTarget - 9) / 100,
          Colors.error[200],
          (survivalTarget - 6) / 100,
          Colors.warning[200],
          (survivalTarget - 3) / 100,
          Colors.primary[200],
          survivalTarget / 100,
          Colors.primary[300],
        ],
      },
      id: 'interpolatedGrid-fill',
      type: 'fill',
    });
    map.addLayer({
      source: 'forestRegistry-source',
      id: 'forestRegistry-symbol',
      type: 'symbol',
      layout: {
        'text-field': ['get', 'label'],
        'text-size': 14,
        'text-font': ['Arial-Regular'],
        'text-anchor': 'center',
        'text-offset': [0, 0],
      },
      paint: {
        'text-color': 'white',
      },
    });
  }

  function zoomFeatures(map: maplibregl.Map) {
    const bounds = new maplibregl.LngLatBounds();
    survivalSurveysDataMap.forEach((forestRegistry) => {
      forestRegistry.coordinates.forEach((polygon) => {
        polygon.coordinates.forEach((coord) =>
          bounds.extend([coord.lng, coord.lat] as LngLatLike),
        );
      });
    });
    map.fitBounds(bounds, { padding: 20, animate: false });
    const zoomActual = map.getZoom();
    map.setZoom(zoomActual > 16 ? 16 : map.getZoom());
    setMapState({
      center: [map.getCenter().lng, map.getCenter().lat],
      zoom: zoomActual > 16 ? 16 : map.getZoom(),
      loaded: true,
    });
  }

  function updateSources(map: maplibregl.Map) {
    const dotsDeath = createPolygons(survivalSurveysDataMap);
    map.getSource('forestRegistry-source')?.setData(dotsDeath);
    zoomFeatures(map);
  }

  const initializeMap = () => {
    if (mapRef.current) {
      return updateSources(mapRef.current);
    }
    try {
      const map = new maplibregl.Map({
        container: mapContainerRef.current,
        attributionControl: false,
        style: styleMapLibre,
        center: mapState.center,
        zoom: mapState.zoom,
        preserveDrawingBuffer: true,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        localIdeographFontFamily: 'Noto',
      });
      map.addControl(new maplibregl.NavigationControl(), 'top-left');

      map.on('load', () => {
        addLayers(map);

        zoomFeatures(map);

        map.on('zoomend', () => {
          setMapState({
            center: [map.getCenter().lng, map.getCenter().lat],
            zoom: map.getZoom(),
            loaded: true,
          });
        });
        map.on('moveend', () => {
          setMapState({
            center: [map.getCenter().lng, map.getCenter().lat],
            zoom: map.getZoom(),
            loaded: true,
          });
        });
      });

      mapRef.current = map;
    } catch (error: any) {
      console.error(error.message);
    }
  };
  return (
    <div className="flex flex-1 shadow-lg">
      <div className="relative rounded-lg w-full dark:text-bgray-300 min-h-[450px]">
        <div
          className="absolute top-0 left-0 right-0 bottom-0 items-center flex justify-center bg-[#DFE0E3] opacity-50 rounded-lg text-darkblack-600 text-4xl"
          style={{ zIndex: 2, display: loading ? 'flex' : 'none' }}
        >
          {tHome('survivalSurveys.map.loading')}
        </div>
        {survivalSurveysDataMap.length > 0 && !loading && (
          <LegendeMap survivalTarget={survivalTarget} />
        )}
        <SelectEvaluationTime />

        {survivalSurveysDataMap.length > 0 && (
          <div
            ref={mapContainerRef}
            style={{ height: '450px', zIndex: 1 }}
            className="rounded-lg w-full bg-bgray-200"
          />
        )}
        {survivalSurveysDataMap.length === 0 && !loading && (
          <div className="rounded-lg w-full bg-bgray-200">
            <EmptyState
              title={tHome('survivalSurveys.map.emptyData.title')}
              description={tHome('survivalSurveys.map.emptyData.description')}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default Map;
