import React, { useContext, useEffect, useRef } from 'react';
import DetailsHeader from './header';
import DetailsBody from './body';
import ModalApprove from '../modals/modal-approve';
import ModalRepprove from '../modals/modal-repprove';
import ModalHistoryApprovals from '../modals/modal-history';
import { TitleContext } from '@/component/layout';
import { useParams } from 'react-router-dom';
import Error from '@/pages/public/error';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';

const SurvivalSurveysDetails = () => {
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const { getEvaluationDetails, evaluationNotFound, handleEvaluationNotFound } =
    survivalSurveysEvaluationsStore;
  const { cod } = useParams();

  const detailsBodyRef = useRef(null);

  async function getDetails() {
    await getEvaluationDetails(Number(cod));
  }

  useEffect(() => {
    getDetails();
    return () => {
      handleEvaluationNotFound(false);
    };
  }, [cod]);
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  useEffect(() => {
    setTitle(
      evaluationNotFound
        ? tSurvivalSurveys('details.titleNotFound')
        : tSurvivalSurveys('details.title'),
    );
    setSubTitle(evaluationNotFound ? ' ' : tSurvivalSurveys('details.subtitle'));
  }, [evaluationNotFound]);

  if (evaluationNotFound) {
    return <Error />;
  }

  const handleSaveAsImage = () => {
    if (detailsBodyRef.current) {
      html2canvas(detailsBodyRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.download = 'details-body.png';
        link.href = canvas.toDataURL('image/png');
        link.click();
      });
    }
  };

  return (
    <div className="w-full relative  min-h-full px-2 py-2 md:px-4 md:py-4 dark:bg-darkblack-700 ">
      <DetailsHeader onSaveImage={handleSaveAsImage} />
      <div ref={detailsBodyRef}>
        <DetailsBody />
      </div>
      <ModalApprove />
      <ModalRepprove />
      <ModalHistoryApprovals />
    </div>
  );
};

export default observer(SurvivalSurveysDetails);
