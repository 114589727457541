import { post } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import { CodeVerifyDataType, NewPasswordDataType } from './left-side-signin-types';

export const requestPasswordRecovery = async (
  email: string,
): Promise<{ message: string }> => {
  return await post(url.REQUEST_PASSWORD_RECOVERY, { email: email });
};

export const codeVerify = async (data: CodeVerifyDataType) => {
  return await post(url.CODE_VERIFY, data);
};
export const requestNewPassword = async (data: NewPasswordDataType) => {
  return await post(url.NEW_PASSWORD, data);
};
