import LoaderItems from './LoaderItems';
import PlantedAreaReasonsItemTable from './table';
import { PlantedAreaReasonsTableControllerType } from './types';

const PlantedAreaReasonsTable = ({
  controller,
}: {
  controller: PlantedAreaReasonsTableControllerType;
}) => {
  const { reasons, loadingReasons, onHandleActive, loadingUpdateReason, canInsert } =
    controller;
  return loadingReasons ? (
    <LoaderItems loading={loadingReasons} />
  ) : (
    <PlantedAreaReasonsItemTable
      canInsert={canInsert}
      onHandleActive={onHandleActive}
      loadingUpdateReason={loadingUpdateReason}
      reasons={reasons}
    />
  );
};
export default PlantedAreaReasonsTable;
