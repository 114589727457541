import theme from '../../tailwind.config';
const { colors } = theme.theme.extend;
const Colors = colors;
export default Colors;

type Color = {
  hex: string;
  rgb: [number, number, number];
  valueMin: number;
  valueMax: number;
};

const palette: Color[] = [
  {
    hex: Colors.error[300],
    rgb: [222, 26, 36],
    valueMin: 0.8,
    valueMax: 1,
  },
  {
    hex: Colors.error[200],
    rgb: [191, 16, 41],
    valueMin: 0.6,
    valueMax: 0.8,
  },
  {
    hex: Colors.warning[300],
    rgb: [117, 145, 22],
    valueMin: 0.4,
    valueMax: 0.6,
  },
  {
    hex: Colors.success[200],
    rgb: [63, 143, 41],
    valueMin: 0.2,
    valueMax: 0.4,
  },
  {
    hex: Colors.success[300],
    rgb: [5, 101, 23],
    valueMin: 0,
    valueMax: 0.2,
  },
];
export function hexToRgbE(hex: string): [number, number, number] {
  const rgb = hex.match(/[0-9a-f]{2}/gi)?.map((x) => parseInt(x, 16));
  if (!rgb || rgb.length !== 3) {
    throw new Error('Invalid hex color format');
  }
  return [rgb[0], rgb[1], rgb[2]];
}

export function rgbToHex(r: number, g: number, b: number): string {
  return (
    '#' +
    [r, g, b]
      .map((x) => x.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase()
  );
}

export function interpolateColorInPalette(value: number): [number, number, number] {
  // Clamping the value between 0 and 1
  if (value > 1) value = 1;
  if (value < 0) value = 0;

  // Find the colors to interpolate between
  const colorRange = palette.find((c) => c.valueMin <= value && value <= c.valueMax);

  if (!colorRange) {
    throw new Error('Value out of range of palette');
  }

  return hexToRgbE(colorRange.hex);
}
