import { translationI18n } from '@/i18n';
import html2canvas from 'html2canvas';

const exportAsImage = async (el: HTMLElement, imageFileName: string) => {
  // Adiciona um atraso para garantir que fontes e outros recursos estejam carregados
  await new Promise((resolve) => setTimeout(resolve, 1500));

  const canvas = await html2canvas(el, {
    useCORS: false, // Habilita o uso de CORS para permitir capturar conteúdo de outros domínios
    scale: 2, // Aumenta a escala para capturar imagens de maior resolução
    // logging: true, // Ativa o logging para depuração
    ignoreElements: (element) => {
      return element.classList.contains('ignore-me');
    },
  });
  const image = canvas.toDataURL('image/png', 1.0);
  downloadImage(image, imageFileName);
};

const downloadImage = (blob: string, fileName: string) => {
  const fakeLink = window.document.createElement('a');
  fakeLink.style.display = 'none';
  fakeLink.download = fileName;
  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};
export const handleDownloadImageByUrl = async (
  url: string,
  imageName: string = translationI18n('common:image'),
) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(translationI18n('general:errorImageDownload'));
    }

    // Extrair o tipo de conteúdo e determinar a extensão
    const contentType = response.headers.get('Content-Type');
    const extension = contentType?.split('/')[1] || 'jpg'; // Padrão para 'jpg' caso a extensão não seja identificada

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = `${imageName}.${extension}`; // Nome do arquivo com a extensão identificada
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Libera o objeto URL da memória
    window.URL.revokeObjectURL(objectUrl);
  } catch (error: any) {
    console.error(error.message);
    console.log(error);
  }
};

export { exportAsImage };
