import FileUploadKMLIcon from '@/assets/images/icons/fileUploadKMLIcon';
import { Toast } from '@/component/Toast';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
const { showError } = Toast;
const DropZoneFileKML = ({
  handleFileSelection,
}: {
  handleFileSelection: (file: File) => void;
}) => {
  const tForestRegistry = useTranslation('forestRegistry').t;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const acceptedExtensions = ['.kml', '.kmz']; // Adicione qualquer outra extensão permitida aqui, se necessário
      const fileExtension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      if (acceptedExtensions.includes(fileExtension)) {
        handleFileSelection(file);
      } else {
        showError({
          message: tForestRegistry('modals.uploadKML.errorFileFormat'),
        });
      }
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { onClick } = getRootProps();
  return (
    <div className="hover:cursor-pointer mt-5 py-10 dark:bg-darkblack-400 bg-bgray-100 border-dashed border-2 flex items-center justify-center rounded-lg px-10">
      <input
        {...getInputProps()}
        type="file"
        // accept="application/vnd.google-earth.kml+xml"
      />
      {isDragActive ? (
        <p className="font-bold my-15 text-3xl text-bgray-600 dark:text-bgray-300">
          {tForestRegistry('modals.uploadKML.dropFileHere')}
        </p>
      ) : (
        <div className="justify-center items-center flex-col" onClick={onClick}>
          <div className="flex items-center justify-center mb-3">
            <FileUploadKMLIcon />
          </div>
          <p className="text-center text-success-200 font-bold">
            {tForestRegistry('modals.uploadKML.selectFileKml')}
          </p>
          <p className="text-center text-sm font-medium mt-2 text-bgray-600 dark:text-bgray-300">
            {tForestRegistry('modals.uploadKML.orDragAndDropHere')}
          </p>
        </div>
      )}
    </div>
  );
};
export default DropZoneFileKML;
