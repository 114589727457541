import { ColumnsTypes, RowType } from './types';
import moment from 'moment';
import get from 'lodash/get';
import PopupOptions from '../PopupOptions';
import { Chip } from '@mui/material';

function Row(rowData: RowType) {
  const {
    row,
    columns,
    onPress = () => {},
    clickable = false,
    options,
    selectable = false,
    selected = false,
    onSelect = () => {},
    disableSelectRow = () => false,
  } = rowData;

  const renderValue = (column: ColumnsTypes, index: number) => {
    const columnType = column.type;
    let value: string | number | undefined | JSX.Element = '';

    const Avatar = ({ imageUrl, text = '' }: { imageUrl: string; text: string }) => {
      const partsOfText = text.split(' ').filter((l) => l);
      const firstLetter = partsOfText[0][0];
      const lastLetter =
        partsOfText.length > 1 ? partsOfText[partsOfText.length - 1][0] : '';
      const letters = firstLetter + lastLetter || '';
      return (
        <div className="w-[40px] h-[40px]">
          {imageUrl.length > 10 ? (
            <img
              className="w-full h-full object-cover rounded-lg"
              src={imageUrl}
              alt=""
            />
          ) : (
            <div className="w-[40px] h-[40px] bg-bgray-200 rounded-lg flex justify-center items-center text-center text-lg text-bgray-600">
              {letters}
            </div>
          )}
        </div>
      );
    };

    switch (columnType) {
      case 'datetime':
        value = row[column.field]
          ? moment(row[column.field]).format('DD/MM/YYYY HH:mm:ss')
          : '';
        break;
      case 'date':
        value = moment(row[column.field]).isValid()
          ? moment(row[column.field]).format('DD/MM/YYYY')
          : '';
        break;
      case 'percetage_decimal':
        value = `${Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(Number(row[column.field]) * 100)}%`;
        break;
      case 'percetage_integer':
        value = `${Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(Number(row[column.field]))}%`;
        break;
      case 'active':
        value = (
          <Chip
            label={get(row, column.field) ? 'Ativo' : 'Inativo'}
            color={get(row, column.field) ? 'success' : 'error'}
            size="small"
          />
        );
        break;
      case 'boolean':
        value = get(row, column.field) ? 'Sim' : 'Não';
        break;
      case 'options':
        value = <PopupOptions options={options} row={row} />;
        break;
      case 'int':
        value = Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(Number(get(row, column.field)));
        break;
      case 'float':
        value = Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(Number(get(row, column.field)));
        break;
      case 'avatar':
        value = (
          <Avatar
            imageUrl={String(get(row, column.field))}
            text={String(get(row, column.alternativeForImage || ''))}
          />
        );
        break;
      case 'custom':
        value = column?.custom && column.custom(row);
        break;
      default:
        value = get(row, column.field);
        break;
    }

    return (
      <td
        key={'rowColumn_' + column.field + index}
        className={`py-1 px-3 dark:text-white ${
          ['options'].includes(columnType) ? 'text-center' : ''
        }`}
        style={{
          cursor: clickable && column.type !== 'options' ? 'pointer' : 'default',
        }}
        onClick={() => (columnType !== 'options' ? onPress() : {})}
      >
        {['options', 'custom', 'avatar', 'active'].includes(columnType) ? (
          value
        ) : (
          <p className="text-base font-semibold text-bgray-900 dark:text-white">
            {value}
          </p>
        )}
      </td>
    );
  };

  return (
    <tr
      className={`border-b border-bgray-400 dark:border-darkblack-400 ${
        selected ? 'bg-bgray-200 dark:bg-darkblack-400' : ''
      }`}
    >
      {selectable && (
        <td
          className="py-1 px-3 text-center"
          onClick={selectable && !disableSelectRow(row) ? onSelect : undefined}
        >
          <input
            type="checkbox"
            disabled={disableSelectRow(row)}
            className={`cursor-pointer text-primary-200 rounded ${disableSelectRow(row) ? 'opacity-10' : ''}`}
            checked={selected}
            onChange={() => {}}
          />
        </td>
      )}
      {columns.map((column, index) => renderValue(column, index))}
    </tr>
  );
}

export default Row;
