import { get, post, postFile, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  AddDataForestRegistry,
  GetForestRegistryDataType,
  GetOptionsForestRegistryDataRequestType,
  GetOptionsForestRegistryDataResponseType,
  ResponseForestRegistryPostAndPutType,
  udpateCoordinatesDataType,
  udpateListCoordinatesDataType,
  ValidExcelResponseType,
  ValidKmlResponseType,
} from './forest-registry-types';
import {
  forestRegistryAllType,
  forestRegistryItemType,
  SummaryByYearDataType,
  ForestRegistrySummaryType,
} from '@/utils/types/ForestRegistryType';
import moment from 'moment';
import ExcelJS from 'exceljs';
import { ColumnsStateType } from './forest-registry-modals/modal-import-kml/select-columns/types';
import { translationI18n } from '@/i18n';

export async function getForestRegistry(
  data: GetForestRegistryDataType,
): Promise<forestRegistryAllType> {
  return await get(url.GET_FOREST_REGISTRY, { params: data });
}
export async function getSummary(data: {
  companyId?: number;
}): Promise<ForestRegistrySummaryType> {
  return await get(url.GET_FOREST_REGISTRY_SUMMARY, { params: data });
}
export async function getSummaryByYear(data: {
  companyId?: number;
  year?: number;
}): Promise<SummaryByYearDataType> {
  return await get(url.GET_FOREST_REGISTRY_SUMMARY_BY_YEAR, { params: data });
}
export async function getOptions(
  data: GetOptionsForestRegistryDataRequestType,
  column: 'fazendas' | 'regioes',
): Promise<GetOptionsForestRegistryDataResponseType> {
  return await get(url.GET_FOREST_REGISTRY_OPTIONS + column, { params: data });
}
export async function postForestRegistry(
  data: AddDataForestRegistry,
): Promise<ResponseForestRegistryPostAndPutType> {
  return await post(url.GET_FOREST_REGISTRY, data);
}
export async function updateListCoordinates(
  data: udpateListCoordinatesDataType,
): Promise<number> {
  return await put(url.POST_FOREST_REGISTRY_UPDATE_LIST_COORDINATES, data);
}
export async function updateCoordinates(
  data: udpateCoordinatesDataType,
): Promise<{ message: string; data: forestRegistryItemType }> {
  return await put(url.POST_FOREST_REGISTRY_UPDATE_COORDINATES + `/` + data.id, data);
}
export async function putForestRegistry(
  data: AddDataForestRegistry,
): Promise<ResponseForestRegistryPostAndPutType> {
  return await put(url.GET_FOREST_REGISTRY + '/' + data?.id, data);
}
export async function changeStatusForestRegistry(
  id: number,
): Promise<ResponseForestRegistryPostAndPutType> {
  return await put(url.FOREST_REGITRY_CHANGE_STATUS + '/' + id);
}
export async function validExcel(
  file: File,
  companyId?: number,
): Promise<ValidExcelResponseType> {
  const formData = new FormData();
  formData.append('file', file);
  if (companyId) formData.append('companyId', companyId.toString());
  const response = (await postFile(
    url.POST_FOREST_REGISTRY_VALID_FILE_EXCEL_IMPORT,
    formData,
  )) as ValidExcelResponseType;
  return response;
}
export async function validKml(
  file: File,
  columns: ColumnsStateType,
  companyId?: number,
): Promise<ValidKmlResponseType> {
  const formData = new FormData();
  formData.append('file', file);
  if (companyId) formData.append('companyId', companyId.toString());
  if (columns.fazenda) formData.append('fazenda', columns.fazenda.toString());
  if (columns.talhao) formData.append('talhao', columns.talhao.toString());
  const response = (await postFile(
    url.POST_FOREST_REGISTRY_VALID_FILE_KML_IMPORT,
    formData,
  )) as ValidKmlResponseType;
  return response;
}
export async function uploadExcel(
  file: File,
  companyId?: number,
): Promise<ValidExcelResponseType> {
  const formData = new FormData();
  formData.append('file', file);
  if (companyId) formData.append('companyId', companyId.toString());
  const response = (await postFile(
    url.POST_FOREST_REGISTRY_UPLOAD_EXCEL_IMPORT,
    formData,
  )) as ValidExcelResponseType;
  return response;
}

// api/forestRegistry/validExcel

export const downloadExcel = (
  data: Partial<forestRegistryItemType>[],
  fileNameExport: string,
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    fileNameExport + '_' + moment().format('DD_MM_YY'),
  );

  // Adicione dados ao arquivo Excel
  worksheet.addRow(Object.keys(data[0]));
  data.forEach((row) => {
    worksheet.addRow(
      Object.keys(row).map((key) => row[key as keyof forestRegistryItemType]),
    );
  });

  // Gere o arquivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    // Crie um blob a partir do buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Crie um URL para o blob
    const url = window.URL.createObjectURL(blob);

    // Crie um link para o URL e simule o clique para iniciar o download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileNameExport + '_' + moment().format('DD_MM_YY') + '.xlsx',
    );
    document.body.appendChild(link);
    link.click();

    // Limpe o URL e remova o link do corpo
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};
export const generateExcelExemple = () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Exemplo');

  // Adicione os cabeçalhos ao arquivo Excel
  worksheet.addRow([
    'registryCompanyId',
    'regiao',
    'fazenda',
    'talhao',
    'ciclo',
    'rotacao',
    'dataPlantio',
    'manejo',
    'area',
    'spacingBetweenLines',
    'spacingBetweenPlants',
    'geneticMaterial',
  ]);

  // Adicione os dados ao arquivo Excel
  worksheet.addRow([
    32592,
    'Região 1',
    'Fazenda 1',
    '11',
    2,
    1,
    '16/10/2016',
    'Reforma',
    32.69,
    3.4,
    2.3,
    'I144',
  ]);
  worksheet.addRow([
    51045,
    'Região',
    'Fazenda 1',
    '11A',
    2,
    1,
    '16/10/2016',
    'Reforma',
    16.61,
    3.4,
    2.3,
    'I144',
  ]);
  worksheet.addRow([
    32593,
    'Região',
    'Fazenda 1',
    '12',
    2,
    1,
    '17/10/2016',
    'Reforma',
    36.08,
    3.4,
    2.3,
    'I144',
  ]);

  // Gere o arquivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    // Crie um blob a partir do buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Crie um URL para o blob
    const url = window.URL.createObjectURL(blob);

    // Crie um link para o URL e simule o clique para iniciar o download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', translationI18n('forestRegistry:fileDownloadName'));
    document.body.appendChild(link);
    link.click();

    // Limpe o URL e remova o link do corpo
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};
