import PlotsRegisteredIcon from '@/assets/images/icons/forestRegistry/PlotsRegisteredIcon';
import CardLoader from './CardLoader';
import { forestRegistryStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const CardPlotsRegistereds = observer(() => {
  const { loadingSummaryData, summary } = forestRegistryStore;
  const tForestRegistry = useTranslation('forestRegistry').t;
  if (loadingSummaryData) {
    return <CardLoader />;
  }
  return (
    <div className="bg-white rounded-lg p-3 flex-1 dark:bg-darkblack-500 shadow-lg">
      <div className="flex gap-3 items-center">
        <PlotsRegisteredIcon />
        <p className="text-nowrap dark:text-bgray-200">
          {summary.nPlots === 1
            ? tForestRegistry('header.plotsRegistered.fieldRegistered')
            : tForestRegistry('header.plotsRegistered.fieldsRegistered')}
        </p>
      </div>
      <p className="text-5xl font-bold dark:text-bgray-200">{`${formatNumber(summary.nPlots, 0)}`}</p>
      <div className="flex gap-1 text-bgray-500">
        <p className="text-success-200">{`${formatNumber(summary.totalArea, 0)} ha`}</p>
        <p>{tForestRegistry('header.plotsRegistered.registereds')}</p>
      </div>
    </div>
  );
});
export default CardPlotsRegistereds;
