import { IconType } from 'react-icons';
import { ScaleLoader } from 'react-spinners';

interface SmallPrimaryButtonProps {
  icon?: IconType;
  text: string;
  onClick: () => void;
  loading: boolean;
  wFull?: boolean;
  disabled?: boolean;
}
const SmallPrimaryButton = ({
  icon: Icon,
  text,
  loading = false,
  onClick = () => {},
  wFull = false,
  disabled = false,
}: SmallPrimaryButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={loading || disabled}
      style={{
        textWrap: 'nowrap',
      }}
      type="button"
      className={`flex px ${
        wFull ? 'w-full' : ''
      } gap-2 items-center bg-primary-200 hover:bg-primary-300 h-[30px] transition-all text-white py-2 px-[30px] font-bold rounded-lg text-xs`}
    >
      {loading && (
        <ScaleLoader loading={loading} color={'white'} height={10} className="px-4" />
      )}
      {Icon !== undefined && !loading ? <Icon size={20} /> : null}
      {!loading ? text : null}
    </button>
  );
};

export default SmallPrimaryButton;
