import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Toast } from '@/component/Toast';
import {
  AddDataForestRegistry,
  FiltersForestRegistryType,
  InputOptionsStateType,
  ModalStateType,
  screensType,
  udpateCoordinatesDataType,
} from './forest-registry-types';
import { StateTableParams } from '@/component/Table/types';
import {
  initialStateInputOptions,
  initialStateListForestRegistry,
  inititalStateFilters,
  inititalStateModal,
  inititalStateParamsTable,
  inititalStateSummaryData,
} from './forest-registry-initial-states';
import {
  forestRegistryAllType,
  forestRegistryItemType,
  SummaryByYearDataType,
  ForestRegistrySummaryType,
} from '@/utils/types/ForestRegistryType';
import {
  changeStatusForestRegistry,
  downloadExcel,
  getForestRegistry,
  getOptions,
  getSummary,
  getSummaryByYear,
  postForestRegistry,
  putForestRegistry,
  updateCoordinates,
  uploadExcel,
} from './forest-registry-services';
import { formatNumber } from '@/utils/helpers/formaters';
import { userStore } from '@/store';
import moment from 'moment';
const { showSuccess } = Toast;
export class ForestRegistryStore {
  constructor() {
    makeAutoObservable(this);
    makeObservable(this);
    void makePersistable(this, {
      name: 'ForestReistryStore',
      properties: [],
    });
  }
  screenActive: screensType = 'table';
  yearSelected: number = moment().year();
  summary: ForestRegistrySummaryType = inititalStateSummaryData;
  summaryByYear: SummaryByYearDataType = {
    yearsFilter: [],
    months: [],
  };
  loadingSummaryData: boolean = false;
  loadingSummaryByYearData: boolean = false;
  tableParams: StateTableParams = inititalStateParamsTable;
  forestRegistryData: forestRegistryAllType = initialStateListForestRegistry;
  forestRegistryMapData: forestRegistryAllType = initialStateListForestRegistry;
  filter: FiltersForestRegistryType = inititalStateFilters;
  loading: boolean = false;
  loadingExport: boolean = false;
  modalsState: ModalStateType = inititalStateModal;
  inputOptions: InputOptionsStateType = initialStateInputOptions;

  reset = () => {
    this.screenActive = 'table';
    this.yearSelected = moment().year();
    this.summary = inititalStateSummaryData;
    this.summaryByYear = {
      yearsFilter: [],
      months: [],
    };
    this.loadingSummaryData = false;
    this.loadingSummaryByYearData = false;
    this.tableParams = inititalStateParamsTable;
    this.forestRegistryData = initialStateListForestRegistry;
    this.forestRegistryMapData = initialStateListForestRegistry;
    this.filter = inititalStateFilters;
    this.loading = false;
    this.loadingExport = false;
    this.modalsState = inititalStateModal;
    this.inputOptions = initialStateInputOptions;
  };

  getForestRegistryData = async () => {
    this.handleLoading();
    try {
      const response = await getForestRegistry({
        ...this.filter,
        ...this.tableParams,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.forestRegistryData = {
          ...response,
          forestRegisters: response.forestRegisters.map((item) => {
            return {
              ...item,
              spacing: `${formatNumber(item.spacingBetweenLines, 2)}${formatNumber(
                item.spacingBetweenPlants,
                2,
              )}`,
            };
          }),
        };
      });
      this.handleLoading();
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading();
    }
  };

  getForestRegistryDataExport = async () => {
    // this.handleLoading();
    this.handleLoadingExport();
    try {
      const response = await getForestRegistry({
        ...this.filter,
        ...this.tableParams,
        size: this.forestRegistryData.totalItems,
        companyId: userStore.companyActive.id,
      });
      downloadExcel(
        response.forestRegisters.map((item) => {
          const ret = {
            ...item,
            companyId: undefined,
            company: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            active: undefined,
            coordinates: undefined,
            status: item.active ? 'Ativo' : 'Inativo',
          };
          delete ret.company;
          delete ret.companyId;
          delete ret.createdAt;
          delete ret.updatedAt;
          delete ret.active;
          delete ret.coordinates;
          return ret;
        }),
        'Cadastro_Florestal',
      );
      this.handleLoadingExport();
    } catch (error: any) {
      console.error(error.message);
      this.handleLoadingExport();
    }
  };

  getForestRegistryMapData = async () => {
    this.handleLoading();
    try {
      const response = await getForestRegistry({
        ...this.filter,
        ...this.tableParams,
        size: this.forestRegistryData.totalItems,
        companyId: userStore.companyActive.id,
        dataMap: true,
      });
      runInAction(() => {
        this.forestRegistryMapData = response;
      });
      this.handleLoading();
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading();
    }
  };

  getOptionsRegioes = async () => {
    this.handleInputOptions({ ...this.inputOptions, loading: 'regioes' });
    try {
      const response = await getOptions(
        {
          companyId: userStore.companyActive.id,
        },
        'regioes',
      );
      const newRegioes = response.map((r) => {
        return { label: r, value: r };
      });
      this.handleInputOptions({ ...this.inputOptions, regioes: newRegioes, loading: '' });
    } catch (error: any) {
      console.error(error.message);
      this.handleInputOptions({ ...this.inputOptions, loading: '' });
    }
  };

  getOptionsFazendas = async (data: AddDataForestRegistry) => {
    try {
      this.handleInputOptions({ ...this.inputOptions, loading: 'fazendas' });
      const response = await getOptions(
        {
          companyId: userStore.companyActive.id,
          regiao: data.regiao || this.modalsState.forestRegistryItem?.regiao || undefined,
        },
        'fazendas',
      );
      const newFazendas = response.map((r) => {
        return { label: r, value: r };
      });
      this.handleInputOptions({
        ...this.inputOptions,
        fazendas: newFazendas,
        loading: '',
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleInputOptions({ ...this.inputOptions, loading: '' });
    }
  };

  saveForestRegistryItem = async (values: AddDataForestRegistry) => {
    const isEdit = !!this.modalsState.forestRegistryItem?.id;
    try {
      this.handleModalLoading();
      const result = isEdit
        ? await putForestRegistry(values)
        : await postForestRegistry(values);
      showSuccess(result);
      if (isEdit) {
        runInAction(() => {
          this.forestRegistryData = {
            ...this.forestRegistryData,
            forestRegisters: this.forestRegistryData.forestRegisters.map((item) =>
              item.id === this.modalsState.forestRegistryItem?.id ? result.data : item,
            ),
          };
        });
      } else {
        await this.getForestRegistryData();
      }
      this.handleModalState('add', false);
      this.handleModalLoading();
    } catch (error: any) {
      console.error(error.message);
      this.handleModalLoading();
      this.handleModalState('add', false);
    }
  };

  updateStatus = async () => {
    this.handleModalLoading();
    try {
      const response = await changeStatusForestRegistry(
        Number(this.modalsState.forestRegistryItem?.id),
      );
      runInAction(() => {
        this.forestRegistryData = {
          ...this.forestRegistryData,
          forestRegisters: this.forestRegistryData.forestRegisters.map((item) =>
            item.id === this.modalsState.forestRegistryItem?.id ? response.data : item,
          ),
        };
        this.handleModalState('confirmChangeStatus', false);
        this.handleModalLoading();
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModalLoading();
    }
  };

  onUpdateCoordinates = async (data: udpateCoordinatesDataType) => {
    this.handleModalLoading();
    try {
      const retorno = await updateCoordinates(data);
      runInAction(() => {
        this.forestRegistryData = {
          ...this.forestRegistryData,
          forestRegisters: this.forestRegistryData.forestRegisters.map((register) =>
            register.id === data.id ? retorno.data : register,
          ),
        };
      });
      this.handleModalState('polygonEditor', false);
      this.handleModalLoading();
      showSuccess(retorno);
    } catch (error: any) {
      console.error(error.message);
      this.handleModalLoading();
    }
  };

  getSummaryData = async () => {
    this.handleLoadingSummary();
    try {
      const newSummaryData = await getSummary({
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.summary = newSummaryData;
      });
      this.handleLoadingSummary();
    } catch (error: any) {
      console.error(error.message);
      this.handleLoadingSummary();
    }
  };
  getSummaryByYearData = async () => {
    this.handleLoadingSummaryByYear();
    try {
      const newSummaryData = await getSummaryByYear({
        companyId: userStore.companyActive.id,
        year: this.yearSelected,
      });
      runInAction(() => {
        this.summaryByYear = newSummaryData;
      });
      this.handleLoadingSummaryByYear();
    } catch (error: any) {
      console.error(error.message);
      this.handleLoadingSummaryByYear();
    }
  };

  uploadExcel = async (fileUpload: File) => {
    this.handleModalLoading();
    try {
      if (!fileUpload) {
        this.handleModalState('upload', false);
        return;
      }
      const reseponse = await uploadExcel(fileUpload, userStore.companyActive.id);
      showSuccess({ message: reseponse.message });
      runInAction(() => {
        this.tableParams = { ...this.tableParams, page: 1 };
      });
      this.handleModalState('upload', false);
      this.handleModalLoading();
    } catch (error: any) {
      console.error(error.message);
      this.handleModalLoading();
    }
  };

  handleLoading = () => {
    runInAction(() => {
      this.loading = !this.loading;
    });
  };

  handleLoadingExport = () => {
    this.loadingExport = !this.loadingExport;
  };

  handleTableParamas = (key: keyof StateTableParams, value: number | string) => {
    runInAction(() => {
      this.tableParams = { ...this.tableParams, [key]: value };
    });
  };

  handleScreenActive = (screen: screensType) => {
    runInAction(() => {
      this.screenActive = screen;
    });
  };

  handleInputOptions = (options: InputOptionsStateType) => {
    runInAction(() => {
      this.inputOptions = options;
    });
  };

  handleFilters = (key: keyof FiltersForestRegistryType, value: string | number) => {
    runInAction(() => {
      this.tableParams = { ...this.tableParams, page: 1 };
      this.filter = { ...this.filter, [key]: value };
    });
  };

  handleClearFilter = () => {
    runInAction(() => {
      this.filter = inititalStateFilters;
    });
  };

  handleLoadingSummary = () => {
    runInAction(() => {
      this.loadingSummaryData = !this.loadingSummaryData;
    });
  };
  handleLoadingSummaryByYear = () => {
    runInAction(() => {
      this.loadingSummaryByYearData = !this.loadingSummaryByYearData;
    });
  };

  handleYearSelected = (newYear: number) => {
    runInAction(() => {
      this.yearSelected = newYear;
    });
  };
  handleModalState = (
    modal:
      | 'add'
      | 'upload'
      | 'uploadKML'
      | 'delete'
      | 'polygonEditor'
      | 'confirmChangeStatus',
    visible: boolean,
    register: forestRegistryItemType | undefined = undefined,
  ) => {
    runInAction(() => {
      this.modalsState = {
        ...this.modalsState,
        [modal]: visible,
        forestRegistryItem: register,
      };
    });
  };
  handleModalLoading = () => {
    runInAction(() => {
      this.modalsState = { ...this.modalsState, loading: !this.modalsState.loading };
    });
  };
}
