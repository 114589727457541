import CardFiltersStatus from './card-filter-status';
import HeaderResults from './header-results';

import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { TitleContext } from '@/component/layout';
import SurvivalSurveysTable from './table';
import ModalHistoryApprovals from '../modals/modal-history';
import TableFilters from './table-filters';
import ButtonApprovation from '../modals/modal-multi-approve';
import ModalApprove from '../modals/modal-approve';
import ModalInactive from '../modals/modal-inactive';
import ModalReactive from '../modals/modal-active';
import { translationI18n } from '@/i18n';

const SurvivalSurveysList = () => {
  const { setTitle, setSubTitle } = useContext(TitleContext);
  useEffect(() => {
    setTitle(translationI18n('survivalSurveys:title'));
    setSubTitle(translationI18n('survivalSurveys:subtitle'));
  }, []);
  return (
    <div className="w-full min-h-full px-2 py-2 md:px-4 md:py-4 pb-14 md:pb-14 dark:bg-darkblack-700 ">
      <HeaderResults />
      <TableFilters />
      <div className="rounded-lg bg-white items-center h-full flex-col dark:bg-darkblack-600 flex-1 shadow-lg">
        <CardFiltersStatus />
        <ButtonApprovation />
        <SurvivalSurveysTable />
      </div>
      <ModalHistoryApprovals />
      <ModalApprove />
      <ModalInactive />
      <ModalReactive />
    </div>
  );
};
export default observer(SurvivalSurveysList);
