import ProtoTypes from 'prop-types';
import logo from '@/assets/images/logo/logo-color.svg';
import logoW from '@/assets/images/logo/logo-white.svg';
import { Link, useLocation } from 'react-router-dom';
import pages from './pages';
import { userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { ServiceSystemType } from '@/utils/types/globalTypes';
import { observer } from 'mobx-react';
import { translationI18n } from '@/i18n';

function Sidebar({ handleActive }: { handleActive: () => void }) {
  const { pathname: location } = useLocation();
  const { theme } = userStore;
  return (
    <aside className="sidebar-wrapper fixed top-0 z-30 block h-screen w-[155px] bg-white dark:bg-darkblack-600 shadow-lg">
      <div className="sidebar-header relative z-30 flex h-[80px] w-full items-center border-b border-r border-b-[#F7F7F7] border-r-[#F7F7F7] pl-[16px] dark:border-darkblack-400">
        <Link to="/" className="flex pr-[32px]">
          <img
            src={logo}
            className="block min-h-[50px] w-[100%] dark:hidden"
            alt="logo"
          />
          <img
            src={logoW}
            className="hidden min-h-[50px] w-[100%] dark:block"
            alt="logo"
          />
        </Link>
        <button
          aria-label="none"
          type="button"
          onClick={handleActive}
          className="drawer-btn absolute right-0 top-auto"
          title="Ctrl+b"
        >
          <span>
            <svg
              width="16"
              height="40"
              viewBox="0 0 16 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 10C0 4.47715 4.47715 0 10 0H16V40H10C4.47715 40 0 35.5228 0 30V10Z"
                fill="#50AE77"
              />
              <path
                d="M10 15L6 20.0049L10 25.0098"
                stroke="#ffffff"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>
      <div className="sidebar-body overflow-style-none  flex-col h-full justify-between z-30 w-full overflow-y-scroll pb-[200px] pl-[16px] pt-[14px]">
        <div className="nav-wrapper mb-[36px] pr-[16px]">
          {pages()
            .filter((x) =>
              x.services.some(
                (y) =>
                  (y?.master && userStore.user.master) ||
                  (!y.master &&
                    checkPermission({
                      permission: 'hasone',
                      service: y.service as ServiceSystemType,
                    })) ||
                  (!y.master && y.service === ''),
              ),
            )
            .map((route, index) => {
              return (
                <ul className="mt-2.5" key={`ul-module_${index}`}>
                  {route.showModuleTitle && (
                    <h4 className="border-b border-bgray-200 text-sm font-medium leading-7 text-bgray-700 dark:border-darkblack-400 dark:text-bgray-50">
                      {route.module}
                    </h4>
                  )}
                  {route.services
                    .filter(
                      (y) =>
                        (y?.master && userStore.user.master) ||
                        (!y.master &&
                          checkPermission({
                            permission: 'hasone',
                            service: y.service as ServiceSystemType,
                          })) ||
                        (!y.master && y.service === ''),
                    )
                    .map((service, indexService) => {
                      return (
                        <li
                          key={`li-service-${index}-${indexService}`}
                          className={`item py-[11px] ${
                            (location.includes(service.path) && service.path !== '/') ||
                            location === service.path
                              ? 'nav-active text-primary-200 dark:text-primary-200'
                              : 'text-bgray-900 dark:text-gray-200'
                          } `}
                        >
                          <Link to={service.path}>
                            <div className="flex items-center justify-between">
                              <div className="flex items-center space-x-2.5">
                                <span className="item-ico">{service.icon(theme)}</span>
                                <span className=" item-text text-sm font-medium leading-none">
                                  {service.serviceName}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              );
            })}
        </div>
      </div>
      <div className="absolute bottom-2 flex flex-col copy-write-text items-center justify-center ">
        {/* <p className="text-xs text-[#969BA0]">© 2024 Todos direitos reservados</p> */}
        <p className="text-xs font-medium text-center text-primary-300 dark:text-primary-200">
          {translationI18n('common:copyriht')}
        </p>
      </div>
    </aside>
  );
}

Sidebar.propTypes = {
  handleActive: ProtoTypes.func,
};

export default observer(Sidebar);
