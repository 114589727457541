import * as turf from '@turf/turf';
import { formatNumber } from '@/utils/helpers/formaters';
import { SurvivalSurveysDataMapType } from '../dashboard-survival-surveys-types';

export function createPolygons(data: SurvivalSurveysDataMapType[]) {
  const response = data.map((forestRegistry) => {
    const { coordinates, ...forestRegistryWithoutCoordinates } = forestRegistry;

    const multipolygon = turf.multiPolygon(
      coordinates.map((polygon) => [
        polygon.coordinates.map((coord) => [coord.lng, coord.lat]),
      ]),
      {
        ...forestRegistryWithoutCoordinates,
        label: `${forestRegistryWithoutCoordinates.talhao}\n${formatNumber(forestRegistryWithoutCoordinates.percentageSurvival, 1)}%`,
      },
    );
    return multipolygon;
  });
  return turf.featureCollection(response);
}
