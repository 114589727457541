import { styled } from '@mui/system';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import Colors from '@/utils/colors';
export const Tab = styled(BaseTab)`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  line-height: 1.5;
  padding: 8px 12px;
  border: none;

  &:hover {
    /* background-color: ${Colors.bgray[200]}; */
  }

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: ${Colors.primary[200]};
    color: white;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-size: 0.875rem;
`;

export const TabsList = styled(BaseTabsList)(
  () => `
  min-width: 400px;
  width: 100%;
  display: flex;
  `,
);
