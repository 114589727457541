import { useEffect, useMemo, useState } from 'react';
import {
  deleteParamsReason,
  downloadExcel,
  getParamsReasons,
  saveParamsReason,
  udpateParamsReason,
} from './planted-area-reasons-services';
import {
  AddPlantedAreaReasonsDataType,
  GetPlantedAreaReasonsResponseType,
  ModalStateType,
  PlantedAreaReasonType,
} from './planted-area-reasons-types';
import { OptionsType, OrderTableTypes } from '@/component/Table/types';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '@/component/Toast';
import { userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { columns } from './planted-area-reasons-initial-states';
import { useTranslation } from 'react-i18next';
import { languages } from '@/utils/helpers/static_options';
const { showSuccess } = Toast;

const PlantedAreaReasonsTableController = () => {
  const { id } = useParams();
  const tMaster = useTranslation('master').t;
  const tCommon = useTranslation('common').t;
  const location = useLocation();
  const currentPath = location.pathname;
  const isMyCompany = currentPath === '/mycompany';
  const companyId = isMyCompany ? userStore.companyActive.id : id;

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [size, setSize] = useState(10);
  const [order, setOrder] = useState<OrderTableTypes>({
    column: 'id',
    direction: 'asc',
  });
  const [reasons, setReasons] = useState<PlantedAreaReasonType[]>([]);
  const [loadingCauses, setLoadingCauses] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [modalsState, setModalsState] = useState<ModalStateType>({
    add: false,
    delete: false,
    upload: false,
    loading: false,
  });
  const getReasons = async () => {
    setLoadingCauses(true);
    const response: GetPlantedAreaReasonsResponseType = await getParamsReasons({
      page,
      size,
      column: order.column,
      direction: order.direction,
      companyId: Number(companyId),
    });

    setReasons(response.reasons);
    setTotalPages(response.totalPages);
    setLoadingCauses(false);
  };
  const onDownloadCauses = async () => {
    setLoadingDownload(true);
    try {
      const response: GetPlantedAreaReasonsResponseType = await getParamsReasons({
        page,
        size: totalPages * size,
        column: order.column,
        direction: order.direction,
        companyId: Number(companyId),
      });
      downloadExcel(response.reasons);
      setLoadingDownload(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingDownload(false);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: modalsState.reason?.id
      ? {
          id: modalsState.reason?.id,
          reason: modalsState.reason?.reason || '',
          description: modalsState.reason?.description || '',
          color: modalsState.reason?.color || '',
          i18n:
            modalsState.reason.i18n ||
            languages.map((language) => ({
              reasonId: modalsState.reason?.id,
              language: language.value,
              origin: '',
              reason: '',
              description: '',
            })),
        }
      : {
          companyId: Number(companyId),
          reason: modalsState.reason?.reason || '',
          description: modalsState.reason?.description || '',
          color: modalsState.reason?.color || '',
          i18n: languages.map((language) => ({
            language: language.value,
            origin: '',
            reason: '',
            description: '',
          })),
        },

    validationSchema: Yup.object({
      reason: Yup.string().required(
        tMaster('paramsPage.paramsContent.plantedArea.reasons.addReason.errors.reason'),
      ),
      description: Yup.string().required(
        tMaster(
          'paramsPage.paramsContent.plantedArea.reasons.addReason.errors.description',
        ),
      ),
      color: Yup.string().required(
        tMaster('paramsPage.paramsContent.plantedArea.reasons.addReason.errors.color'),
      ),
      i18n: Yup.array().of(
        Yup.object().shape({
          language: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.plantedArea.reasons.addReason.errors.languageRequired',
            ),
          ),
          reason: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.plantedArea.reasons.addReason.errors.reason',
            ),
          ),
          description: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.plantedArea.reasons.addReason.errors.description',
            ),
          ),
        }),
      ),
    }),

    onSubmit: async (values: AddPlantedAreaReasonsDataType) => {
      try {
        setModalsState({ ...modalsState, loading: true });
        const result = modalsState.reason?.id
          ? await udpateParamsReason(values)
          : await saveParamsReason(values);
        showSuccess(result);
        if (modalsState.reason?.id) {
          setReasons(
            reasons.map((t) => {
              return modalsState.reason?.id && modalsState.reason?.id === t.id
                ? result.data
                : t;
            }),
          );
        } else {
          getReasons();
        }
        setModalsState({
          ...modalsState,
          loading: false,
          add: false,
          reason: undefined,
        });
      } catch (error: any) {
        console.error(error.message);
        setModalsState({
          ...modalsState,
          loading: false,
        });
      }
    },
  });

  const onDelete = async () => {
    setModalsState({ ...modalsState, loading: true });
    try {
      const responseDelete = await deleteParamsReason({ id: modalsState.reason?.id });
      showSuccess(responseDelete);
      setReasons(reasons.filter((t) => t.id !== modalsState.reason?.id));
      setModalsState({
        ...modalsState,
        delete: false,
        loading: false,
        reason: undefined,
      });
    } catch (error: any) {
      console.error(error.message);
      setModalsState({ ...modalsState, loading: false });
    }
  };

  useMemo(() => {
    if (modalsState.reason?.id) {
      validation.setFieldValue('id', modalsState.reason?.id);
      validation.setFieldValue('reason', modalsState.reason?.reason);
      validation.setFieldValue('description', modalsState.reason?.description);
      validation.setFieldValue('companyId', Number(modalsState.reason?.companyId));
      if (modalsState.reason?.i18n?.length) {
        validation.setFieldValue('i18n', modalsState.reason.i18n);
      } else {
        languages.forEach((language, index) => {
          validation.setFieldValue(`i18n[${index}].reasonId`, modalsState.reason?.id);
          validation.setFieldValue(`i18n[${index}].language`, language.value);
        });
      }
      validation.resetForm();
    } else {
      validation.resetForm();
    }
  }, [modalsState.reason, modalsState.add]);

  useEffect(() => {
    getReasons();
  }, [page, size, order]);

  const options = (reason: PlantedAreaReasonType): OptionsType[] => {
    return [
      {
        label: tCommon('actions.edit'),
        action: () => setModalsState({ ...modalsState, add: true, reason: reason }),

        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionUpdate',
            service: 'plantedArea',
          }),
      },
      {
        label: tCommon('actions.delete'),
        action: () => setModalsState({ ...modalsState, delete: true, reason: reason }),
        variant: 'danger',
        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionDelete',
            service: 'plantedArea',
          }),
      },
    ].filter((x) => !x.hide);
  };
  const canInsert =
    (isMyCompany &&
      checkPermission({
        permission: 'permissionInsert',
        service: 'plantedArea',
      })) ||
    userStore.user.master;
  return {
    columns,
    page,
    size,
    order,
    setOrder,
    setSize,
    setPage,
    modalsState,
    setModalsState,
    validation,
    totalPages,
    reasons,
    loadingCauses,
    loadingDownload,
    options,
    onDelete,
    canInsert,
    onDownloadCauses,
  };
};
export default PlantedAreaReasonsTableController;
