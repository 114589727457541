import { useTranslation } from 'react-i18next';
import { survivalSurveysEvaluationType } from '../../quality-survival-surveys-types';

const BadgeStatusApprovals = ({
  evaluation,
  showLevel = true,
}: {
  evaluation: Partial<survivalSurveysEvaluationType>;
  showLevel?: boolean;
}) => {
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const status = evaluation.disapproved
    ? 'rejected'
    : evaluation.approved
      ? 'approved'
      : 'flowApproved';
  return (
    <div className="flex">
      <div
        className={`px-2 py-1 text-sm items-center text-nowrap font-semibold justify-center rounded ${status === 'approved' ? 'bg-success-100 text-success-300' : status === 'rejected' ? 'bg-error-50 text-error-300' : 'bg-warning-50  text-warning-400'}`}
      >
        {evaluation.disapproved
          ? `${tSurvivalSurveys('badgeStatusApprovals.repproved')} ${showLevel ? `| </br>${evaluation.lastLevelApproved?.levelName}` : ''}`
          : evaluation.approved
            ? tSurvivalSurveys('badgeStatusApprovals.approved')
            : `${tSurvivalSurveys('badgeStatusApprovals.approveIn')} ${showLevel ? `| ${evaluation.nextLevelApproved?.levelName}` : ''}`}
      </div>
    </div>
  );
};
export default BadgeStatusApprovals;
