import { put } from '@/utils/helpers/api_helper';
import { FormNewPassordType } from './left-side-new-password-types';
import * as url from '@/utils/helpers/url_helper';
import { UserType } from '@/utils/types/UsersType';

export const requestNewPassword = async (
  data: FormNewPassordType,
): Promise<{
  data: UserType;
  message: string;
}> => {
  return await put(url.NEW_PASSWORD_AUTENTICADO, data);
};
