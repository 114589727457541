import { ColumnsTypes, StateTableParams } from '@/component/Table/types';
import { userStore } from '@/store';
import { InputOptionsStateType } from './forest-registry-types';
import MiniMapComponent from './forest-registry-body/Table/MiniMapComponent';
import { LatLngExpression } from 'leaflet';
import { checkPermission } from '@/utils/helpers/validations';
import { translationI18n } from '@/i18n';

export const initialStateListForestRegistry = {
  currentPage: 0,
  filters: {
    fazenda: [],
    regiao: [],
    talhao: [],
    manejo: [],
    companyId: [],
    active: [],
  },
  totalItems: 0,
  forestRegisters: [],
  totalPages: 0,
};

export const inititalStateParamsTable: StateTableParams = {
  page: 1,
  size: 10,
  column: 'id',
  direction: 'desc',
};
export const inititalStateModal = {
  add: false,
  delete: false,
  upload: false,
  polygonEditor: false,
  uploadKML: false,
  confirmChangeStatus: false,
  loading: false,
};

export const columns = (): ColumnsTypes[] => {
  return [
    {
      title: translationI18n('forestRegistry:body.table.columns.cod'),
      field: 'cod',
      type: 'int',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.polygon'),
      ordenable: false,
      field: 'coordinates',
      type: 'custom',
      custom: (row: { coordinates: { coordinates: LatLngExpression[] }[] }) => {
        return <MiniMapComponent polygonPositions={row.coordinates} />;
      },
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.company'),
      field: 'company.name',
      type: 'string',
      hide: !userStore.user.master,
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.region'),
      field: 'regiao',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.farm'),
      field: 'fazenda',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.plot'),
      field: 'talhao',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.area'),
      field: 'area',
      type: 'float',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.manejo'),
      field: 'manejo',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.cicle'),
      field: 'ciclo',
      type: 'int',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.rotation'),
      field: 'rotacao',
      type: 'int',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.plant'),
      field: 'dataPlantio',
      type: 'date',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.genetic'),
      field: 'geneticMaterial',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.spaceBetweenLines'),
      field: 'spacingBetweenLines',
      type: 'float',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.spaceBetweenPlants'),
      field: 'spacingBetweenPlants',
      type: 'float',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.idCadCompany'),
      field: 'registryCompanyId',
      type: 'string',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.active'),
      field: 'active',
      type: 'active',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.createdAt'),
      field: 'createdAt',
      type: 'datetime',
    },
    {
      title: translationI18n('forestRegistry:body.table.columns.actions'),
      field: 'c',
      type: 'options',
      ordenable: false,
      hide: !checkPermission({
        permission: 'permissionUpdate',
        service: 'forestRegistry',
      }),
    },
  ].filter((item) => !item.hide);
};

export const initialStateInputOptions: InputOptionsStateType = {
  regioes: [],
  fazendas: [],
  loading: '',
};
export const initialStateFileInfos = {
  totalPolygons: 0,
  updatePolygons: [],
  createPolygons: [],
  notFoundPolygons: 0,
};
export const inititalStateStatusUploadKML = {
  create: '',
  update: '',
};
export const initalStateColumns = {
  fazenda: '',
  talhao: '',
};
export const inititalStateFilters = {
  fazenda: [],
  regiao: [],
  talhao: [],
  companyId: [],
  manejo: [],
  query: '',
  active: [true],
};
export const inititalStateSummaryData = {
  totalArea: 0,
  nPlots: 0,
  availableArea: 0,
  plantedArea: 0,
};
