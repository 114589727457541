import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { observer } from 'mobx-react';
import privateRoutes from './private-routes';
import publicRoutes from './public-routes';
import { userStore } from '../store';
import { useEffect } from 'react';
import privateNewUserRoutes from './private-new-user-routes';

const Router = () => {
  const isAuthenticated = !!userStore?.user?.id;
  const isNewUser = userStore?.user?.newUser;
  const { handleLoadingPage } = userStore;
  async function getData() {
    if (isAuthenticated) {
      //OPÇÃO PARA LOGAR NOVAMENTE PARA NOVA ESTRUTURA DE COMPANYACTIVE
      if (userStore.companyActive.id === 0) {
        userStore.logout();
      }
      //OPÇÃO PARA LOGAR NOVAMENTE PARA NOVA ESTRUTURA DE COMPANYACTIVE
      else {
        handleLoadingPage(true);
        await userStore.myUser();
        localStorage.setItem('language', userStore.user.language);
        handleLoadingPage(false);
      }
    } else {
      localStorage.setItem('language', navigator.language);
    }
  }
  useEffect(() => {
    getData();
  }, [isAuthenticated]);
  const routerBrowse = createBrowserRouter(
    !isAuthenticated ? publicRoutes : isNewUser ? privateNewUserRoutes : privateRoutes,
  );
  return <RouterProvider router={routerBrowse} />;
};

export default observer(Router);
