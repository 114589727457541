/* eslint-disable  */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Skeleton, FormControlLabel, Checkbox } from '@mui/material';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import * as turf from '@turf/turf';
import { FaLayerGroup } from 'react-icons/fa';
import { styleMapLibre } from '@/utils/geo';
import { Feature, GeoJsonProperties, MultiPolygon, Point } from 'geojson';
import { useTranslation } from 'react-i18next';

import imageIcon from './marker.png';
const LAYER_IDS = {
  FOREST_REGISTRY_LINE: 'forestRegistry-line',
  COLLECT_POINT: 'collect-point',
};

const DetailsMap = () => {
  const tPlantedArea = useTranslation('plantedArea').t;
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const [mapState, setMapState] = useState<{
    center: LngLatLike;
    zoom: number;
    loaded: boolean;
  }>({
    center: [-52.842663, -20.504497],
    zoom: 20,
    loaded: false,
  });

  const { detailsNote, loading, handleModal } = plantedAreaNoteStore;

  const loadingNote = loading.details;

  const [point, setPoint] = useState<Feature<Point, GeoJsonProperties>>(
    turf.point([0, 0]),
  );
  const [forestRegistry, setForestRegistry] = useState<
    Feature<MultiPolygon, GeoJsonProperties>
  >(turf.multiPolygon([]));

  const [loadedGeometries, setLoadedGeometries] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState({
    forestRegistry: true,
    collect: true,
  });

  const generateGeometries = useCallback(() => {
    setLoadedGeometries(false);
    try {
      const { coordinates, ...forestRegistryProps } = detailsNote.forestRegistry;

      const multipolygon = turf.multiPolygon(
        coordinates.map((polygon) => [
          polygon.coordinates.map((coord) => [coord.lng, coord.lat]),
        ]),
        forestRegistryProps,
      );
      setForestRegistry(multipolygon);

      setPoint(turf.point([detailsNote.coordinates.lng, detailsNote.coordinates.lat]));

      setLoadedGeometries(true);
    } catch (error: any) {
      setLoadedGeometries(true);
      console.error('Error generating geometries:', error.message);
    }
  }, [detailsNote]);

  const zoomFeatures = useCallback(
    (map: maplibregl.Map) => {
      const allCoordinates: LngLatLike[] = [
        [detailsNote.coordinates.lng, detailsNote.coordinates.lat],
        ...detailsNote.forestRegistry.coordinates.flatMap((coordinates) =>
          coordinates.coordinates.map((coord) => [coord.lng, coord.lat] as LngLatLike),
        ),
      ];

      if (allCoordinates?.length > 0) {
        const bounds = new maplibregl.LngLatBounds();
        allCoordinates.forEach((coord) => bounds.extend(coord));
        map.fitBounds(bounds, { padding: 20, animate: false, maxZoom: 18 });
      }
    },
    [detailsNote],
  );

  const addForestRegistryLayer = useCallback(
    (map: maplibregl.Map) => {
      if (detailsNote.forestRegistry.coordinates?.length > 0) {
        map.addLayer({
          id: LAYER_IDS.FOREST_REGISTRY_LINE,
          type: 'line',
          source: {
            type: 'geojson',
            data: forestRegistry,
          },
          paint: {
            'line-color': 'red',
            'line-width': 2,
          },
        });
      }
    },
    [detailsNote.forestRegistry.coordinates?.length, forestRegistry],
  );

  const addPointLayer = useCallback(
    async (map: maplibregl.Map) => {
      const image = await map.loadImage(imageIcon);
      map.addImage('custom-marker', image.data);
      map.addLayer({
        id: LAYER_IDS.COLLECT_POINT,
        type: 'symbol',
        source: {
          type: 'geojson',
          data: point,
        },
        layout: {
          'icon-image': 'custom-marker', // Ícone padrão de marcador
          'icon-size': 0.1,
          'icon-anchor': 'bottom',
        },
      });
    },
    [point],
  );

  const addLayers = useCallback(
    (map: maplibregl.Map) => {
      addPointLayer(map);
      addForestRegistryLayer(map);
    },
    [addForestRegistryLayer, addPointLayer],
  );

  const initializeMap = useCallback(() => {
    if (mapRef.current) {
      return;
    }
    try {
      const map = new maplibregl.Map({
        container: mapContainerRef.current as HTMLElement,
        attributionControl: false,
        maxZoom: 18,
        style: styleMapLibre,
        center: mapState.center,
        zoom: mapState.zoom,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        localIdeographFontFamily: 'Noto',
      });

      map.addControl(new maplibregl.NavigationControl(), 'top-left');

      map.on('load', () => {
        addLayers(map);
        if (!mapState.loaded) {
          zoomFeatures(map);
        }

        const updateMapState = () => {
          setMapState({
            center: [map.getCenter().lng, map.getCenter().lat],
            zoom: map.getZoom(),
            loaded: true,
          });
        };

        map.on('zoomend', updateMapState);
        map.on('moveend', updateMapState);
      });

      mapRef.current = map;
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [addLayers, mapState.loaded, zoomFeatures]);

  // Initialize Map when geometries are loaded
  useEffect(() => {
    if (mapContainerRef.current && loadedGeometries) {
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initializeMap, loadedGeometries]);

  // Generate geometries when evaluation data changes
  useEffect(() => {
    if (detailsNote.id) {
      generateGeometries();
    }
  }, [detailsNote, generateGeometries]);

  // Update layer visibility based on selectedLayers
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    const layerVisibilityMap: { [key: string]: string } = {
      [LAYER_IDS.FOREST_REGISTRY_LINE]: selectedLayers.forestRegistry
        ? 'visible'
        : 'none',
      [LAYER_IDS.COLLECT_POINT]: selectedLayers.collect ? 'visible' : 'none',
    };

    Object.entries(layerVisibilityMap).forEach(([layerId, visibility]) => {
      if (map.getLayer(layerId)) {
        map.setLayoutProperty(layerId, 'visibility', visibility);
      }
    });
  }, [selectedLayers]);

  const handleLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedLayers((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className="relative rounded-lg w-full dark:text-bgray-300 min-h-[454.5px] h-full shadow-lg">
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        className={`fixed z-[2] left-0 top-0 h-full w-full ${open ? 'block' : 'hidden'}`}
      ></div>
      {loadingNote ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="454.5px"
          className="rounded-lg dark:bg-darkblack-500"
        />
      ) : (
        <div>
          <button
            className="p-3 rounded shadow absolute top-3 right-3 bg-white text-darkblack-400 z-20"
            onClick={handleClick}
          >
            <FaLayerGroup />
          </button>
          <BasePopup
            id={id}
            open={open}
            anchor={anchor}
            placement="bottom-end"
            offset={10}
            style={{ zIndex: 3 }}
          >
            <div className="bg-white flex flex-col dark:bg-darkblack-300 rounded-lg shadow-lg space-y-1 p-2 mt-[-10px] overflow-y-auto overflow-style-none">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.collect}
                    onChange={handleLayerChange}
                    color="success"
                    name="collect"
                  />
                }
                label={tPlantedArea('detailsNote.map.modalLayers.point')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.forestRegistry}
                    onChange={handleLayerChange}
                    color="success"
                    name="forestRegistry"
                  />
                }
                label={tPlantedArea('detailsNote.map.modalLayers.field')}
              />
            </div>
          </BasePopup>
          <div
            ref={mapContainerRef}
            style={{ height: '454.5px', minHeight: 350, zIndex: 1 }}
            className="rounded-lg w-full h-full bg-bgray-200"
          />
        </div>
      )}
    </div>
  );
};

export default observer(DetailsMap);
