import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';
import { InputPasswordType } from './types';
import { useState } from 'react';

const InputPassword = ({
  value,
  onChange,
  onBlur = () => {},
  disabled = false,
  placeholder,
  error = false,
  errorMessage,
}: InputPasswordType) => {
  const [show, setShow] = useState(false);
  return (
    <div className="mb-3 relative items-center">
      <input
        type={show ? 'text' : 'password'}
        className={`h-[40px] text-bgray-800 text-md border ${error ? 'border-error-300' : 'border-bgray-400'} h-[40px] w-full focus:border-primary-200 focus:ring-0  rounded-lg px-4 placeholder:text-bgray-500 placeholder:text-base items-center dark:bg-darkblack-500 text-vertical-center dark:text-gray-200`}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
      {error && <p className="text-error-300 text-base mt-1">{errorMessage}</p>}
      <button
        aria-label="none"
        className="absolute top-[0px] right-4 h-[40px]"
        type="button"
        onClick={() => setShow(!show)}
      >
        {show ? (
          <FaRegEyeSlash color={'#718096'} size={25} />
        ) : (
          <FaRegEye color={'#718096'} size={25} />
        )}
      </button>
    </div>
  );
};
export default InputPassword;
