import { get, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  GetSurvivalSurveysCausesType,
  SurvivalSurveyCauseType,
} from './survival-surveys-causes-types';

export const getParamsCauses = async (
  data: GetSurvivalSurveysCausesType,
): Promise<SurvivalSurveyCauseType[]> => {
  const result = (await get(url.SURVIVALSURVEYS_CAUSES_OF_COMPANY, {
    params: data,
  })) as SurvivalSurveyCauseType[];
  return result;
};

type saveCompanyDataType = {
  companyId: number;
  causeId: number;
};
type updateAllCompanyDataType = {
  companyId: number;
  active: boolean;
};

type updateAllCompanyDataUpdateType = {
  data: SurvivalSurveyCauseType[];
  message: string;
};
export async function putChangeCauseCompany(
  data: saveCompanyDataType,
): Promise<{ data: SurvivalSurveyCauseType; message: string }> {
  return await put(url.SURVIVALSURVEYS_CAUSES_OF_COMPANY, data);
}
export async function putChangeAllCauseCompany(
  data: updateAllCompanyDataType,
): Promise<updateAllCompanyDataUpdateType> {
  return await put(url.SURVIVALSURVEYS_CAUSES_UPDATEALL, data);
}
