import { plantedAreaNoteStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { Skeleton } from '@mui/material';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const NoteSeedlings = observer(() => {
  const { detailsNote: note, loading } = plantedAreaNoteStore;
  const loadingNote = loading.details;
  const tPlantedArea = useTranslation('plantedArea').t;
  const infosShow = [
    {
      field: 'geneticMaterial.genetic',
      label: tPlantedArea('detailsNote.items.seedLings.geneticMaterial'),
      format: () => note.geneticMaterial.genetic,
    },
    {
      field: 'seedlingsUsed',
      label: tPlantedArea('detailsNote.items.seedLings.seedlingsUseds'),
      format: () => {
        return `${formatNumber(!loadingNote && note?.id ? note.seedlingsUsed : 0, 0)} ${tPlantedArea('detailsNote.items.seedLings.seedlingsUsedsSuffix')}`;
      },
    },
    {
      field: 'plantingDensity',
      label: tPlantedArea('detailsNote.items.seedLings.density'),
      format: () => {
        return `${formatNumber(!loadingNote && note?.id ? note.plantingDensity : 0, 0)} ${tPlantedArea('detailsNote.items.seedLings.densitySuffix')}`;
      },
    },
    {
      field: 'plantingDensity',
      label: tPlantedArea('detailsNote.items.seedLings.targetDensity'),
      format: () => {
        return `${formatNumber(!loadingNote && note?.id ? note.targetPlantingDensity : 0, 0)} ${tPlantedArea('detailsNote.items.seedLings.densitySuffix')}`;
      },
    },
    {
      field: 'seedlingsDiscarded',
      label: tPlantedArea('detailsNote.items.seedLings.seedlingsDiscarded'),
      format: () => {
        return `${formatNumber(!loadingNote && note?.id ? note.seedlingsDiscarded : 0, 0)} (${formatNumber(note?.percentageDiscarded, 0)}%)`;
      },
    },
    {
      field: 'reason.reason',
      label: tPlantedArea('detailsNote.items.seedLings.reasonDiscarded'),
    },
  ];

  const ItemLoader = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <Skeleton
          variant="rectangular"
          className="mb-1 dark:bg-darkblack-500 "
          width={Math.floor(Math.random() * (130 - 100 + 1)) + 100}
          height={22}
        />
        <Skeleton
          variant="rectangular"
          className={' dark:bg-darkblack-500'}
          width={Math.floor(Math.random() * (80 - 50 + 1)) + 50}
          height={10}
        />
      </div>
    );
  };
  return (
    <div
      className="w-full
    dark:bg-darkblack-500 
    shadow-lg 
    py-3
    px-3
    rounded-lg
    bg-white"
    >
      <div className="px-3 text-primary-300 dark:text-white text-base md:text-xl font-semibold mb-2">
        {tPlantedArea('detailsNote.items.seedLings.title')}
      </div>
      <div
        className="
     flex-1
      items-center 
      grid 
      4xl:grid-cols-6
      3xl:grid-cols-5
      2xl:grid-cols-4
      xl:grid-cols-3
      lg:grid-cols-3 
      md:grid-cols-3 
      sm:grid-cols-2 
      grid-cols-2
      gap-3 "
      >
        {infosShow.map((info, index) => {
          return loadingNote ? (
            <ItemLoader key={'loaderEvaluationData_' + index} />
          ) : (
            <div className="text-center" key={`itemHeaderInfos${index}`}>
              <p className="sm:text-xl text-base xs:text-lg font-bold dark:text-white">
                {info.format !== undefined ? info.format() : get(note, info.field)}
              </p>
              <p className=" mt-[-4px] text-gray-300 text-sm lg:text-sm xl:text-medium md:text-sm xs:text-sm sm:text-sm">
                {info.label}
              </p>
            </div>
          );
        })}
        {note.observation && (
          <div className="text-start col-span-2 pl-[12.5%] dark:text-white">
            <p className="text-base font-semibold">
              {tPlantedArea('detailsNote.items.observation')}:
            </p>
            <p className="text-sm">{note.observation}</p>
          </div>
        )}
      </div>
    </div>
  );
});
