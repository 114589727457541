import ModalActions from '@/component/modals/modal-actions';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const DeleteUserModal = observer(() => {
  const { modalStates, handleModal, onDelete } = companyProfileStore;
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  return (
    <ModalActions
      isActive={modalStates.deleteUser}
      handleClose={() =>
        handleModal({
          ...modalStates,
          user: undefined,
          deleteUser: false,
          loading: false,
        })
      }
      handleSubmit={onDelete}
      variant={'delete'}
      loading={modalStates.loading}
      title={tCompanyProfile('body.screens.userList.modals.modalDelete.title')}
      description={tCompanyProfile(
        'body.screens.userList.modals.modalDelete.description',
      )}
      submitText={tCommon('actions.delete')}
      cancelText={tCommon('actions.cancel')}
    />
  );
});
