import { TitleContext } from '@/component/layout';
import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import ForestRegistryData from './dashboard-forest-registry';
import CardSurvivalSurveys from './survival-surveys';
import { userStore } from '@/store';
import { checkPermission, checkPermissionCompany } from '@/utils/helpers/validations';
import EmptyState from '@/component/EmptyState';
import { useTranslation } from 'react-i18next';

function Home() {
  const tHome = useTranslation('home').t;
  document.title = tHome('documentTitle');
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const language = localStorage.getItem('language');
  useEffect(() => {
    setTitle(tHome('title'));
    setSubTitle(tHome('subtitle'));
  }, [language, userStore.user.language]);
  const hasPermissionSobrevivencia =
    checkPermissionCompany({
      companyData: userStore.companyActive,
      service: 'survivalSurveys',
    }) && checkPermission({ permission: 'permissionRead', service: 'survivalSurveys' });
  const hasPermissionForestRegistry =
    checkPermissionCompany({
      companyData: userStore.companyActive,
      service: 'forestRegistry',
    }) && checkPermission({ permission: 'permissionRead', service: 'forestRegistry' });
  if (!hasPermissionForestRegistry && !hasPermissionSobrevivencia) {
    return (
      <div className="w-full h-full rounded-lg flex-col flex-1 px-6 py-6 space-y-3 dark:bg-darkblack-700">
        <EmptyState
          title={tHome('emptyData.title')}
          description={tHome('emptyData.description')}
        />
      </div>
    );
  }
  return (
    <div className="w-full min-h-full rounded-lg flex-col flex-1 px-2 py-2 md:px-4 md:py-4 space-y-10 dark:bg-darkblack-700">
      <ForestRegistryData />
      <CardSurvivalSurveys />
    </div>
  );
}

export default observer(Home);
