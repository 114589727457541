import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { RouterStore } from 'mobx-react-router';
import { UserLoginType, UserType } from '@/utils/types/UsersType';
import { getMyUser, loginRequest } from '@/pages/public/signin/signin-services';
import { FormNewPassordType } from '@/pages/public/signin/left-side-new-password/left-side-new-password-types';
import { requestNewPassword } from '@/pages/public/signin/left-side-new-password/left-side-new-password-services';
import {
  LanguageTypes,
  ModalChangeCompaniesType,
  ModalChangePasswordType,
  ModalMyUserType,
  ThemeTypes,
} from './types';
import { ChangeEvent } from 'react';
import { ChangeImage, changeLanguage, changeUserConfig, removeImage } from './services';
import { getModulesAndServices } from '@/pages/private/config/company-profile/company-profile-body/modules-and-services/modules-and-services-services';
import { ModulesType } from '@/pages/private/config/company-profile/company-profile-body/modules-and-services/modules-and-services-types';
import {
  confirmPassword,
  newPasswordRequest,
  updateMyUser as updateUser,
} from '@/pages/private/config/company-profile/company-profile-body/services';
import { Toast } from '@/component/Toast';
import { UpdateMyUserDataType } from '@/component/modals/modal-user/types';
import { getCompanies } from '@/pages/private/master/companies/companies-list/master-companies-services';
import { getCompany } from '@/pages/private/config/company-profile/company-profile-services';
import { CompanyDataType } from '@/pages/private/config/company-profile/company-profile-types';
import { inititalStateCompanyData } from '@/pages/private/config/company-profile/company-profile-initial-states';
import { resetAllStores } from '@/store';
import i18n from '@/i18n';

const routing = new RouterStore();
const { showSuccess } = Toast;
export class UserStore {
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'UserStore',
      properties: ['user', 'companyActive', 'theme'],
    });
  }
  theme: ThemeTypes = 'light';
  loadingPage: boolean = true;
  loadingChangeLanguage: boolean = false;
  companyActive: CompanyDataType = inititalStateCompanyData;
  companiesOptions: { label: string; value: string | number }[] = [];
  modalChangeCompany: ModalChangeCompaniesType = { show: false, loading: false };
  user: UserType = {} as UserType;
  permissionsCompany: ModulesType[] = [] as ModulesType[];
  modalMyUser: ModalMyUserType = {
    show: false,
    editable: false,
    loading: false,
  };
  modalChangePassword: ModalChangePasswordType = {
    show: false,
    screen: 'confirmOldPassword',
    loading: false,
  };
  loadingChangeImage: boolean = false;
  modalLogout = {
    showModal: false,
  };

  reset() {
    this.theme = 'light';
    this.loadingPage = true;
    this.companyActive = inititalStateCompanyData; // Ou o valor padrão desejado
    this.companiesOptions = [];
    this.modalChangeCompany = { show: false, loading: false };
    this.user = {} as UserType; // Ou o valor padrão desejado
    this.permissionsCompany = [] as ModulesType[];
    this.modalMyUser = {
      show: false,
      editable: false,
      loading: false,
    };
    this.modalChangePassword = {
      show: false,
      screen: 'confirmOldPassword',
      loading: false,
    };
    this.loadingChangeImage = false;
    this.modalLogout = {
      showModal: false,
    };
    localStorage.setItem('language', navigator.language);
  }
  handleTheme = async (theme: ThemeTypes) => {
    await changeUserConfig({
      webDarkMode: theme === 'dark',
    });
    runInAction(() => {
      this.theme = theme;
    });
  };
  setTheme = (theme: ThemeTypes) => {
    runInAction(() => {
      this.theme = theme;
    });
  };
  handleLoadingChangeLanguage = (state: boolean) => {
    runInAction(() => {
      this.loadingChangeLanguage = state;
    });
  };
  handleLanguage = async (language: LanguageTypes) => {
    this.handleLoadingPage(true);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
    try {
      await changeLanguage({
        id: this.user.id,
        language: language,
      });
      window.location.reload();
    } catch (error: any) {
      console.log(error.message);
      this.handleLoadingPage(false);
    }
  };
  login = async (data: UserLoginType) => {
    const response = await loginRequest(data);
    const language = response?.data?.user.language;
    localStorage.setItem('token', response?.data?.accessToken);
    localStorage.setItem('language', language);
    runInAction(() => {
      this.user = response?.data?.user;
      this.companyActive = response?.data?.user?.company;
      i18n.changeLanguage(language);
    });
  };
  newPassword = async (data: FormNewPassordType) => {
    const response = await requestNewPassword(data);
    runInAction(() => {
      this.user = response.data;
    });
    routing.push('/');
  };
  myUser = async () => {
    const response = await getMyUser();
    const responseModules = await getModulesAndServices({
      companyId: Number(response?.user?.company?.id),
    });
    i18n.changeLanguage(response?.user.language);
    localStorage.setItem('language', response.user.language);
    runInAction(() => {
      this.theme = response.user.config.webDarkMode ? 'dark' : 'light';
      this.permissionsCompany = responseModules?.modules;
      this.user = response?.user;
    });
  };

  getCompanies = async () => {
    const response = await getCompanies({
      page: 1,
      size: 100,
      column: 'name',
      direction: 'asc',
    });
    runInAction(() => {
      this.companiesOptions = response.companies.map((company) => {
        return {
          value: company.id,
          label: company.name,
        };
      });
    });
  };
  handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    this.handleLoadingChangeImage();
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Verificar se o arquivo selecionado é uma imagem
      if (file.type.startsWith('image/')) {
        // Aqui você pode fazer o que quiser com o arquivo selecionado, como enviar para o servidor, etc.
        const response = await ChangeImage({ file: file, id: this.user.id });
        runInAction(() => {
          this.handleLoadingChangeImage();
          this.user = response.data;
        });
      } else {
        this.handleLoadingChangeImage();
      }
    }
  };
  handeRemoveImagem = async () => {
    this.handleLoadingChangeImage();
    // Aqui você pode fazer o que quiser com o arquivo selecionado, como enviar para o servidor, etc.
    const response = await removeImage(this.user.id);
    runInAction(() => {
      this.handleLoadingChangeImage();
      this.user = response.data;
    });
  };
  updateMyUser = async (values: UpdateMyUserDataType) => {
    this.handleModalUser({ ...this.modalMyUser, loading: true });
    try {
      const result = await updateUser(values);
      runInAction(() => {
        this.user = result.data;
        showSuccess(result);
        this.handleModalUser({ ...this.modalMyUser, loading: false, editable: false });
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };
  confirmOldPassword = async (password: string) => {
    this.handleModalChangePassword({ ...this.modalChangePassword, loading: true });
    try {
      const result = await confirmPassword({ password: password });
      this.handleModalChangePassword({
        ...this.modalChangePassword,
        loading: false,
        screen: result.data.valid ? 'newPassword' : 'confirmOldPassword',
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModalChangePassword({ ...this.modalChangePassword, loading: false });
    }
  };
  changePassword = async (data: { newPassword: string; confirmPassword: string }) => {
    this.handleModalChangePassword({ ...this.modalChangePassword, loading: true });
    try {
      const response = await newPasswordRequest(data);
      this.handleModalChangePassword({
        loading: false,
        screen: 'confirmOldPassword',
        show: false,
      });
      showSuccess({ message: response.message });
    } catch (error: any) {
      console.error(error.message);
      this.handleModalChangePassword({ ...this.modalChangePassword, loading: false });
    }
  };

  handleModalLogout = () => {
    runInAction(() => {
      this.modalLogout.showModal = !this.modalLogout.showModal;
    });
  };
  logout = () => {
    localStorage.setItem('token', '');
    resetAllStores();
  };

  handleModalUser = (modalUser: ModalMyUserType) => {
    runInAction(() => {
      this.modalMyUser = modalUser;
    });
  };
  handleModalChangePassword = (modalChangePassword: ModalChangePasswordType) => {
    runInAction(() => {
      this.modalChangePassword = modalChangePassword;
    });
  };
  handleLoadingChangeImage = () => {
    runInAction(() => {
      this.loadingChangeImage = !this.loadingChangeImage;
    });
  };
  handleModalChangeCompanies = (modalCompanies: ModalChangeCompaniesType) => {
    runInAction(() => {
      this.modalChangeCompany = modalCompanies;
    });
  };
  handleCompanyAcitve = async (companyId: number) => {
    const response = await getCompany(companyId);
    runInAction(() => {
      this.companyActive = response;
    });
  };
  handleLoadingPage = (newState: boolean) => {
    runInAction(() => {
      this.loadingPage = newState;
    });
  };
}
