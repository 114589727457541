import { useTranslation } from 'react-i18next';
import { PlantedAreaNoteInterface } from '../../planted-area-types';

const BadgeStatusApprovals = ({
  note,
  showLevel = true,
}: {
  note: Partial<PlantedAreaNoteInterface>;
  showLevel?: boolean;
}) => {
  const tPlantedArea = useTranslation('plantedArea').t;
  const status = note.error ? 'error' : note.approved ? 'approved' : 'flowApproved';
  return (
    <div className="flex">
      <div
        className={`px-2 py-1 text-sm items-center text-nowrap font-semibold justify-center rounded ${status === 'error' ? 'bg-error-100 text-white' : status === 'approved' ? 'bg-success-100 text-success-300' : 'bg-warning-50  text-warning-400'}`}
      >
        {note.error
          ? `${tPlantedArea('badgeStatusApprovals.isError')}`
          : note.approved
            ? tPlantedArea('badgeStatusApprovals.approved')
            : `${tPlantedArea('badgeStatusApprovals.approveIn')} ${showLevel ? `| ${note.nextLevelApproved?.levelName}` : ''}`}
      </div>
    </div>
  );
};
export default BadgeStatusApprovals;
