import { servicesParamsStore, userStore } from '@/store';
import ParamsItem from './Item';
import { useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { translationI18n } from '@/i18n';

const SurvivalSurveysParams = () => {
  const { id } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const isMyCompany = currentPath.includes('mycompany');
  const companyId = Number(isMyCompany ? userStore.companyActive.id : id);
  const {
    survivalSurveys,
    saveSurvivalSurveys,
    loading,
    getParamsSurvivalSurveys: getSurvivalSurveys,
  } = servicesParamsStore;

  useEffect(() => {
    getSurvivalSurveys(companyId);
  }, [currentPath, companyId]);
  return (
    <div className="bg-white w-[100%] px-4 pb-3 pt-5  rounded-lg dark:bg-darkblack-600 h-full shadow-lg">
      <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
        {translationI18n('companyProfile:body.screens.params.name')}
      </h1>
      <div className="grid w-full grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 my-3 gap-3">
        <ParamsItem
          title={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.paramsEvaluation.targetSurvival',
          )}
          loading={loading.survivalSurveys}
          value={survivalSurveys.survivalTarget}
          onSubmit={(newValue) =>
            saveSurvivalSurveys({
              companyId: companyId,
              survivalTarget: Number(newValue),
            })
          }
        />
        <ParamsItem
          title={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.paramsEvaluation.targetSampling',
          )}
          loading={loading.survivalSurveys}
          value={survivalSurveys.samplingTarget}
          onSubmit={(newValue) =>
            saveSurvivalSurveys({
              companyId: companyId,
              samplingTarget: Number(newValue),
            })
          }
        />
        <ParamsItem
          title={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.paramsEvaluation.limitForEvaluation',
          )}
          loading={loading.survivalSurveys}
          value={survivalSurveys.averageDays}
          suffix={` ${translationI18n('survivalSurveys:survivalSurveysList.days')}`}
          number="int"
          onSubmit={(newValue) =>
            saveSurvivalSurveys({
              companyId: companyId,
              averageDays: Number(newValue),
            })
          }
        />
      </div>
    </div>
  );
};

export default observer(SurvivalSurveysParams);
