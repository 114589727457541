import { plantedAreaNoteStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { Skeleton } from '@mui/material';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const NoteForestRegistry = observer(() => {
  const { detailsNote: note, loading } = plantedAreaNoteStore;
  const loadingNote = loading.details;
  const tPlantedArea = useTranslation('plantedArea').t;
  const infosShow = [
    {
      field: 'forestRegistry.regiao',
      label: tPlantedArea('detailsNote.items.forestRegistry.region'),
    },
    {
      field: 'forestRegistry.fazenda',
      label: tPlantedArea('detailsNote.items.forestRegistry.farm'),
    },
    {
      field: 'forestRegistry.talhao',
      label: tPlantedArea('detailsNote.items.forestRegistry.field'),
    },
    {
      field: 'forestRegistry.area',
      label: tPlantedArea('detailsNote.items.forestRegistry.fieldArea'),
      format: () => {
        return `${formatNumber(
          !loadingNote && note?.id ? note.forestRegistry.area : 0,
          2,
        )} ha`;
      },
    },
  ];

  const ItemLoader = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <Skeleton
          variant="rectangular"
          className="mb-1 dark:bg-darkblack-500 "
          width={Math.floor(Math.random() * (130 - 100 + 1)) + 100}
          height={22}
        />
        <Skeleton
          variant="rectangular"
          className={' dark:bg-darkblack-500'}
          width={Math.floor(Math.random() * (80 - 50 + 1)) + 50}
          height={10}
        />
      </div>
    );
  };
  return (
    <div
      className="w-full
      dark:bg-darkblack-500 
      shadow-lg 
      py-3
      px-3
      rounded-lg
      bg-white"
    >
      <div className="px-3 text-primary-300 dark:text-white text-base md:text-xl font-semibold mb-2">
        {tPlantedArea('detailsNote.items.forestRegistry.title')}
      </div>
      <div
        className="
      flex-1
      items-center 
      grid 
      4xl:grid-cols-6
      3xl:grid-cols-5
      2xl:grid-cols-4
      xl:grid-cols-3
      lg:grid-cols-3 
      md:grid-cols-3 
      sm:grid-cols-2 
      grid-cols-2
      gap-3"
      >
        {infosShow.map((info, index) => {
          return loadingNote ? (
            <ItemLoader key={'loaderEvaluationData_' + index} />
          ) : (
            <div className="text-center" key={`itemHeaderInfos${index}`}>
              <p className="sm:text-xl text-base xs:text-lg font-bold dark:text-white">
                {info.format !== undefined ? info.format() : get(note, info.field)}
              </p>
              <p className=" mt-[-4px] text-gray-300 text-sm lg:text-sm xl:text-medium md:text-sm xs:text-sm sm:text-sm">
                {info.label}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
});
