import ExcelJS from 'exceljs';
import { del, get, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  AddPlantedAreaReasonsDataType,
  AddPlantedAreaReasonResponseType,
  DeletePlantedAreaReasonResponseType,
  GetPlantedAreaReasonsResponseType,
  GetPlantedAreaReasonsType,
  PlantedAreaReasonType,
} from './planted-area-reasons-types';
import moment from 'moment';

export const getParamsReasons = async (
  data: GetPlantedAreaReasonsType,
): Promise<GetPlantedAreaReasonsResponseType> => {
  const result = (await get(url.PLANTEDAREA_REASONS, {
    params: data,
  })) as GetPlantedAreaReasonsResponseType;
  return result;
};

export const saveParamsReason = async (
  data: AddPlantedAreaReasonsDataType,
): Promise<AddPlantedAreaReasonResponseType> => {
  const resultSave = (await post(
    url.PLANTEDAREA_REASONS,
    data,
  )) as GetPlantedAreaReasonsResponseType;
  return resultSave;
};
export const udpateParamsReason = async (
  data: Partial<AddPlantedAreaReasonsDataType>,
): Promise<AddPlantedAreaReasonResponseType> => {
  const resultSave = (await put(
    url.PLANTEDAREA_REASONS + '/' + data.id,
    data,
  )) as AddPlantedAreaReasonResponseType;
  return resultSave;
};
export const deleteParamsReason = async (
  data: Partial<AddPlantedAreaReasonsDataType>,
): Promise<DeletePlantedAreaReasonResponseType> => {
  const resultSave = (await del(
    url.PLANTEDAREA_REASONS + '/' + data.id,
  )) as DeletePlantedAreaReasonResponseType;
  return resultSave;
};

export const downloadExcel = (data: PlantedAreaReasonType[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    'Motivos de descarte ' + moment().format('DD_MM_YY'),
  );

  // Adicione dados ao arquivo Excel
  worksheet.addRow(['id', 'motivo', 'descricao', 'cor']);
  data.forEach((row) => {
    worksheet.addRow([row.id, row.reason, row.description, row.color]);
  });

  // Gere o arquivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    // Crie um blob a partir do buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Crie um URL para o blob
    const url = window.URL.createObjectURL(blob);

    // Crie um link para o URL e simule o clique para iniciar o download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exemplo_upload_motivos.xlsx');
    document.body.appendChild(link);
    link.click();

    // Limpe o URL e remova o link do corpo
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};
