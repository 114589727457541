import CodeVerify from './components/code-verify';
import RequestCode from './components/request-code';
import NewPass from './components/new-pass';
import SuccessFull from './components/SuccessFull';
import { PasswordResetModalControllerType } from '../../signin-types';

function PasswordResetModal({
  passwordResetModalControllers,
}: PasswordResetModalControllerType) {
  const { modalOpen, modalData } = passwordResetModalControllers;
  return (
    <div
      className={`modal fixed inset-0 z-50 overflow-y-auto flex items-center justify-center ${
        modalOpen ? '' : 'hidden'
      }`}
      id="multi-step-modal"
    >
      <div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75 dark:bg-bgray-900 dark:opacity-50"></div>
      <div className="modal-content w-full max-w-lg mx-auto px-4">
        {modalData === 'verify' ? (
          <CodeVerify passwordResetModalControllers={passwordResetModalControllers} />
        ) : modalData === 'newPass' ? (
          <NewPass passwordResetModalControllers={passwordResetModalControllers} />
        ) : modalData === 'success' ? (
          <SuccessFull passwordResetModalControllers={passwordResetModalControllers} />
        ) : (
          <RequestCode passwordResetModalControllers={passwordResetModalControllers} />
        )}
      </div>
    </div>
  );
}

export default PasswordResetModal;
