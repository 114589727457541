import FilterIcon from '@/assets/images/icons/FilterIcon';
import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardForestRegistryStore, userStore } from '@/store';
import Select from '@/component/forms/Select';
import { observer } from 'mobx-react';
import {
  DashboardFiltersForestRegistryRequestType,
  DashboardFiltersForestRegistryType,
} from '../dashboard-forest-registry-types';
import { useTranslation } from 'react-i18next';

const ForestRegistryFilters = observer(() => {
  const {
    loading,
    filterForestRegistry,
    filtersForestRegistry,
    handleFiltersForestRegistry,
    handleClearFiltersForestRegistry,
    getFiltersForestRegistry,
  } = dashboardForestRegistryStore;
  const { dashboardFiltersForestRegistry: loadingItems } = loading;
  const [filterOpen, setFilterOpen] = useState(false);
  useEffect(() => {
    getFiltersForestRegistry();
  }, [filterForestRegistry, userStore.companyActive]);
  const tHome = useTranslation('home').t;
  return (
    <div className="rounded-lg pl-3 px-3 bg-white items-center h-full flex-col dark:bg-darkblack-400 flex-1 py-3 mb-3 shadow-lg">
      <div className="sm:flex xs:flex-col gap-2">
        <div className="flex flex-col h-[43px] w-full">
          <p className="font-semibold text-xl dark:text-bgray-200">
            {tHome('forestRegistry.title')}
          </p>
          <p className="font-light text-bgray-600 text-sm">
            {tHome('forestRegistry.subtitle')}
          </p>
        </div>
        <div className="flex items-center gap-2 justify-end">
          <button
            className="text-sm text-nowrap text-bgray-700 dark:text-bgray-200"
            onClick={handleClearFiltersForestRegistry}
          >
            {tHome('forestRegistry.filters.clearFilters')}
          </button>
          <button
            className="flex items-center gap-1 px-3 bg-primary-100 rounded-md h-[40px] text-success-200 bg-opacity-20 border border-success-200"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <FilterIcon />
            {tHome('forestRegistry.filters.filters')}
          </button>
        </div>
      </div>
      <Collapse in={filterOpen} timeout="auto" unmountOnExit>
        <div
          className="
            grid
            sm:grid-cols-2
            md:grid-cols-3 
            lg:grid-cols-4 
            2xl:grid-cols-4
            3xl:grid-cols-4 
            xl:grid-cols-4  
            gap-x-3"
        >
          {filtersForestRegistry &&
            filterForestRegistry &&
            Object.keys(filtersForestRegistry).map((chave, index) => {
              const options =
                filtersForestRegistry[chave as keyof DashboardFiltersForestRegistryType];
              let label;
              switch (chave) {
                case 'regiao':
                  label = tHome('forestRegistry.filters.fields.regiao');
                  break;
                case 'fazenda':
                  label = tHome('forestRegistry.filters.fields.fazenda');
                  break;
                case 'talhao':
                  label = tHome('forestRegistry.filters.fields.talhao');
                  break;
                case 'manejo':
                  label = tHome('forestRegistry.filters.fields.manejo');
                  break;
                case 'time':
                  label = tHome('forestRegistry.filters.fields.time');
                  break;
              }
              if (!options.length) {
                return (
                  <Select
                    key={index}
                    loading={loadingItems}
                    multiselect={true}
                    label={label}
                    value={
                      filterForestRegistry[
                        chave as keyof DashboardFiltersForestRegistryRequestType
                      ]
                    }
                    data={options}
                    onChange={function (value): void {
                      handleFiltersForestRegistry(
                        chave as keyof DashboardFiltersForestRegistryRequestType,
                        value as string[],
                      );
                    }}
                  />
                );
              }
              return (
                <Select
                  key={index}
                  loading={loadingItems}
                  multiselect={true}
                  label={label}
                  value={
                    filterForestRegistry[
                      chave as keyof DashboardFiltersForestRegistryRequestType
                    ]
                  }
                  data={options}
                  onChange={function (value): void {
                    handleFiltersForestRegistry(
                      chave as keyof DashboardFiltersForestRegistryRequestType,
                      value as string[],
                    );
                  }}
                />
              );
            })}
        </div>
      </Collapse>
    </div>
  );
});
export default ForestRegistryFilters;
