import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { SurvivalSurveysEvaluationPhotoInterface } from '../../../../quality-survival-surveys-types';
import { useEffect, useMemo, useState } from 'react';
import ModalGallery from '@/component/modals/modal-gallery';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { ScaleLoader } from 'react-spinners';
import Colors from '@/utils/colors';

type GalleryProps = {
  evaluationPhotos: SurvivalSurveysEvaluationPhotoInterface[];
};

const Gallery = observer(({ evaluationPhotos = [] }: GalleryProps) => {
  const images = evaluationPhotos;
  const {
    activeImage,
    handleImageActiveGallery,
    modalGalleryIsOpen,
    setModalGalleryIsOpen,
  } = survivalSurveysEvaluationsStore;
  const [loadingImage, setLoadingImage] = useState(false);
  useMemo(() => {
    if (activeImage.imageUrl) {
      setLoadingImage(true);
    }
  }, [activeImage]);
  useEffect(() => {
    if (images.length > 0) {
      handleImageActiveGallery(images[0]);
    }
  }, [images]);

  const nextImage = () => {
    const currentIndex = images.findIndex((img) => img.id === activeImage.id);
    const nextIndex = (currentIndex + 1) % images.length;
    handleImageActiveGallery(images[nextIndex]);
  };

  const prevImage = () => {
    const currentIndex = images.findIndex((img) => img.id === activeImage.id);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    handleImageActiveGallery(images[prevIndex]);
  };

  const openModal = () => {
    setModalGalleryIsOpen(true);
  };

  const closeModal = () => {
    setModalGalleryIsOpen(false);
  };

  return (
    <div
      className={`max-h-[332px]
        min-h-[332px]
        flex-col
        rounded-t-lg 
        bg-white
        dark:bg-darkblack-500
        relative`}
    >
      <div
        className={`
        rounded-t-lg 
        flex
        border-b
        flex-1`}
      >
        <div
          className={`overflow-y-auto 
          flex-col 
          h-[282px]
          min-h-[282px]
          max-h-[282px]
          w-[88px] 
          overflow-style-none
          rounded-t-lg
          ${evaluationPhotos.length === 1 ? 'hidden' : ''}`}
        >
          {images.map((image) => {
            return (
              <img
                onClick={() => handleImageActiveGallery(image)}
                key={image.id}
                className={`m-1 hover:cursor-pointer w-[80px] h-[80px] ${image.id === activeImage.id ? 'border-success-200 border-4' : ''}`}
                src={image.imageUrl}
              />
            );
          })}
        </div>
        <div className={`flex-col relative flex-1`}>
          <div className="flex flex-1 min-h-[282px] max-h-[282px] relative">
            {loadingImage && (
              <div className="absolute left-0 right-0 top-0 bottom-0 justify-center items-center flex z-20 bg-white dark:bg-darkblack-500 bg-opacity-70 dark:bg-opacity-70">
                <ScaleLoader
                  color={Colors.primary[200]}
                  height={75}
                  width={6}
                  speedMultiplier={0.7}
                />
              </div>
            )}
            <img
              className={`object-contain cursor-pointer my-1`}
              src={activeImage.imageUrl}
              onClick={openModal}
              onLoad={() => setLoadingImage(false)}
              loading="lazy"
            />
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              prevImage();
            }}
            className={` ${evaluationPhotos.length === 1 ? 'hidden' : ''}z-30 absolute left-0 top-0 bottom-0 text-primary-200 hover:bg-gradient-to-r hover:bg-success-100 hover:bg-opacity-50 `}
          >
            <BiChevronLeft fontSize={36} className="" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              nextImage();
            }}
            className={` ${evaluationPhotos.length === 1 ? 'hidden' : ''} rounded-tr absolute right-0 top-0 bottom-0  hover:bg-gradient-to-r hover:bg-success-100 hover:bg-opacity-50 text-primary-200 z-30`}
          >
            <BiChevronRight fontSize={36} className="" />
          </button>
        </div>
        <ModalGallery
          activeImage={activeImage}
          handleActiveImage={handleImageActiveGallery}
          closeModal={closeModal}
          images={images}
          isOpen={modalGalleryIsOpen}
        />
      </div>
      <div className="flex px-2 py-2 items-center">
        <p className="text-sm text-ellipsis flex-1 overflow-hidden flex-nowrap dark:text-white">
          {activeImage.observation}
        </p>
        <div className="flex-col">
          <p className="text-xs dark:text-white">
            Lat:{activeImage.coordinates.lat.toFixed(5)}
          </p>
          <p className="text-xs dark:text-white">
            Lon:{activeImage.coordinates.lng.toFixed(5)}
          </p>
        </div>
      </div>
    </div>
  );
});

export default Gallery;
