import { ServiceSystemType } from '@/utils/types/globalTypes';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  getPlantedAreaParams,
  getSurvivalSurveysParams,
  savePlantedAreaParams,
  saveSurvivalSurveysParams,
} from './services-params-services';
import {
  PlantedAreaParamsSaveType,
  SuvivalSurveysParamsDataSaveType,
  PlantedAreaParamsType,
  PlantedAreaParamsModalStateInterface,
} from './services-params-types';

export class ServicesParamsStore {
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'ServicesParamsStore',
      properties: ['survivalSurveys'],
    });
  }
  loading: {
    [x in ServiceSystemType]: boolean;
  } = {
    survivalSurveys: false,
    levels: false,
    users: false,
    forestRegistry: false,
    params: false,
    plantedArea: false,
  };
  survivalSurveys = {
    samplingTarget: 0,
    survivalTarget: 0,
    averageDays: 0,
  };
  plantedArea: PlantedAreaParamsType = {
    targetPlantingDensityMinError: 0,
    targetPlantingDensityMinAlert: 0,
    targetPlantingDensity: 0,
    targetPlantingDensityMaxAlert: 0,
    targetPlantingDensityMaxError: 0,
    targetDiscardedPlantsMinError: 0,
    targetDiscardedPlantsMinAlert: 0,
    targetDiscardedPlants: 0,
    targetDiscardedPlantsMaxAlert: 0,
    targetDiscardedPlantsMaxError: 0,
  };

  modalState: PlantedAreaParamsModalStateInterface = { plantedArea: '' };

  reset = () => {
    this.loading = {
      survivalSurveys: false,
      levels: false,
      users: false,
      forestRegistry: false,
      params: false,
      plantedArea: false,
    };
    this.survivalSurveys = {
      samplingTarget: 0,
      survivalTarget: 0,
      averageDays: 0,
    };
    this.plantedArea = {
      targetPlantingDensityMinError: 0,
      targetPlantingDensityMinAlert: 0,
      targetPlantingDensity: 0,
      targetPlantingDensityMaxAlert: 0,
      targetPlantingDensityMaxError: 0,
      targetDiscardedPlantsMinError: 0,
      targetDiscardedPlantsMinAlert: 0,
      targetDiscardedPlants: 0,
      targetDiscardedPlantsMaxAlert: 0,
      targetDiscardedPlantsMaxError: 0,
    };
    this.modalState = { plantedArea: '' };
  };

  handleLoading(service: ServiceSystemType) {
    runInAction(() => {
      this.loading[service] = !this.loading[service];
    });
  }
  handleModalState = (newModalState: PlantedAreaParamsModalStateInterface) => {
    runInAction(() => {
      this.modalState = newModalState;
    });
  };
  getParamsSurvivalSurveys = async (id: number) => {
    this.handleLoading('survivalSurveys');
    try {
      const survivalSurveysParams = await getSurvivalSurveysParams(id);
      runInAction(() => {
        this.survivalSurveys = survivalSurveysParams;
      });
      this.handleLoading('survivalSurveys');
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('survivalSurveys');
    }
  };

  saveSurvivalSurveys = async (data: SuvivalSurveysParamsDataSaveType) => {
    this.handleLoading('survivalSurveys');
    try {
      const response = await saveSurvivalSurveysParams(data);
      runInAction(() => {
        this.survivalSurveys = response.data;
        this.handleLoading('survivalSurveys');
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('survivalSurveys');
    }
  };

  getParamsPlantedArea = async (id: number) => {
    this.handleLoading('plantedArea');
    try {
      const plantedAreaParams = await getPlantedAreaParams(id);
      runInAction(() => {
        this.plantedArea = plantedAreaParams;
      });
      this.handleLoading('plantedArea');
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('plantedArea');
    }
  };

  savePlantedAreaParams = async (data: PlantedAreaParamsSaveType) => {
    this.handleLoading('plantedArea');
    try {
      const response = await savePlantedAreaParams(data);
      runInAction(() => {
        this.plantedArea = response.data;
        this.handleLoading('plantedArea');
        this.modalState = { ...this.modalState, plantedArea: '' };
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('plantedArea');
    }
  };
}
