import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { userStore } from '@/store';
import { Toast } from '@/component/Toast';
import { translationI18n } from '@/i18n';

const { showError } = Toast;
const API_URL = import.meta.env.VITE_APP_BASE_URL as string;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// Interceptor de resposta do axios
axiosApi.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError<unknown>) => {
    if (error.code === 'ERR_NETWORK') {
      showError({
        message: translationI18n('general:errorServer'),
      });
    } else {
      showError({
        message:
          (error.response?.data as { message: string })?.message ||
          translationI18n('general:errorUndefined'),
      });
      if (error.response?.status === 401) {
        userStore.logout();
      }
    }
    return Promise.reject(error);
  },
);
axiosApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Baerer ${token}`;
  }
  config.headers['Accept-Language'] = localStorage.getItem('language');
  return config;
});

interface Params {
  params?: any;
  query?: any;
}

export async function get(url: string, config: Params = {}) {
  return (await axiosApi.get(url, { ...config })).data;
}

export async function post(url: string, data: any, config = {}) {
  return (await axiosApi.post(url, { ...data }, { ...config })).data;
}

export async function patch(url: string, data: any, config = {}) {
  return (await axiosApi.patch(url, { ...data }, { ...config })).data;
}

export async function put(url: string, data?: any, config = {}) {
  return (await axiosApi.put(url, { ...data }, { ...config })).data;
}

export async function putFile(url: string, formData: FormData) {
  const response = await axiosApi.put(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}
export async function postFile(url: string, formData: FormData) {
  const response = await axiosApi.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export async function putArr<T>(
  url: string,
  data: T[],
  config: AxiosRequestConfig = {},
): Promise<T> {
  return (await axiosApi.put(url, [...data], { ...config })).data;
}

export async function del(url: string, config = {}) {
  return await axiosApi.delete(url, { ...config }).then((response) => response?.data);
}
