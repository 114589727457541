import { EChartsOption } from 'echarts';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';

const LegendChart = observer(
  ({ optionsSurvivalChart }: { optionsSurvivalChart: EChartsOption }) => {
    return (
      <div className="flex top-2 px-4 flex-wrap gap-3">
        {isArray(optionsSurvivalChart?.series) &&
          optionsSurvivalChart?.series[0] &&
          isArray(optionsSurvivalChart?.series?.[0]?.data) &&
          optionsSurvivalChart?.series?.[0]?.data?.map((item, index) => {
            return (
              <div key={index} className="flex gap-1 items-center cursor-pointer">
                <div
                  className="w-8 h-4 rounded"
                  style={{
                    backgroundColor: item?.itemStyle?.color,
                    opacity: 1,
                  }}
                />
                <span className={`dark:text-bgray-200 text-lg `}>
                  {item?.name}: {item?.value}
                </span>
              </div>
            );
          })}
      </div>
    );
  },
);

export default LegendChart;
