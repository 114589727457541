import { useTranslation } from 'react-i18next';

const DEVELOPMENT_MODE = import.meta.env.VITE_DEVELOPMENT_MODE as string;
const HMLComponent = () => {
  const tCommon = useTranslation('common').t;
  if (String(DEVELOPMENT_MODE) === 'PROD') {
    return null;
  }
  return (
    <div className="bg-warning-200 absolute bottom-[50px] left-[-50px] z-50 rotate-45 px-[60px] py-1 text-white font-bold shadow">
      {tCommon('approvalTag')}
    </div>
  );
};

export default HMLComponent;
