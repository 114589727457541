import Input from '@/component/forms/Input';
import Modal from '@/component/modals/modal-default';
import {
  AddSurvivalSurveysCauseDataType,
  ModalStateType,
} from '../survival-surveys-causes-types';
import { FormikContextType } from 'formik';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import Select from '@/component/forms/Select';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mui/base/Tabs';
import { Tab, TabPanel, TabsList } from './tabs';
import { LanguageTypes } from '@/store/config/user/types';
import { languages } from '@/utils/helpers/static_options';

const ModalAddSurvivalSurveyscause = ({
  validation,
  modalState,
  setModalState,
}: {
  validation: FormikContextType<AddSurvivalSurveysCauseDataType>;
  modalState: ModalStateType;

  setModalState: (modalState: ModalStateType) => void;
}) => {
  const { cause } = modalState;
  const tCommon = useTranslation('common').t;
  const tMaster = useTranslation('master').t;
  const handleClose = () => {
    setModalState({ ...modalState, add: false, cause: undefined });
  };
  const optionsCauses = [
    tMaster('paramsPage.params.survivalSurveys.origins.climate', { lng: 'pt-br' }),
    tMaster('paramsPage.params.survivalSurveys.origins.diseases', { lng: 'pt-br' }),
    tMaster('paramsPage.params.survivalSurveys.origins.operational', { lng: 'pt-br' }),
    tMaster('paramsPage.params.survivalSurveys.origins.pests', { lng: 'pt-br' }),
    tMaster('paramsPage.params.survivalSurveys.origins.nursery', { lng: 'pt-br' }),
    tMaster('paramsPage.params.survivalSurveys.origins.others', { lng: 'pt-br' }),
  ].map((x) => {
    return { label: x, value: x };
  });
  const options = (language: LanguageTypes): string[] => [
    tMaster('paramsPage.params.survivalSurveys.origins.climate', {
      lng: language,
    }),
    tMaster('paramsPage.params.survivalSurveys.origins.diseases', {
      lng: language,
    }),
    tMaster('paramsPage.params.survivalSurveys.origins.operational', {
      lng: language,
    }),
    tMaster('paramsPage.params.survivalSurveys.origins.pests', {
      lng: language,
    }),
    tMaster('paramsPage.params.survivalSurveys.origins.nursery', {
      lng: language,
    }),
    tMaster('paramsPage.params.survivalSurveys.origins.others', {
      lng: language,
    }),
  ];
  return (
    <Modal
      title={`${cause?.id ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('cause')}`}
      isActive={modalState.add}
      variant="sm"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton text={tCommon('actions.cancel')} onClick={handleClose} />
          <ButtonSubmit
            title={cause?.id ? tCommon('actions.update') : tCommon('actions.save')}
            disabled={modalState.loading}
            loading={modalState.loading}
            onClick={() => validation.handleSubmit()}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <Select
              disabled={modalState.loading}
              value={validation.values.origin}
              onBlur={validation.handleBlur('origin')}
              onChange={(value) => {
                validation.setFieldValue('origin', value);
                languages.forEach((translation, index) => {
                  validation.setFieldValue(
                    `i18n[${index}].origin`,
                    options(translation.value)[
                      optionsCauses.findIndex((r) => r.value === value)
                    ],
                  );
                });
              }}
              label={tMaster(
                'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.originCause',
              )}
              error={!!(validation.errors.origin && validation.touched.origin)}
              errorMessage={validation.errors.origin}
              data={optionsCauses}
            />
          </div>
          <div className="flex-[0.45]">
            <Input
              type="color"
              label={tMaster(
                'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.colorCause',
              )}
              value={validation.values.color}
              onBlur={validation.handleBlur('color')}
              onChange={validation.handleChange('color')}
              error={!!(validation.errors.color && validation.touched.color)}
              errorMessage={validation.errors.color}
            />
          </div>
        </div>
        <Tabs
          defaultValue={'pt-br'}
          className="mt-2 w-full min-w-0"
          style={{ minWidth: 0 }}
        >
          <TabsList className="border-b-2 min-w-0 w-full" style={{ minWidth: 0 }}>
            <Tab
              value="pt-br"
              className={`bg-primary-200 px-2 py-2 dark:text-white rounded-t-lg ${validation.errors.cause || validation.errors.origin || validation.errors.description ? 'text-red-500' : 'text-darkblack-600 dark:text-white'}`}
            >
              {tCommon('languages.portuguese')}
            </Tab>
            {languages.map((language, index) => {
              return (
                <Tab
                  key={`TAB${language.value}`}
                  value={language.value}
                  className={`bg-primary-200 px-2 py-2 rounded-t-lg ${validation.errors.i18n?.[index] ? 'text-red-500' : 'text-darkblack-600 dark:text-white'}`}
                >
                  {language.label}
                </Tab>
              );
            })}
          </TabsList>
          <TabPanel value="pt-br">
            <Input
              disabled={modalState.loading}
              value={validation.values.cause}
              onChange={validation.handleChange('cause')}
              onBlur={validation.handleBlur('cause')} // Adicione esta linha
              label={tMaster(
                'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.cause',
              )}
              type={'text'}
              error={!!(validation.errors.cause && validation.touched.cause)}
              errorMessage={validation.errors.cause}
            />
            <Input
              disabled={modalState.loading}
              value={validation.values.description}
              onChange={validation.handleChange('description')}
              onBlur={validation.handleBlur('description')} // Adicione esta linha
              label={tMaster(
                'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.descriptionCause',
              )}
              type={'textarea'}
              error={!!(validation.errors.description && validation.touched.description)}
              numRows={5}
              errorMessage={validation.errors.description}
            />
          </TabPanel>
          {languages.map((language, index) => {
            return (
              <TabPanel value={language.value} key={language.value}>
                {/* <div className="flex items-center gap-2">
                  <div className="flex-1">
                    <Select
                      disabled={modalState.loading}
                      value={validation.values.i18n?.[index]?.origin}
                      onBlur={validation.handleBlur(`i18n[${index}].origin`)}
                      onChange={(value) => {
                        validation.setFieldValue(`i18n[${index}].origin`, value);
                      }}
                      label={tMaster(
                        'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.originCause',
                      )}
                      error={
                        !!(
                          validation.errors.i18n &&
                          validation.errors.i18n?.[index]?.origin &&
                          validation.touched.i18n?.[index]?.origin
                        )
                      }
                      errorMessage={validation.errors.i18n?.[index]?.origin}
                      data={options}
                    />
                  </div>
                  <div className="flex-[0.45]">
                    <Input
                      type="color"
                      label={tMaster(
                        'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.colorCause',
                      )}
                      value={validation.values.color}
                      onBlur={validation.handleBlur('color')}
                      onChange={validation.handleChange('color')}
                      error={!!(validation.errors.color && validation.touched.color)}
                      errorMessage={validation.errors.color}
                    />
                  </div>
                </div> */}
                <Input
                  disabled={modalState.loading}
                  value={validation.values.i18n?.[index]?.cause}
                  onChange={validation.handleChange(`i18n[${index}].cause`)}
                  onBlur={validation.handleBlur(`i18n[${index}].cause`)} // Adicione esta linha
                  label={tMaster(
                    'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.cause',
                  )}
                  type={'text'}
                  error={
                    !!(
                      validation.errors.i18n &&
                      validation.errors.i18n?.[index]?.cause &&
                      validation.touched.i18n?.[index]?.cause
                    )
                  }
                  errorMessage={validation.errors.i18n?.[index]?.cause}
                />
                <Input
                  disabled={modalState.loading}
                  value={validation.values.i18n?.[index]?.description}
                  onChange={validation.handleChange(`i18n[${index}].description`)}
                  onBlur={validation.handleBlur(`i18n[${index}].description`)} // Adicione esta linha
                  label={tMaster(
                    'paramsPage.paramsContent.survivalSurveys.causes.addCause.labels.descriptionCause',
                  )}
                  type={'textarea'}
                  error={
                    !!(
                      validation.errors.i18n &&
                      validation.errors.i18n?.[index]?.description &&
                      validation.touched.i18n?.[index]?.description
                    )
                  }
                  numRows={5}
                  errorMessage={validation.errors.i18n?.[index]?.description}
                />
              </TabPanel>
            );
          })}
        </Tabs>
      </form>
    </Modal>
  );
};
export default ModalAddSurvivalSurveyscause;
