import { useEffect, useState, useContext } from 'react';
import { changeStatusCompany, getCompanies } from './master-companies-services';
import { CompanyControllerType, CompanyType } from './master-companies-types';
import { ColumnsTypes, OptionsType, OrderTableTypes } from '@/component/Table/types';
import { TitleContext } from '@/component/layout';
import { Toast } from '@/component/Toast';
import { acceptedsLanguages } from '@/component/header/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { userStore } from '@/store';
const { showSuccess } = Toast;
const CompanysController = (): CompanyControllerType => {
  const tMaster = useTranslation('master').t;
  const tCommon = useTranslation('common').t;
  document.title = tMaster('companies.document-title');
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const columns: ColumnsTypes[] = [
    { title: tMaster('companies.columns.cod'), field: 'id', type: 'int' },
    {
      title: '',
      field: 'imageUrl',
      alternativeForImage: 'name',
      type: 'avatar',

      ordenable: false,
    },
    { title: tMaster('companies.columns.nameCompany'), field: 'name', type: 'string' },
    { title: tMaster('companies.columns.doc'), field: 'doc', type: 'string' },
    { title: tMaster('companies.columns.contact'), field: 'contact', type: 'string' },
    { title: tMaster('companies.columns.manager'), field: 'manager', type: 'string' },
    {
      title: tMaster('companies.columns.createdAt'),
      field: 'createdAt',
      type: 'datetime',
    },
    { title: tMaster('companies.columns.status'), field: 'active', type: 'active' },
    {
      title: tMaster('companies.columns.language'),
      field: 'language',
      type: 'custom',
      custom: (row) => acceptedsLanguages.find((r) => r.value === row.language)?.label,
    },
    {
      title: tMaster('companies.columns.actions'),
      field: 'c',
      type: 'options',
      ordenable: false,
    },
  ];

  const [reloadDataState, setReloadDataState] = useState(false);
  const reloadData = () => setReloadDataState(true);
  const [companyUpdate, setCompanyUpdate] = useState<CompanyType | undefined>(undefined);
  const [isActiveModalAddCompany, setIsActiveModalAddCompany] = useState(false);
  const [isActiveModalChangeStatusCompany, setIsActiveModalChangeStatusCompany] =
    useState(false);
  const [loadingChangeStatusCompany, setLoadingChangeStatusCompany] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [size, setSize] = useState(10);
  const [order, setOrder] = useState<OrderTableTypes>({
    column: 'id',
    direction: 'asc',
  });
  const [query, setQuery] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<CompanyType[]>([]);

  const getData = async () => {
    setLoading(true);
    const companysResult = await getCompanies({
      page,
      size,
      ...order,
      query: query ? query : undefined,
    });
    setCompanies(companysResult.companies);
    setTotalItems(companysResult.totalItems);
    setTotalPages(companysResult.totalPages);
    setReloadDataState(false);
    setLoading(false);
  };
  useEffect(() => {
    setTitle(tMaster('companies.listTitle'));
    setSubTitle(tMaster('companies.listSubTitle'));
  }, [userStore.user.language, localStorage.getItem('language')]);

  useEffect(() => {
    getData();
  }, [page, size, query, order]);

  useEffect(() => {
    reloadDataState && getData();
  }, [reloadDataState]);

  const handleChangeCompany = (company: CompanyType) => {
    setCompanyUpdate(company);
    setIsActiveModalAddCompany(true);
  };
  const handleChangeStatusCompany = (company: CompanyType) => {
    setCompanyUpdate(company);
    setIsActiveModalChangeStatusCompany(true);
  };

  const handleCloseModal = () => {
    setCompanyUpdate(undefined);
    setIsActiveModalAddCompany(false);
  };

  const onChangeStatusCompany = async () => {
    try {
      setLoadingChangeStatusCompany(true);
      const response = await changeStatusCompany({ id: companyUpdate?.id });
      showSuccess(response);
      setCompanies(
        companies.map((cpm) =>
          cpm.id === companyUpdate?.id ? { ...cpm, active: !cpm.active } : cpm,
        ),
      );
      setIsActiveModalChangeStatusCompany(false);
      setLoadingChangeStatusCompany(false);
      setCompanyUpdate(undefined);
    } catch (error: any) {
      console.error(error.message);
      setIsActiveModalChangeStatusCompany(false);
      setLoadingChangeStatusCompany(false);
      setCompanyUpdate(undefined);
    }
  };

  const options = (company: CompanyType): OptionsType[] => [
    {
      label: tCommon('actions.edit'),
      action: (x: CompanyType) => handleChangeCompany(x),
    },
    {
      label: company.active ? tCommon('actions.inactive') : tCommon('actions.active'),
      action: (x: CompanyType) => handleChangeStatusCompany(x),
      variant: company.active ? 'danger' : 'success',
    },
  ];
  return {
    columns,
    page,
    totalPages,
    setPage,
    size,
    setSize,
    loading,
    companies,
    order,
    setOrder,
    query,
    setQuery: (query: string) => setQuery(query),
    totalItems,
    isActiveModalAddCompany,
    setIsActiveModalAddCompany,
    companyUpdate,
    options,
    reloadData,
    handleCloseModal,
    isActiveModalChangeStatusCompany,
    onChangeStatusCompany,
    setIsActiveModalChangeStatusCompany,
    loadingChangeStatusCompany,
  };
};

export default CompanysController;
