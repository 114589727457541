import { ThemeTypes } from '@/store/config/user/types';
import Colors from '@/utils/colors';

const DashboardIcon = (theme: ThemeTypes) => {
  return (
    <div>
      <svg
        width="18"
        height="21"
        viewBox="0 0 18 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="path-1"
          d="M0 8.84719C0 7.99027 0.366443 7.17426 1.00691 6.60496L6.34255 1.86217C7.85809 0.515019 10.1419 0.515019 11.6575 1.86217L16.9931 6.60496C17.6336 7.17426 18 7.99027 18 8.84719V17C18 19.2091 16.2091 21 14 21H4C1.79086 21 0 19.2091 0 17V8.84719Z"
          fill={theme === 'dark' ? Colors.primary[50] : Colors.primary[400]}
        />
        <path
          className="path-2"
          d="M5 17C5 14.7909 6.79086 13 9 13C11.2091 13 13 14.7909 13 17V21H5V17Z"
          fill={Colors.primary[200]}
        />
      </svg>
    </div>
  );
};
export default DashboardIcon;
