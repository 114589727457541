import { survivalSurveysEvaluationsStore } from '@/store';
import { useFetch } from '@/hooks';
import { observer } from 'mobx-react';
import ModalActions from '@/component/modals/modal-actions';
import { useTranslation } from 'react-i18next';
const ModalInactive = () => {
  const { modalStates, handleModal, loading, deleteEvaluation } =
    survivalSurveysEvaluationsStore;
  const { request: requestDelete } = useFetch(deleteEvaluation);

  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  return (
    <ModalActions
      isActive={modalStates.delete}
      handleClose={function (): void {
        handleModal('delete', false);
      }}
      handleSubmit={requestDelete}
      variant={'delete'}
      title={tSurvivalSurveys('modals.modalInactive.title')}
      description={tSurvivalSurveys('modals.modalInactive.description', {
        cod: modalStates.evaluation?.cod,
      })}
      submitText={tCommon('actions.inactive')}
      cancelText={tCommon('actions.cancel')}
      loading={loading.modalDelete}
    />
  );
};

export default observer(ModalInactive);
