import { configurePersistable } from 'mobx-persist-store';
import { UserStore } from './config/user/user.store';
import { ServicesParamsStore } from '@/pages/private/config/company-profile/company-profile-body/services-params/services-params.store';
import { GlobalStore } from './global.store';
import { ForestRegistryStore } from '@/pages/private/forest-registry/forest-registry.store';
import { SurvivalSurveysEvaluationStore } from '@/pages/private/quality/silviculture/survival-surveys/quality-survival-surveys.store';
import { DashboardForestRegistryStore } from '@/pages/private/dashboard/dashboard-forest-registry/dashboard-forest-registry.store';
import { CompanyProfileStore } from '@/pages/private/config/company-profile/company-profile.store';
import { DashboardSurvivalSurveysStore } from '@/pages/private/dashboard/survival-surveys/dashboard-survival-surveys.store';
import { PlantedAreaNoteStore } from '@/pages/private/control/silviculture/planted-area/planted-area.store';

configurePersistable({
  storage: window.localStorage,
});

//CONFIG
const globalStore = new GlobalStore();
const userStore = new UserStore();
const companyProfileStore = new CompanyProfileStore();
const servicesParamsStore = new ServicesParamsStore();

//FOREST REGISTRY
const forestRegistryStore = new ForestRegistryStore();
const dashboardForestRegistryStore = new DashboardForestRegistryStore();

//CONTROL
////SILVILCULTURE
const plantedAreaNoteStore = new PlantedAreaNoteStore();

//QUALITY
////SILVICULTURE
const survivalSurveysEvaluationsStore = new SurvivalSurveysEvaluationStore();
const dashboardSurvivalSurveysStore = new DashboardSurvivalSurveysStore();

export {
  //CONFIG
  globalStore,
  userStore,
  companyProfileStore,
  servicesParamsStore,

  //FOREST REGISTRY
  forestRegistryStore,
  dashboardForestRegistryStore,

  //CONTROL
  ////SILVILCULTURE
  plantedAreaNoteStore,

  //QUALITY
  ////SILVICULTURE
  survivalSurveysEvaluationsStore,
  dashboardSurvivalSurveysStore,
};
export const resetAllStores = () => {
  //CONFIG
  globalStore.reset();
  userStore.reset();
  companyProfileStore.reset();
  servicesParamsStore.reset();

  //FOREST REGISTRY
  forestRegistryStore.reset();
  dashboardForestRegistryStore.reset();

  //CONTROL
  ////SILVILCULTURE
  plantedAreaNoteStore.reset();

  //QUALITY
  ////SILVICULTURE
  survivalSurveysEvaluationsStore.reset();
  dashboardSurvivalSurveysStore.reset();
};
