import { useEffect, useMemo, useState } from 'react';
import {
  deleteParamsCause,
  downloadExcel,
  getParamsCauses,
  postFileCauses,
  saveParamsCause,
  udpateParamsCause,
} from './survival-surveys-causes-services';
import {
  AddSurvivalSurveysCauseDataType,
  GetSurvivalSurveysCausesResponseType,
  ModalStateType,
  SurvivalSurveyCauseType,
} from './survival-surveys-causes-types';
import { OptionsType, OrderTableTypes } from '@/component/Table/types';
import { useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '@/component/Toast';
import { userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { columns } from './survival-surveys-causes-initial-states';
import { useTranslation } from 'react-i18next';
import { languages } from '@/utils/helpers/static_options';
const { showSuccess } = Toast;

const SurvivalSurveysTimesTableController = () => {
  const { id } = useParams();
  const tMaster = useTranslation('master').t;
  const tCommon = useTranslation('common').t;
  const location = useLocation();
  const currentPath = location.pathname;
  const isMyCompany = currentPath === '/mycompany';
  const companyId = isMyCompany ? userStore.companyActive.id : id;

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [size, setSize] = useState(10);
  const [order, setOrder] = useState<OrderTableTypes>({
    column: 'id',
    direction: 'asc',
  });
  const [causes, setCauses] = useState<SurvivalSurveyCauseType[]>([]);
  const [loadingCauses, setLoadingCauses] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [modalsState, setModalsState] = useState<ModalStateType>({
    add: false,
    delete: false,
    upload: false,
    loading: false,
  });
  const getCauses = async () => {
    setLoadingCauses(true);
    const response: GetSurvivalSurveysCausesResponseType = await getParamsCauses({
      page,
      size,
      column: order.column,
      direction: order.direction,
      companyId: Number(companyId),
    });

    setCauses(response.causes);
    setTotalPages(response.totalPages);
    setLoadingCauses(false);
  };
  const onDownloadCauses = async () => {
    setLoadingDownload(true);
    try {
      const response: GetSurvivalSurveysCausesResponseType = await getParamsCauses({
        page,
        size: totalPages * size,
        column: order.column,
        direction: order.direction,
        companyId: Number(companyId),
      });
      downloadExcel(response.causes);
      setLoadingDownload(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingDownload(false);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: modalsState.cause?.id
      ? {
          id: modalsState.cause?.id,
          cause: modalsState.cause?.cause || '',
          description: modalsState.cause?.description || '',
          color: modalsState.cause?.color || '',
          origin: modalsState.cause?.origin || '',
          i18n:
            modalsState.cause.i18n ||
            languages.map((language) => ({
              causeId: modalsState.cause?.id,
              language: language.value,
              origin: '',
              cause: '',
              description: '',
            })),
        }
      : {
          companyId: Number(companyId),
          cause: modalsState.cause?.cause || '',
          description: modalsState.cause?.description || '',
          color: modalsState.cause?.color || '',
          origin: modalsState.cause?.origin || '',
          i18n: languages.map((language) => ({
            language: language.value,
            origin: '',
            cause: '',
            description: '',
          })),
        },

    validationSchema: Yup.object({
      cause: Yup.string().required(
        tMaster('paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.cause'),
      ),
      description: Yup.string().required(
        tMaster(
          'paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.description',
        ),
      ),
      origin: Yup.string().required(
        tMaster('paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.origin'),
      ),
      color: Yup.string().required(
        tMaster('paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.color'),
      ),
      i18n: Yup.array().of(
        Yup.object().shape({
          language: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.languageRequired',
            ),
          ),
          cause: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.cause',
            ),
          ),
          description: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.description',
            ),
          ),
          origin: Yup.string().required(
            tMaster(
              'paramsPage.paramsContent.survivalSurveys.causes.addCause.errors.origin',
            ),
          ),
        }),
      ),
    }),

    onSubmit: async (values: AddSurvivalSurveysCauseDataType) => {
      try {
        setModalsState({ ...modalsState, loading: true });
        const result = modalsState.cause?.id
          ? await udpateParamsCause(values)
          : await saveParamsCause(values);
        showSuccess(result);
        if (modalsState.cause?.id) {
          setCauses(
            causes.map((t) => {
              return modalsState.cause?.id && modalsState.cause?.id === t.id
                ? result.data
                : t;
            }),
          );
        } else {
          getCauses();
        }
        setModalsState({
          ...modalsState,
          loading: false,
          add: false,
          cause: undefined,
        });
      } catch (error: any) {
        console.error(error.message);
        setModalsState({
          ...modalsState,
          loading: false,
        });
      }
    },
  });

  const onDelete = async () => {
    setModalsState({ ...modalsState, loading: true });
    try {
      const responseDelete = await deleteParamsCause({ id: modalsState.cause?.id });
      showSuccess(responseDelete);
      setCauses(causes.filter((t) => t.id !== modalsState.cause?.id));
      setModalsState({ ...modalsState, delete: false, loading: false, cause: undefined });
    } catch (error: any) {
      console.error(error.message);
      setModalsState({ ...modalsState, loading: false });
    }
  };
  const onSendFileUpload = async (file: File) => {
    setModalsState({ ...modalsState, loading: true });
    try {
      const response = await postFileCauses(file, Number(companyId));
      await getCauses();
      showSuccess(response);
      setModalsState({ ...modalsState, upload: false, loading: false });
    } catch (error: any) {
      console.error(error.message);
      setModalsState({ ...modalsState, loading: false });
    }
  };
  useMemo(() => {
    if (modalsState.cause?.id) {
      validation.setFieldValue('id', modalsState.cause?.id);
      validation.setFieldValue('cause', modalsState.cause?.cause);
      validation.setFieldValue('description', modalsState.cause?.description);
      validation.setFieldValue('origin', modalsState.cause?.origin);
      validation.setFieldValue('companyId', Number(modalsState.cause?.companyId));
      if (modalsState.cause?.i18n?.length) {
        validation.setFieldValue('i18n', modalsState.cause.i18n);
      } else {
        languages.forEach((language, index) => {
          validation.setFieldValue(`i18n[${index}].causeId`, modalsState.cause?.id);
          validation.setFieldValue(`i18n[${index}].language`, language.value);
        });
      }
      validation.resetForm();
    } else {
      validation.resetForm();
    }
  }, [modalsState.cause, modalsState.add]);

  useEffect(() => {
    getCauses();
  }, [page, size, order]);

  const options = (cause: SurvivalSurveyCauseType): OptionsType[] => {
    return [
      {
        label: tCommon('actions.edit'),
        action: () => setModalsState({ ...modalsState, add: true, cause: cause }),

        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionUpdate',
            service: 'survivalSurveys',
          }),
      },
      {
        label: tCommon('actions.delete'),
        action: () => setModalsState({ ...modalsState, delete: true, cause: cause }),
        variant: 'danger',
        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionDelete',
            service: 'survivalSurveys',
          }),
      },
    ].filter((x) => !x.hide);
  };
  const canInsert =
    (isMyCompany &&
      checkPermission({
        userStore: userStore,
        permission: 'permissionInsert',
        service: 'survivalSurveys',
      })) ||
    userStore.user.master;
  return {
    columns,
    page,
    size,
    order,
    setOrder,
    setSize,
    setPage,
    modalsState,
    setModalsState,
    validation,
    totalPages,
    causes,
    loadingCauses,
    loadingDownload,
    options,
    onDelete,
    canInsert,
    onSendFileUpload,
    onDownloadCauses,
  };
};
export default SurvivalSurveysTimesTableController;
