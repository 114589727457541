import Modal from '@/component/modals/modal-default';
import { AddDataForestRegistry } from '../../forest-registry-types';
import Input from '@/component/forms/Input';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import Select from '@/component/forms/Select';
import { manejoList } from '@/utils/helpers/static_options';
import { forestRegistryStore, userStore } from '@/store';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useFetch } from '@/hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { initialStateInputOptions } from '../../forest-registry-initial-states';
import { useTranslation } from 'react-i18next';
import { checkPermissionCompany } from '@/utils/helpers/validations';

const ForestRegistryModalAdd = () => {
  const {
    modalsState,
    handleModalState,
    inputOptions,
    saveForestRegistryItem,
    getOptionsRegioes,
    handleInputOptions,
    getOptionsFazendas,
  } = forestRegistryStore;
  const { request: requestSaveForestRegistry } = useFetch(saveForestRegistryItem);
  const isEdit = modalsState.forestRegistryItem?.id !== undefined;
  Yup.setLocale({
    mixed: {
      notType: 'Data inválida',
    },
  });
  const initialValues = {
    id: modalsState.forestRegistryItem?.id,
    companyId: userStore.companyActive.id,
    regiao: modalsState.forestRegistryItem?.regiao || '',
    fazenda: modalsState.forestRegistryItem?.fazenda || '',
    talhao: modalsState.forestRegistryItem?.talhao || '',
    area: modalsState.forestRegistryItem?.area || undefined,
    manejo: modalsState.forestRegistryItem?.manejo || manejoList[0].value,
    ciclo: modalsState.forestRegistryItem?.ciclo || 1,
    rotacao: modalsState.forestRegistryItem?.rotacao || 1,
    dataPlantio: modalsState.forestRegistryItem?.dataPlantio || undefined,
    geneticMaterial: modalsState.forestRegistryItem?.geneticMaterial || '',
    spacingBetweenPlants: modalsState.forestRegistryItem?.companyId || 3,
    spacingBetweenLines: modalsState.forestRegistryItem?.companyId || 3,
    registryCompanyId: modalsState.forestRegistryItem?.registryCompanyId || '',
  };
  const tForestRegistry = useTranslation('forestRegistry').t;
  const tCommon = useTranslation('common').t;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: initialValues,

    validationSchema: Yup.object({
      id: Yup.number(),
      regiao: Yup.string().required(tForestRegistry('modals.add.errors.regiao')),
      fazenda: Yup.string().required(tForestRegistry('modals.add.errors.fazenda')),
      talhao: Yup.string().required(tForestRegistry('modals.add.errors.talhao')),
      manejo: Yup.string().required(tForestRegistry('modals.add.errors.manejo')),
      area: Yup.number()
        .min(0.01, tForestRegistry('modals.add.errors.area.min'))
        .required(tForestRegistry('modals.add.errors.area.required')),
      ciclo: Yup.number().required(tForestRegistry('modals.add.errors.ciclo')),
      rotacao: Yup.number().required(tForestRegistry('modals.add.errors.rotacao')),
      dataPlantio: Yup.date().nullable(),
      geneticMaterial: Yup.string().nullable(),
      spacingBetweenPlants: Yup.number().nullable(),
      spacingBetweenLines: Yup.number().nullable(),
      registryCompanyId: Yup.string(),
    }),

    onSubmit: async (values: AddDataForestRegistry) => {
      await requestSaveForestRegistry(values);
    },
  });

  useEffect(() => {
    if (inputOptions.regioes.length && validation.values.regiao) {
      getOptionsFazendas(validation.values);
    } else {
      handleInputOptions({ ...inputOptions, fazendas: [] });
    }
  }, [validation.values.regiao, inputOptions.regioes]);

  useEffect(() => {
    if (modalsState.add) {
      validation.resetForm();
      getOptionsRegioes();
    } else {
      handleInputOptions(initialStateInputOptions);
    }
  }, [modalsState.add]);

  function handleClose() {
    handleModalState('add', false);
    validation.resetForm();
  }

  const hasPermissionPlantedArea = checkPermissionCompany({
    companyData: userStore.companyActive,
    service: 'plantedArea',
  });
  console.log(userStore.companyActive);
  return (
    <Modal
      title={`${isEdit ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('talhao')}`}
      isActive={modalsState.add}
      variant="xl"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton text={'Cancelar'} onClick={handleClose} />
          <ButtonSubmit
            onClick={() => validation.handleSubmit()}
            title={isEdit ? tCommon('actions.update') : tCommon('actions.save')}
            disabled={modalsState.loading}
            loading={modalsState.loading}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <div className="relative grid grid-cols-1 lg:grid-cols-2 gap-x-5 flex-1 ">
          <Select
            disabled={modalsState.loading}
            data={inputOptions.regioes}
            loading={inputOptions.loading === 'regioes'}
            onBlur={validation.handleBlur('regiao')} // Adicione esta linha
            placeholder={tForestRegistry('modals.add.inputs.reigao.placeholder')}
            label={tForestRegistry('modals.add.inputs.reigao.label')}
            value={validation.values.regiao}
            onChange={(value) => validation.setFieldValue('regiao', value)}
            error={!!(validation.errors.regiao && validation.touched.regiao)}
            errorMessage={validation.errors.regiao}
            creatable
            required
          />
          <Select
            disabled={modalsState.loading}
            data={inputOptions.fazendas}
            onBlur={validation.handleBlur('fazenda')} // Adicione esta linha
            loading={inputOptions.loading === 'fazendas'}
            label={tForestRegistry('modals.add.inputs.fazenda.label')}
            placeholder={
              !validation.values.regiao
                ? tForestRegistry('modals.add.inputs.fazenda.placeholderWithoutRegion')
                : tForestRegistry('modals.add.inputs.fazenda.placeholder')
            }
            value={validation.values.fazenda}
            required
            onChange={(option) => validation.setFieldValue('fazenda', option)}
            error={!!(validation.errors.fazenda && validation.touched.fazenda)}
            errorMessage={validation.errors.fazenda}
            creatable
          />
          <div className="flex gap-x-2 w-full">
            <div className="flex-1">
              <Input
                disabled={modalsState.loading}
                value={validation.values.talhao}
                onChange={validation.handleChange('talhao')}
                onBlur={validation.handleBlur('talhao')}
                label={tForestRegistry('modals.add.inputs.talhao')}
                type={'text'}
                required
                error={!!(validation.errors.talhao && validation.touched.talhao)}
                errorMessage={validation.errors.talhao}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={modalsState.loading}
                value={validation.values.area}
                onChange={validation.handleChange('area')}
                onBlur={validation.handleBlur('area')}
                label={tForestRegistry('modals.add.inputs.area')}
                type={'number'}
                step={0.01}
                required
                error={!!(validation.errors.area && validation.touched.area)}
                errorMessage={validation.errors.area}
              />
            </div>
          </div>
          <Select
            disabled={modalsState.loading}
            value={validation.values.manejo}
            onBlur={validation.handleBlur('manejo')} // Adicione esta linha
            onChange={(value) => {
              validation.setFieldValue('manejo', value);
            }}
            required
            label={tForestRegistry('modals.add.inputs.manejo')}
            error={!!(validation.errors.manejo && validation.touched.manejo)}
            errorMessage={validation.errors.manejo}
            data={manejoList}
          />
          <div className="flex gap-x-2 w-full">
            <div className="flex-1">
              <Input
                disabled={modalsState.loading}
                value={validation.values.ciclo}
                required
                onChange={validation.handleChange('ciclo')}
                onBlur={validation.handleBlur('ciclo')}
                label={tForestRegistry('modals.add.inputs.ciclo')}
                type={'number'}
                step={'1'}
                error={!!(validation.errors.ciclo && validation.touched.ciclo)}
                errorMessage={validation.errors.ciclo}
              />
            </div>
            <div className="flex-1">
              <Input
                disabled={modalsState.loading}
                required
                value={validation.values.rotacao}
                onChange={validation.handleChange('rotacao')}
                onBlur={validation.handleBlur('rotacao')}
                label={tForestRegistry('modals.add.inputs.rotacao')}
                type={'number'}
                step={'1'}
                error={!!(validation.errors.rotacao && validation.touched.rotacao)}
                errorMessage={validation.errors.rotacao}
              />
            </div>
          </div>
          <div className="flex gap-x-2 w-full relative">
            <Input
              disabled={modalsState.loading}
              value={Number(validation.values.spacingBetweenLines)}
              onChange={validation.handleChange('spacingBetweenLines')}
              onBlur={validation.handleBlur('spacingBetweenLines')} // Adicione esta linha
              label={tForestRegistry('modals.add.inputs.spacingBetweenLines')}
              type={'number'}
              step={'0.01'}
              error={
                !!(
                  validation.errors.spacingBetweenLines &&
                  validation.touched.spacingBetweenLines
                )
              }
              errorMessage={validation.errors.spacingBetweenLines}
            />
            <Input
              disabled={modalsState.loading}
              value={Number(validation.values.spacingBetweenPlants)}
              onChange={validation.handleChange('spacingBetweenPlants')}
              onBlur={validation.handleBlur('spacingBetweenPlants')} // Adicione esta linha
              label={tForestRegistry('modals.add.inputs.spacingBetweenPlants')}
              type={'number'}
              step={0.01}
              error={
                !!(
                  validation.errors.spacingBetweenPlants &&
                  validation.touched.spacingBetweenPlants
                )
              }
              errorMessage={validation.errors.spacingBetweenPlants}
            />
          </div>
          <Input
            disabled={modalsState.loading}
            value={String(validation.values.geneticMaterial)}
            onChange={validation.handleChange('geneticMaterial')}
            onBlur={validation.handleBlur('geneticMaterial')} // Adicione esta linha
            label={tForestRegistry('modals.add.inputs.geneticMaterial')}
            type={'text'}
            error={
              !!(validation.errors.geneticMaterial && validation.touched.geneticMaterial)
            }
            errorMessage={validation.errors.geneticMaterial}
          />
          {(!hasPermissionPlantedArea || (hasPermissionPlantedArea && !isEdit)) && (
            <Input
              disabled={modalsState.loading}
              value={validation.values.dataPlantio || null}
              onChange={(date) => {
                validation.setFieldValue('dataPlantio', date);
              }}
              onBlur={validation.handleBlur('dataPlantio')} // Adicione esta linha
              label={tForestRegistry('modals.add.inputs.dataPlantio')}
              type={'date'}
              error={!!(validation.errors.dataPlantio && validation.touched.dataPlantio)}
              errorMessage={validation.errors.dataPlantio}
            />
          )}
          <Input
            disabled={modalsState.loading}
            value={String(validation.values.registryCompanyId)}
            onChange={validation.handleChange('registryCompanyId')}
            onBlur={validation.handleBlur('registryCompanyId')} // Adicione esta linha
            label={tForestRegistry('modals.add.inputs.registryCompanyId')}
            type={'text'}
            error={
              !!(
                validation.errors.registryCompanyId &&
                validation.touched.registryCompanyId
              )
            }
            errorMessage={validation.errors.registryCompanyId}
          />
        </div>
        <p className="text-sm text-gray-400 dark:text-white">
          (*) {tForestRegistry('modals.add.requiredFields')}
        </p>
      </form>
    </Modal>
  );
};
export default observer(ForestRegistryModalAdd);
