import { translationI18n } from '@/i18n';

const TableHeader = () => {
  return (
    <tr className="border-b border-bgray-400 dark:border-darkblack-400">
      <th
        className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
      >
        {translationI18n(
          'companyProfile:body.screens.modulesAndServices.headerColumsn.service',
        )}
      </th>
      <th
        className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
      >
        {translationI18n(
          'companyProfile:body.screens.modulesAndServices.headerColumsn.description',
        )}
      </th>
      <th
        className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
      >
        {translationI18n(
          'companyProfile:body.screens.modulesAndServices.headerColumsn.permission',
        )}
      </th>
    </tr>
  );
};
export default TableHeader;
