import { Navigate, RouteObject } from 'react-router-dom';
import Home from '../pages/private/dashboard';
import Error from '../pages/public/error';
import Layout from '../component/layout';
import Companies from '../pages/private/master/companies/companies-list';
import ForestRegistry from '@/pages/private/forest-registry';
import ParamsPage from '@/pages/private/master/params';
import SurvivalSurveysList from '@/pages/private/quality/silviculture/survival-surveys/list';
import SurvivalSurveysDetails from '@/pages/private/quality/silviculture/survival-surveys/details';
import CompanyProfile from '@/pages/private/config/company-profile';
import TermsOfUse from '@/pages/public/terms-of-use';
import PdfReport from '@/pages/private/quality/silviculture/survival-surveys/report-pdf';
import { Privacy } from '@/pages/public/terms-of-privacy';
import { ControlSilviculturePlantedArea } from '@/pages/private/control/silviculture/planted-area/screens/list';
import { ControlSilviculturePlantedAreaDetails } from '@/pages/private/control/silviculture/planted-area/screens/details';

const privateRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/companies',
        element: <Companies />,
      },
      {
        path: '/params',
        element: <ParamsPage />,
      },
      {
        path: '/companies/:id',
        element: <CompanyProfile />,
      },
      {
        path: '/mycompany',
        element: <CompanyProfile />,
      },
      {
        path: '/forestRegistry',
        element: <ForestRegistry />,
      },
      {
        path: '/quality/survivalSurveys',
        element: <SurvivalSurveysList />,
      },
      {
        path: '/quality/survivalSurveys/:cod',
        element: <SurvivalSurveysDetails />,
      },
      {
        path: '/control/plantedArea',
        element: <ControlSilviculturePlantedArea />,
      },
      {
        path: '/control/plantedArea/:cod',
        element: <ControlSilviculturePlantedAreaDetails />,
      },
    ],
  },
  {
    path: '/termsofuse',
    element: <TermsOfUse />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/termsofuse/:newLanguage',
    element: <TermsOfUse />,
  },
  {
    path: '/privacy/:newLanguage',
    element: <Privacy />,
  },
  {
    path: '/survivalSurveys/reportPDF',
    element: <PdfReport />,
  },
  {
    path: '*',
    element: <Error />,
  },
  {
    path: '/signin',
    element: <Navigate to={'/'} />,
  },
  {
    path: '/resetPassword',
    element: <Navigate to={'/'} />,
  },
];
export default privateRoutes;
