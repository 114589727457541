import { survivalSurveysEvaluationsStore } from '@/store';
import { useFetch } from '@/hooks';
import { observer } from 'mobx-react';
import ModalActions from '@/component/modals/modal-actions';
import { useTranslation } from 'react-i18next';
const ModalReactive = () => {
  const { modalStates, handleModal, loading, reactiveEvaluation } =
    survivalSurveysEvaluationsStore;
  const { request: requestDelete } = useFetch(reactiveEvaluation);

  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  return (
    <ModalActions
      isActive={modalStates.reactive}
      handleClose={function (): void {
        handleModal('reactive', false);
      }}
      handleSubmit={requestDelete}
      variant={`warning`}
      title={tSurvivalSurveys('modals.modalReactive.title')}
      description={tSurvivalSurveys('modals.modalReactive.description', {
        cod: modalStates.evaluation?.cod,
      })}
      submitText={tCommon('actions.reactive')}
      cancelText={tCommon('actions.cancel')}
      loading={loading.modalReactive}
    />
  );
};

export default observer(ModalReactive);
