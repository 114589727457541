import { userStore } from '@/store';
import { observer } from 'mobx-react';
import { ptBR, es } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';

export const InputDatePicker = observer(({ value, setValue, ...restProps }) => {
  let languageActive = 'pt-BR';
  switch (userStore.user.language) {
    case 'pt-br':
      registerLocale('pt-BR', ptBR);
      languageActive = 'pt-BR';
      break;
    case 'es':
      languageActive = 'es';
      registerLocale('es', es);
      break;
  }
  return (
    <DatePicker
      locale={languageActive}
      {...restProps}
      peekNextMonth
      dateFormat={'dd/MM/yyyy'}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      selected={value}
      onChange={(date) => setValue(date)}
    />
  );
});
