import BtnClose from '@/component/buttons/BtnClose';
import { PasswordResetModalControllerType } from '../../../signin-types';
import HeadingLogo from './header-logo';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';

const SuccessFull = ({
  passwordResetModalControllers,
}: PasswordResetModalControllerType) => {
  const {
    handleSubmitLogin,
    validationEmail,
    validationNewPassword,
    loading,
    modalOpen,
    setModalOpen,
  } = passwordResetModalControllers;
  const onFinish = () => {
    handleSubmitLogin({
      email: validationEmail.values.emailRecoveryPassword,
      password: validationNewPassword.values.newPassword,
    });
  };
  const tSignin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="step-content step-4">
      <div className="relative  transform overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 p-8 text-left transition-all">
        <BtnClose close={() => setModalOpen(false)} />
        <div className="text-center mt-4">
          <HeadingLogo />
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-gray-200 mb-3">
            {tSignin('resetPasswordModal.successChangePassword.title')}
          </h3>
          <p className="text-base font-medium text-bgray-600 dark:text-darkblack-300 mb-7">
            {tSignin('resetPasswordModal.successChangePassword.subtitle')}
          </p>
          <PrimaryButton
            wFull
            text={tCommon('actions.enter')}
            id="step-4-cancel"
            loading={loading && modalOpen}
            onClick={onFinish}
          />
        </div>
      </div>
    </div>
  );
};
export default SuccessFull;
