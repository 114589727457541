import { ColumnsTypes } from '@/component/Table/types';
import EChartComponent from '@/component/Echart';
import SurvivalSurveysIcon from '@/assets/images/icons/survivalSurveysIcon';
import { formatNumber } from '@/utils/helpers/formaters';
import * as echarts from 'echarts';
import Colors from '@/utils/colors';
import {
  FilterDataType,
  LoadingType,
  ModalStatesType,
  survivalSurveysEvaluationType,
  survivalSurveysListEvaluationsType,
  SurvivalSurveysReboleiraInterface,
} from './quality-survival-surveys-types';
import BadgeStatusApprovals from './list/badge-status-approvals';
import MiniMap from './list/mini-map';
import { translationI18n } from '@/i18n';

export const initialStateSurvivalSurveys: survivalSurveysListEvaluationsType = {
  totalItems: 0,
  evaluations: [],
  totalPages: 0,
  currentPage: 0,
  filters: {
    fazenda: [],
    regiao: [],
    talhao: [],
    user: [],
    company: [],
    time: [],
  },
};

export const initialSateModalState: ModalStatesType = {
  history: false,
  evaluation: undefined,
  approve: false,
  repprove: false,
  changeCause: false,
  approveMultiple: false,
  delete: false,
  reactive: false,
};

export const initialStateSurvivalSurveysList: {
  page: number;
  size: number;
  companyId?: number;
  order: { column: string; direction: 'asc' | 'desc' };
} = {
  page: 1,
  size: 10,
  order: { column: 'id', direction: 'desc' },
};

export const inititalStateFilters: FilterDataType = {
  fazenda: [],
  regiao: [],
  talhao: [],
  company: [],
  time: [],
  query: '',
  active: [true],
};
export const inititalStateLoading: LoadingType = {
  statusFilter: false,
  graph: false,
  pendencies: false,
  myApprovals: false,
  table: false,
  modalDelete: false,
  modalApprove: false,
  modalChangeCause: false,
  evaluationDetails: false,
  modalRepprove: false,
  tableExport: false,
  modalReactive: false,
};

const survivalPercentRow = (row: survivalSurveysEvaluationType) => {
  const isAlert = row?.percentageSurvival < row.survivalTarget;
  const option: echarts.EChartsOption = {
    series: [
      {
        name: translationI18n(
          'survivalSurveys:survivalSurveysList.table.columns.customRows.survival',
        ),
        type: 'pie',
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        radius: ['70%', '90%'],
        data: [
          {
            value: row?.percentageSurvival,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.survival',
            ),
          },
          {
            value: 100 - row?.percentageSurvival,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.deaded',
            ),
          },
        ],
      },
    ],

    animation: false,
    color: [isAlert ? Colors.error[200] : Colors.primary[200], Colors.bgray[300]],
  };

  return (
    <div className="items-center flex gap-2">
      <div className="items-center flex flex-col">
        <div className="items-center justify-center flex">
          <div className="absolute">
            <SurvivalSurveysIcon alert={isAlert} />
          </div>
          <EChartComponent
            option={option}
            style={{ height: '40px', width: '40px', zIndex: 0 }}
          />
        </div>
        <p className="text-base dark:text-white">
          {formatNumber(row.percentageSurvival)}%
        </p>
      </div>
      <MiniMap row={row} key={`MINIMAP_${row.uuid}`} />
    </div>
  );
};

const deathPercentRow = (row: survivalSurveysEvaluationType) => {
  const isAlert = row?.percentageSurvival < row.survivalTarget;
  const option: echarts.EChartsOption = {
    series: [
      {
        name: translationI18n(
          'survivalSurveys:survivalSurveysList.table.columns.customRows.survival',
        ),
        type: 'pie',
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        radius: ['70%', '90%'],
        data: [
          {
            value: 100 - row?.percentageSurvival,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.deaded',
            ),
          },
          {
            value: row?.percentageSurvival,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.survival',
            ),
          },
        ],
      },
    ],
    animation: false,
    color: [isAlert ? Colors.error[200] : Colors.primary[200], Colors.bgray[300]],
  };

  return (
    <div className="items-center flex flex-col">
      <div className="items-center justify-center flex">
        <div className="absolute">
          <SurvivalSurveysIcon alert={isAlert} />
        </div>
        <EChartComponent
          option={option}
          style={{ height: '40px', width: '40px', zIndex: 0 }}
        />
      </div>
      <p className="text-base dark:text-white">
        {formatNumber(100 - row.percentageSurvival)}%
      </p>
    </div>
  );
};
const samplingPercentRow = (row: survivalSurveysEvaluationType) => {
  const isAlert = row?.percentageSampling < row.samplingTarget;
  const option: echarts.EChartsOption = {
    series: [
      {
        name: translationI18n(
          'survivalSurveys:survivalSurveysList.table.columns.customRows.samplingPercentRow.sampling',
        ),
        type: 'pie',
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        radius: ['70%', '90%'],
        data: [
          {
            value: row?.percentageSampling,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.samplingPercentRow.sampling',
            ),
          },
          {
            value: 100 - row?.percentageSampling,
            name: translationI18n(
              'survivalSurveys:survivalSurveysList.table.columns.customRows.samplingPercentRow.notSampling',
            ),
          },
        ],
      },
    ],
    animation: false,
    color: [isAlert ? Colors.error[200] : Colors.primary[200], Colors.bgray[300]],
  };

  return (
    <div className="items-center flex flex-col">
      <div className="items-center justify-center flex">
        <div className="absolute">
          <SurvivalSurveysIcon alert={isAlert} />
        </div>
        <EChartComponent
          option={option}
          style={{ height: '40px', width: '40px', zIndex: 0 }}
        />
      </div>
      <p className="text-base dark:text-white">{formatNumber(row.percentageSampling)}%</p>
    </div>
  );
};
const locationRow = (row: survivalSurveysEvaluationType) => {
  return (
    <div className="items-center justify-center flex  dark:text-white">
      <div className="flex justify-end items-center w-[55px] pr-2">
        {/* <IoLocationOutline size={20} /> */}
        <div>
          <p className="text-lg text-right">{row.forestRegistry.talhao}</p>
          <p className="text-sm mt-[-7px] text-right">
            {formatNumber(row.forestRegistry.area, 2)}ha
          </p>
        </div>
      </div>

      <div className="border-l-[1px] flex-1 flex-col pl-2">
        <p className="text-lg">{row.forestRegistry.fazenda}</p>
        <p className="text-sm mt-[-7px]">{row.forestRegistry.regiao}</p>
      </div>
    </div>
  );
};
export const columnsState: () => ColumnsTypes[] = () => [
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.cod'),
    field: 'cod',
    type: 'int',
  },

  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.talhao'),
    field: 'forestRegistry.fazenda',
    type: 'custom',
    custom: locationRow,
  },
  {
    title: translationI18n(
      'survivalSurveys:survivalSurveysList.table.columns.evaluation',
    ),
    field: 'time',
    type: 'custom',
    custom: (row: survivalSurveysEvaluationType) =>
      `${row.time} ${translationI18n('survivalSurveys:survivalSurveysList.days')}`,
  },
  {
    title: translationI18n(
      'survivalSurveys:survivalSurveysList.table.columns.survivalPercentage',
    ),
    field: 'percentageSurvival',
    type: 'custom',
    custom: (row: survivalSurveysEvaluationType) => survivalPercentRow(row),
  },
  {
    title: translationI18n(
      'survivalSurveys:survivalSurveysList.table.columns.deathPercentage',
    ),
    field: 'percentageSurvival',
    type: 'custom',
    custom: deathPercentRow,
  },
  {
    title: translationI18n(
      'survivalSurveys:survivalSurveysList.table.columns.samplingPercetage',
    ),
    field: 'percentageSampling',
    type: 'custom',
    custom: samplingPercentRow,
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.approve'),
    field: 'nextLevelApproved.level',
    type: 'custom',
    custom: (row: survivalSurveysEvaluationType) => (
      <BadgeStatusApprovals evaluation={row} />
    ),
  },
  {
    title: translationI18n(
      'survivalSurveys:survivalSurveysList.table.columns.plantAlive',
    ),
    field: 'liveSeedlings',
    type: 'custom',
    custom: (row: survivalSurveysEvaluationType) => {
      return `${formatNumber(row.liveSeedlings, 0)}/${formatNumber(
        row.totalSeedlings,
        0,
      )}`;
    },
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.user'),
    field: 'user.name',
    type: 'string',
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.spot'),
    field: 'spotEvaluation',
    type: 'boolean',
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.createdAt'),
    field: 'createdAt',
    type: 'datetime',
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.status'),
    field: 'active',
    type: 'active',
  },
  {
    title: translationI18n('survivalSurveys:survivalSurveysList.table.columns.actions'),
    field: 'options',
    type: 'options',
  },
];

export const inititalStateEvaluationItem: survivalSurveysEvaluationType = {
  id: 0,
  uuid: '',
  companyId: 0,
  userId: 0,
  forestRegistryId: 0,
  time: 0,
  dateTimeStart: '',
  dateTimeEnd: '',
  source: '',
  polyline: [],
  spacingBetweenPlants: 0,
  spacingBetweenLines: 0,
  deadSeedlings: 0,
  liveSeedlings: 0,
  totalSeedlings: 0,
  percentageSurvival: 0,
  percentageSampling: 0,
  levelIdLastAction: 0,
  levelIdNextAction: 0,
  spotEvaluation: false,
  active: false,
  createdAt: '',
  updatedAt: '',
  forestRegistry: {
    id: 0,
    companyId: 0,
    regiao: '',
    fazenda: '',
    talhao: '',
    area: 0,
    manejo: '',
    ciclo: 0,
    rotacao: 0,
    dataPlantio: '',
    active: false,
    geneticMaterial: '',
    spacingBetweenPlants: 0,
    spacingBetweenLines: 0,
    registryCompanyId: '',
    createdAt: '',
    updatedAt: '',
    coordinates: [],
  },
  approved: false,
  disapproved: false,
  approvals: [],
  lastLevelApproved: null,
  nextLevelApproved: null,
  survivalSurveyDots: [],
  user: {
    id: 0,
    name: '',
    email: '',
    active: false,
    master: false,
    keyUser: false,
    newUser: '',
    imageName: '',
    imageUrl: '',
    contact: '',
    level: {
      id: 0,
      levelName: '',
      level: 0,
      permissions: [],
    },
    company: {
      id: 0,
      name: '',
      contact: '',
      manager: '',
      active: false,
      doc: '',
      imageName: '',
      imageUrl: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      levels: [],
      permissions: [],
      language: 'pt-br',
    },
    createdAt: new Date(),
    updatedAt: '',
  },
  survivalTarget: 0,
  samplingTarget: 0,
  survivalSurveyReboleiras: [],
  cod: 0,
  imageMapUrl: '',
  observation: '',
  interpolatedMap: [],
  evaluationPhotos: [],
};

export const initialStateReboleiraHighlight: SurvivalSurveysReboleiraInterface = {
  deathCause: {
    cause: '',
    description: '',
    origin: '',
    color: '',
  },
  id: 0,
  uuid: '',
  survivalSurveyUuid: '',
  coordinates: [],
  deathCauseId: 0,
  createdMobileAt: new Date(),
  createdAt: new Date(),
  updatedAt: new Date(),
  reboleiraPhotos: [],
  observation: '',
};
