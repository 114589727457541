import { useEffect, useMemo, useState } from 'react';
import {
  getParamsReasons,
  putChangeAllReasonsCompany,
  putChangeCauseCompany,
} from './services';
import { companyProfileStore, userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import _ from 'lodash';
import { PlantedAreaReasonsTableControllerType, PlantedAreaReasonType } from './types';

const PlantedAreaReasonsTableController = (): PlantedAreaReasonsTableControllerType => {
  const { companyData, isMyCompany } = companyProfileStore;
  const companyId = companyData.id;
  const [reasons, setReasons] = useState<PlantedAreaReasonType[]>([]);
  const [loadingReasons, setLoadingReasons] = useState(false);
  const [allSelected, setAllSelected] = useState(true);
  const getReasons = async () => {
    setLoadingReasons(true);
    const response: PlantedAreaReasonType[] = await getParamsReasons({
      companyId: Number(companyId),
    });
    setReasons(response);
    setLoadingReasons(false);
  };
  useMemo(() => {
    getReasons();
  }, [companyData.id]);
  useEffect(() => {
    if (reasons) {
      // Obtém um array dos valores do objeto causes
      const reasonsArray = Object.values(reasons);

      // Verifica se há algum item desativado em qualquer subarray dentro de causesArray
      const hasDisabledItem = reasonsArray.some((reason) => !reason.active);

      // Define o estado allSelected com base na verificação
      setAllSelected(!hasDisabledItem);
    }
  }, [reasons]);

  const [loadingUpdateReason, setLoadingUpdateReason] = useState('');

  async function onHandleActive(reason: PlantedAreaReasonType) {
    setLoadingUpdateReason(reason.reason);
    try {
      const response = await putChangeCauseCompany({
        companyId: companyData.id,
        reasonId: reason.id,
      });
      if (reasons) {
        const newReasons: PlantedAreaReasonType[] = _.map(reasons, function (ca) {
          return ca.id === reason.id ? response.data : ca;
        });

        setReasons(newReasons);
      }
      setLoadingUpdateReason('');
    } catch (error: any) {
      console.error(error.message);
      setLoadingUpdateReason('');
    }
  }

  async function onChangeAllReasonsOfCompany() {
    try {
      setLoadingUpdateReason('all');
      const response = await putChangeAllReasonsCompany({
        companyId: companyData.id,
        active: !allSelected,
      });
      setReasons(response.data);
      setAllSelected(!allSelected);
      setLoadingUpdateReason('');
    } catch (error: any) {
      console.error(error.message);
      setLoadingUpdateReason('');
    }
  }

  const canInsert =
    (isMyCompany &&
      checkPermission({
        permission: 'hasone',
        service: 'plantedArea',
      }) &&
      checkPermission({
        service: 'params',
        permission: 'permissionUpdate',
      })) ||
    userStore.user.master;
  return {
    reasons: reasons,
    loadingReasons: loadingReasons,
    canInsert,
    onHandleActive,
    loadingUpdateReason,
    onChangeAllReasonsOfCompany,
    allSelected,
  };
};
export default PlantedAreaReasonsTableController;
