import Colors from '@/utils/colors';
import * as turf from '@turf/turf';
import {
  Feature,
  GeoJsonProperties,
  Polygon,
  MultiPolygon,
  Geometry,
  Point,
} from 'geojson';
import { ColorSpecification, DataDrivenPropertyValueSpecification } from 'maplibre-gl';

export function createInterpolatedGridOverlay(
  points: Feature<Point, GeoJsonProperties>[],
  forestRegistry: Feature<MultiPolygon, GeoJsonProperties>,
  survivalSurveyTarget: number,
) {
  const bbox = turf.bbox(forestRegistry);
  const pointsExternal = [
    turf.point([bbox[0] - 0.00025, bbox[1] - 0.00025], {
      value: 1,
    }),
    turf.point([bbox[2] + 0.00025, bbox[3] + 0.00025], {
      value: 1,
    }),
  ];
  const pointFeatures = [...points, ...pointsExternal];

  const cellSize = Math.sqrt(1000); // Tamanho da célula em graus
  const pointCollection = turf.featureCollection(pointFeatures);
  const pointsBbox = turf.bbox(pointCollection);
  const initialGrid = turf.squareGrid(pointsBbox, Math.sqrt(5000), {
    units: 'meters',
  });
  const GridPoints = initialGrid.features.map((featureGrid) => {
    const pointsInGrid = turf.pointsWithinPolygon(pointCollection, featureGrid);

    const isAlive = pointsInGrid.features.length
      ? pointsInGrid.features.filter((feature) => feature.properties.isAlive).length
      : 1;
    const totalPlants = pointsInGrid.features.length || 1;
    if (totalPlants > 10) {
      const survival = isAlive / totalPlants;
      return {
        ...featureGrid,
        properties: {
          value: survival,
          points: totalPlants,
        },
      };
    } else {
      return featureGrid;
    }
  });

  const gridPointsFiltered = GridPoints.filter((grid) => grid.properties?.points > 0);
  const pointsSampling = gridPointsFiltered.map((grid) => {
    return turf.centroid(grid, { properties: grid.properties });
  });
  const grid = turf.interpolate(
    turf.featureCollection([...pointsSampling, ...pointsExternal]),
    cellSize,
    {
      gridType: 'square',
      property: 'value',
      units: 'meters',
    },
  );
  const gridPolygons = turf.featureCollection(grid.features);
  const intersectedFeatures: Feature<MultiPolygon | Polygon, GeoJsonProperties>[] = [];
  gridPolygons.features.forEach((feature) => {
    forestRegistry.geometry.coordinates.forEach((coord) => {
      const polygon = turf.polygon(coord);
      if (turf.booleanIntersects(feature, polygon)) {
        const polyg = turf.featureCollection([feature, polygon]);

        const intersection = turf.intersect(polyg);

        if (intersection?.properties && feature.properties) {
          intersection.properties.value = feature.properties?.value;
          intersectedFeatures.push(intersection);
        }
      }
    });
  });
  const intersectedGrid = turf.featureCollection(intersectedFeatures);

  return {
    intersectedGrid,
    fillColor: [
      'step',
      ['get', 'value'],
      Colors.error[300],
      (survivalSurveyTarget - 12) / 100,
      Colors.error[300],
      (survivalSurveyTarget - 9) / 100,
      Colors.error[200],
      (survivalSurveyTarget - 6) / 100,
      Colors.warning[200],
      (survivalSurveyTarget - 3) / 100,
      Colors.primary[200],
      survivalSurveyTarget / 100,
      Colors.primary[300],
    ] as DataDrivenPropertyValueSpecification<ColorSpecification>,
  };
}

export function createDotsOverlay(
  points: Feature<Point, GeoJsonProperties>[],
  isAlive: boolean,
) {
  const dots: Feature<Geometry, GeoJsonProperties>[] = [];
  points.forEach((dot) => {
    if (dot.properties?.isAlive === isAlive) {
      dots.push(dot);
    }
  });
  const dotsColletion = turf.featureCollection(dots);

  return dotsColletion;
}
