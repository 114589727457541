import Modal from '@/component/modals/modal-default';
import ButtonSubmit from '@/component/forms/button-submit';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import Input from '@/component/forms/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Toast } from '@/component/Toast';
import { AddLevelDataType, AddLevelModalProps } from './types';
import { saveLevel, updateLevel } from '../levels-permissions-services';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
const { showSuccess } = Toast;
const AddLevelModal = observer(
  ({
    isActive,
    handleClose,
    level,
    company,
    newLevel,
    addNewLevel,
    updateLocaLevel,
  }: AddLevelModalProps) => {
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const tCompanyProfile = useTranslation('companyProfile').t;
    const tCommon = useTranslation('common').t;
    const validation = useFormik({
      enableReinitialize: true,

      initialValues: level?.id
        ? {
            id: level?.id,
            levelName: level?.levelName || '',
          }
        : {
            levelName: level?.levelName || '',
            level: newLevel,
            companyId: company?.id || 0,
          },

      validationSchema: Yup.object(
        level?.id
          ? {
              id: Yup.number(),
              levelName: Yup.string().required(
                tCompanyProfile('body.screens.levelsPermissions.add.errors.levelName'),
              ),
            }
          : {
              levelName: Yup.string().required(
                tCompanyProfile('body.screens.levelsPermissions.add.errors.levelName'),
              ),
              level: Yup.number().required(
                tCompanyProfile('body.screens.levelsPermissions.add.errors.level'),
              ),
              companyId: Yup.number().required(
                tCompanyProfile('body.screens.levelsPermissions.add.errors.companyId'),
              ),
            },
      ),

      onSubmit: async (values: AddLevelDataType) => {
        try {
          setLoadingSubmit(true);
          const result = level?.id ? await updateLevel(values) : await saveLevel(values);
          if (level?.id) {
            updateLocaLevel(result?.data);
          } else {
            addNewLevel(result?.data);
          }
          showSuccess(result);

          setLoadingSubmit(false);
        } catch (error: any) {
          console.error(error.message);
          setLoadingSubmit(false);
        }
      },
    });

    useEffect(() => {
      if (level?.id) {
        validation.setFieldValue('id', level?.id);
        validation.setFieldValue('levelName', level?.levelName);
      } else {
        validation.resetForm();
      }
    }, [level?.id]);
    useMemo(() => {
      if (!isActive) {
        validation.resetForm();
      }
    }, [isActive]);
    return (
      <Modal
        title={`${level?.id ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('level')}`}
        isActive={isActive}
        variant="sm"
        footer={
          <div className="flex w-full justify-end space-x-3">
            <SecondaryButton text={tCommon('actions.cancel')} onClick={handleClose} />
            <ButtonSubmit
              title={level?.id ? tCommon('actions.update') : tCommon('actions.save')}
              disabled={loadingSubmit}
              loading={loadingSubmit}
              onClick={() => validation.handleSubmit()}
            />
          </div>
        }
        handleClose={handleClose}
      >
        <form onSubmit={validation.handleSubmit} className="w-full">
          <Input
            disabled={loadingSubmit}
            value={validation.values.levelName}
            onChange={validation.handleChange('levelName')}
            onBlur={validation.handleBlur('levelName')} // Adicione esta linha
            label={tCompanyProfile('body.screens.levelsPermissions.add.inputLabel')}
            type={'text'}
            error={!!(validation.errors.levelName && validation.touched.levelName)}
            errorMessage={validation.errors.levelName}
          />
        </form>
      </Modal>
    );
  },
);

export default AddLevelModal;
