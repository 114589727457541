import React, { FormEvent, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useFetch } from '@/hooks';
import { userStore } from '@/store';
import {
  UserEmailValidationType,
  UserLoginType,
  UserNewPasswordType,
} from '@/utils/types/UsersType';
import {
  codeVerify,
  requestNewPassword,
  requestPasswordRecovery,
} from './left-side-signin-services';
import { modalDataType } from './left-side-signin-types';
import {
  emailValidation,
  passwordConfirmationValidation,
  passwordValidation,
} from '@/utils/helpers/validations';
interface ExtendedFormEvent<T> extends React.FormEvent<T> {
  inputType?: string;
}
const PasswordResetModalControllers = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<modalDataType>('');
  const [timer, setTimer] = useState(60);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const inputs = useRef<HTMLInputElement[]>([]);
  const [code, setCode] = useState('');
  const [loadingVerifyCode, setLoadingVerifyCode] = useState(false);
  const [loadingNewPassword, setLoadingNewPassword] = useState(false);
  const validCodeInputs = !isNaN(Number(code)) && code.length === 5;
  const { loading: loadingLogin, request: login } = useFetch(userStore.login);
  const onClose = () => {
    setModalOpen(false);
    validation.resetForm();
    validationNewPassword.resetForm();
    validationEmail.resetForm();
  };
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: emailValidation,
      password: passwordValidation(),
    }),

    onSubmit: async (values: UserLoginType) => {
      const data: UserLoginType = {
        email: values.email,
        password: values.password,
      };
      await handleSubmitLogin(data);
    },
  });

  const validationNewPassword = useFormik({
    enableReinitialize: true,

    initialValues: {
      newPassword: '',
      newPasswordConfirm: '',
    },

    validationSchema: Yup.object({
      newPassword: passwordValidation(),
      newPasswordConfirm: passwordConfirmationValidation(),
    }),

    onSubmit: async (values: UserNewPasswordType) => {
      setLoadingNewPassword(true);
      try {
        await requestNewPassword({
          email: validationEmail.values.emailRecoveryPassword,
          password: values.newPassword,
          confirmPassword: values.newPasswordConfirm,
          code: code,
        });
        setModalData('success');
        setLoadingNewPassword(false);
      } catch (error: any) {
        console.error(error.message);
        setLoadingNewPassword(false);
      }
    },
  });
  const validationEmail = useFormik({
    enableReinitialize: true,

    initialValues: {
      emailRecoveryPassword: '',
    },

    validationSchema: Yup.object({
      emailRecoveryPassword: emailValidation,
    }),

    onSubmit: async (values: UserEmailValidationType) => {
      setLoadingSendEmail(true);
      try {
        await requestPasswordRecovery(values.emailRecoveryPassword);
        setModalData('verify');
        setLoadingSendEmail(false);
      } catch (error: any) {
        console.error(error.message);
        setLoadingSendEmail(false);
      }
    },
  });
  async function onSubmitSendEmail() {
    setLoadingSendEmail(true);
    try {
      await requestPasswordRecovery(validationEmail.values.emailRecoveryPassword);
      setModalData('verify');
      setLoadingSendEmail(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingSendEmail(false);
    }
  }
  const handleSubmitLogin = async (values: UserLoginType) => {
    try {
      await login(values);
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };

  const verifyCode = async (event: FormEvent) => {
    event.preventDefault();
    setLoadingVerifyCode(true);
    try {
      // showSuccess(responseCode);
      if (
        await codeVerify({
          code: Number(code),
          email: validationEmail.values.emailRecoveryPassword,
        })
      ) {
        setModalData('newPass');
      }
      setLoadingVerifyCode(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingVerifyCode(false);
    }
  };

  useEffect(() => {
    if (modalData === '') {
      setLoadingSendEmail(false);
    }
    if (modalData === 'verify') {
      setTimer(60);
      setLoadingVerifyCode(false);
      if (inputs.current[0]) {
        inputs.current[0].focus();
      }
    }
  }, [modalData]);

  useEffect(() => {
    let intervalId: number | undefined;

    if (timer > 0 && modalOpen) {
      clearInterval(intervalId);
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [timer, modalOpen]);

  useEffect(() => {
    const handleInput = (index: number, event: ExtendedFormEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      const isDelete = event.inputType === 'deleteContentBackward';

      if (isDelete && value === '') {
        if (inputs.current[index - 1]) {
          inputs.current[index - 1].focus();
          return;
        }
      }

      if (value.length === 1) {
        if (inputs.current[index + 1]) {
          inputs.current[index + 1].focus();
        }
      } // Atualiza o estado com os valores dos inputs concatenados
      const novoNumero = inputs.current.reduce((acumulador, input) => {
        return acumulador + (input?.value || '');
      }, '');

      setCode(novoNumero.slice(0, 5)); // Limita o número a 5 dígitos
    };
    if (modalData === 'verify') {
      inputs.current.forEach((input, index) => {
        input.addEventListener('input', (event) => handleInput(index, event));
        input.addEventListener('focus', () => input.select());
      });

      return () => {
        setLoadingVerifyCode(false);
        inputs.current.forEach((input, index) => {
          input?.removeEventListener('input', (event) => handleInput(index, event));
          input?.removeEventListener('focus', () => input.select());
        });
      };
    }
  }, [modalData]);

  return {
    onClose,
    modalOpen,
    setModalOpen,
    modalData,
    handleSubmitLogin,
    setModalData,
    validation,
    loading: loadingLogin,
    loadingSendEmail,
    loadingVerifyCode,
    inputs,
    timer,
    setTimer,
    validCodeInputs,
    verifyCode,
    validationNewPassword,
    loadingNewPassword,
    code,
    validationEmail,
    onSubmitSendEmail,
  };
};
export default PasswordResetModalControllers;
