import DetailsCausesGraph from './DetailsCausesGraph';
import DetailsGraphs from './DetailsGraphs';
import { observer } from 'mobx-react';
import { survivalSurveysEvaluationType } from '../../../quality-survival-surveys-types';
import EmptyState from '@/component/EmptyState';
import { useTranslation } from 'react-i18next';

const ReportPdfItemResults = ({
  evaluation,
}: {
  evaluation: survivalSurveysEvaluationType;
}) => {
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  return (
    <div className="flex-1 flex-col gap-3">
      <DetailsGraphs evaluation={evaluation} />
      <div className="flex flex-1 mt-3 items-center relative">
        <div className="w-[66.5%]">
          <DetailsCausesGraph evaluation={evaluation} />
        </div>
        <div className="w-[32%] bg-bgray-200 rounded-lg absolute top-0 bottom-0 right-0">
          {evaluation.imageMapUrl ? (
            <img
              // className="bg-bgray-200"
              style={{ objectFit: 'contain' }}
              src={evaluation.imageMapUrl}
              width={'100%'}
              height={'100%'}
            />
          ) : (
            <EmptyState
              bg={'bgray-200'}
              size="sm"
              title={tSurvivalSurveys('reportPdf.emptyMap')}
              description={''}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ReportPdfItemResults);
