import Modal from '@/component/modals/modal-default';
import { formatNumber } from '@/utils/helpers/formaters';
import Colors from '@/utils/colors';
import { IoMdAlert, IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import moment from 'moment';
import { MdRunCircle } from 'react-icons/md';
import { IoSyncCircleSharp } from 'react-icons/io5';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LogsType } from '../../../planted-area-types';
import BadgeStatusApprovals from '../../badge-status-approvals';

const ModalHistory = () => {
  const { modalStates, handleModal } = plantedAreaNoteStore;
  const { note, history: visible } = modalStates;
  const tPlantedArea = useTranslation('plantedArea').t;
  const LogApprove = ({ log, index }: { log: LogsType; index: number }) => {
    return (
      <div key={log.id} className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          {note?.approved ? (
            <IoMdCheckmarkCircle
              color={Colors.primary[200]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
          ) : (
            <IoMdCloseCircle
              color={Colors.error[200]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
          )}
          <div
            className={`border-[1px] w-[1px] h-[${note?.approved && index === note.logs.length - 1 ? '40px' : '80px'}]`}
          ></div>
        </div>
        <div className="flex-1">
          <BadgeStatusApprovals note={{ approved: true }} showLevel={false} />
          <p className="dark:text-bgray-200">
            {tPlantedArea('listNotes.modals.modalHistory.infoApprove', {
              levelName: log.user.level.levelName,
              date: moment(log.createdAt).format('DD/MM/YYYY'),
              hour: moment(log.createdAt).format('HH:mm'),
              name: log.user.name,
            })}
          </p>
        </div>
      </div>
    );
  };

  if (!note) {
    return null;
  }
  const NextLevelApprovation = () => {
    if (!note.approved) {
      return (
        <div className="flex items-center">
          <div className="w-[10%] h-[80px] justify-center flex relative">
            <IoMdAlert
              color={Colors.warning[300]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
            <div className="border-[1px] w-[1px] h-[40px]"></div>
          </div>
          <div className="flex-1">
            <BadgeStatusApprovals
              note={{
                approved: false,
              }}
              showLevel={false}
            />
            <p className="dark:text-bgray-200">
              {tPlantedArea('listNotes.modals.modalHistory.pendingApprove', {
                levelName: note.nextLevelApproved
                  ? `| ${note.nextLevelApproved?.levelName}`
                  : '',
              })}
            </p>
          </div>
        </div>
      );
    }
  };

  const ColectTag = () => {
    return (
      <div className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          <MdRunCircle
            color={Colors.gray[300]}
            className="absolute top-[25px] z-10 bg-white dark:bg-darkblack-600"
            size={30}
          />
          <div className="border-[1px] w-[1px] top-[40px] absolute h-[40px]"></div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <div
              className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded bg-bgray-200 text-gray-400`}
            >
              {tPlantedArea('listNotes.modals.modalHistory.colected')}
            </div>
          </div>
          <p className="dark:text-bgray-200">
            {tPlantedArea('listNotes.modals.modalHistory.colectedInfo', {
              date: moment(note.createdAtMobile).format('DD/MM/YYYY'),
              hour: moment(note.createdAtMobile).format('HH:mm'),
              name: note.user.name,
            })}
          </p>
        </div>
      </div>
    );
  };
  const SyncTag = () => {
    return (
      <div className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          <IoSyncCircleSharp
            color={Colors.success[300]}
            className="absolute top-[25px] bg-white dark:bg-darkblack-600"
            size={30}
          />
          <div className="border-[1px] w-[1px] h-[80px]"></div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <div
              className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded bg-success-300 text-white`}
            >
              {tPlantedArea('listNotes.modals.modalHistory.synchronized')}
            </div>
          </div>
          <p className="dark:text-bgray-200">
            {tPlantedArea('listNotes.modals.modalHistory.synchronizedInfo', {
              date: moment(note.createdAt).format('DD/MM/YYYY'),
              hour: moment(note.createdAt).format('HH:mm'),
            })}
          </p>
        </div>
      </div>
    );
  };
  return (
    <Modal
      isActive={visible}
      handleClose={() => handleModal('history', false)}
      title={`${tPlantedArea('listNotes.modals.modalHistory.title')} #${formatNumber(note.id, 0)}`}
      badge={<BadgeStatusApprovals note={note} />}
    >
      <div className="w-full pb-5">
        {/* <ColectTag />
        <SyncTag /> */}
        {note?.logs?.length > 0 &&
          note.logs.map((log, index) => {
            if (log.action === 'createdAt') {
              return <ColectTag key={log.id} />;
            }
            if (log.action === 'synchronize') {
              return <SyncTag key={log.id} />;
            }
            if (log.action === 'approve') {
              return <LogApprove index={index} key={log.id} log={log} />;
            }
            return <LogApprove index={index} key={log.id} log={log} />;
          })}
        <NextLevelApprovation />
      </div>
    </Modal>
  );
};
export default observer(ModalHistory);
