import Modal from '@/component/modals/modal-default';
import { formatNumber } from '@/utils/helpers/formaters';
import BadgeStatusApprovals from '../../list/badge-status-approvals';
import { ApprovationType } from '../../quality-survival-surveys-types';
import Colors from '@/utils/colors';
import { IoMdAlert, IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import moment from 'moment';
import { MdRunCircle } from 'react-icons/md';
import { IoSyncCircleSharp } from 'react-icons/io5';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ModalHistoryApprovals = () => {
  const { modalStates, handleModal } = survivalSurveysEvaluationsStore;
  const { evaluation, history: visible } = modalStates;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const ItemEvaluationApproval = ({
    approvation,
    index,
  }: {
    approvation: ApprovationType;
    index: number;
  }) => {
    return (
      <div key={approvation.id} className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          {evaluation?.approved ? (
            <IoMdCheckmarkCircle
              color={Colors.primary[200]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
          ) : (
            <IoMdCloseCircle
              color={Colors.error[200]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
          )}
          <div
            className={`border-[1px] w-[1px] h-[${(evaluation?.approved || evaluation?.disapproved) && index === evaluation.approvals.length - 1 ? '40px' : '80px'}]`}
          ></div>
        </div>
        <div className="flex-1">
          <BadgeStatusApprovals
            evaluation={{
              approved: approvation.approved,
              disapproved: !approvation.approved,
            }}
            showLevel={false}
          />
          <p className="dark:text-bgray-200 text-sm ">
            {tSurvivalSurveys('modals.modalHistory.infoApprove', {
              levelName: approvation.user.level.levelName,
              date: moment(approvation.createdAt).format('DD/MM/YYYY'),
              hour: moment(approvation.createdAt).format('HH:mm'),
              name: approvation.user.name,
            })}
          </p>
        </div>
      </div>
    );
  };

  if (!evaluation) {
    return null;
  }
  const NextLevelApprovation = () => {
    if (!evaluation.approved && !evaluation.disapproved) {
      return (
        <div className="flex items-center">
          <div className="w-[10%] h-[80px] justify-center flex relative">
            <IoMdAlert
              color={Colors.warning[300]}
              className="absolute top-[25px] bg-white dark:bg-darkblack-600"
              size={30}
            />
            <div className="border-[1px] w-[1px] h-[40px]"></div>
          </div>
          <div className="flex-1">
            <BadgeStatusApprovals
              evaluation={{
                approved: false,
                disapproved: false,
              }}
              showLevel={false}
            />
            <p className="dark:text-bgray-200">
              {tSurvivalSurveys('modals.modalHistory.pendingApprove', {
                levelName: evaluation.nextLevelApproved
                  ? `| ${evaluation.nextLevelApproved?.levelName}`
                  : '',
              })}
            </p>
          </div>
        </div>
      );
    }
  };

  const ColectTag = () => {
    return (
      <div className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          <MdRunCircle
            color={Colors.gray[300]}
            className="absolute top-[25px] z-10 bg-white dark:bg-darkblack-600"
            size={30}
          />
          <div className="border-[1px] w-[1px] top-[40px] absolute h-[40px]"></div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <div
              className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded bg-bgray-200 text-gray-400`}
            >
              {tSurvivalSurveys('modals.modalHistory.colected')}
            </div>
          </div>
          <p className="dark:text-bgray-200">
            {tSurvivalSurveys('modals.modalHistory.colectedInfo', {
              date: moment(evaluation.dateTimeStart).format('DD/MM/YYYY'),
              hour: moment(evaluation.dateTimeStart).format('HH:mm'),
              name: evaluation.user.name,
            })}
          </p>
        </div>
      </div>
    );
  };
  const SyncTag = () => {
    return (
      <div className="flex items-center">
        <div className="w-[10%] h-[80px] justify-center flex relative">
          <IoSyncCircleSharp
            color={Colors.success[300]}
            className="absolute top-[25px] bg-white dark:bg-darkblack-600"
            size={30}
          />
          <div className="border-[1px] w-[1px] h-[80px]"></div>
        </div>
        <div className="flex-1">
          <div className="flex">
            <div
              className={`px-2 py-1 text-sm items-center font-semibold justify-center rounded bg-success-300 text-white`}
            >
              {tSurvivalSurveys('modals.modalHistory.synchronized')}
            </div>
          </div>
          <p className="dark:text-bgray-200">
            {tSurvivalSurveys('modals.modalHistory.synchronizedInfo', {
              date: moment(evaluation.createdAt).format('DD/MM/YYYY'),
              hour: moment(evaluation.createdAt).format('HH:mm'),
            })}
          </p>
        </div>
      </div>
    );
  };
  return (
    <Modal
      isActive={visible}
      handleClose={() => handleModal('history', false)}
      title={`${tSurvivalSurveys('modals.modalHistory.title')} #${formatNumber(evaluation.id, 0)}`}
      badge={<BadgeStatusApprovals evaluation={evaluation} />}
    >
      <div className="w-full pb-5">
        <ColectTag />
        <SyncTag />
        {evaluation?.approvals?.length > 0 &&
          evaluation.approvals.map((approvation, index) => {
            return (
              <ItemEvaluationApproval
                index={index}
                key={approvation.id}
                approvation={approvation}
              />
            );
          })}
        <NextLevelApprovation />
      </div>
    </Modal>
  );
};
export default observer(ModalHistoryApprovals);
