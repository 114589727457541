import { observer } from 'mobx-react';
import { forestRegistryStore, userStore } from '@/store';
import { useFetch } from '@/hooks';
import { useEffect } from 'react';
import CardFiltersStatus from './CardFilterStatus';
import ForestRegistryTableHeader from './TableHeader';
import ForestRegistryTable from './Table';
import ForestRegistryMap from './Map';

const ForestRegistryBody = () => {
  const {
    getForestRegistryData,
    getForestRegistryMapData,
    filter: filters,
    tableParams,
    screenActive,
  } = forestRegistryStore;
  const { request } = useFetch(getForestRegistryData);
  const { request: requestMap } = useFetch(getForestRegistryMapData);

  async function requestData() {
    screenActive === 'map' ? await requestMap() : await request();
  }
  useEffect(() => {
    requestData();
  }, [filters, tableParams, screenActive, userStore.companyActive]);

  return (
    <div className="rounded-lg bg-white items-center text-nowrap dark:bg-darkblack-600 shadow-lg">
      <CardFiltersStatus />
      <ForestRegistryTableHeader />
      <ForestRegistryTable />
      <ForestRegistryMap />
    </div>
  );
};
export default observer(ForestRegistryBody);
