import RightSide from './right-side-signin';
import signinImg from '@/assets/images/background/login_background.png';
import { useLocation } from 'react-router-dom';
import LeftSideLogin from './left-side-signin';
import LeftSideNewPassword from './left-side-new-password';
import { observer } from 'mobx-react';
import HMLComponent from '@/component/hml-component';
import { translationI18n } from '@/i18n';

function SignIn() {
  document.title = translationI18n('signin:document-title');
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <section className="bg-white dark:bg-darkblack-500 h-full">
      <HMLComponent />
      <div className="flex flex-col lg:flex-row justify-between min-h-screen">
        {currentPath === '/resetPassword' ? <LeftSideNewPassword /> : <LeftSideLogin />}
        <RightSide img={signinImg} />
      </div>
    </section>
  );
}

export default observer(SignIn);
