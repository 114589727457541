import SmallPrimaryButton from '@/component/buttons/SmallPrimaryButton';
import SmallSecondaryButton from '@/component/buttons/SmallSecondaryButton';
import Input from '@/component/forms/Input';
import { translationI18n } from '@/i18n';
import { userStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { checkPermission } from '@/utils/helpers/validations';
import { Skeleton } from '@mui/material';
import { isNull } from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';

type Props = {
  title: string;
  value: string | number;
  onSubmit: (value: string | number) => void;
  loading?: boolean;
  suffix?: string;
  number?: 'float' | 'int';
};
const ParamsItem = ({
  title,
  value,
  onSubmit,
  loading = false,
  suffix = '%',
  number = 'float',
}: Props) => {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  function handleEditable() {
    setEditable(!editable);
  }
  useEffect(() => {
    setInputValue(value);
  }, [value]);
  const isFloat = number === 'float';
  const hasPermission =
    (checkPermission({
      service: 'survivalSurveys',
      permission: 'hasone',
    }) &&
      checkPermission({
        service: 'params',
        permission: 'permissionUpdate',
      })) ||
    userStore.user.master;
  return (
    <div className="flex-col">
      <div className="px-3 py-2 border rounded-lg ">
        <p className="text-xl text-center dark:text-white">{title}</p>
        {editable ? (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit(inputValue);
              handleEditable();
            }}
            className="mt-3"
          >
            <Input
              value={inputValue}
              type={'number'}
              step={isFloat ? 0.01 : 1}
              onChange={function (event: ChangeEvent<HTMLInputElement>): void {
                setInputValue(event.target.value);
              }}
              error={isNull(inputValue)}
              errorMessage={translationI18n(
                'companyProfile:body.screens.params.items.survivalSurveys.params.paramsEvaluation.fieldRequired',
              )}
            />
            <div className="flex items-center justify-center gap-2">
              <SmallSecondaryButton
                text={translationI18n('common:actions.cancel')}
                onClick={handleEditable}
              />
              <SmallPrimaryButton
                text={translationI18n('common:actions.save')}
                onClick={() => {
                  onSubmit(inputValue);
                  handleEditable();
                }}
                loading={loading}
              />
            </div>
          </form>
        ) : loading ? (
          <div className="flex gap-2 items-center justify-center">
            <Skeleton
              variant="rectangular"
              height={48}
              width={130}
              className="rounded dark:bg-darkblack-400"
            />
          </div>
        ) : (
          <div className="flex gap-2 items-center justify-center">
            <p className="text-5xl text-center font-bold text-gray-700 dark:text-white">{`${formatNumber(value, isFloat ? 2 : 0)}${suffix}`}</p>
            {hasPermission && (
              <button className="" onClick={handleEditable}>
                <FaEdit size={25} className="text-gray-700 dark:text-white" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ParamsItem;
