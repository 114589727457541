import Input from '@/component/forms/Input';
import Modal from '@/component/modals/modal-default';
import {
  AddSurvivalSurveysDataType,
  ModalStateType,
} from '../survival-surveys-times-types';
import { FormikContextType } from 'formik';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import { translationI18n } from '@/i18n';

const ModalAddSurvivalSurveysTime = ({
  validation,
  modalState,
  setModalState,
}: {
  validation: FormikContextType<AddSurvivalSurveysDataType>;
  modalState: ModalStateType;

  setModalState: (modalState: ModalStateType) => void;
}) => {
  const { time } = modalState;
  const handleClose = () => {
    setModalState({ ...modalState, add: false, time: undefined });
  };
  return (
    <Modal
      title={`${time?.id ? translationI18n('common:actions.edit') : translationI18n('common:actions.add')} ${translationI18n('common:parameter')}`}
      isActive={modalState.add}
      variant="sm"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton
            text={translationI18n('common:actions.cancel')}
            onClick={handleClose}
          />
          <ButtonSubmit
            title={
              time?.id
                ? translationI18n('common:actions.update')
                : translationI18n('common:actions.save')
            }
            disabled={modalState.loading}
            loading={modalState.loading}
            onClick={() => validation.handleSubmit()}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <Input
          disabled={modalState.loading}
          value={validation.values.time}
          onChange={validation.handleChange('time')}
          onBlur={validation.handleBlur('time')} // Adicione esta linha
          label={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.addLabel',
          )}
          type={'text'}
          error={!!(validation.errors.time && validation.touched.time)}
          errorMessage={validation.errors.time}
        />
      </form>
    </Modal>
  );
};
export default ModalAddSurvivalSurveysTime;
