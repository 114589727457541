import { dashboardSurvivalSurveysStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const SelectEvaluationTime = observer(() => {
  const {
    filtersSurvivalSurveys,
    handleSurvivalSurveysEvaluationTime,
    survivalSurveyEvaluationMapFilter,
  } = dashboardSurvivalSurveysStore;
  const { time: times } = filtersSurvivalSurveys;
  const tHome = useTranslation('home').t;
  if (!times || times.length <= 1) return null;
  return (
    <div className="flex flex-col absolute z-[2] right-2 top-2 text-darkblack-600 bg-white rounded">
      <div className="px-2 py-1 border-b-2">
        {tHome('survivalSurveys.map.evaluation')}
      </div>
      {times.map((time) => {
        return (
          <button
            key={time.value}
            onClick={() => handleSurvivalSurveysEvaluationTime(Number(time.value))}
            className={`px-2 py-1 ${survivalSurveyEvaluationMapFilter === Number(time.value) ? 'bg-success-100' : null}`}
          >
            {time.label}
          </button>
        );
      })}
    </div>
  );
});

export default SelectEvaluationTime;
