import { translationI18n } from '@/i18n';
import { Skeleton } from '@mui/material';

const PermissionsLoader = () => {
  const nItems = [1, 2, 3, 4, 5];
  const nRows = 1;
  const rows = [];
  for (let i = 0; i <= nRows; i++) {
    rows.push(
      <tr
        key={`PermissionLoader_${i}`}
        className="border-b border-bgray-400 dark:border-darkblack-400"
      >
        <td className={`py-2 px-3 min-w-[260px]`}>
          <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
            <Skeleton
              width={`${Math.floor(Math.random() * (150 - 110 + 1)) + 110}px`}
              height={19}
              className="dark:bg-darkblack-400"
            />
          </p>
        </td>
        {nItems.map((p) => {
          return (
            <td
              key={'items_' + p}
              className={`py-3 px-3 text-center items-center justify-center`}
            >
              <Skeleton
                className="dark:bg-darkblack-400"
                width={50}
                height={30}
                style={{ borderRadius: 10, alignSelf: 'center' }}
              />
            </td>
          );
        })}
      </tr>,
    );
  }

  const items = [];
  for (let i = 0; i < 3; i++) {
    items.push(
      <div
        key={`PermissionsLoaderItem_${i}`}
        className="rounded-lg bg-white px-[24px] py-[20px] dark:bg-darkblack-600"
      >
        <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
          <Skeleton
            className="dark:bg-darkblack-400"
            height={26}
            width={`${Math.floor(Math.random() * (150 - 110 + 1)) + 110}px`}
          />
        </h1>
        <div className="mt-3 overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="border-b border-bgray-400 dark:border-darkblack-400">
                <th
                  className={`py-2 text-left text-sm text-gray-500 min-w-[260px] dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.service',
                  )}
                </th>
                <th
                  className={`py-2 text-center text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.read',
                  )}
                </th>
                <th
                  className={`py-2 text-center text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.insert',
                  )}
                </th>
                <th
                  className={`py-2 text-center text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.update',
                  )}
                </th>
                <th
                  className={`py-2 text-center text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.delete',
                  )}
                </th>
                <th
                  className={`py-2 text-center text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-3`}
                >
                  {translationI18n(
                    'companyProfile:body.screens.levelsPermissions.columns.approve',
                  )}
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </div>,
    );
  }
  return items;
};

export default PermissionsLoader;
