import Table from '@/component/Table';
import { OptionsType, OrderTableTypes } from '@/component/Table/types';
import { useNavigate } from 'react-router-dom';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { useFetch } from '@/hooks';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { survivalSurveysEvaluationType } from '../../quality-survival-surveys-types';
import { checkPermission } from '@/utils/helpers/validations';
import EmptyState from '@/component/EmptyState';
import { columnsState } from '../../quality-survival-surveys-initital-states';
import { useTranslation } from 'react-i18next';
const SurvivalSurveysTable = () => {
  const navigate = useNavigate();
  const {
    getDataTable,
    filterStatus,
    dataRequest,
    listEvaluations,
    handleDataRequest,
    loading: loadinStore,
    handleModal,
    filter,
    filtersStatus,
    selectedRows,
    setSelectedRows,
  } = survivalSurveysEvaluationsStore;
  const { [filterStatus]: dataRequestStatus } = dataRequest;
  const { [filterStatus]: survivalSurveys } = listEvaluations;
  const { loading, request } = useFetch(getDataTable);
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  async function getSurveys() {
    await request();
  }

  useEffect(() => {
    getSurveys();
  }, [dataRequestStatus, filterStatus, filter, userStore.companyActive]);

  const options = (rowItem: { active: any }): OptionsType[] => {
    return [
      {
        label: tSurvivalSurveys('survivalSurveysList.table.options.viewHistory'),
        action: (row: survivalSurveysEvaluationType) => handleModal('history', true, row),
      },
      {
        label: tCommon('actions.inactive'),
        variant: 'danger',
        action: (row: survivalSurveysEvaluationType) => handleModal('delete', true, row),
        hide:
          !checkPermission({
            permission: 'permissionDelete',
            service: 'survivalSurveys',
          }) || !rowItem.active,
      },
      {
        label: tCommon('actions.reactive'),
        variant: 'success',
        action: (row: survivalSurveysEvaluationType) =>
          handleModal('reactive', true, row),
        hide:
          !checkPermission({
            permission: 'permissionDelete',
            service: 'survivalSurveys',
          }) || rowItem.active,
      },
    ].filter((x) => !x.hide);
  };
  if (
    (!loading && survivalSurveys.evaluations.length === 0) ||
    Number(filtersStatus.filter((option) => option.value === filterStatus)[0]?.value) ===
      0
  ) {
    return (
      <EmptyState
        title={tSurvivalSurveys('survivalSurveysList.table.emptyState.title')}
        description={tSurvivalSurveys('survivalSurveysList.table.emptyState.description')}
      />
    );
  }
  return (
    <Table
      disableSelectRow={(row: survivalSurveysEvaluationType) => {
        return row.nextLevelApproved?.id !== userStore.user.level.id;
      }}
      selectable={filterStatus === 'flowApproved'}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      columns={columnsState()}
      page={dataRequestStatus.page}
      size={dataRequestStatus.size}
      options={options}
      showSearch={false}
      totalPages={survivalSurveys.totalPages}
      data={survivalSurveys.evaluations}
      setPage={function (page: number): void {
        handleDataRequest(filterStatus, 'page', page);
      }}
      setSize={function (size: number): void {
        handleDataRequest(filterStatus, 'size', size);
      }}
      clickable={true}
      onPressRow={(row: survivalSurveysEvaluationType) =>
        navigate('/quality/survivalSurveys/' + row.cod)
      }
      loading={loading || loadinStore.statusFilter || loadinStore.table}
      order={dataRequestStatus.order}
      setOrder={function (order: OrderTableTypes): void {
        handleDataRequest(filterStatus, 'order', order);
      }}
      pt={0}
    />
  );
};
export default observer(SurvivalSurveysTable);
