import { Skeleton } from '@mui/material';

const HeaderLoader = () => {
  return (
    <div className="flex flex-col">
      <Skeleton
        variant="rectangular"
        className="mb-1 dark:bg-darkblack-400"
        width={170}
        height={27}
      />
      <Skeleton
        variant="rectangular"
        width={300}
        className=" dark:bg-darkblack-400"
        height={15}
      />
    </div>
  );
};
export default HeaderLoader;
