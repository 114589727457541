import { del, get, post, put, putFile } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  DataChartResultsType,
  DataUpdateCauseType,
  DeathCauseGroupedType,
  PendencieType,
  PutChangeImageResponseType,
  survivalSurveysDataRequestGetType,
  survivalSurveysEvaluationType,
  survivalSurveysListEvaluationsType,
  SurvivalSurveysListStatusType,
} from './quality-survival-surveys-types';
import { newApprovationOrReprovationDataType } from './modals/modal-repprove/types';
import moment from 'moment';
import ExcelJS from 'exceljs';
import _ from 'lodash';
import { translationI18n } from '@/i18n';
export async function getSurvivalSurveys(
  data: survivalSurveysDataRequestGetType,
): Promise<survivalSurveysListEvaluationsType> {
  const result = (await get(url.GET_QUALITY_SURVIVAL_SURVEYS, {
    params: data,
  })) as survivalSurveysListEvaluationsType;
  return result;
}
export async function getSurvivalSurveysById(
  id: number,
  companyId?: number,
): Promise<survivalSurveysEvaluationType> {
  const result = (await get(
    url.GET_QUALITY_SURVIVAL_SURVEYS + '/' + id,
    companyId ? { params: { companyId } } : undefined,
  )) as survivalSurveysEvaluationType;
  return result;
}
export async function getSurvivalSurveysByCod(
  cod: number,
  companyId?: number,
): Promise<survivalSurveysEvaluationType> {
  const result = (await get(
    url.GET_QUALITY_SURVIVAL_SURVEYS_BY_COD + '/' + cod,
    companyId ? { params: { companyId } } : undefined,
  )) as survivalSurveysEvaluationType;
  return result;
}
export async function getSurvivalSurveyDeathCauses(): Promise<DeathCauseGroupedType[]> {
  const result = await get(url.GET_QUALITY_SURVIVAL_SURVEYS_CAUSES_DEATH_MOBILE);
  return result;
}
export async function getListStatus(data: {
  companyId?: number;
}): Promise<SurvivalSurveysListStatusType> {
  const result = (await get(url.GET_QUALITY_SURVIVAL_SURVEYS_LIST_STATUS, {
    params: data,
  })) as SurvivalSurveysListStatusType;
  return result;
}
export async function getPendencies(data: {
  companyId: number;
}): Promise<PendencieType[]> {
  return (await get(url.GET_QUALITY_SURVIVAL_SURVEYS_PENDENCIES, {
    params: data,
  })) as PendencieType[];
}
export async function getChartsMonths(data: {
  year?: number;
  companyId: number;
}): Promise<DataChartResultsType> {
  return await get(url.GET_QUALITY_SURVIVAL_SURVEYS_SUMMARY_BY_YEAR, {
    params: data,
  });
}

export async function updateCause(data: DataUpdateCauseType) {
  return await put(url.PUT_QUALITY_SURVIVAL_SURVEYS_UPDATE_CAUSE + data.uuid, data);
}
export async function getMyApprovals(data: {
  companyId: number;
}): Promise<survivalSurveysEvaluationType[]> {
  return await get(url.GET_QUALITY_SURVIVAL_SURVEYS_MY_APPROVALS, { params: data });
}
export async function approveEvaluationsArr(data: {
  evaluations: number[];
}): Promise<survivalSurveysEvaluationType[]> {
  return await post(url.GET_QUALITY_SURVIVAL_SURVEYS_APPROVALS_ARR, data);
}
export async function approveEvaluation(
  data: newApprovationOrReprovationDataType,
): Promise<{ message: string; data: survivalSurveysEvaluationType }> {
  return await post(url.GET_QUALITY_SURVIVAL_SURVEYS_APPROVALS, data);
}
export async function deleteEvaluationRequest(id: number): Promise<{ message: string }> {
  return await del(url.GET_QUALITY_SURVIVAL_SURVEYS + '/' + id);
}
export async function reactiveEvaluationRequest(
  id: number,
): Promise<{ message: string }> {
  return await put(url.GET_QUALITY_SURVIVAL_SURVEYS_REACTIVE + id);
}

export const downloadExcel = (
  data: survivalSurveysEvaluationType[],
  fileNameExport: string,
) => {
  const getColumns = function (evaluation: survivalSurveysEvaluationType) {
    return {
      id: evaluation.id,
      regiao: evaluation.forestRegistry.regiao,
      fazenda: evaluation.forestRegistry.fazenda,
      talhao: evaluation.forestRegistry.talhao,
      'area (ha)': evaluation.forestRegistry.area,
      'data plantio': moment(evaluation.forestRegistry.dataPlantio).format('DD/MM/YYYY'),
      manejo: evaluation.forestRegistry.manejo,
      ciclo: evaluation.forestRegistry.ciclo,
      rotacao: evaluation.forestRegistry.rotacao,
      'material genetico': evaluation.forestRegistry.geneticMaterial,
      'espacamento entre linhas (m)': evaluation.forestRegistry.spacingBetweenLines,
      'espacamento entre plantas (m)': evaluation.forestRegistry.spacingBetweenPlants,
      'espacamento entre linhas aferido (m)': evaluation.spacingBetweenLines,
      'espacamento entre plantas aferido (m)': evaluation.spacingBetweenPlants,
      avaliacao: `${evaluation.time} ${translationI18n('survivalSurveys:survivalSurveysList.days')}`,
      'avaliacao real': evaluation.spotEvaluation
        ? 'SPOT'
        : moment(evaluation.dateTimeStart).diff(
            moment(evaluation.forestRegistry.dataPlantio),
            'days',
          ) + ` ${translationI18n('survivalSurveys:survivalSurveysList.days')}`,
      'sobrevivencia (%)': evaluation.percentageSurvival,
      mortalidade: 100 - evaluation.percentageSurvival,
      'amostragem (%)': evaluation.percentageSampling,
      'mudas avaliadas': evaluation.totalSeedlings,
      'mudas vivas': evaluation.liveSeedlings,
      'mudas mortas': evaluation.deadSeedlings,
      'data hora inicio': moment(evaluation.dateTimeStart).format('DD/MM/YYYY HH:mm:ss'),
      'data hora fim': moment(evaluation.dateTimeStart).format('DD/MM/YYYY HH:mm:ss'),
    };
  };

  const evaluations = data.map(getColumns);
  const summaryCauses = data.flatMap((evaluation) => {
    // Filtra os pontos que não estão vivos
    const deadDots = evaluation.survivalSurveyDots.filter((dot) => !dot.isAlive);

    // Agrupa os pontos mortos por 'deathCauseId'
    const groupedByDeathCause = _.groupBy(deadDots, 'deathCauseId');

    // Mapeia os grupos para o formato desejado
    return Object.values(groupedByDeathCause).map((dots) => {
      const firstDot = dots[0];
      return {
        ...getColumns(evaluation),
        origem: firstDot.deathCause?.origin,
        causa: firstDot.deathCause?.cause,
        descricao: firstDot.deathCause?.description,
        quantidade: dots.length,
      };
    });
  });
  const summaryCauses2 = data.map((evaluation) => {
    const deadDots = evaluation.survivalSurveyDots.filter((dot) => !dot.isAlive);
    const groupedByDeathCause = _.groupBy(deadDots, 'deathCauseId');

    const causesSummary = Object.values(groupedByDeathCause).reduce(
      (acc: Record<string, number>, dots) => {
        const cause = dots[0].deathCause?.cause || 'Unknown';
        acc[cause] = (acc[cause] || 0) + dots.length;
        return acc;
      },
      {},
    );

    return {
      ...getColumns(evaluation),
      ...causesSummary,
    };
  });
  const listAllCauses = data.flatMap((evaluation) => {
    const deadDots = evaluation.survivalSurveyDots.filter((dot) => !dot.isAlive);

    // Mapeia os grupos para o formato desejado
    return deadDots.map((dot) => {
      return {
        ...getColumns(evaluation),
        origem: dot.deathCause?.origin,
        causa: dot.deathCause?.cause,
        descricao: dot.deathCause?.description,
        latitude: dot.coordinates.lat,
        longitude: dot.coordinates.lng,
      };
    });
  });

  // Identificar todas as causas para garantir que todas as colunas sejam adicionadas ao Excel
  const allCauses = new Set<string>();
  summaryCauses2.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (
        ![
          'id',
          'regiao',
          'fazenda',
          'talhao',
          'area (ha)',
          'data plantio',
          'manejo',
          'ciclo',
          'rotacao',
          'material genetico',
          'espacamento entre linhas (m)',
          'espacamento entre plantas (m)',
          'espacamento entre linhas aferido (m)',
          'espacamento entre plantas aferido (m)',
          'avaliacao',
          'avaliacao real',
          'sobrevivencia (%)',
          'mortalidade',
          'amostragem (%)',
          'mudas avaliadas',
          'mudas vivas',
          'mudas mortas',
          'data hora inicio',
          'data hora fim',
        ].includes(key)
      ) {
        allCauses.add(key);
      }
    });
  });

  const allCausesArray = Array.from(allCauses).sort();

  const workbook = new ExcelJS.Workbook();
  const worksheetEvaluation = workbook.addWorksheet('Avaliações');
  const worksheetSummaryCauses = workbook.addWorksheet('Resumo Causas');
  const worksheetSummaryCauses2 = workbook.addWorksheet('Resumo Causas2');
  const worksheetSummaryPontos = workbook.addWorksheet('Pontos');

  // Adicione linha de cabeçalho para Avaliações
  worksheetEvaluation.addRow(Object.keys(evaluations[0]));
  evaluations.forEach((row) => {
    worksheetEvaluation.addRow(Object.keys(row).map((k) => row[k]));
  });
  worksheetSummaryCauses.addRow(Object.keys(summaryCauses[0]));
  summaryCauses.forEach((row) => {
    worksheetSummaryCauses.addRow(Object.keys(row).map((k) => row[k]));
  });
  worksheetSummaryPontos.addRow(Object.keys(listAllCauses[0]));
  listAllCauses.forEach((row) => {
    worksheetSummaryPontos.addRow(Object.keys(row).map((k) => row[k]));
  });

  // Adicione linha de cabeçalho para Resumo Causas2
  worksheetSummaryCauses2.addRow([
    ...Object.keys(getColumns(data[0])),
    ...allCausesArray,
  ]);
  summaryCauses2.forEach((row) => {
    const rowData = Object.keys(getColumns(data[0])).map((k) => row[k]);
    allCausesArray.forEach((cause) => {
      rowData.push(row[cause] || 0);
    });
    worksheetSummaryCauses2.addRow(rowData);
  });

  // Gere o arquivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileNameExport + '_' + moment().format('DD_MM_YY') + '.xlsx',
    );
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};

export const ChangeImage = async ({
  uuid,
  file,
}: {
  uuid: string;
  file: File;
}): Promise<PutChangeImageResponseType> => {
  const formData = new FormData();

  formData.append('file', file);
  const result = (await putFile(
    url.PUT_QUALITY_SURVIVAL_SURVEYS_UPDATE_IMAGE_MAP + uuid,
    formData,
  )) as PutChangeImageResponseType;
  return result;
};
