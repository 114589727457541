import { Link, useLocation } from 'react-router-dom';
import logo from '@/assets/images/logo/logo-short.svg';
import logoW from '@/assets/images/logo/logo-short-white.svg';
import pages from './pages';
import { userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { ServiceSystemType } from '@/utils/types/globalTypes';
import { observer } from 'mobx-react';
import { Tooltip } from '@mui/material';

function SidebarV2({ sideBar }: { sideBar: boolean }) {
  const { pathname: location } = useLocation();
  const { theme } = userStore;
  return (
    <aside
      className={`relative ${!sideBar ? 'block' : 'hidden'} w-[60px] bg-white  dark:bg-darkblack-600 overflow-y-auto shadow-lg z-40`}
      style={{ minHeight: window.innerHeight }}
    >
      <div className="relative top-0 z-30 w-full">
        <div className="sidebar-header sticky top-0 z-20 flex h-[80px] w-full items-center justify-center border-b border-r border-b-[#F7F7F7] border-r-[#F7F7F7] bg-white dark:border-darkblack-500 dark:bg-darkblack-600">
          <Link to="/" className="px-2">
            <img src={logo} className="block h-[50px] w-[100%] dark:hidden" alt="logo" />
            <img src={logoW} className="hidden h-[50px] w-[100%] dark:block" alt="logo" />
          </Link>
        </div>
        <div className="sidebar-body w-full pt-[14px]">
          <div className="flex flex-col items-center">
            <div className="nav-wrapper mb-[36px]">
              {pages()
                .filter((x) =>
                  x.services.some(
                    (y) =>
                      (y?.master && userStore.user.master) ||
                      (!y.master &&
                        checkPermission({
                          permission: 'hasone',
                          service: y.service as ServiceSystemType,
                        })) ||
                      (!y.master && y.service === ''),
                  ),
                )
                .map((route, indexModule) => {
                  return (
                    <div className="item-wrapper mb-5" key={'menu_' + indexModule}>
                      <ul className="mt-2.5 flex flex-col items-center justify-center">
                        {route.services
                          .filter(
                            (y) =>
                              (y?.master && userStore.user.master) ||
                              (!y.master &&
                                checkPermission({
                                  permission: 'hasone',
                                  service: y.service as ServiceSystemType,
                                })) ||
                              (!y.master && y.service === ''),
                          )
                          .map((service, indexService) => {
                            return (
                              <Tooltip
                                key={'submenu_' + indexModule + '-' + indexService}
                                title={service.serviceName}
                                placement="right"
                              >
                                <li className="item py-[11px]">
                                  <Link
                                    to={service.path}
                                    className={`${
                                      location === service.path ? 'nav-active' : ''
                                    }`}
                                  >
                                    <span className="item-ico">
                                      {service.icon(theme)}
                                    </span>
                                  </Link>
                                </li>
                              </Tooltip>
                            );
                          })}
                      </ul>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default observer(SidebarV2);
