import _ from 'lodash';
import OriginItem from './origin-item';
import LoaderItems from './loader-item';
import { SurvivalSurveysCausesTableControllerType } from './survival-surveys-causes-types';

const SurvivalSurveysCausesTable = ({
  controller,
}: {
  controller: SurvivalSurveysCausesTableControllerType;
}) => {
  const { causes, loadingCauses, onHandleActive, loadingUpdateCause, canInsert } =
    controller;
  return loadingCauses ? (
    <LoaderItems loading={loadingCauses} />
  ) : (
    causes &&
      _.map(causes, (origin, key) => (
        <OriginItem
          key={key}
          canInsert={canInsert}
          onHandleActive={onHandleActive}
          loadingUpdateCause={loadingUpdateCause}
          title={key}
          origin={origin}
        />
      ))
  );
};
export default SurvivalSurveysCausesTable;
