import ExcelJS from 'exceljs';
import { del, get, post, postFile, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  AddSurvivalSurveysCauseDataType,
  AddSurvivalSurveysCauseResponseType,
  DeleteSurvivalSurveysCauseResponseType,
  GetSurvivalSurveysCausesResponseType,
  GetSurvivalSurveysCausesType,
  SurvivalSurveyCauseType,
} from './survival-surveys-causes-types';
import moment from 'moment';

export const getParamsCauses = async (
  data: GetSurvivalSurveysCausesType,
): Promise<GetSurvivalSurveysCausesResponseType> => {
  const result = (await get(url.SURVIVALSURVEYS_CAUSES, {
    params: data,
  })) as GetSurvivalSurveysCausesResponseType;
  return result;
};

export const saveParamsCause = async (
  data: AddSurvivalSurveysCauseDataType,
): Promise<AddSurvivalSurveysCauseResponseType> => {
  const resultSave = (await post(
    url.SURVIVALSURVEYS_CAUSES,
    data,
  )) as GetSurvivalSurveysCausesResponseType;
  return resultSave;
};
export const udpateParamsCause = async (
  data: Partial<AddSurvivalSurveysCauseDataType>,
): Promise<AddSurvivalSurveysCauseResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await put(
    url.SURVIVALSURVEYS_CAUSES + '/' + data.id,
    data,
  )) as AddSurvivalSurveysCauseResponseType;
  return resultSave;
};
export const deleteParamsCause = async (
  data: Partial<AddSurvivalSurveysCauseDataType>,
): Promise<DeleteSurvivalSurveysCauseResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await del(
    url.SURVIVALSURVEYS_CAUSES + '/' + data.id,
  )) as DeleteSurvivalSurveysCauseResponseType;
  return resultSave;
};
export const postFileCauses = async (
  file: File,
  companyId: number,
): Promise<DeleteSurvivalSurveysCauseResponseType> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('companyId', companyId.toString());
  const resultSave = (await postFile(
    url.SURVIVALSURVEYS_CAUSES_UPLOAD,
    formData,
  )) as DeleteSurvivalSurveysCauseResponseType;
  return resultSave;
};
export const downloadExcel = (data: SurvivalSurveyCauseType[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Causas ' + moment().format('DD_MM_YY'));

  // Adicione dados ao arquivo Excel
  worksheet.addRow([
    'id',
    'origem',
    'causa',
    'descricao',
    'cor',
    ...data[0].i18n.flatMap((lang) => [
      lang.language + '_origem',
      lang.language + '_causa',
      lang.language + '_descricao',
    ]),
  ]);
  data.forEach((row) => {
    worksheet.addRow([
      row.id,
      row.origin,
      row.cause,
      row.description,
      row.color,
      ...row.i18n.flatMap((lang) => [lang.origin, lang.cause, lang.description]),
    ]);
  });

  // Gere o arquivo Excel
  workbook.xlsx.writeBuffer().then((buffer) => {
    // Crie um blob a partir do buffer
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Crie um URL para o blob
    const url = window.URL.createObjectURL(blob);

    // Crie um link para o URL e simule o clique para iniciar o download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'exemplo_upload_causas.xlsx');
    document.body.appendChild(link);
    link.click();

    // Limpe o URL e remova o link do corpo
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};
