import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import Modal from '@/component/modals/modal-default';
import { useState } from 'react';
import { forestRegistryStore } from '@/store';
import MapPolygonEditor from './map-polygon-editor';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ModalEditPolygon = () => {
  const { handleModalState, modalsState, onUpdateCoordinates } = forestRegistryStore;
  const [coordinates, setCoordinates] = useState<
    { coordinates: { lat: number; lng: number }[] }[]
  >([]);
  function handleClose() {
    handleModalState('polygonEditor', false);
  }
  const tForestRegistry = useTranslation('forestRegistry').t;
  const tCommon = useTranslation('common').t;
  return (
    <Modal
      handleClose={handleClose}
      isActive={modalsState.polygonEditor}
      title={tForestRegistry('modals.updatePolygon.title')}
      variant="lg"
      footer={
        <div className="flex justify-end gap-3">
          <SecondaryButton
            text={tCommon('actions.cancel')}
            disabled={modalsState.loading}
            onClick={handleClose}
          />
          <ButtonSubmit
            disabled={modalsState.loading || coordinates.length === 0}
            loading={modalsState.loading}
            onClick={() =>
              modalsState.forestRegistryItem &&
              onUpdateCoordinates({
                id: modalsState.forestRegistryItem?.id,
                coordinates: coordinates,
              })
            }
            title={tCommon('actions.save')}
          />
        </div>
      }
    >
      <div className="w-full">
        {modalsState?.forestRegistryItem && (
          <MapPolygonEditor
            forestRegistry={modalsState.forestRegistryItem}
            onCoordinatesChange={function (
              newCoordinates: { coordinates: { lat: number; lng: number }[] }[],
            ): void {
              setCoordinates(newCoordinates);
            }}
          />
        )}
      </div>
    </Modal>
  );
};
export default observer(ModalEditPolygon);
