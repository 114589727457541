import PaginationSize from './PaginationSize';
import PaginationPages from './PaginationPages';
import { PaginationType } from './types';
import { Skeleton } from '@mui/material';

function Pagination({
  page,
  setPage,
  totalPages,
  size,
  setSize,
  loading,
}: PaginationType) {
  return (
    <div className="pagination-content w-full">
      <div className="flex flex-col sm:flex-row space-y-4 w-full items-center justify-between sm:space-y-0">
        <PaginationSize size={size} setSize={setSize} />
        {loading && totalPages === 0 ? (
          <Skeleton className="dark:bg-darkblack-400" width={200} height={40} />
        ) : (
          <PaginationPages page={page} setPage={setPage} totalPages={totalPages} />
        )}
      </div>
    </div>
  );
}

export default Pagination;
