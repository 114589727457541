import PlotsAvaliableIcon from '@/assets/images/icons/forestRegistry/PlotsAvaliableIcon';
import { dashboardForestRegistryStore } from '@/store';
import CardLoader from './card-loader';
import { formatNumber } from '@/utils/helpers/formaters';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const CardPlotsAvaliables = observer(() => {
  const { loading, forestRegistrySummary: summary } = dashboardForestRegistryStore;
  const { forestRegistrySummary: loadingSummaryData } = loading;
  const tHome = useTranslation('home').t;
  if (loadingSummaryData) {
    return <CardLoader />;
  }
  if (loadingSummaryData) {
    return <CardLoader />;
  }
  return (
    <div className="bg-white rounded-lg p-3 flex-1 dark:bg-darkblack-500 shadow-lg">
      <div className="flex gap-3 items-center">
        <PlotsAvaliableIcon />
        <p className="dark:text-bgray-200">
          {tHome('forestRegistry.plotsAvaliables.title')}
        </p>
      </div>
      <p className="text-5xl font-bold dark:text-bgray-200">{`${formatNumber(summary.availableArea, 0)}ha`}</p>
      <div className="flex gap-1 text-bgray-500">
        <p>{`${formatNumber((summary.availableArea / summary.totalArea) * 100, 0)}%`}</p>
      </div>
    </div>
  );
});
export default CardPlotsAvaliables;
