import { userStore } from '@/store';
import Modal from '../modal-default';
import { observer } from 'mobx-react';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputPassword from '@/component/forms/InputPassword';
import { useEffect } from 'react';
import {
  passwordConfirmationValidation,
  passwordValidation,
} from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';

const ModalChangePassoword = () => {
  const tSignin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;
  const {
    modalChangePassword,
    handleModalChangePassword,
    confirmOldPassword,
    changePassword,
  } = userStore;

  function handleClose() {
    handleModalChangePassword({ ...modalChangePassword, show: false });
  }

  const validationOldPassword = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: passwordValidation(),
    }),

    onSubmit: async (values: { password: string }) => {
      await confirmOldPassword(values.password);
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      confirmPassword: '',
      newPassword: '',
    },

    validationSchema: Yup.object({
      newPassword: passwordValidation(),
      confirmPassword: passwordConfirmationValidation(),
    }),

    onSubmit: async (values: { newPassword: string; confirmPassword: string }) => {
      await changePassword(values);
    },
  });
  useEffect(() => {
    if (modalChangePassword.show) {
      validation.resetForm();
      validationOldPassword.resetForm();
    }
  }, [modalChangePassword.show, userStore.user.language]);

  return (
    <Modal
      variant="sm"
      isActive={modalChangePassword.show}
      handleClose={() =>
        handleModalChangePassword({ ...modalChangePassword, show: false })
      }
      title={tSignin('modalChangePassword.title')}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton
            text={
              modalChangePassword.screen === 'confirmOldPassword'
                ? tCommon('actions.cancel')
                : tCommon('actions.back')
            }
            onClick={() =>
              modalChangePassword.screen === 'confirmOldPassword'
                ? handleClose()
                : handleModalChangePassword({
                    ...modalChangePassword,
                    screen: 'confirmOldPassword',
                  })
            }
          />
          <ButtonSubmit
            onClick={() =>
              modalChangePassword.screen === 'confirmOldPassword'
                ? validationOldPassword.handleSubmit()
                : validation.handleSubmit()
            }
            title={
              modalChangePassword.screen === 'confirmOldPassword'
                ? tCommon('actions.valid')
                : tCommon('actions.save')
            }
            disabled={modalChangePassword.loading}
            loading={modalChangePassword.loading}
          />
        </div>
      }
    >
      {modalChangePassword.screen === 'confirmOldPassword' ? (
        <div className="w-full">
          <p className="text-bgray-600 dark:text-bgray-200 text-md mb-5">
            {tSignin('modalChangePassword.notePassword')}
          </p>
          <InputPassword
            value={validationOldPassword.values.password}
            disabled={modalChangePassword.loading}
            placeholder={tSignin('modalChangePassword.actualPassword')}
            onBlur={validationOldPassword.handleBlur('password')}
            onChange={validationOldPassword.handleChange('password')}
            error={
              !!(
                validationOldPassword.errors.password &&
                validationOldPassword.touched.password
              )
            }
            errorMessage={validationOldPassword.errors.password}
          />
        </div>
      ) : (
        <div className="w-full">
          <p className="text-bgray-600 dark:text-bgray-200 text-md mb-5">
            {tSignin('modalChangePassword.noteNewPassword')}
          </p>
          <InputPassword
            value={validation.values.newPassword}
            disabled={modalChangePassword.loading}
            placeholder={tSignin('modalChangePassword.newPassword')}
            onChange={validation.handleChange('newPassword')}
            onBlur={validation.handleBlur('newPassword')}
            error={!!(validation.errors.newPassword && validation.touched.newPassword)}
            errorMessage={validation.errors.newPassword}
          />
          <InputPassword
            value={validation.values.confirmPassword}
            disabled={modalChangePassword.loading}
            placeholder={tSignin('modalChangePassword.confirmNewPassword')}
            onBlur={validation.handleBlur('confirmPassword')}
            onChange={validation.handleChange('confirmPassword')}
            error={
              !!(validation.errors.confirmPassword && validation.touched.confirmPassword)
            }
            errorMessage={validation.errors.confirmPassword}
          />
        </div>
      )}
    </Modal>
  );
};

export default observer(ModalChangePassoword);
