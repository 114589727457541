import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from '@/assets/animations/loading.json'; // Substitua pelo caminho da sua animação
import { userStore } from '@/store';
import { observer } from 'mobx-react';
import { translationI18n } from '@/i18n';

const LoadingPage = observer(() => {
  const { loadingPage } = userStore;
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    let timer: number | undefined;

    if (loadingPage) {
      // Se o estado de carregamento for verdadeiro, inicia a animação
      setIsAnimating(true);
    } else if (isAnimating) {
      // Se o estado de carregamento for falso, mantém a animação por 1500ms
      timer = setTimeout(() => {
        setIsAnimating(false);
      }, 1450);
    }

    return () => {
      if (timer) {
        clearTimeout(timer); // Limpa o temporizador quando o componente for desmontado ou o loadingPage mudar
      }
    };
  }, [loadingPage, isAnimating]);

  // Configurações para a animação Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (!isAnimating) {
    return null;
  }

  return (
    <div className="z-50 fixed top-0 bottom-0 left-0 right-0 bg-white dark:bg-darkblack-500 flex items-center justify-center">
      <div className="flex-col justify-center items-center text-center">
        <div className="text-3xl text-primary-300 dark:text-white font-semibold">
          OnForest
        </div>
        <Lottie options={defaultOptions} height={250} width={300} />
        <div className="text-2xl text-primary-300 dark:text-white">
          {translationI18n('common:onForestNameSuffix')}
        </div>
      </div>
    </div>
  );
});

export default LoadingPage;
