export const IconWarning = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#FACC15" fillOpacity="0.1" />
      <mask
        id="mask0_2001_944"
        maskUnits="userSpaceOnUse"
        x="7"
        y="7"
        width="24"
        height="24"
      >
        <rect x="7" y="7" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2001_944)">
        <path
          d="M18 24H20V18H18V24ZM19 16C19.2833 16 19.5208 15.9042 19.7125 15.7125C19.9042 15.5208 20 15.2833 20 15C20 14.7167 19.9042 14.4792 19.7125 14.2875C19.5208 14.0958 19.2833 14 19 14C18.7167 14 18.4792 14.0958 18.2875 14.2875C18.0958 14.4792 18 14.7167 18 15C18 15.2833 18.0958 15.5208 18.2875 15.7125C18.4792 15.9042 18.7167 16 19 16ZM19 29C17.6167 29 16.3167 28.7375 15.1 28.2125C13.8833 27.6875 12.825 26.975 11.925 26.075C11.025 25.175 10.3125 24.1167 9.7875 22.9C9.2625 21.6833 9 20.3833 9 19C9 17.6167 9.2625 16.3167 9.7875 15.1C10.3125 13.8833 11.025 12.825 11.925 11.925C12.825 11.025 13.8833 10.3125 15.1 9.7875C16.3167 9.2625 17.6167 9 19 9C20.3833 9 21.6833 9.2625 22.9 9.7875C24.1167 10.3125 25.175 11.025 26.075 11.925C26.975 12.825 27.6875 13.8833 28.2125 15.1C28.7375 16.3167 29 17.6167 29 19C29 20.3833 28.7375 21.6833 28.2125 22.9C27.6875 24.1167 26.975 25.175 26.075 26.075C25.175 26.975 24.1167 27.6875 22.9 28.2125C21.6833 28.7375 20.3833 29 19 29ZM19 27C21.2333 27 23.125 26.225 24.675 24.675C26.225 23.125 27 21.2333 27 19C27 16.7667 26.225 14.875 24.675 13.325C23.125 11.775 21.2333 11 19 11C16.7667 11 14.875 11.775 13.325 13.325C11.775 14.875 11 16.7667 11 19C11 21.2333 11.775 23.125 13.325 24.675C14.875 26.225 16.7667 27 19 27Z"
          fill="#FACC15"
        />
      </g>
    </svg>
  );
};
