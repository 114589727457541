import { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react';
import { plantedAreaNoteStore, userStore } from '@/store';
import { useFetch } from '@/hooks';
import { FilterStatusType } from '../../../../planted-area-types';

const CardFiltersStatus = observer(() => {
  const {
    getDataFilters,
    filterStatus,
    filtersStatus: filters,
    filter,
    handleFilterStatus,
    loading,
  } = plantedAreaNoteStore;
  const { request: requestDataFilters } = useFetch(getDataFilters);

  async function getRequestDataFilters() {
    await requestDataFilters();
  }
  useEffect(() => {
    getRequestDataFilters();
  }, [filter, filter.query, userStore.companyActive]); // Atualiza os filtros quando o componente montar

  return (
    <div className="rounded-t-lg border-b overflow-x-auto overflow-style-none px-3 bg-white items-center text-nowrap h-full flex-col dark:bg-darkblack-600">
      <div className="w-full flex h-full">
        {loading.statusFilter
          ? [1, 2, 3, 4].map((item) => (
              <span key={item} className="px-2 py-3">
                <Skeleton
                  height={33}
                  width={120}
                  variant="text"
                  className=" dark:bg-darkblack-400"
                />
              </span>
            ))
          : filters.map((filter) => (
              <label
                key={filter.value}
                className={`flex items-center px-2 cursor-pointer  ${
                  filter.value === filterStatus
                    ? 'border-b-[3px] border-b-success-200 text-success-200 dark:text-success-200'
                    : 'dark:text-bgray-200'
                }`}
              >
                <input
                  type="radio"
                  name="filter"
                  value={filter.value}
                  checked={filter.value === filterStatus}
                  onChange={() =>
                    handleFilterStatus(filter.value as unknown as FilterStatusType)
                  }
                  className="hidden"
                />
                <span className={`px-2 py-3 text-md`}>{filter.label}</span>
              </label>
            ))}
      </div>
    </div>
  );
});

export default CardFiltersStatus;
