import React from 'react';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/calendar.css';
import './assets/css/date-picker.css';
import './assets/css/customStyles.css';
import './assets/css/font-awesome-all.min.css';
import './index.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-quill/dist/quill.snow.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'react-datepicker/dist/react-datepicker.css';

import ReactDOM from 'react-dom/client';

import { ToastContainer } from 'react-toastify';

const root = document.getElementById('root');
if (root) {
  ReactDOM.createRoot(root).render(
    <React.Fragment>
      <App />
      <ToastContainer />
    </React.Fragment>,
  );
}
