import { userStore } from '@/store';
import Modal from '../modal-default';
import { observer } from 'mobx-react';
import ButtonCameraIcon from '@/assets/images/icons/ButtonCamera';
import Input from '@/component/forms/Input';
import React, { useEffect, useRef } from 'react';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { ScaleLoader } from 'react-spinners';
import Colors from '@/utils/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { UpdateMyUserDataType } from './types';
import { emptyStateValidation, phoneValidation } from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';

const ModalUser = () => {
  const tSignin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;

  const {
    handleModalUser,
    modalMyUser,
    user,
    handleChangeImage,
    handeRemoveImagem,
    loadingChangeImage,
    updateMyUser,
    handleModalChangePassword,
    modalChangePassword,
  } = userStore;
  const userName = user.name;
  const partsOfName = userName.split(' ');

  function handleCancelEdit() {
    handleModalUser({ ...modalMyUser, editable: false });
    validation.resetForm();
  }

  function handleClose() {
    handleModalUser({ ...modalMyUser, show: false });
    validation.resetForm();
  }
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: user.id,
      name: user.name,
      contact: user.contact,
    },
    validationSchema: Yup.object({
      id: Yup.number(),
      name: emptyStateValidation(),
      contact: phoneValidation(),
    }),

    onSubmit: async (values: UpdateMyUserDataType) => {
      await updateMyUser(values);
    },
  });

  useEffect(() => {
    if (user?.id) {
      validation.setFieldValue('id', user?.id);
      validation.setFieldValue('name', user?.name);
      validation.setFieldValue('levelId', Number(user?.level?.id));
    } else {
      validation.resetForm();
    }
  }, [user]);
  return (
    <Modal
      variant="sm"
      isActive={modalMyUser.show}
      handleClose={() => handleModalUser({ ...modalMyUser, show: false })}
      title={tSignin('modalMyUser.title')}
      footer={
        <div className="flex w-full justify-end space-x-3">
          {modalMyUser.editable && (
            <SecondaryButton
              text={
                modalMyUser.editable
                  ? tCommon('actions.cancel')
                  : tCommon('actions.close')
              }
              onClick={() => (modalMyUser.editable ? handleCancelEdit() : handleClose())}
            />
          )}
          <ButtonSubmit
            onClick={() =>
              modalMyUser.editable
                ? validation.handleSubmit()
                : handleModalUser({ ...modalMyUser, editable: true })
            }
            title={
              modalMyUser.editable ? tCommon('actions.save') : tCommon('actions.edit')
            }
            disabled={modalMyUser.loading}
            loading={modalMyUser.loading}
          />
        </div>
      }
    >
      <div className="w-full">
        <div
          onClick={() => setAnchor(null)}
          id="noti-outside"
          className={`fixed left-0 top-0 z-0 h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
        <div className="w-full items-center justify-center flex-col flex mb-5">
          <div className="h-[80px] w-[80px] rounded-3xl border border-bgray-400 relative">
            {loadingChangeImage ? (
              <div className="flex h-full w-full items-center justify-center">
                <ScaleLoader color={Colors.primary[200]} />
              </div>
            ) : userStore.user.imageUrl ? (
              <img
                className="object-cover h-full w-full rounded-3xl"
                src={userStore.user.imageUrl}
                alt={userStore.user.name}
                loading="lazy"
              />
            ) : (
              <div className="w-full h-full items-center justify-center flex text-5xl font-bold text-primary-200">
                {partsOfName[0][0] + partsOfName[partsOfName.length - 1][0]}
              </div>
            )}
            <button
              className="absolute right-[-23px] bottom-[-23px]"
              onClick={handleClick}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleChangeImage}
                accept="image/*"
              />
              <ButtonCameraIcon />
            </button>
            <BasePopup
              disablePortal
              id={id}
              open={open}
              anchor={anchor}
              placement="bottom"
            >
              <div className="bg-white z-20 dark:bg-darkblack-300 rounded-lg shadow-lg space-y-1 p-2 mt-[-10px] max-h-48 overflow-y-auto overflow-style-none">
                <p
                  className="text-base cursor-pointer dark:text-white text-nowrap text-center"
                  onClick={(event) => {
                    handleUploadButtonClick();
                    handleClick(event);
                  }}
                >
                  {user.imageUrl
                    ? tSignin('modalMyUser.changeImage')
                    : tSignin('modalMyUser.addImage')}
                </p>
                {user.imageUrl && (
                  <p
                    className="text-base cursor-pointer dark:text-white text-nowrap text-center"
                    onClick={(event) => {
                      handeRemoveImagem();
                      handleClick(event);
                    }}
                  >
                    {tSignin('modalMyUser.removeImage')}
                  </p>
                )}
              </div>
            </BasePopup>
          </div>
          <p className="mt-4 font-bold dark:text-white">{user.level.levelName}</p>
        </div>
        <Input
          value={validation.values.name}
          type={'text'}
          disabled={!modalMyUser.editable}
          label={tSignin('modalMyUser.fields.name')}
          onChange={validation.handleChange('name')}
          onBlur={validation.handleBlur('name')}
          error={!!(validation.errors.name && validation.touched.name)}
          errorMessage={validation.errors.name}
        />
        <Input
          value={validation.values.contact}
          type={'tel'}
          disabled={!modalMyUser.editable}
          label={tSignin('modalMyUser.fields.phone')}
          onChange={validation.handleChange('contact')}
          onBlur={validation.handleBlur('contact')}
          error={!!(validation.errors.contact && validation.touched.contact)}
          errorMessage={validation.errors.contact}
        />
        <div className="w-full items-center justify-center flex-col flex my-10">
          <button
            className="text-success-200 underline text-base"
            onClick={() => {
              handleModalChangePassword({ ...modalChangePassword, show: true });
              handleModalUser({ ...modalMyUser, show: false });
            }}
          >
            {tSignin('modalMyUser.changePassword')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(ModalUser);
