const NoMapIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48px"
      viewBox="0 -960 960 960"
      width="48px"
      fill="#EA3323"
    >
      <path d="M464.62-473.08h30.76v-227.69h-30.76v227.69ZM480-363.85q8.54 0 14.27-5.73t5.73-14.27q0-8.53-5.73-14.27-5.73-5.73-14.27-5.73t-14.27 5.73q-5.73 5.74-5.73 14.27 0 8.54 5.73 14.27t14.27 5.73Zm0 203.31q117.62-102.54 185.35-206.81 67.73-104.27 67.73-180.8 0-114.16-72.04-188Q589-810 480-810q-109 0-181.04 73.85-72.04 73.84-72.04 188 0 76.53 68.46 180.8Q363.85-263.08 480-160.54Zm0 41.31Q339-243.92 267.58-351.81q-71.43-107.88-71.43-196.34 0-126.93 82.66-209.39Q361.46-840 480-840q118.54 0 201.19 82.46 82.66 82.46 82.66 209.39 0 88.46-71.43 196.34Q621-243.92 480-119.23Zm0-436.15Z" />
    </svg>
  );
};

export default NoMapIcon;
