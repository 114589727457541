import { PuffLoader } from 'react-spinners';
import SwitchInput from '@/component/forms/SwitchInput';
import { PermissionItemType, permissionsPropsType } from './levels-permissions-types';
import { checkPermission } from '@/utils/helpers/validations';
import { ClickAwayListener, Tooltip } from '@mui/material';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useState } from 'react';
import { userStore } from '@/store';
import { observer } from 'mobx-react';
import { translationI18n } from '@/i18n';

const PermissionItem = ({
  module,
  onHandlePermission,
  loading,
  isMyCompany,
}: PermissionItemType) => {
  const permissions: permissionsPropsType[] = [
    'permissionRead',
    'permissionInsert',
    'permissionUpdate',
    'permissionDelete',
    'permissionApprove',
  ];
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div
      key={`permissions_${module.moduleName}`}
      className="rounded-lg bg-white px-[24px] py-[20px] dark:bg-darkblack-600 shadow-lg"
    >
      <div className="flex items-center gap-2">
        <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
          {module.moduleName}
        </h1>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            arrow
            disableTouchListener
            title={module.description}
          >
            <button onClick={handleTooltipOpen}>
              <FaRegQuestionCircle
                size={14}
                className="text-dark-300 dark:text-bgray-100"
              />
            </button>
          </Tooltip>
        </ClickAwayListener>
      </div>
      <div className="mt-3 overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-bgray-400 dark:border-darkblack-400">
              <th
                className={`py-2 text-left text-sm text-gray-500 dark:text-bgray-100 min-w-[260px] dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.service',
                )}
              </th>
              <th
                className={`py-2 text-center text-sm text-gray-500 dark:text-bgray-100 dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.read',
                )}
              </th>
              <th
                className={`py-2 text-center text-sm text-gray-500 dark:text-bgray-100 dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.insert',
                )}
              </th>
              <th
                className={`py-2 text-center text-sm text-gray-500 dark:text-bgray-100 dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.update',
                )}
              </th>
              <th
                className={`py-2 text-center text-sm text-gray-500 dark:text-bgray-100 dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.delete',
                )}
              </th>
              <th
                className={`py-2 text-center text-sm text-gray-500 dark:text-bgray-100 dark:bg-darkblack-600 bg-white px-3`}
              >
                {translationI18n(
                  'companyProfile:body.screens.levelsPermissions.columns.approve',
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {module.services?.map((service) => {
              const [openService, setOpenService] = useState(false);
              return (
                <tr
                  key={'tr_service' + service.service}
                  className="border-b border-bgray-400 dark:border-darkblack-400"
                >
                  <td className={`py-3 px-3 min-w-[260px] flex items-center gap-2`}>
                    <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
                      {service.serviceName}
                    </p>
                    <ClickAwayListener onClickAway={() => setOpenService(false)}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                        }}
                        onClose={() => setOpenService(false)}
                        open={openService}
                        disableFocusListener
                        disableHoverListener
                        arrow
                        disableTouchListener
                        title={service.description}
                      >
                        <button onClick={() => setOpenService(true)}>
                          <FaRegQuestionCircle
                            size={14}
                            className="text-bgray-900 dark:text-gray-200"
                          />
                        </button>
                      </Tooltip>
                    </ClickAwayListener>
                  </td>
                  {permissions.map((p) => {
                    return (
                      <td
                        key={'td_' + p}
                        className={`py-3 px-6 flex-col dark:text-gray-200 text-center items-center justify-center`}
                      >
                        {loading.service === service.service &&
                        loading.permissionField === p &&
                        loading.loading ? (
                          <div className="flex items-center justify-center">
                            <PuffLoader
                              color={userStore.theme === 'dark' ? 'white' : '#718096'}
                              size={26}
                            />
                          </div>
                        ) : service.permissions[p] ? (
                          <SwitchInput
                            disabled={
                              (isMyCompany &&
                                !checkPermission({
                                  service: 'levels',
                                  permission: 'permissionUpdate',
                                })) ||
                              (loading.loading && loading.service === service.service)
                            }
                            readOnly={
                              loading.loading ||
                              (isMyCompany &&
                                !checkPermission({
                                  service: 'levels',
                                  permission: 'permissionUpdate',
                                }))
                            }
                            checked={service[p]}
                            onChange={() => {
                              onHandlePermission(service, p);
                            }}
                            value={service[p]}
                            color={service[p] ? 'success' : 'error'}
                          />
                        ) : (
                          `N / A`
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default observer(PermissionItem);
