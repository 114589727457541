export const IconExclude = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="19" cy="19" r="19" fill="#FF0000" fillOpacity="0.13" />
      <mask
        id="mask0_2001_942"
        maskUnits="userSpaceOnUse"
        x="6"
        y="7"
        width="24"
        height="24"
      >
        <rect x="6" y="7" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2001_942)">
        <path
          d="M13 28C12.45 28 11.9792 27.8042 11.5875 27.4125C11.1958 27.0208 11 26.55 11 26V13H10V11H15V10H21V11H26V13H25V26C25 26.55 24.8042 27.0208 24.4125 27.4125C24.0208 27.8042 23.55 28 23 28H13ZM23 13H13V26H23V13ZM15 24H17V15H15V24ZM19 24H21V15H19V24Z"
          fill="#FF4747"
        />
      </g>
    </svg>
  );
};
