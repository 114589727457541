import PasswordResetModalControllers from './left-side-signin-controller';
import ButtonSubmit from '@/component/forms/button-submit';
import InputPassword from '@/component/forms/InputPassword';
import PasswordResetModal from './password-reset-password';
import Input from '@/component/forms/Input';
import { useTranslation } from 'react-i18next';

function LeftSideLogin() {
  const Controllers = PasswordResetModalControllers();
  const { setModalOpen, setModalData, validation, loading, modalOpen } = Controllers;
  const { t: tSignin } = useTranslation('signin');
  const { t: tCommon } = useTranslation('common');
  return (
    <div className="lg:w-1/2 px-5 xl:pl-12 pt-10">
      <div className="max-w-[450px] m-auto h-screen items-center justify-center flex">
        <div>
          <div className="flex-1">
            <header className="text-center mb-8">
              <h2 className="text-bgray-900 dark:text-gray-200 text-4xl font-semibold font-poppins mb-2">
                {tSignin('enterSystem')}
              </h2>
              <p className="font-urbanis text-base font-medium text-bgray-600 dark:text-bgray-50">
                {tSignin('enterSystemSubtitle')}
              </p>
            </header>

            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <Input
                value={validation.values.email}
                onBlur={validation.handleBlur('email')}
                disabled={loading}
                onChange={validation.handleChange('email')}
                type="text"
                placeholder={tCommon('email')}
                error={!!(validation.errors.email && validation.touched.email)}
                errorMessage={validation.errors.email}
              />

              <InputPassword
                value={validation.values.password}
                onChange={validation.handleChange('password')}
                disabled={loading}
                onBlur={validation.handleBlur('password')}
                placeholder={tCommon('password')}
                error={!!(validation.errors.password && validation.touched.password)}
                errorMessage={validation.errors.password}
              />

              <div className="flex justify-between mb-7">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setModalData('');
                      setModalOpen(true);
                    }}
                    data-target="#multi-step-modal"
                    className="modal-open text-primary-200 font-semibold text-base underline"
                  >
                    {tSignin('forgotMyPassword')}
                  </button>
                </div>
              </div>
              <ButtonSubmit
                loading={loading && !modalOpen}
                disabled={false}
                title={tCommon('actions.enter')}
              />
            </form>
          </div>
          <p className="text-bgray-600 dark:text-gray-200 text-center text-sm mt-6">
            {tCommon('copyriht')}
          </p>
        </div>
      </div>
      <PasswordResetModal passwordResetModalControllers={Controllers} />
    </div>
  );
}

export default LeftSideLogin;
