import LinkGreenIcon from '@/assets/images/icons/LinkGreenIcon';
import Select from '@/component/forms/Select';
import _ from 'lodash';
import { ForestRegistryModalImportKMLControllerType } from '../modal-import-kml-types';
import { useTranslation } from 'react-i18next';

const Columns = ({
  controller,
}: {
  controller: ForestRegistryModalImportKMLControllerType;
}) => {
  const { columns, columnsOfKML, setColumns, fileInfos } = controller;
  const tForestRegistry = useTranslation('forestRegistry').t;
  const optionDefault = {
    label: tForestRegistry('modals.uploadKML.columnsSelect.selectColumn'),
    value: '',
  };
  const options = [
    ...[optionDefault],
    ...columnsOfKML.map((column) => {
      return { label: column, value: column };
    }),
  ].sort((a, b) => a - b);
  return (
    <div className="flex w-full justify-center mt-3">
      <div className="flex-col flex-1">
        <p className="text-center text-gray-600 font-medium">
          {tForestRegistry('modals.uploadKML.columnsSelect.actualFields')}
        </p>
        <div className="bg-bgray-200 px-2 h-10  mb-3 mt-1 flex items-center rounded-lg text-gray-600 text-base">
          {tForestRegistry('modals.uploadKML.columnsSelect.fazenda')}
        </div>
        <div className="bg-bgray-200 px-2 h-10  mb-3 flex items-center rounded-lg text-gray-600 text-base">
          {tForestRegistry('modals.uploadKML.columnsSelect.talhao')}
        </div>
      </div>
      <LinkGreenIcon />
      <div className="flex-col flex-1">
        <p className="text-center text-gray-600 font-medium">
          {tForestRegistry('modals.uploadKML.columnsSelect.referenceFields')}
        </p>
        <Select
          disabled={fileInfos.totalPolygons > 0}
          small
          value={columns.fazenda}
          data={options.filter(
            (option) => option.value !== columns.talhao || option.value === '',
          )}
          onChange={function (newValue): void {
            setColumns({ ...columns, fazenda: String(newValue) });
          }}
        />
        <Select
          small
          disabled={fileInfos.totalPolygons > 0}
          value={columns.talhao}
          data={_.filter(
            options,
            (option) => option.value !== columns.fazenda || option.value === '',
          )}
          onChange={(newValue) => {
            setColumns({ ...columns, talhao: String(newValue) });
          }}
        />
      </div>
    </div>
  );
};
export default Columns;
