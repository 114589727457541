import { useEffect, useState } from 'react';
import {
  deleteParamsGeneticMaterial,
  getParamsGeneticMaterial,
  saveParamsGeneticMaterial,
  udpateParamsGeneticMaterial,
} from './services';
import {
  AddControlSilviculturePlantedAreaGeneticMaterialDataType,
  GetControlSilviculturePlantedAreaGeneticMaterialResponseType,
  ModalStateType,
  PlantedAreaGeneticMaterialsTableTableControllerType as PlantedAreaGeneticMaterialsTableControllerType,
  ControlSilviculturePlantedAreaGeneticMaterialType,
} from './types';
import { ColumnsTypes, OptionsType, OrderTableTypes } from '@/component/Table/types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '@/component/Toast';
import { companyProfileStore, userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { translationI18n } from '@/i18n';
const { showSuccess } = Toast;
const PlantedAreaGeneticMaterialsTableController =
  (): PlantedAreaGeneticMaterialsTableControllerType => {
    const { companyData, isMyCompany } = companyProfileStore;
    const companyId = companyData.id;
    const columns: () => ColumnsTypes[] = () => {
      return [
        {
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.columns.cod',
          ),
          field: 'cod',
          type: 'int',
        },
        {
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.columns.geneticMaterialName',
          ),
          field: 'genetic',
          type: 'string',
        },
        {
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.columns.status',
          ),
          field: 'active',
          type: 'active',
        },
        {
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.columns.createdAt',
          ),
          field: 'createdAt',
          type: 'datetime',
        },
        {
          title: translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.columns.actions',
          ),
          field: 'c',
          type: 'options',
          ordenable: false,
          hide: !(
            checkPermission({ service: 'params', permission: 'permissionUpdate' }) ||
            checkPermission({ service: 'params', permission: 'permissionDelete' })
          ),
        },
      ].filter((x) => !x.hide);
    };
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [size, setSize] = useState(10);
    const [order, setOrder] = useState<OrderTableTypes>({
      column: 'id',
      direction: 'asc',
    });
    const [geneticMaterials, setGeneticMaterials] = useState<
      ControlSilviculturePlantedAreaGeneticMaterialType[]
    >([]);
    const [loadingGeneticMaterials, setLoadingGeneticMaterials] = useState(false);
    const [modalsState, setModalsState] = useState<ModalStateType>({
      add: false,
      delete: false,
      loading: false,
    });
    const getTimes = async () => {
      setLoadingGeneticMaterials(true);
      const response: GetControlSilviculturePlantedAreaGeneticMaterialResponseType =
        await getParamsGeneticMaterial({
          page,
          size,
          column: order.column,
          direction: order.direction,
          companyId: Number(companyId),
        });

      setGeneticMaterials(response.geneticMaterials);
      setTotalPages(response.totalPages);
      setLoadingGeneticMaterials(false);
    };
    const validation = useFormik({
      enableReinitialize: true,

      initialValues: modalsState.geneticMaterial?.id
        ? {
            id: modalsState.geneticMaterial?.id,
            geneticMaterial: modalsState.geneticMaterial?.genetic || 1,
          }
        : {
            companyId: Number(companyId),
            geneticMaterial: modalsState.geneticMaterial?.genetic || 1,
          },

      validationSchema: Yup.object({
        genetic: Yup.string().required(
          translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.fieldRequired',
          ),
        ),
      }),

      onSubmit: async (
        values: AddControlSilviculturePlantedAreaGeneticMaterialDataType,
      ) => {
        try {
          setModalsState({ ...modalsState, loading: true });
          const result = modalsState.geneticMaterial?.id
            ? await udpateParamsGeneticMaterial(values)
            : await saveParamsGeneticMaterial(values);
          showSuccess(result);
          if (modalsState.geneticMaterial?.id) {
            setGeneticMaterials(
              geneticMaterials.map((t) => {
                return modalsState.geneticMaterial?.id &&
                  modalsState.geneticMaterial?.id === t.id
                  ? result.data
                  : t;
              }),
            );
          } else {
            getTimes();
          }
          setModalsState({
            ...modalsState,
            loading: false,
            add: false,
            geneticMaterial: undefined,
          });
        } catch (error: any) {
          console.error(error.message);
          setModalsState({
            ...modalsState,
            loading: false,
          });
        }
      },
    });

    const onDelete = async () => {
      setModalsState({ ...modalsState, loading: true });
      try {
        await deleteParamsGeneticMaterial({ id: modalsState.geneticMaterial?.id });
        setGeneticMaterials(
          geneticMaterials.filter((t) => t.id !== modalsState.geneticMaterial?.id),
        );
        setModalsState({
          ...modalsState,
          delete: false,
          loading: false,
          geneticMaterial: undefined,
        });
      } catch (error: any) {
        console.error(error.message);
        setModalsState({ ...modalsState, loading: false });
      }
    };
    useEffect(() => {
      if (modalsState.geneticMaterial?.id) {
        validation.setFieldValue('id', modalsState.geneticMaterial?.id);
        validation.setFieldValue('genetic', modalsState.geneticMaterial?.genetic);
        validation.setFieldValue(
          'companyId',
          Number(modalsState.geneticMaterial?.companyId),
        );
      } else {
        validation.resetForm();
      }
    }, [modalsState.geneticMaterial, modalsState.add]);

    useEffect(() => {
      getTimes();
    }, [page, size, order]);

    const options = (
      time: ControlSilviculturePlantedAreaGeneticMaterialType,
    ): OptionsType[] => {
      return [
        {
          label: translationI18n('common:actions.edit'),
          action: () =>
            setModalsState({ ...modalsState, add: true, geneticMaterial: time }),
          hide:
            isMyCompany &&
            !(
              checkPermission({
                permission: 'hasone',
                service: 'plantedArea',
              }) &&
              checkPermission({
                service: 'params',
                permission: 'permissionUpdate',
              })
            ),
        },
        {
          label: translationI18n('common:actions.delete'),
          action: () =>
            setModalsState({ ...modalsState, delete: true, geneticMaterial: time }),
          variant: 'danger',
          hide:
            isMyCompany &&
            !(
              checkPermission({
                permission: 'hasone',
                service: 'plantedArea',
              }) &&
              checkPermission({
                service: 'params',
                permission: 'permissionDelete',
              })
            ),
        },
      ].filter((x) => !x.hide);
    };
    const canInsert =
      (isMyCompany &&
        checkPermission({
          permission: 'hasone',
          service: 'plantedArea',
        }) &&
        checkPermission({
          service: 'params',
          permission: 'permissionInsert',
        })) ||
      userStore.user.master;
    return {
      columns,
      page,
      size,
      order,
      setOrder,
      setSize,
      setPage,
      modalsState,
      setModalsState,
      validation,
      totalPages,
      geneticMaterials,
      loadingGeneticMaterials,
      options,
      onDelete,
      canInsert,
    };
  };
export default PlantedAreaGeneticMaterialsTableController;
