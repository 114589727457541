import { translationI18n } from '../../i18n';

// export const getErrorMessage = (error): string => {
//   const hasMessage = error.message?.response?.data?.message;

//   return !!hasMessage ? hasMessage : defaultErrorMessage;
// };

const defaultErrorMessage = translationI18n('errorUndefined');
export class ResponseError extends Error {
  message: string;
  defaultErrorMessage = translationI18n('errorUndefined');

  constructor(error: unknown) {
    super();

    if (error instanceof Error) {
      if (
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response
      ) {
        const response = error.response as { data?: { message?: string } };
        this.message = response.data?.message || defaultErrorMessage;
      } else {
        this.message = error.message || defaultErrorMessage;
      }
    } else {
      this.message = defaultErrorMessage;
    }
  }
}
