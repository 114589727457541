import { useFetch } from '@/hooks';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import ApprovalItem from './AprrovalItem';
import SurvivalSurveysEmptyState from './EmptyState';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const MyApprovals = observer(
  ({ hasPermissionApprove }: { hasPermissionApprove: boolean }) => {
    const tSurvivalSurveys = useTranslation('survivalSurveys').t;
    if (!hasPermissionApprove) {
      return null;
    }
    const { getMyListApprovals, myApprovals } = survivalSurveysEvaluationsStore;
    const { loading, request } = useFetch(getMyListApprovals);
    async function getMyApprovals() {
      await request();
    }
    useEffect(() => {
      getMyApprovals();
    }, [userStore.companyActive]);

    return (
      <div className="rounded-lg  px-3  bg-white py-3 items-center h-full flex-col dark:bg-darkblack-600 flex-1 shadow-lg">
        <div className="flex items-center">
          <p className="dark:text-white font-bold">
            {tSurvivalSurveys('survivalSurveysList.headerResults.myApprovals.title')}
          </p>
          <p className="ml-2 bg-bgray-300 rounded text-sm px-2">
            {loading ? (
              <Skeleton variant="text" className=" dark:bg-darkblack-400" />
            ) : (
              formatNumber(myApprovals.length, 0)
            )}
          </p>
        </div>
        <div className="overflow-y-auto flex-col flex overflow-style-none space-y-2 h-[250px]">
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={'305px'}
                className=" rounded dark:bg-darkblack-400"
              />
              <Skeleton
                variant="rectangular"
                width={'100%'}
                height={305}
                className=" rounded dark:bg-darkblack-400"
              />
            </>
          ) : myApprovals.length === 0 ? (
            <SurvivalSurveysEmptyState
              description={tSurvivalSurveys(
                'survivalSurveysList.headerResults.myApprovals.emptyData',
              )}
            />
          ) : (
            myApprovals.map((evaluation) => {
              return <ApprovalItem key={evaluation.id} evaluation={evaluation} />;
            })
          )}
        </div>
      </div>
    );
  },
);

export default MyApprovals;
