import { SelectProps } from './types';
import CreatableSelect from 'react-select/creatable';
import ReactSelect, {
  GroupBase,
  OptionsOrGroups,
  SingleValue,
  StylesConfig,
} from 'react-select';
import Theme from '../../../../tailwind.config';
import { observer } from 'mobx-react';
import { isArray } from 'lodash';
import { userStore } from '@/store';
import Colors from '@/utils/colors';
import { useTranslation } from 'react-i18next';

const { colors } = Theme.theme.extend;

const Select = ({
  label = '',
  placeholder = '',
  value,
  data,
  error = false,
  errorMessage,
  required = false,
  creatable = false,
  loading = false,
  disabled = false,
  small = false,
  multiselect = false,
  onChange,
  onBlur = undefined,
}: SelectProps) => {
  const tCommon = useTranslation('common').t;
  const className = `items-center justify-stretch flex text-bgray-800 text-md border ${error ? 'border-error-300' : 'border-bgray-400'} ${
    small
      ? 'min-h-[30px] placeholder:text-base text-base'
      : 'min-h-[40px] placeholder:text-medium text-medium'
  } w-full focus:border-primary-200 focus:ring-0 rounded-lg SelectInput ${disabled ? 'placeholder:text-bgray-200' : 'dark:placeholder:text-white placeholder:text-bgray-500'} dark:bg-darkblack-500 dark:text-white`;

  const { theme } = userStore;
  const customStyles:
    | StylesConfig<string | number, false, GroupBase<string | number>>
    | undefined = {
    control: (provided) => ({
      ...provided,
      border: `0px solid`,
      boxShadow: 'none',
      borderRadius: '10px',
      height: '100%',
      width: '100%',
      alignItems: 'center',
      color: theme === 'dark' ? 'white' : colors.bgray[800],
      backgroundColor: theme === 'dark' ? colors.darkblack[500] : 'white',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: small ? '14px' : undefined,
      backgroundColor: theme === 'dark' ? colors.darkblack[500] : 'white',
      color: theme === 'dark' ? colors.gray[200] : colors.bgray[800],
      ':hover': {
        backgroundColor: theme === 'dark' ? colors.darkblack[300] : colors.gray[100],
      },
      ':selected': {
        color: theme === 'dark' ? colors.gray[200] : colors.primary[200],
      },
      zIndex: 999999,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: disabled
        ? Colors.bgray[500]
        : error && !provided.isFocused
          ? colors.error[300]
          : theme === 'dark'
            ? colors.bgray[200]
            : colors.bgray[800],
    }),
    input: (provided) => ({
      ...provided,
      alignItems: 'center',
      height: '100%',
      border: '0px solid',
      color: colors.primary[200],
      ringColor: 'transparent',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme === 'dark' ? colors.darkblack[500] : 'white',
    }),
  };

  const handleChange = (
    selectedOption: SingleValue<{ value: number | string; label: string }>,
  ) => {
    if (multiselect && isArray(selectedOption) && selectedOption) {
      onChange(selectedOption.map((option) => option.value));
    } else if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  return (
    <div className="mb-3">
      {label && (
        <label
          className={`block text-md mb-1 text-md font-medium ${
            error ? 'text-error-300' : 'text-bgray-600'
          } dark:text-white`}
        >
          {`${label} ${required ? '*' : ''}`}
        </label>
      )}
      {creatable ? (
        <CreatableSelect
          isLoading={loading}
          options={
            data as unknown as OptionsOrGroups<
              string | number,
              GroupBase<string | number>
            >
          }
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          value={
            multiselect && isArray(value)
              ? data?.filter((item) => value.includes(item.value))
              : data?.find((item) => item.value === value)
          }
          noOptionsMessage={() => tCommon('components.select.insertNewOption')}
          isDisabled={disabled || loading}
          className={className}
          styles={customStyles}
          formatCreateLabel={(label) =>
            tCommon('components.select.addLabel', { label: label })
          }
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement={'bottom'}
        />
      ) : (
        <ReactSelect
          isMulti={multiselect}
          isDisabled={disabled || loading}
          styles={customStyles}
          onBlur={onBlur}
          onChange={handleChange}
          noOptionsMessage={() =>
            tCommon('components.select.emptyResults', { label: label })
          }
          className={className}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          menuPlacement={'bottom'}
          value={
            multiselect && isArray(value)
              ? data?.filter((item) => value.includes(item.value))
              : data?.find((item) => item.value === value)
          }
          options={
            data as unknown as OptionsOrGroups<
              string | number,
              GroupBase<string | number>
            >
          }
          placeholder={placeholder}
          isLoading={loading}
        />
      )}
      {error && <p className="text-error-300 text-base mt-1">{errorMessage}</p>}
    </div>
  );
};

export default observer(Select);
