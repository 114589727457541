import { Skeleton } from '@mui/material';
import { ColumnsTypes } from '../types';

const LoadingTable = ({ columns, nRows }: { columns: ColumnsTypes[]; nRows: number }) => {
  const rows = []; // Inicializando um array para armazenar os elementos do loop

  // Utilizando um loop for para criar as linhas da tabela
  for (let i = 0; i < nRows; i++) {
    rows.push(
      <tr
        key={'RowLoading' + i}
        className="border-b border-bgray-400 dark:border-darkblack-400 w-full"
      >
        <td className={`py-2 justify-center px-6`} colSpan={columns.length}>
          <Skeleton className="dark:bg-darkblack-400" height={22} width={`100%`} />
        </td>
      </tr>,
    );
    // rows.push(
    //   <tr
    //     key={'RowLoading' + i}
    //     className="border-b border-bgray-400 dark:border-darkblack-400"
    //   >
    //     {columns.map((column) => (
    //       <td
    //         key={column.field}
    //         className={`py-2 justify-center px-6`}
    //         style={{
    //           minWidth: column?.width,
    //           maxWidth: column?.width,
    //         }}
    //       >
    //         <Skeleton
    //           className="dark:bg-gray-200"
    //           height={22}
    //           width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
    //         />
    //       </td>
    //     ))}
    //   </tr>,
    // ); // Adicionando uma linha à matriz
  }

  return rows;
};

export default LoadingTable;
