import { dashboardSurvivalSurveysStore } from '@/store';
import { formatNumber } from '@/utils/helpers/formaters';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';

const LegendChart = observer(() => {
  const {
    optionsSurvivalChart,
    causesDeaths,
    handleCausesDeaths,
    handleDeathCauseHighlight,
    survivalSurveysDataCause,
  } = dashboardSurvivalSurveysStore;

  return (
    <div className="flex top-2 px-4 flex-wrap gap-1">
      {isArray(optionsSurvivalChart?.series) &&
        optionsSurvivalChart?.series[0] &&
        isArray(optionsSurvivalChart?.series?.[0]?.data) &&
        optionsSurvivalChart?.series?.[0]?.data?.map((item, index) => {
          const causeObj = survivalSurveysDataCause.find(
            (cause) => cause.cause === item.name,
          );
          const isVisible = causesDeaths
            .filter((cause) => cause.show)
            .map((cause) => cause.cause)
            .includes(String(item.name));
          return (
            <button
              onClick={() => handleCausesDeaths(String(item.name))}
              key={index}
              // style={{ alignItems: 'center', justifyContent: 'center' }}
              className="flex gap-1 items-center cursor-pointer justify-center"
              onMouseEnter={() => {
                handleDeathCauseHighlight(String(item.name));
              }}
              onMouseOut={() => {
                handleDeathCauseHighlight('');
              }}
            >
              <div
                className="w-8 h-4 rounded"
                style={{
                  backgroundColor: item?.itemStyle?.color,
                  opacity: !isVisible ? 0.2 : 1,
                }}
              />
              <span
                className={`dark:text-bgray-200 text-sm inline-block ${
                  isVisible ? '' : 'line-through opacity-20'
                }`}
              >
                {item?.name}: {formatNumber(Number(causeObj?.percetage_quantity), 1)}%
              </span>
            </button>
          );
        })}
    </div>
  );
});

export default LegendChart;
