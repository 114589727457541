import { userStore } from '@/store';
import ModalActions from '../modal-actions';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ModalConfirmLogout = () => {
  const tSingnin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;
  const { handleModalLogout, logout, modalLogout } = userStore;
  return (
    <ModalActions
      isActive={modalLogout.showModal}
      handleClose={function (): void {
        handleModalLogout();
      }}
      handleSubmit={logout}
      variant={'alert'}
      title={tSingnin('modalConfirmLogout.title')}
      description={tSingnin('modalConfirmLogout.description')}
      submitText={tSingnin('modalConfirmLogout.title')}
      cancelText={tCommon('actions.cancel')}
      loading={false}
    />
  );
};
export default observer(ModalConfirmLogout);
