import { observer } from 'mobx-react';
import { ServicesParamsControllerTypes } from '../services-params-types';
import PlantedAreaGeneticMaterialsTableTable from './control/silvilculture/planted-area/planted-area-genetic-material';
import { PlantedAreaParamsTable } from './control/silvilculture/planted-area/planted-area-params';
import PlantedAreaReasonsTable from './control/silvilculture/planted-area/planted-area-reasons';
import EmptyStateServicesParams from './empty-state';
import SurvivalSurveysCausesTable from './quality/silviculture/survival-surveys/survival-surveys-causes';
import SurvivalSurveysParams from './quality/silviculture/survival-surveys/survival-surveys-params';
import SurvivalSurveysTimesTable from './quality/silviculture/survival-surveys/survival-surveys-times';

const ParamsTable = ({ controller }: { controller: ServicesParamsControllerTypes }) => {
  const {
    activeParam,
    CausesController,
    TimesController,
    ReasonsController,
    GeneticMaterialsController,
  } = controller;
  if (activeParam === '') return <EmptyStateServicesParams />;
  let activeScreen;
  switch (activeParam) {
    case 'survivalSurveysTime':
      activeScreen = <SurvivalSurveysTimesTable controller={TimesController} />;
      break;
    case 'survivalSurveysParams':
      activeScreen = <SurvivalSurveysParams controller={CausesController} />;
      break;
    case 'survivalSurveysCause':
      activeScreen = <SurvivalSurveysCausesTable controller={CausesController} />;
      break;
    case 'plantedAreaReason':
      activeScreen = <PlantedAreaReasonsTable controller={ReasonsController} />;
      break;
    case 'plantedAreaGeneticMaterial':
      activeScreen = (
        <PlantedAreaGeneticMaterialsTableTable controller={GeneticMaterialsController} />
      );
      break;
    case 'plantedAreaParams':
      activeScreen = <PlantedAreaParamsTable />;
      break;
  }
  return activeScreen;
};

export default observer(ParamsTable);
