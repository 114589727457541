const PendencieIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_526_21992)">
        <path
          d="M15.2978 15.5192C15.2978 15.5192 14.0394 17.4638 11.9871 17.8229C10.4106 18.099 8.58618 16.0751 8.58618 16.0751C8.58618 16.0751 9.81573 14.7012 11.3923 14.4251C13.244 14.1011 15.2978 15.5192 15.2978 15.5192Z"
          stroke="#FF4141"
          strokeWidth="1.26"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M4.38574 11.3304C4.38574 11.3304 5.81427 10.1413 7.47222 10.356C8.74587 10.5209 9.66042 12.5069 9.66042 12.5069C9.66042 12.5069 8.39044 13.2661 7.11627 13.1007C5.62054 12.9075 4.38574 11.3304 4.38574 11.3304Z"
          stroke="#FF4141"
          strokeWidth="1.26"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M11.3916 9.97565C11.3916 9.97565 10.0277 8.49252 10.1715 6.68547C10.2823 5.29737 12.3833 4.20117 12.3833 4.20117C12.3833 4.20117 13.2732 5.53572 13.1624 6.92382C13.0333 8.55447 11.3916 9.97565 11.3916 9.97565Z"
          stroke="#FF4141"
          strokeWidth="1.26"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M15.5435 9.32129C15.5435 9.32129 14.3534 11.2932 14.9261 13.2845C15.3661 14.8144 17.9696 15.5641 17.9696 15.5641C17.9696 15.5641 18.6626 13.8662 18.2226 12.3369C17.7055 10.5398 15.5435 9.32129 15.5435 9.32129Z"
          stroke="#FF4141"
          strokeWidth="1.26"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M19.0861 15.7488C12.2611 15.7488 7.53613 10.4988 7.53613 3.67383"
          stroke="#FF4141"
          strokeWidth="1.26"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <mask
          id="mask0_526_21992"
          maskUnits="userSpaceOnUse"
          x="9"
          y="8"
          width="15"
          height="14"
        >
          <rect
            x="10.3511"
            y="8.5"
            width="13"
            height="13"
            fill="#D9D9D9"
            stroke="white"
          />
        </mask>
        <g mask="url(#mask0_526_21992)">
          <path
            d="M14.703 20.0653C15.3766 20.356 16.0943 20.5 16.8511 20.5C17.6078 20.5 18.3255 20.356 18.9992 20.0653C19.6631 19.7788 20.2456 19.3876 20.7421 18.8911C21.2386 18.3945 21.6299 17.812 21.9164 17.1481C22.2071 16.4745 22.3511 15.7567 22.3511 15C22.3511 14.2433 22.2071 13.5255 21.9164 12.8519C21.6299 12.188 21.2386 11.6055 20.7421 11.1089C20.2456 10.6124 19.6631 10.2212 18.9992 9.93467C18.3255 9.64399 17.6078 9.5 16.8511 9.5C16.0943 9.5 15.3766 9.64399 14.703 9.93467C14.039 10.2212 13.4565 10.6124 12.96 11.1089C12.4635 11.6055 12.0722 12.188 11.7857 12.8519C11.4951 13.5255 11.3511 14.2433 11.3511 15C11.3511 15.7567 11.4951 16.4745 11.7857 17.1481C12.0722 17.812 12.4635 18.3945 12.96 18.8911C13.4565 19.3876 14.039 19.7788 14.703 20.0653Z"
            fill="#FF4141"
            stroke="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_526_21992">
          <rect width="21" height="21" fill="white" transform="translate(0.851074)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PendencieIcon;
