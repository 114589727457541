import CompanyIcon from '@/assets/images/icons/menus/CompanyIcon';
import DashboardIcon from '@/assets/images/icons/menus/DashboardIcon';
import ForestRegistryIcon from '@/assets/images/icons/menus/ForestRegistryIcon';
import ParamsIcon from '@/assets/images/icons/menus/ParamsIcon';
import PlantedAreaIcon from '@/assets/images/icons/menus/PlantedAreaIcon';
import QualitySurvivalsurveysIcon from '@/assets/images/icons/menus/QualitySurvivalsurveysIcon';
import { translationI18n } from '@/i18n';
import { ThemeTypes } from '@/store/config/user/types';
import { ServiceSystemType } from '@/utils/types/globalTypes';

type PageType = {
  module: string;
  showModuleTitle: boolean;
  services: serviceType[];
};
type serviceType = {
  service: ServiceSystemType | '';
  path: string;

  icon: (theme: ThemeTypes) => JSX.Element;
  master?: boolean;
  serviceName: string;
};
type pagesTypes = PageType[];

const pages: () => pagesTypes = () => [
  {
    module: translationI18n('layout:pages.dashboard.title'),
    showModuleTitle: false,
    services: [
      {
        serviceName: translationI18n('layout:pages.dashboard.title'),
        service: '',
        path: '/',
        icon: DashboardIcon,
      },
    ],
  },
  {
    module: translationI18n('layout:pages.master.title'),
    showModuleTitle: true,
    services: [
      {
        serviceName: translationI18n('layout:pages.master.companies'),
        service: '',
        path: '/companies',
        icon: CompanyIcon,
        master: true,
      },
      {
        serviceName: translationI18n('layout:pages.master.params'),
        service: '',
        path: '/params',
        icon: ParamsIcon,
        master: true,
      },
    ],
  },
  {
    module: translationI18n('layout:pages.manage.title'),
    showModuleTitle: true,
    services: [
      {
        serviceName: translationI18n('layout:pages.manage.myCompany'),
        service: '',
        path: '/mycompany',
        icon: CompanyIcon,
      },
      {
        serviceName: translationI18n('layout:pages.manage.forestRegistry'),
        service: 'forestRegistry',
        path: '/forestRegistry',
        icon: ForestRegistryIcon,
      },
    ],
  },
  {
    module: translationI18n('layout:pages.quality.title'),
    showModuleTitle: true,
    services: [
      {
        serviceName: translationI18n('layout:pages.quality.survival'),
        service: 'survivalSurveys',
        path: '/quality/survivalSurveys',
        icon: QualitySurvivalsurveysIcon,
      },
    ],
  },
  {
    module: translationI18n('layout:pages.control.title'),
    showModuleTitle: true,
    services: [
      {
        serviceName: translationI18n('layout:pages.control.plantedArea'),
        service: 'plantedArea',
        path: '/control/plantedArea',
        icon: PlantedAreaIcon,
      },
    ],
  },
];

export default pages;
