import Colors from '@/utils/colors';

const PlotsRegisteredIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0837 9.41602V18.7493M18.7503 14.0827H9.41699"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14.0833C3 8.8586 3 6.24624 4.62311 4.62311C6.24624 3 8.8586 3 14.0833 3C19.308 3 21.9204 3 23.5436 4.62311C25.1667 6.24624 25.1667 8.8586 25.1667 14.0833C25.1667 19.308 25.1667 21.9204 23.5436 23.5436C21.9204 25.1667 19.308 25.1667 14.0833 25.1667C8.8586 25.1667 6.24624 25.1667 4.62311 23.5436C3 21.9204 3 19.308 3 14.0833Z"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
      />
    </svg>
  );
};

export default PlotsRegisteredIcon;
