import EmptyState from '@/component/EmptyState';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import * as turf from '@turf/turf';
import _ from 'lodash';
import { formatNumber } from '@/utils/helpers/formaters';
import { initialStateReboleiraHighlight } from '../../../quality-survival-surveys-initital-states';
import { Skeleton } from '@mui/material';
import { BiImage } from 'react-icons/bi';
import { useEffect, useState } from 'react';
import ModalGallery from '@/component/modals/modal-gallery';
import {
  ReboleiraPhotosInterface,
  SurvivalSurveysReboleiraInterface,
} from '../../../quality-survival-surveys-types';
import { useTranslation } from 'react-i18next';

const ReboleirasResults = observer(() => {
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  const { evaluationDetails, handleReboleiraHighlight, handleReboleiraToZoom, loading } =
    survivalSurveysEvaluationsStore;
  const { survivalSurveyReboleiras: reboleiras } = evaluationDetails;
  const [reboleiraActive, setReboleiraActive] =
    useState<SurvivalSurveysReboleiraInterface>(initialStateReboleiraHighlight);
  const [activeImage, setActiveImage] = useState<ReboleiraPhotosInterface>();
  function handleActiveImage(image: ReboleiraPhotosInterface) {
    setActiveImage(image);
  }
  useEffect(() => {
    if (reboleiraActive.id) {
      setActiveImage(reboleiraActive.reboleiraPhotos[0]);
    }
  }, [reboleiraActive]);
  const areaTotalReboleiras =
    formatNumber(
      _.sumBy(
        reboleiras,
        (reboleira) =>
          turf.area(
            turf.polygon([reboleira.coordinates.map((coord) => [coord.lng, coord.lat])]),
          ) / 10000,
      ),
    ) + tCommon('ha');
  return (
    <div className="dark:bg-darkblack-500 flex flex-col shadow-lg bg-white p-3 rounded-lg flex-1">
      {activeImage?.id && (
        <ModalGallery
          activeImage={activeImage}
          handleActiveImage={handleActiveImage}
          closeModal={() => {
            handleReboleiraHighlight(initialStateReboleiraHighlight);
            setReboleiraActive(initialStateReboleiraHighlight);
            setActiveImage(undefined);
          }}
          images={reboleiraActive.reboleiraPhotos}
          isOpen={reboleiraActive.id > 0}
        />
      )}
      <div className="flex justify-between items-center mb-2">
        <p className="text-semibold dark:text-white">
          {tSurvivalSurveys('details.body.reboleiraResults.title')}
        </p>
        <p className=" dark:text-white">{`${!loading.evaluationDetails ? reboleiras.length : 0} (${areaTotalReboleiras})`}</p>
      </div>
      {loading.evaluationDetails ? (
        <Skeleton
          variant="rectangular"
          height={334}
          width={'100%'}
          className="max-h-[334px] min-h-[334px] rounded-lg dark:bg-darkblack-500"
        />
      ) : evaluationDetails.survivalSurveyReboleiras.length === 0 ? (
        <EmptyState
          bg="white"
          size="md"
          bgDark="darkblack-500"
          title={tSurvivalSurveys('details.body.reboleiraResults.emptyData.title')}
          description={tSurvivalSurveys(
            'details.body.reboleiraResults.emptyData.description',
          )}
        />
      ) : (
        <div className="overflow-y-auto overflow-style-none max-h-[334px] min-h-[334px] rounded-lg bg-white py-3 flex-col flex  items-center dark:bg-darkblack-500">
          {reboleiras.map((reboleira, index) => {
            const area =
              new Intl.NumberFormat('pt-BR', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(
                turf.area(
                  turf.polygon([
                    reboleira.coordinates.map((coord) => [coord.lng, coord.lat]),
                  ]),
                ) / 10000,
              ) + tCommon('ha');
            return (
              <div
                key={`${reboleira.id}_${index}`}
                className={`flex border-b w-full pr-2 items-center hover:cursor-pointer`}
                onMouseEnter={(e) => {
                  handleReboleiraHighlight(reboleira);
                  e.currentTarget.style.backgroundColor =
                    reboleira.deathCause.color.toLowerCase() + '50';
                }}
                onMouseLeave={(e) => {
                  if (reboleiraActive.id === 0) {
                    handleReboleiraHighlight(initialStateReboleiraHighlight);
                    e.currentTarget.style.backgroundColor = 'transparent';
                  }
                }}
              >
                <div
                  className={`border-l-8 pl-2 flex-1`}
                  style={{ borderColor: reboleira.deathCause.color }}
                >
                  <div className="flex space-x-2 items-center">
                    <p
                      className="text-md font-semibold dark:text-white"
                      onClick={() => {
                        handleReboleiraToZoom(reboleira);
                      }}
                    >
                      {reboleira.deathCause.cause}
                    </p>
                    {reboleira.reboleiraPhotos.length > 0 && (
                      <a
                        className="hover:text-success-200 hover:dark:text-success-200 dark:text-white flex"
                        onClick={() => {
                          setActiveImage(reboleira.reboleiraPhotos[0]);
                          setReboleiraActive(reboleira);
                          handleReboleiraHighlight(reboleira);
                        }}
                      >
                        <BiImage fontSize={24} className="mr-1" />
                        {` (${reboleira.reboleiraPhotos.length})`}
                      </a>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleReboleiraToZoom(reboleira);
                    }}
                    className="text-sm dark:text-white"
                  >
                    {reboleira.deathCause.description}
                  </div>
                  {reboleira.observation && (
                    <div className="text-base dark:text-white">
                      <strong className="font-medium">
                        {tSurvivalSurveys('details.body.reboleiraResults.observation')}:{' '}
                      </strong>
                      {reboleira.observation}
                    </div>
                  )}
                </div>
                <div
                  className="text-2xl font-semibold dark:text-white"
                  onClick={() => {
                    handleReboleiraToZoom(reboleira);
                  }}
                >
                  {area}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
});

export default ReboleirasResults;
