import servicesParamsController from './services-params-controller';
import EmptyStateServicesParams from './services-params-content/empty-state';
import ParamsComponent from './services-params-list';
import ParamsTable from './services-params-content';
import SwitchInput from '@/component/forms/SwitchInput';
import { checkPermission } from '@/utils/helpers/validations';
import servicesParams from './services-params-list/data';
import { translationI18n } from '@/i18n';
import { observer } from 'mobx-react';

const ServicesParams = () => {
  const controller = servicesParamsController();
  const { activeParam, setActiveParam, CausesController, ReasonsController } = controller;
  const { onChangeAllCausesOfCompany, allSelected, loadingUpdateCause } =
    CausesController;

  const canUpdateSurvivalSurveys =
    checkPermission({
      service: 'survivalSurveys',
      permission: 'hasone',
    }) &&
    checkPermission({
      service: 'params',
      permission: 'permissionUpdate',
    });
  const canUpdatePlantedArea =
    checkPermission({
      service: 'plantedArea',
      permission: 'hasone',
    }) &&
    checkPermission({
      service: 'params',
      permission: 'permissionUpdate',
    });
  return (
    <div className="w-full">
      <div className="rounded-lg bg-white mt-5 px-6 py-5 justify-between items-center flex dark:bg-darkblack-600 shadow-lg">
        <div className="flex flex-col">
          <p className="font-bold text-xl dark:text-gray-200">
            {translationI18n('companyProfile:body.screens.params.name')}
          </p>
          <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
            {translationI18n('companyProfile:body.screens.params.description')}
          </p>
        </div>
        {activeParam === 'survivalSurveysCause' && (
          <div className="flex items-center gap-2">
            <p
              className={`text-xs font-semibold ${
                !canUpdateSurvivalSurveys
                  ? 'text-error-100'
                  : allSelected
                    ? 'text-error-200'
                    : 'text-success-200'
              }`}
            >{`${!allSelected ? translationI18n('common:actions.enable') : translationI18n('common:actions.disable')} ${translationI18n('common:all')}`}</p>
            <SwitchInput
              disabled={!canUpdateSurvivalSurveys || loadingUpdateCause}
              value={allSelected}
              checked={allSelected}
              onChange={() => onChangeAllCausesOfCompany()}
            />
          </div>
        )}
        {activeParam === 'plantedAreaReason' && (
          <div className="flex items-center gap-2">
            <p
              className={`text-xs font-semibold ${
                !canUpdatePlantedArea
                  ? 'text-error-100'
                  : ReasonsController.allSelected
                    ? 'text-error-200'
                    : 'text-success-200'
              }`}
            >{`${!ReasonsController.allSelected ? translationI18n('common:actions.enable') : translationI18n('common:actions.disable')} ${translationI18n('common:all')}`}</p>
            <SwitchInput
              disabled={
                !canUpdatePlantedArea || ReasonsController.loadingUpdateReason !== ''
              }
              value={ReasonsController.allSelected}
              checked={ReasonsController.allSelected}
              onChange={() => ReasonsController.onChangeAllReasonsOfCompany()}
            />
          </div>
        )}
      </div>
      <div className="w-full lg:flex lg:space-x-5 mt-5">
        {servicesParams().length > 0 && (
          <div className="bg-white lg:w-[30%] px-4 py-3  rounded-lg dark:bg-darkblack-600 shadow-lg">
            <ParamsComponent activeParam={activeParam} setActiveParam={setActiveParam} />
          </div>
        )}
        <div className="lg:w-[70%] lg:mt-0 mt-5 space-y-5">
          {activeParam !== '' ? (
            <ParamsTable controller={controller} />
          ) : (
            <EmptyStateServicesParams />
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ServicesParams);
