import ModalActions from '@/component/modals/modal-actions';
import ForestRegistryModalAdd from './modal-add';
import ModalEditPolygon from './modal-edit-polygon';
import { forestRegistryStore } from '@/store';
import { observer } from 'mobx-react';
import ForestRegistryModalImportExcel from './modal-import-excel';
import ForestRegistryModalImportKML from './modal-import-kml';
import { useTranslation } from 'react-i18next';
const ForestRegistryModals = () => {
  const { modalsState, handleModalState, updateStatus } = forestRegistryStore;
  const tForestRegistry = useTranslation('forestRegistry').t;
  const tCommon = useTranslation('common').t;
  return (
    <>
      <ForestRegistryModalAdd />
      <ModalEditPolygon />
      <ForestRegistryModalImportExcel />
      <ForestRegistryModalImportKML />
      <ModalActions
        isActive={modalsState.confirmChangeStatus}
        handleClose={() => handleModalState('confirmChangeStatus', false)}
        handleSubmit={updateStatus}
        variant={modalsState.forestRegistryItem?.active ? 'inactive' : 'warning'}
        loading={modalsState.loading}
        title={
          modalsState.forestRegistryItem?.active
            ? tForestRegistry('modals.modalChangeStatus.titleDeactive', {
                talhao: modalsState.forestRegistryItem?.talhao,
              })
            : tForestRegistry('modals.modalChangeStatus.titleActive', {
                talhao: modalsState.forestRegistryItem?.talhao,
              })
        }
        description={tForestRegistry('modals.modalChangeStatus.description')}
        submitText={
          modalsState.forestRegistryItem?.active
            ? tCommon('actions.inactive')
            : tCommon('actions.active')
        }
        cancelText={tCommon('actions.cancel')}
      />
    </>
  );
};
export default observer(ForestRegistryModals);
