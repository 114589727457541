// src/components/PdfReport.tsx
import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { observer } from 'mobx-react';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { survivalSurveysEvaluationType } from '../quality-survival-surveys-types';
import ReportPdfItem from './ReportPdfItem';
import { useReactToPrint } from 'react-to-print';
import logo from '@/assets/images/logo/logo-color.svg'; // Ajuste o caminho conforme necessário
import { useTranslation } from 'react-i18next';

const PdfReport = observer(() => {
  const { companyActive } = userStore;
  const { getDataTableReportPdf, filter, filterStatus, dataRequest, listEvaluations } =
    survivalSurveysEvaluationsStore;

  const totalItems = listEvaluations[filterStatus]?.totalItems || 0;

  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const [evaluations, setEvaluations] = useState<survivalSurveysEvaluationType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);

  // Função para buscar os dados do relatório
  async function getDataTableReport() {
    setLoading(true);
    const data = await getDataTableReportPdf(
      filter,
      filterStatus,
      dataRequest,
      totalItems,
    );
    if (data) {
      setEvaluations(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    getDataTableReport();
  }, [filter, filterStatus, dataRequest, totalItems]);

  // Configurar a função de impressão (PDF)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      window.close(); // Fecha a janela após a impressão
    },
    pageStyle: `
      @page { 
        margin: 20mm !important; 
      }
      @media print {
        body {
          -webkit-print-color-adjust: exact;
        }
      }
    `,
  });

  // Iniciar a impressão automaticamente quando os dados estiverem prontos
  useEffect(() => {
    if (evaluations.length > 0) {
      // Delay para garantir que o conteúdo esteja completamente renderizado
      const timer = setTimeout(() => {
        handlePrint();
      }, 500); // Ajuste o tempo conforme necessário

      return () => clearTimeout(timer);
    }
  }, [evaluations, handlePrint]);

  if (!filter || !filterStatus || !dataRequest) {
    return <div className="p-4">{tSurvivalSurveys('reportPdf.emptyText')}</div>;
  }

  return (
    <div className="print-container w-full min-h-screen flex flex-col items-center bg-bgray-300 relative">
      <div
        className="printable-content w-[842px] bg-white dark:bg-darkblack-700 overflow-auto space-y-5 relative"
        ref={componentRef}
      >
        {/* Cabeçalho */}
        <div className="header fixed top-0 left-0 w-full flex items-center justify-between p-4 bg-bgray-50 dark:bg-darkblack-700">
          <img src={logo} alt="Logo" className="h-12" />
          <h1 className="text-2xl font-bold">{tSurvivalSurveys('reportPdf.title')}</h1>
          <img src={companyActive.imageUrl} alt={companyActive.name} className="h-12" />
        </div>
        {/* Espaço para evitar sobreposição com o cabeçalho fixo */}
        {evaluations.map((evaluation) => (
          <div key={evaluation.id.toString()} className="page-break pt-20">
            <ReportPdfItem evaluation={evaluation} />
          </div>
        ))}
      </div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="loader text-white">{tSurvivalSurveys('reportPdf.loading')}</div>
        </div>
      )}
    </div>
  );
});

export default PdfReport;
