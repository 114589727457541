import { action, makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  DataChartResultsType,
  DataUpdateCauseType,
  DeathCauseHighlightType,
  DeathCauseGroupedType,
  DeathCauseType,
  FilterDataType,
  FilterStatusType,
  LoadingType,
  ModalStatesType,
  OptionFilterType,
  PendencieType,
  SurvivalSurveyDot,
  survivalSurveysEvaluationType,
  DataRequestType,
  SurvivalSurveysReboleiraInterface,
  SurvivalSurveysEvaluationPhotoInterface,
} from './quality-survival-surveys-types';
import {
  approveEvaluation,
  approveEvaluationsArr,
  deleteEvaluationRequest,
  downloadExcel,
  getChartsMonths,
  getListStatus,
  getMyApprovals,
  getPendencies,
  getSurvivalSurveyDeathCauses,
  getSurvivalSurveys,
  getSurvivalSurveysByCod,
  reactiveEvaluationRequest,
  updateCause,
} from './quality-survival-surveys-services';
import { formatNumber } from '@/utils/helpers/formaters';
import {
  initialSateModalState,
  initialStateReboleiraHighlight,
  initialStateSurvivalSurveys,
  initialStateSurvivalSurveysList,
  inititalStateEvaluationItem,
  inititalStateFilters,
  inititalStateLoading,
} from './quality-survival-surveys-initital-states';
import {
  FiltersOptionsType,
  survivalSurveysListEvaluationsType,
} from '@/pages/private/quality/silviculture/survival-surveys/quality-survival-surveys-types';
import { OrderTableTypes } from '@/component/Table/types';
import moment from 'moment';
import _, { isArray } from 'lodash';
import { Toast } from '@/component/Toast';
import { newApprovationOrReprovationDataType } from './modals/modal-repprove/types';
import { userStore } from '@/store';
import { EChartsOption } from 'echarts';
import { getOptionsChartsBar } from './details/body/evaluation-results/causes-graph/bar-chart-option';
import {
  filterAndGroupByDeathCause,
  getPathologyData,
} from './details/body/evaluation-results/causes-graph/services';
import { translationI18n } from '@/i18n';
const { showSuccess } = Toast;
export class SurvivalSurveysEvaluationStore {
  constructor() {
    makeAutoObservable(this, { handleSelectedChart: action });
    makeObservable(this);
    void makePersistable(this, {
      name: 'SurvivalSurveysEvaluationStore',
      properties: [],
    });
  }

  listEvaluations: { [index in FilterStatusType]: survivalSurveysListEvaluationsType } = {
    all: initialStateSurvivalSurveys,
    approved: initialStateSurvivalSurveys,
    rejected: initialStateSurvivalSurveys,
    flowApproved: initialStateSurvivalSurveys,
  };
  filter: FilterDataType = inititalStateFilters;
  filterStatus: FilterStatusType = 'flowApproved';
  evaluationDetails: survivalSurveysEvaluationType = inititalStateEvaluationItem;
  filters: FiltersOptionsType = {
    fazenda: [],
    regiao: [],
    talhao: [],
    user: [],
    company: [],
    time: [],
  };
  filtersStatus: OptionFilterType[] = [];
  loading: LoadingType = inititalStateLoading;
  dataRequest: DataRequestType = {
    flowApproved: initialStateSurvivalSurveysList,
    approved: initialStateSurvivalSurveysList,
    rejected: initialStateSurvivalSurveysList,
    all: initialStateSurvivalSurveysList,
  };
  dataChartResults: DataChartResultsType = {
    yearsFilter: [],
    months: [],
  };
  selectedRows: number[] = [];
  pendencies: PendencieType[] = [];
  myApprovals: survivalSurveysEvaluationType[] = [];
  selectedYear: number = moment().year();
  modalStates: ModalStatesType = initialSateModalState;
  optionsSurvivalChart: EChartsOption = {};
  causesDeaths: {
    cause: string;
    show: boolean;
  }[] = [];
  selectedChart: {
    value: string;
    label: string;
  } = {
    label: translationI18n(
      'survivalSurveys:details.body.detailsResults.causesGraph.types.pie',
    ),
    value: 'Pie',
  };

  evaluationNotFound: boolean = false;
  deathCausesList: DeathCauseGroupedType[] = [];
  deathCauseUpdate: DeathCauseType = {
    id: 0,
    origin: '',
    cause: '',
    color: '',
    description: '',
    active: false,
  };
  deathCauseHighlight: DeathCauseHighlightType = {
    cause: '',
    apply: '',
  };
  reboleiraHighlight: SurvivalSurveysReboleiraInterface = initialStateReboleiraHighlight;
  reboleiraToZoom: SurvivalSurveysReboleiraInterface = initialStateReboleiraHighlight;
  activeImage: SurvivalSurveysEvaluationPhotoInterface = {
    coordinates: {
      lat: 0,
      lng: 0,
    },
    id: 0,
    uuid: '',
    survivalSurveyUuid: '',
    observation: '',
    imageName: '',
    imageUrl: '',
    createdMobileAt: new Date(),
    createdAt: new Date(),
    updatedAt: new Date(),
  };
  modalGalleryIsOpen: boolean = false;

  reset = () => {
    this.listEvaluations = {
      all: initialStateSurvivalSurveys,
      approved: initialStateSurvivalSurveys,
      rejected: initialStateSurvivalSurveys,
      flowApproved: initialStateSurvivalSurveys,
    };
    this.filter = inititalStateFilters;
    this.filterStatus = 'flowApproved';
    this.evaluationDetails = inititalStateEvaluationItem;
    this.filters = {
      fazenda: [],
      regiao: [],
      talhao: [],
      user: [],
      company: [],
      time: [],
    };
    this.filtersStatus = [];
    this.loading = inititalStateLoading;
    this.dataRequest = {
      flowApproved: initialStateSurvivalSurveysList,
      approved: initialStateSurvivalSurveysList,
      rejected: initialStateSurvivalSurveysList,
      all: initialStateSurvivalSurveysList,
    };
    this.dataChartResults = {
      yearsFilter: [],
      months: [],
    };
    this.selectedRows = [];
    this.pendencies = [];
    this.myApprovals = [];
    this.selectedYear = moment().year();
    this.modalStates = initialSateModalState;
    this.optionsSurvivalChart = {};
    this.causesDeaths = [];
    this.selectedChart = {
      label: translationI18n(
        'survivalSurveys:details.body.detailsResults.causesGraph.types.pie',
      ),
      value: 'Pie',
    };

    this.evaluationNotFound = false;
    this.deathCausesList = [];
    this.deathCauseUpdate = {
      id: 0,
      origin: '',
      cause: '',
      color: '',
      description: '',
      active: false,
    };
    this.deathCauseHighlight = {
      cause: '',
      apply: '',
    };
    this.reboleiraHighlight = initialStateReboleiraHighlight;
    this.reboleiraToZoom = initialStateReboleiraHighlight;
    this.activeImage = {
      coordinates: {
        lat: 0,
        lng: 0,
      },
      id: 0,
      uuid: '',
      survivalSurveyUuid: '',
      observation: '',
      imageName: '',
      imageUrl: '',
      createdMobileAt: new Date(),
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    this.modalGalleryIsOpen = false;
  };
  getDataTable = async () => {
    try {
      runInAction(() => {
        this.clearSelectedRows();
        this.handleLoading('table', true);
      });
      const response = await getSurvivalSurveys({
        ...this.filter,
        filterStatus: this.filterStatus,
        ...this.dataRequest[this.filterStatus],
        ...this.dataRequest[this.filterStatus].order,
        companyId: userStore.companyActive.id,
        interpolatedMap: true,
      });
      runInAction(() => {
        this.listEvaluations[this.filterStatus] = response;
        this.filters = response.filters;
        this.handleLoading('table', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('table', false);
    }
  };

  getDataTableExport = async () => {
    this.handleLoading('tableExport', true);
    try {
      const response = await getSurvivalSurveys({
        ...this.filter,
        filterStatus: this.filterStatus,
        ...this.dataRequest[this.filterStatus],
        ...this.dataRequest[this.filterStatus].order,
        size: this.listEvaluations[this.filterStatus].totalItems,
        page: 1,
        dots: true,
        companyId: userStore.companyActive.id,
      });
      downloadExcel(
        response.evaluations,
        translationI18n('survivalSurveys:details.body.detailsResults.graphs.survival'),
      );

      this.handleLoading('tableExport', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('tableExport', false);
    }
  };
  getDataTableReportPdf = async (
    filter: FilterDataType,
    filterStatus: FilterStatusType,
    dataRequest: DataRequestType,
    totalItems: number,
  ) => {
    this.handleLoading('tableExport', true);
    try {
      const response = await getSurvivalSurveys({
        ...filter,
        filterStatus: filterStatus,
        ...dataRequest[filterStatus],
        ...dataRequest[filterStatus].order,
        size: totalItems,
        page: 1,
        dots: true,
        companyId: userStore.companyActive.id,
      });
      this.handleLoading('tableExport', false);
      return response.evaluations;
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('tableExport', false);
    }
  };

  getDataFilters = async () => {
    this.handleLoading('statusFilter', true);

    const responseFilters = await getListStatus({
      ...this.filter,
      ...this.dataRequest[this.filterStatus],
      companyId: userStore.companyActive.id,
    });
    try {
      const newFilters = Object.keys(responseFilters).map((keyFilter) => {
        const filter = responseFilters[keyFilter as FilterStatusType];
        return {
          label: `${keyFilter === 'approved' ? translationI18n('survivalSurveys:survivalSurveysList.labelsTab.approved') : keyFilter === 'rejected' ? translationI18n('survivalSurveys:survivalSurveysList.labelsTab.repproved') : keyFilter === 'flowApproved' ? translationI18n('survivalSurveys:survivalSurveysList.labelsTab.inApprove') : translationI18n('survivalSurveys:survivalSurveysList.labelsTab.all')} (${formatNumber(filter, 0)})`,
          value: keyFilter as FilterStatusType,
        };
      }) as unknown as OptionFilterType[];
      runInAction(() => {
        this.filtersStatus = newFilters; // Atribuição direta dentro de runInAction
        this.handleLoading('statusFilter', false);
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };

  getChartResults = async () => {
    this.handleLoading('graph', true);
    try {
      const resultCharts = await getChartsMonths({
        year: this.selectedYear,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.dataChartResults = resultCharts;
      });
      this.handleLoading('graph', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('graph', false);
    }
  };

  getPendencies = async () => {
    this.handleLoading('pendencies', true);
    try {
      const newPendencies = await getPendencies({
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.pendencies = _.orderBy(
          newPendencies.map(
            (pendencie: {
              dataPlantio: moment.MomentInput;
              time: moment.DurationInputArg1;
            }) => {
              const time = moment().diff(
                moment(pendencie.dataPlantio).add(pendencie.time, 'day'),
                'days',
              );
              return {
                ...pendencie,
                timeExec: time,
              };
            },
          ),
          'timeExec',
          'desc',
        ) as PendencieType[];
        this.handleLoading('pendencies', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('pendencies', false);
    }
  };

  getMyListApprovals = async () => {
    this.handleLoading('myApprovals', true);
    try {
      const responseMyApprovals = await getMyApprovals({
        companyId: userStore.user.company.id,
      });
      runInAction(() => {
        this.myApprovals = responseMyApprovals;
        this.handleLoading('myApprovals', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('myApprovals', false);
    }
  };

  getEvaluationDetails = async (cod: number) => {
    this.handleLoading('evaluationDetails', true);
    try {
      const responseDetails = await getSurvivalSurveysByCod(
        cod,
        userStore.companyActive.id,
      );
      runInAction(() => {
        this.evaluationDetails = responseDetails;
        this.evaluationNotFound = false;
      });
      this.handleSelectedChart({
        value: 'Pie',
        label: translationI18n(
          'survivalSurveys:details.body.detailsResults.causesGraph.types.pie',
        ),
      });
      this.handleLoading('evaluationDetails', false);
    } catch (error: any) {
      console.error(error.message);
      if (error.response.status === 404) {
        runInAction(() => {
          this.evaluationNotFound = true;
        });
      }

      this.handleLoading('evaluationDetails', false);
    }
  };

  getDeathCausesList = async () => {
    this.handleLoading('modalChangeCause', true);
    try {
      const responseDeathCausesList = await getSurvivalSurveyDeathCauses();
      runInAction(() => {
        this.deathCausesList = responseDeathCausesList;
      });
      this.handleLoading('modalChangeCause', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalChangeCause', false);
    }
  };

  approveEvaluation = async () => {
    this.handleLoading('modalApprove', true);
    try {
      const response = await approveEvaluation({
        approved: true,
        evaluationId: Number(this.modalStates.evaluation?.id),
        motivation: '',
        description: '',
      });
      runInAction(() => {
        this.evaluationDetails = response.data;
      });
      await this.getMyListApprovals();
      showSuccess(response);
      this.handleModal('approve', false);
      this.handleLoading('modalApprove', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalApprove', false);
    }
  };

  repproveEvaluation = async (data: newApprovationOrReprovationDataType) => {
    this.handleLoading('modalRepprove', true);
    try {
      const response = await approveEvaluation(data);
      runInAction(() => {
        this.evaluationDetails = response.data;
      });
      showSuccess(response);
      this.getMyListApprovals();
      this.handleModal('repprove', false);
      this.handleLoading('modalRepprove', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalRepprove', false);
    }
  };

  deleteEvaluation = async () => {
    this.handleLoading('modalDelete', true);
    try {
      if (this.modalStates.evaluation?.id) {
        const response = await deleteEvaluationRequest(this.modalStates.evaluation?.id);
        runInAction(() => {
          this.listEvaluations[this.filterStatus] = {
            ...this.listEvaluations[this.filterStatus],
            evaluations: this.listEvaluations[this.filterStatus].evaluations.filter(
              (evaluation) => evaluation.id !== this.modalStates.evaluation?.id,
            ),
          };
        });
        showSuccess(response);
      }
      this.getMyListApprovals();
      this.handleModal('delete', false);
      this.handleLoading('modalDelete', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalDelete', false);
    }
  };
  reactiveEvaluation = async () => {
    this.handleLoading('modalReactive', true);
    try {
      if (this.modalStates.evaluation?.id) {
        const response = await reactiveEvaluationRequest(this.modalStates.evaluation?.id);
        runInAction(() => {
          this.listEvaluations[this.filterStatus] = {
            ...this.listEvaluations[this.filterStatus],
            evaluations: this.listEvaluations[this.filterStatus].evaluations.filter(
              (evaluation) => evaluation.id !== this.modalStates.evaluation?.id,
            ),
          };
        });
        showSuccess(response);
      }
      await this.getMyListApprovals();
      this.handleModal('reactive', false);
      this.handleLoading('modalReactive', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalReactive', false);
    }
  };

  approveArrEvaluations = async () => {
    this.handleLoading('modalApprove', true);
    const evaluations = this.listEvaluations[this.filterStatus].evaluations
      .filter((evaluation, index) => this.selectedRows.includes(index))
      .map((row) => row.id);
    await approveEvaluationsArr({ evaluations });
    this.handleModal('approveMultiple', false);
    this.handleLoading('modalApprove', false);
    await this.getDataTable();
    await this.getDataFilters();
    await this.getMyListApprovals();
    runInAction(() => {
      this.selectedRows = [];
    });
  };

  onSubmitUpdateCause = async (data: DataUpdateCauseType) => {
    this.handleLoading('modalChangeCause', true);
    try {
      const response = await updateCause(data);
      runInAction(() => {
        this.evaluationDetails = {
          ...this.evaluationDetails,
          survivalSurveyDots: this.evaluationDetails.survivalSurveyDots.map((dot) => {
            return dot.uuid === data.uuid ? response.data : dot;
          }),
        };
      });
      this.handleSelectedChart(this.selectedChart);
      this.handleModal('changeCause', false);
      this.handleLoading('modalChangeCause', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('modalChangeCause', false);
    }
  };

  handleDataRequest = (
    status: FilterStatusType,
    propertie: string,
    value: number | string | OrderTableTypes,
  ) => {
    this.dataRequest[status] = { ...this.dataRequest[status], [propertie]: value };
  };

  handleFilterStatus = (status: FilterStatusType) => {
    try {
      runInAction(() => {
        this.filterStatus = status;
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };

  handleLoading = (key: keyof LoadingType, loading: boolean) => {
    try {
      runInAction(() => {
        this.loading[key] = loading;
      });
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
  };

  handleCausesDeaths = (cause: string) => {
    runInAction(() => {
      this.causesDeaths = this.causesDeaths.map((item) =>
        cause === item.cause ? { cause: cause, show: !item.show } : item,
      );
    });
  };

  handleEvaluationNotFound = (newStatus: boolean) => {
    runInAction(() => {
      this.evaluationNotFound = newStatus;
    });
  };

  handleSelectedYear = (year: number) => {
    this.selectedYear = year;
  };

  handleFilterEvaluation = (
    filter: keyof FilterDataType,
    value: string | number | boolean | string[] | number[] | boolean[],
  ) => {
    runInAction(() => {
      this.filter = {
        ...this.filter,
        [filter]: value,
      };

      this.dataRequest = {
        all: {
          ...this.dataRequest.all,
          page: 1,
        },
        approved: {
          ...this.dataRequest.approved,
          page: 1,
        },
        flowApproved: {
          ...this.dataRequest.flowApproved,
          page: 1,
        },
        rejected: {
          ...this.dataRequest.rejected,
          page: 1,
        },
      };
    });
  };

  handleClearFilterEvaluation = () => {
    runInAction(() => {
      this.filter = inititalStateFilters;
    });
  };

  handleModal = (
    key: keyof ModalStatesType,
    visible: boolean,
    evaluation: survivalSurveysEvaluationType | undefined = undefined,
    dot: SurvivalSurveyDot | undefined = undefined,
  ) => {
    runInAction(() => {
      this.modalStates = {
        ...this.modalStates,
        evaluation: evaluation,
        dot: dot,
        [key]: visible,
      };
      if (dot && dot.deathCause) {
        this.deathCauseUpdate = JSON.parse(String(dot.deathCause));
      }
    });
  };

  handleDeathCauseUpdate = (deathCause: DeathCauseType) => {
    runInAction(() => {
      this.deathCauseUpdate = deathCause;
    });
  };

  handleDeathCauseHighlight = (newDeathCauseHighlight: DeathCauseHighlightType) => {
    runInAction(() => {
      this.deathCauseHighlight = newDeathCauseHighlight;
    });
  };

  handleReboleiraHighlight = (
    newReboleiraHighlight: SurvivalSurveysReboleiraInterface,
  ) => {
    runInAction(() => {
      this.reboleiraHighlight = newReboleiraHighlight;
    });
  };
  handleReboleiraToZoom = (newReboleiraToZoom: SurvivalSurveysReboleiraInterface) => {
    runInAction(() => {
      this.reboleiraToZoom = newReboleiraToZoom;
    });
  };

  handleSelectedChart = (newSelectedChart: { label: string; value: string }) => {
    let newOptions: EChartsOption = {};
    if (newSelectedChart.value === 'Pie') {
      const groupedDeath = filterAndGroupByDeathCause(this.evaluationDetails);
      newOptions = getPathologyData({
        deathCauses: groupedDeath,
      });
    }
    if (newSelectedChart.value === 'Bar') {
      newOptions = getOptionsChartsBar(this.evaluationDetails, userStore.theme);
    }
    let causesDeath = this.causesDeaths;
    if (
      isArray(newOptions.series) &&
      newOptions.series.length > 0 &&
      isArray(newOptions.series[0].data)
    ) {
      causesDeath = newOptions.series[0].data.map((serie) => {
        return {
          cause: serie.name as string,
          show: true,
        };
      });
    }
    runInAction(() => {
      this.selectedChart = newSelectedChart;
      this.optionsSurvivalChart = newOptions;
      this.causesDeaths = causesDeath;
    });
  };

  setSelectedRows = (rows: number[]) => {
    runInAction(() => {
      this.selectedRows = rows;
    });
  };

  clearSelectedRows = () => {
    runInAction(() => {
      this.selectedRows = [];
    });
  };

  handleImageActiveGallery = (activeImage: SurvivalSurveysEvaluationPhotoInterface) => {
    runInAction(() => {
      this.activeImage = activeImage;
    });
  };
  setModalGalleryIsOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.modalGalleryIsOpen = isOpen;
    });
  };
}
