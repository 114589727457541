import { useEffect, useMemo, useRef, useState } from 'react';
import maplibregl from 'maplibre-gl';
import * as turf from '@turf/turf';
import Colors from '@/utils/colors';
import { survivalSurveysEvaluationType } from '../quality-survival-surveys-types';
import { ChangeImage } from '../quality-survival-surveys-services';
import { translationI18n } from '@/i18n';
const MiniMap = ({ row }: { row: survivalSurveysEvaluationType }) => {
  if (row.imageMapUrl) {
    return (
      <img
        key={`mini-map-${row.uuid}`}
        className="h-[50px] w-[50px] object-cover"
        src={row.imageMapUrl}
      />
    );
  }
  if (String(row.interpolatedMap) === '') {
    return (
      <p className="text-sm text-center text-error-200">
        {translationI18n('survivalSurveys:survivalSurveysList.table.emptyMiniMap')}
      </p>
    );
  }

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const initializeMap = () => {
    const mapContainer = mapContainerRef.current;
    if (!mapContainer) return;

    const map = new maplibregl.Map({
      container: mapContainer,
      attributionControl: false,
      style: {
        version: 8,
        sources: {},
        layers: [],
      },
      center: [-52.842663, -20.504497],
      zoom: 20,
      scrollZoom: false,
    });

    map.on('load', () => {
      map.addSource('interpolated-source', {
        type: 'geojson',
        data: turf.featureCollection(row.interpolatedMap),
      });

      map.addLayer({
        source: 'interpolated-source',
        paint: {
          'fill-color': [
            'step',
            ['get', 'value'],
            Colors.error[300],
            (row.survivalTarget - 12) / 100,
            Colors.error[300],
            (row.survivalTarget - 9) / 100,
            Colors.error[200],
            (row.survivalTarget - 6) / 100,
            Colors.warning[200],
            (row.survivalTarget - 3) / 100,
            Colors.primary[200],
            row.survivalTarget / 100,
            Colors.primary[300],
          ],
        },
        id: 'interpolatedGrid-fill',
        type: 'fill',
      });

      const bbox = turf.bbox(turf.featureCollection(row.interpolatedMap));
      map.fitBounds(bbox, { padding: 0, animate: false });

      map.once('idle', () => {
        const canvas = map.getCanvas();
        canvas.toBlob((blob) => {
          if (blob) {
            const reader = new FileReader();
            reader.onloadend = async () => {
              setImageSrc(reader.result as string); // Cria um arquivo a partir do blob
              const file = new File([blob], `${row.uuid}.png`, { type: 'image/png' });

              // Supondo que sua função `changeImage` aceite o arquivo e o UUID
              await ChangeImage({ uuid: row.uuid, file: file });
              map.remove(); // Remova o mapa para liberar o contexto WebGL
            };
            reader.readAsDataURL(blob);
          }
        });
      });
    });
  };

  useMemo(() => {
    initializeMap();
  }, [row.id, mapContainerRef.current]);

  useEffect(() => {
    setImageSrc(null);
  }, []);

  return (
    <div
      style={{ height: '50px', width: '50px', position: 'relative' }}
      className="rounded-lg flex-1 bg-white"
    >
      {imageSrc && <img src={imageSrc} alt="Map" />}
      <div
        ref={mapContainerRef}
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0 }}
      />
    </div>
  );
};

export default MiniMap;
