import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { forestRegistryStore } from '@/store';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import { styleMapLibre } from '@/utils/geo';
import EmptyState from '@/component/EmptyState';
import { translationI18n } from '@/i18n';

const ForestRegistryMap = observer(() => {
  const [mapState, setMapState] = useState<{ center: LngLatLike; zoom: number }>({
    center: [-52.842663, -20.504497],
    zoom: 5,
  });
  const { forestRegistryMapData, screenActive, loading } = forestRegistryStore;
  const { forestRegisters } = forestRegistryMapData;
  const mapRef = useRef<maplibregl.Map | null>(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    if (mapContainerRef.current && forestRegisters.length) {
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current = null;
      }
    };
  }, [mapContainerRef.current, forestRegisters]);

  const initializeMap = () => {
    if (mapRef.current) return; // Prevent multiple initializations
    mapRef.current = new maplibregl.Map({
      container: mapContainerRef.current,
      attributionControl: false,
      style: styleMapLibre,
      center: mapState.center,
      zoom: mapState.zoom,
      glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf', // URL dos glyphs públicos
      localIdeographFontFamily: 'Noto',
    }).addControl(new maplibregl.NavigationControl(), 'top-right');

    // Add event listener for moveend
    mapRef.current.on('moveend', () => {
      if (mapRef.current) {
        setMapState({
          center: [mapRef.current.getCenter().lng, mapRef.current.getCenter().lat],
          zoom: mapRef.current.getZoom(),
        });
      }
    });

    // Add event listener for map load to add sources and layers
    mapRef.current.on('load', () => {
      addSourceAndLayers();
    });
  };

  useEffect(() => {
    if (mapRef.current && mapRef.current.isStyleLoaded()) {
      updateSourceAndLayers();
    }
  }, [forestRegisters]);

  const addSourceAndLayers = () => {
    if (mapRef.current) {
      fitMapToBounds();
      if (!mapRef.current.getSource('forest-registers')) {
        mapRef.current.addSource('forest-registers', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: forestRegisters.map((register) => ({
              type: 'Feature',
              geometry: {
                type: 'MultiPolygon',
                coordinates: [
                  register.coordinates.map((coord) =>
                    coord.coordinates.map((c) => [c.lng, c.lat]),
                  ),
                ],
              },
              properties: {
                talhao: register.talhao,
              },
            })),
          },
        });
        mapRef.current.addLayer({
          id: 'forest-registers-labels',
          type: 'symbol',
          source: 'forest-registers',
          minzoom: 10,
          layout: {
            'text-field': ['get', 'talhao'],
            'text-size': 12,
            'text-font': ['Arial-Regular'],
            'text-anchor': 'center',
          },
          paint: {
            'text-color': 'white',
          },
        });
        mapRef.current.addLayer({
          id: 'forest-registers-layer',
          type: 'fill',
          source: 'forest-registers',
          paint: {
            'fill-color': 'red',
            'fill-opacity': 0,
          },
        });

        mapRef.current.addLayer({
          id: 'forest-registers-outline',
          type: 'line',
          source: 'forest-registers',
          paint: {
            'line-color': 'red',
            'line-width': 2,
          },
        });
      }
    }
  };

  const updateSourceAndLayers = () => {
    if (mapRef.current) {
      fitMapToBounds();
      const source = mapRef.current.getSource('forest-registers');
      if (source) {
        source.setData({
          type: 'FeatureCollection',
          features: forestRegisters.map((register) => ({
            type: 'Feature',
            geometry: {
              type: 'MultiPolygon',
              coordinates: [
                register.coordinates.map((coord) =>
                  coord.coordinates.map((c) => [c.lng, c.lat]),
                ),
              ],
            },
            properties: {
              talhao: register.talhao,
            },
          })),
        });
      }
    }
  };

  const fitMapToBounds = () => {
    if (mapRef.current) {
      const bounds = new maplibregl.LngLatBounds();

      forestRegisters.forEach((register) => {
        register.coordinates.forEach((coordGroup) => {
          coordGroup.coordinates.forEach((coord) => {
            bounds.extend([coord.lng, coord.lat]);
          });
        });
      });

      mapRef.current.fitBounds(bounds, { padding: 20, animate: false });
    }
  };

  if (screenActive !== 'map') {
    return null;
  }
  if (!loading && !forestRegisters.length) {
    return (
      <EmptyState
        size="lg"
        title={translationI18n(
          'survivalSurveys:details.body.detailsResults.map.empty.title',
        )}
        description={translationI18n(
          'survivalSurveys:details.body.detailsResults.map.empty.description',
        )}
      />
    );
  }

  return (
    <div className="w-full relative rounded-lg bg-white p-5 dark:bg-darkblack-600">
      <div
        className="absolute m-5 top-0 left-0 right-0 bottom-0 items-center flex justify-center bg-darkblack-600 opacity-50 rounded-lg text-white text-4xl"
        style={{ zIndex: 2, display: loading ? 'flex' : 'none' }}
      >
        {translationI18n('survivalSurveys:details.body.detailsResults.map.loading')}
      </div>
      <div
        ref={mapContainerRef}
        id="map"
        style={{ zIndex: 1 }}
        className="h-[500px]  bg-bgray-200 rounded-lg"
      ></div>
    </div>
  );
});

export default ForestRegistryMap;
