import PrimaryButton from '@/component/buttons/PrimaryButton';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import Modal from '@/component/modals/modal-default';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import SelectChangeCause from './select-change-cause';
import { DeathCauseType } from '../../../../quality-survival-surveys-types';
import { useTranslation } from 'react-i18next';

const ModalChangeCause = observer(() => {
  const {
    modalStates,
    loading: loadingSurvivalSurveys,
    handleModal,
    getDeathCausesList,
    deathCausesList,
    deathCauseUpdate,
    handleDeathCauseUpdate,
    onSubmitUpdateCause,
  } = survivalSurveysEvaluationsStore;
  const { changeCause: isVisible, dot } = modalStates;
  const { modalChangeCause: loading } = loadingSurvivalSurveys;
  const [editable, setEditable] = useState(false);
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  function handleCloseModal() {
    handleModal('changeCause', false);
  }

  function handleEditable() {
    if (editable && dot?.deathCause) {
      handleDeathCauseUpdate(JSON.parse(String(dot?.deathCause)) as DeathCauseType);
    }
    setEditable(!editable);
  }

  useMemo(() => {
    setEditable(false);
  }, [isVisible]);

  useEffect(() => {
    if (!deathCausesList.length) {
      getDeathCausesList();
    }
  }, []);

  if (!dot) {
    return null;
  }
  return (
    <Modal
      isActive={isVisible}
      handleClose={handleCloseModal}
      title={
        !editable
          ? tSurvivalSurveys('details.body.detailsResults.modalChangeCause.titleDetails')
          : tSurvivalSurveys('details.body.detailsResults.modalChangeCause.titleChange')
      }
      variant="md"
      footer={
        <div className={`flex justify-end items-end space-x-3`}>
          <SecondaryButton
            text={editable ? tCommon('actions.cancel') : tCommon('actions.close')}
            onClick={editable ? handleEditable : handleCloseModal}
          />
          {editable && (
            <PrimaryButton
              text={tCommon('actions.change')}
              disabled={
                Number(deathCauseUpdate.id) ===
                Number(JSON.parse(String(dot.deathCause)).id)
              }
              loading={loading}
              onClick={() => {
                if (
                  Number(deathCauseUpdate.id) !==
                  Number(JSON.parse(String(dot.deathCause)).id)
                ) {
                  onSubmitUpdateCause({
                    causeId: deathCauseUpdate.id,
                    uuid: dot.uuid,
                  });
                }
              }}
            />
          )}
        </div>
      }
    >
      <SelectChangeCause
        deathCause={deathCauseUpdate}
        editable={editable}
        handleEditable={handleEditable}
      />
    </Modal>
  );
});

export default ModalChangeCause;
