import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptBR from './locales/pt-BR';
import esES from './locales/es';

const resources = {
  'pt-br': ptBR,
  es: esES,
};

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources,
  fallbackLng: 'pt-br',
  lng: localStorage.getItem('language') || 'pt-br',
  lowerCaseLng: true,
  interpolation: {
    escapeValue: false,
  },
});
const translationI18n = i18n.t;
export { translationI18n };
export default i18n;
