import { observer } from 'mobx-react';
import { forestRegistryStore } from '@/store';
import { GrTable } from 'react-icons/gr';
import { FaRegMap } from 'react-icons/fa';
import { translationI18n } from '@/i18n';
const CardFiltersStatus = observer(() => {
  const { screenActive, handleScreenActive } = forestRegistryStore;
  return (
    <div className="rounded-t-lg border-b overflow-x-auto overflow-style-none px-3 bg-white items-center text-nowrap h-full flex-col dark:bg-darkblack-600">
      <div className="w-full flex h-full">
        <label
          className={`flex items-center px-2 cursor-pointer  ${
            screenActive === 'table'
              ? 'border-b-[3px] border-b-success-200 text-success-200 dark:text-success-200'
              : 'dark:text-bgray-200'
          }`}
        >
          <input
            type="radio"
            name="filter"
            value={'table'}
            checked={screenActive === 'table'}
            onChange={() => handleScreenActive('table')}
            className="hidden"
          />
          <span className={`px-2 py-3 text-md flex gap-2 items-center text-nowrap`}>
            <GrTable />
            <p>{translationI18n('forestRegistry:body.cardFiltersStatus.table')}</p>
          </span>
        </label>
        <label
          className={`flex items-center px-2 cursor-pointer  ${
            screenActive === 'map'
              ? 'border-b-[3px] border-b-success-200 text-success-200 dark:text-success-200'
              : 'dark:text-bgray-200'
          }`}
        >
          <input
            type="radio"
            name="filter"
            value={'map'}
            checked={screenActive === 'map'}
            onChange={() => handleScreenActive('map')}
            className="hidden"
          />
          <span className={`px-2 py-3 text-md flex gap-2 items-center text-nowrap`}>
            <FaRegMap />
            <p>{translationI18n('forestRegistry:body.cardFiltersStatus.map')}</p>
          </span>
        </label>
      </div>
    </div>
  );
});

export default CardFiltersStatus;
