import { survivalSurveysEvaluationsStore } from '@/store';
import DetailsResult from './evaluation-results';
import HeaderInfos from './header-infos';
import { observer } from 'mobx-react';
import ReboleirasResults from './reboleira-results';
import EvaluatuationPhotos from './evaluation-photos';
import { useTranslation } from 'react-i18next';

const DetailsBody = () => {
  const { handleModal, evaluationDetails } = survivalSurveysEvaluationsStore;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  return (
    <div className="rounded-lg mt-5 justify-between items-center flex-col">
      <button
        className="py-2 font-semibold dark:text-bgray-200 text-gray-600"
        style={{ textDecoration: 'underline' }}
        onClick={() => handleModal('history', true, evaluationDetails)}
      >
        {tSurvivalSurveys('details.body.viewHistory')}
      </button>
      <HeaderInfos />
      <DetailsResult />
      <div className="md:flex-col lg:flex xl:flex w-full gap-3 sm:flex-col lg:flex-row mt-3">
        <ReboleirasResults />
        <EvaluatuationPhotos />
      </div>
    </div>
  );
};

export default observer(DetailsBody);
