import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ImportAskIcon from '@/assets/images/icons/ImportAskIcon';
const ItemFeedback = ({
  Icon,
  text,
  description,
  rightAction = () => {},
  rightText = '',
}: {
   
  Icon: JSX.Element;
  text: string;
  description: string;
  rightAction?: () => void;
  rightText?: string;
}) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };
  return (
    <div className="flex rounded-md bg-bgray-100 dark:bg-darkblack-400 items-center justify-between">
      <div className="flex gap-2 items-center  py-2 px-3 ">
        {Icon}
        <p className="text-sm font-medium dark:text-bgray-500">{text}</p>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            placement="right"
            open={openTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={description}
          >
            <button onClick={handleTooltipOpen}>
              <ImportAskIcon />
            </button>
          </Tooltip>
        </ClickAwayListener>
      </div>
      {rightText && (
        <button
          onClick={rightAction}
          className="px-4 py-2 text-sm font-semibold dark:text-white"
          style={{ textDecoration: 'underline' }}
        >
          {rightText}
        </button>
      )}
    </div>
  );
};

export default ItemFeedback;
