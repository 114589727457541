import { FC, useState } from 'react';
import { createContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import SidebarV2 from '../sidebar/SidebarV2';
import HeaderOne from '../header/HeaderOne';
import { LayoutProps, ThemeContextProps, TitleContextProps } from './types';
import ModalChangePassoword from '../modals/modal-change-passoword';
import ModalConfirmLogout from '../modals/modal-confirm-logout';
import { observer } from 'mobx-react';
import ModalChangeCompany from '../modals/modal-change-company';
import HMLComponent from '../hml-component';
import ModalUser from '../modals/modal-user';
import LoadingPage from '../loading-page';

export const ThemeContext = createContext<ThemeContextProps>({
  theme: null,
  setTheme: () => {},
});

export const TitleContext = createContext<TitleContextProps>({
  title: null,
  setTitle: () => {},
  subTitle: null,
  setSubTitle: () => {},
});

const Layout: FC<LayoutProps> = ({ bg }) => {
  const [sidebar, setSidebar] = useState<boolean>(
    window.outerWidth < 1000 ? false : true,
  );
  const [title, setTitle] = useState<string | null>(null);
  const [subTitle, setSubTitle] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  // Função para verificar se existem rotas para trás
  const temRotasParaTras = () => {
    return navigate.length > 0 && currentPath !== '/';
  };

  // Função para voltar para a rota anterior
  const voltarParaRotaAnterior = () => {
    navigate(-1);
  };
  return (
    <TitleContext.Provider value={{ title, setTitle, subTitle, setSubTitle }}>
      <div
        className={`layout-wrapper ${
          sidebar ? 'active' : ''
        }  w-full h-hull dark:bg-darkblack-600 bg-bgray-200 `}
        style={{
          borderColor: '#2a313c',
        }}
      >
        <HMLComponent />
        <div className="relative flex w-full">
          <Sidebar handleActive={() => setSidebar(!sidebar)} />
          <SidebarV2 sideBar={sidebar} />
          <div
            className={` body-wrapper flex-col h-screen relative overflow-y-hidden w-full overflow-x-hidden ${
              bg ? bg : 'dark:bg-darkblack-500'
            } `}
          >
            <HeaderOne
              goBack={voltarParaRotaAnterior}
              showGoBack={temRotasParaTras()}
              handleSidebar={() => setSidebar(!sidebar)}
              title={title}
              subTitle={subTitle}
            />
            <div className="body relative overflow-y-scroll">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <ModalUser />
      <ModalChangePassoword />
      <ModalConfirmLogout />
      <ModalChangeCompany />
      <LoadingPage />
    </TitleContext.Provider>
  );
};

export default observer(Layout);
