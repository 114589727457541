import { EChartsOption } from 'echarts';
import { isArray } from 'lodash';
import { SurvivalSurveysDataCausesType } from '../dashboard-survival-surveys-types';
import { userStore } from '@/store';

export function getPathologyData({
  deathCauses,
}: {
  deathCauses: SurvivalSurveysDataCausesType[];
}): EChartsOption {
  const data: EChartsOption = {
    darkMode: userStore.theme === 'dark',
    grid: {
      top: '0%',
      containLabel: true,
    },
    animation: false,
    series: [
      {
        type: 'pie',
        radius: ['50%', '75%'],
        center: ['50%', '50%'],
        itemStyle: {
          borderRadius: 5,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: 'bold',
          },
        },
        data: [],
      },
    ],
  };

  const totalDeaths = deathCauses.reduce((total, cause) => total + cause.total, 0);

  deathCauses.forEach((cause) => {
    const percentage = (cause.total / totalDeaths) * 100;

    if (
      data?.series &&
      isArray(data?.series) &&
      data?.series.length > 0 &&
      isArray(data?.series?.[0]?.data)
    ) {
      data.series[0].data.push({
        value: percentage.toFixed(0),
        name: cause.cause,
        itemStyle: {
          color: cause.color,
        },
        label: {
          show: true,
          formatter: `${percentage.toFixed(0)}% (${cause.total})`,
          color: cause.color,
          fontSize: 10,
          position: 'outside',
        },
      });
    }
  });

  return data;
}
