import Modal from '@/component/modals/modal-default';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  emptyStateValidation,
  numberQtdValidation,
  plantDensityValidation,
  plantDiscardedValidation,
  validatePlantDensity,
  validatePlantDiscarded,
} from '@/utils/helpers/validations';
import { formatNumber } from '@/utils/helpers/formaters';
import { PlantedAreaModalEditHeader } from './modal-edit-header';
import Input from '@/component/forms/Input';
import { useEffect, useMemo, useState } from 'react';
import { round } from 'lodash';
import Select from '@/component/forms/Select';
import SwitchInput from '@/component/forms/SwitchInput';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { updatePlantedAreaNote } from '../../../planted-area-services';

export const ModalPlantedAreaNoteEdit = observer(() => {
  const {
    modalStates,
    handleModal,
    getDataTable,
    getDataFilters,
    handleLoading,
    params,
    reasons,
    genetics,
    loading,
  } = plantedAreaNoteStore;
  const { note, edit: visible } = modalStates;
  const { optionsForm: loadingInput, modalEdit: loadingModal } = loading;
  function onClose() {
    handleModal('edit', false, undefined);
  }
  const [densityAlert, setDensityAlert] = useState({
    isValid: true,
    message: '',
  });

  const [discardedAlert, setDiscardedAlert] = useState({
    isValid: true,
    message: '',
  });

  const tPlantedArea = useTranslation('plantedArea').t;
  const tCommon = useTranslation('common').t;

  const plantedArea =
    Number(note?.forestRegistry.plantedArea) - (note?.error ? 0 : Number(note?.area));
  const availableArea = Number(note?.forestRegistry.area) - plantedArea;
  const optionsReasons = reasons.map((reason) => ({
    label: reason.reason,
    value: reason.id,
  }));

  const optionsGenetics = genetics.map((genetic) => ({
    label: genetic.genetic,
    value: genetic.id,
  }));

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      area: 0,
      seedlingsUsed: 0,
      seedlingsDiscarded: 0,
      observation: '',
      reasonId: 0,
      geneticMaterialId: 0,
      plantingEnd: false,
    },

    validationSchema: Yup.object().shape({
      area: numberQtdValidation(availableArea),
      seedlingsUsed: plantDensityValidation(
        params?.targetPlantingDensityMinError,
        params?.targetPlantingDensityMaxError,
      ),
      seedlingsDiscarded: plantDiscardedValidation(
        params?.targetDiscardedPlantsMinError,
        params?.targetDiscardedPlantsMaxError,
      ),
      reasonId: emptyStateValidation(),
      geneticMaterialId: emptyStateValidation(),
    }),

    onSubmit: async (values: {
      area: number;
      seedlingsUsed: number;
      seedlingsDiscarded: number;
      observation: string;
      reasonId: number;
      geneticMaterialId: number;
      plantingEnd: boolean;
    }) => {
      const {
        area,
        seedlingsUsed,
        seedlingsDiscarded,
        observation,
        plantingEnd,
        geneticMaterialId,
        reasonId,
      } = values;
      if (note?.id) {
        const dataUpdate = {
          id: note?.id,
          area: area,
          seedlingsUsed: seedlingsUsed,
          seedlingsDiscarded: seedlingsDiscarded,
          plantingDensity: Number((seedlingsUsed / area)?.toFixed(0)),
          percentageDiscarded: Number(
            ((seedlingsDiscarded / (seedlingsUsed + seedlingsDiscarded)) * 100)?.toFixed(
              2,
            ),
          ),
          targetPlantingDensity: params?.targetPlantingDensity,
          targetDiscardedPlants: params?.targetDiscardedPlants,
          observation: observation,
          plantingEnd: plantingEnd,
          reasonId: reasonId,
          geneticMaterialId: geneticMaterialId,
          totalPlantedArea: plantedArea + area,
          availableArea: availableArea < 0 ? 0 : availableArea,
        };
        handleLoading('modalEdit', true);
        await updatePlantedAreaNote(dataUpdate);
        handleLoading('modalEdit', false);
        onClose();
        await getDataTable();
        await getDataFilters();
      }
    },
  });

  useMemo(() => {
    if (note && modalStates.edit) {
      validation.setFieldValue('area', note.area);
      validation.setFieldValue('seedlingsUsed', note.seedlingsUsed);
      validation.setFieldValue('seedlingsDiscarded', note.seedlingsDiscarded);
      validation.setFieldValue('observation', note.observation);
      validation.setFieldValue('reasonId', note.reasonId);
      validation.setFieldValue('geneticMaterialId', note.geneticMaterialId);
      validation.setFieldValue('plantingEnd', note.plantingEnd);
    }
  }, [note, modalStates.edit]);
  useEffect(() => {
    if (validation?.touched?.seedlingsUsed && validation?.touched?.area) {
      const resp = validatePlantDensity({
        min: params?.targetPlantingDensityMinAlert,
        max: params?.targetPlantingDensityMaxAlert,
        area: validation?.values?.area,
        seedlingsUsed: validation?.values?.seedlingsUsed,
      });
      setDensityAlert(resp);
    }
  }, [
    validation?.values?.area,
    validation?.values?.seedlingsUsed,
    validation?.touched?.seedlingsUsed,
    validation?.touched?.area,
    params,
    modalStates.edit,
  ]);

  useEffect(() => {
    if (validation?.touched?.seedlingsDiscarded && validation?.touched?.seedlingsUsed) {
      const resp = validatePlantDiscarded({
        max: params?.targetDiscardedPlantsMaxAlert,
        min: params?.targetDiscardedPlantsMinAlert,
        seedlingsDiscarded: validation?.values?.seedlingsDiscarded,
        seedlingsUsed: validation?.values?.seedlingsUsed,
      });
      setDiscardedAlert(resp);
    }
  }, [
    validation?.values?.seedlingsUsed,
    validation?.values?.seedlingsDiscarded,
    params,
    validation?.touched?.seedlingsDiscarded,
    validation?.touched?.seedlingsUsed,
    modalStates.edit,
  ]);
  useEffect(() => {
    if (Number(validation?.values?.area) >= Number(availableArea)) {
      validation.setFieldValue('plantingEnd', true);
      validation.setFieldValue(
        'area',
        validation?.values?.area ? round(validation?.values?.area, 2) : null,
      );
    } else {
      validation.setFieldValue('plantingEnd', false);
    }
  }, [validation?.values?.area, availableArea]);
  return (
    <Modal
      variant="xl"
      isActive={visible}
      handleClose={onClose}
      title={tPlantedArea('listNotes.modals.modalEdit.title', { value: note?.cod })}
      footer={
        <div className={`flex justify-end items-end space-x-3`}>
          <SecondaryButton text={tCommon('actions.cancel')} onClick={onClose} />
          <PrimaryButton
            text={tCommon('actions.update')}
            loading={loadingModal}
            onClick={validation.submitForm}
          />
        </div>
      }
    >
      <div className="w-full">
        {note && <PlantedAreaModalEditHeader note={note} />}
        <div className=" md:grid-cols-2 grid gap-x-3 mt-3">
          <Input
            value={validation.values.area}
            type={'number'}
            step={0.01}
            additionalInfo={`${formatNumber(
              (validation.values.area / Number(note?.forestRegistry.area)) * 100,
              2,
            )}%`}
            max={availableArea}
            onChange={(event) => {
              const finalArea = event.target.value
                ? Number(event.target.value) > availableArea
                  ? availableArea
                  : event.target.value
                : null;
              validation.setFieldValue(
                'area',
                finalArea ? round(Number(finalArea), 2) : null,
              );
            }}
            onBlur={validation.handleBlur('area')}
            error={!!(validation.errors.area && validation.touched.area)}
            errorMessage={validation.errors.area}
            placeholder={tPlantedArea(
              'listNotes.modals.modalEdit.labels.typePlantedArea',
            )}
            label={tPlantedArea('listNotes.modals.modalEdit.labels.plantedArea')}
          />
          <Input
            value={validation.values.seedlingsUsed}
            type={'number'}
            step={1}
            additionalInfo={tPlantedArea(
              'listNotes.modals.modalEdit.labels.seedlingsUsedSuffix',
              {
                value: formatNumber(
                  validation.values.seedlingsUsed / Number(note?.forestRegistry.area),
                  2,
                ),
              },
            )}
            onChange={(event) => {
              validation.setFieldValue(
                'seedlingsUsed',
                event.target.value ? round(Number(event.target.value), 0) : null,
              );
            }}
            onBlur={validation.handleBlur('seedlingsUsed')}
            error={
              !!(validation.errors.seedlingsUsed && validation.touched.seedlingsUsed)
            }
            errorMessage={validation.errors.seedlingsUsed}
            placeholder={tPlantedArea(
              'listNotes.modals.modalEdit.labels.typeSeedlingsUsed',
            )}
            label={tPlantedArea('listNotes.modals.modalEdit.labels.seedlingsUsed')}
            warning={!densityAlert.isValid}
            warningMessage={densityAlert.message}
          />
          <Input
            value={validation.values.seedlingsDiscarded}
            type={'number'}
            step={1}
            additionalInfo={`${formatNumber((validation.values.seedlingsDiscarded / (validation.values.seedlingsDiscarded + validation.values.seedlingsUsed)) * 100, 2)}%`}
            onChange={(event) => {
              validation.setFieldValue(
                'seedlingsDiscarded',
                event.target.value ? round(Number(event.target.value), 0) : null,
              );
            }}
            onBlur={validation.handleBlur('seedlingsDiscarded')}
            error={
              !!(
                validation.errors.seedlingsDiscarded &&
                validation.touched.seedlingsDiscarded
              )
            }
            errorMessage={validation.errors.seedlingsDiscarded}
            placeholder={tPlantedArea(
              'listNotes.modals.modalEdit.labels.typeSeedlingsDiscarded',
            )}
            label={tPlantedArea('listNotes.modals.modalEdit.labels.seedlingsDiscarded')}
            warning={!discardedAlert.isValid}
            warningMessage={discardedAlert.message}
          />
          <div className="row-span-2">
            <Input
              type="textarea"
              numRows={6}
              value={validation.values.observation}
              onBlur={validation.handleBlur('observation')}
              error={!!(validation.errors.observation && validation.touched.observation)}
              errorMessage={validation.errors.observation}
              onChange={validation.handleChange('observation')}
              placeholder={tPlantedArea(
                'listNotes.modals.modalEdit.labels.typeObservation',
              )}
              label={tPlantedArea('listNotes.modals.modalEdit.labels.observation')}
            />
          </div>
          <Select
            value={validation.values.geneticMaterialId}
            data={optionsGenetics}
            label={tPlantedArea('listNotes.modals.modalEdit.labels.geneticMaterial')}
            disabled={loadingInput}
            loading={loadingInput}
            onChange={(value) =>
              validation.setFieldValue('geneticMaterialId', Number(value))
            }
            onBlur={validation.handleBlur('geneticMaterialId')} // Adicione esta linha
            error={
              !!(
                validation.errors.geneticMaterialId &&
                validation.touched.geneticMaterialId
              )
            }
            errorMessage={validation.errors.geneticMaterialId}
          />
          <Select
            value={validation.values.reasonId}
            data={optionsReasons}
            label={tPlantedArea('listNotes.modals.modalEdit.labels.discardedCause')}
            disabled={loadingInput}
            loading={loadingInput}
            onChange={(value) => validation.setFieldValue('reasonId', Number(value))}
            onBlur={validation.handleBlur('reasonId')} // Adicione esta linha
            error={!!(validation.errors.reasonId && validation.touched.reasonId)}
            errorMessage={validation.errors.reasonId}
          />
          <div>
            <div className="flex dark:text-white gap-2 items-center h-full">
              <SwitchInput
                checked={validation.values.plantingEnd}
                onChange={validation.handleChange('plantingEnd')}
                value={validation.values.plantingEnd}
              />
              {tPlantedArea('listNotes.modals.modalEdit.labels.plantingEnd')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
