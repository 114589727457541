import { Skeleton } from '@mui/material';

const CardLoader = () => {
  return (
    <Skeleton
      variant="rectangular"
      className="flex-1 rounded-lg dark:bg-darkblack-400"
      height={124}
    />
  );
};
export default CardLoader;
