import { get, putFile, del, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  CompanyDataType,
  PutChangeImageResponseType,
  TableParamsType,
  UserListType,
} from './company-profile-types';
import { UpdateMyUserDataType } from '@/component/modals/modal-user/types';
import {
  AddUserDataType,
  AddUserResponseType,
  DeleteUserResponseType,
  UpdateUserData,
} from './company-profile-body/types';

export const getCompany = async (id: number): Promise<CompanyDataType> => {
  const result = (await get(url.GET_COMPANYS + '/' + id)) as CompanyDataType;
  return result;
};
export const getMyCompany = async (): Promise<CompanyDataType> => {
  const result = (await get(url.GET_MYCOMPANY)) as CompanyDataType;
  return result;
};
export const ChangeImage = async ({
  id,
  file,
}: {
  id: number;
  file: File;
}): Promise<PutChangeImageResponseType> => {
  const formData = new FormData();
  formData.append('file', file);
  const result = (await putFile(
    url.PUT_CHANGE_IMAGE + '/' + id,
    formData,
  )) as PutChangeImageResponseType;
  return result;
};

export const getUserList = async (data: TableParamsType): Promise<UserListType> => {
  return await get(url.GET_USERS, { params: data });
};

export const saveUser = async (data: AddUserDataType): Promise<AddUserResponseType> => {
  return await post(url.POST_USERS, data);
};

export const updateUser = async (data: UpdateUserData): Promise<AddUserResponseType> => {
  return await put(url.POST_USERS + '/' + data?.id, data);
};
export const updateMyUser = async (
  data: UpdateMyUserDataType,
): Promise<AddUserResponseType> => {
  return await put(url.POST_USERS + '/' + data.id, data);
};
export const changeKeyUser = async (
  data: UpdateUserData,
): Promise<AddUserResponseType> => {
  return await put(url.PUT_CHANGE_KEY_USER + '/' + data?.id, data);
};
export const changeMasterUser = async (
  data: UpdateUserData,
): Promise<AddUserResponseType> => {
  return await put(url.PUT_CHANGE_MASTER_USER + '/' + data?.id, data);
};

export const deleteUser = async (id: number): Promise<DeleteUserResponseType> => {
  return await del(url.POST_USERS + '/' + id);
};
export const confirmPassword = async (data: {
  password: string;
}): Promise<{ data: { valid: boolean }; message: string }> => {
  return await post(url.CONFIRM_PASSWORD, data);
};
export const newPasswordRequest = async (data: {
  newPassword: string;
  confirmPassword: string;
}) => {
  return await put(url.NEW_PASSWORD_AUTENTICADO, data);
};
