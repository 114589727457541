import React from 'react';
import ProtoTypes from 'prop-types';
import logoWhite from '@/assets/images/logo/logo-square.svg';
import { useTranslation } from 'react-i18next';

function RightSide({ img }: { img: string }) {
  const tSignin = useTranslation('signin').t;
  return (
    <div className="lg:w-1/2 lg:block hidden bg-[#EDF2F7] dark:bg-darkblack-600 relative min-h-screen ">
      <img
        src={img}
        alt=""
        style={{ zIndex: 0, objectFit: 'cover' }}
        className=" absolute w-full h-full"
      />
      <div className="z-10 w-full h-screen relative  justify-center flex px-10">
        <div className="text-center px-1.5 flex-col flex justify-end">
          <div className="flex-1"></div>
          <div className="mb-5">
            <img src={logoWhite} alt="" />
            <h3 className="text-bgray-200 dark:text-gray-200 font-semibold font-popins text-4xl mb-4">
              {tSignin('titleLogo')}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

RightSide.propTypes = {
  img: ProtoTypes.string,
};

export default RightSide;
