import React, { useEffect, useRef } from 'react';
import EChartComponent from '@/component/Echart';
import * as echarts from 'echarts';
import { formatNumber } from '@/utils/helpers/formaters';
import Colors from '@/utils/colors';
import { FaChevronDown } from 'react-icons/fa';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Skeleton } from '@mui/material';
import { forestRegistryStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { useFetch } from '@/hooks';
import SurvivalSurveysEmptyState from '@/pages/private/quality/silviculture/survival-surveys/list/header-results/myApprovals/EmptyState';
import { useTranslation } from 'react-i18next';

const SummaryByYearChart = observer(() => {
  const { theme } = userStore;
  const {
    getSummaryByYearData,
    yearSelected,
    summaryByYear,
    loadingSummaryByYearData,
    handleYearSelected,
  } = forestRegistryStore;
  const { request: requestChart } = useFetch(getSummaryByYearData);
  async function fetchCharts() {
    await requestChart();
  }
  useEffect(() => {
    fetchCharts();
  }, [yearSelected, userStore.companyActive]);
  const chartRef = useRef<HTMLDivElement>(null);
  const isDark = theme === 'dark';
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const tForestRegistry = useTranslation('forestRegistry').t;
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const optionConfig: echarts.EChartsOption = {
    legend: {
      bottom: 0,
      textStyle: {
        color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
        fontSize: 10,
      },
      show: true,
      data: [tForestRegistry('header.summaryByYearChart.plantedArea')],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: {
      type: 'category',
      data: summaryByYear.months.map((month) => month.monthName),
      axisLine: {
        lineStyle: { color: isDark ? Colors.bgray[200] : Colors.darkblack[600] },
      },
      axisLabel: {
        show: true,
        fontSize: 10,
        color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    yAxis: [
      {
        nameLocation: 'middle',
        nameGap: 5,
        show: false,
        type: 'value',
        name: tForestRegistry('header.summaryByYearChart.plantedArea'),
        axisLine: {
          show: false,
        },
        interval: 50,
        axisLabel: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        name: tForestRegistry('header.summaryByYearChart.plantedArea'),
        color: Colors.primary[200],
        label: {
          show: true,
          rotate: 90,
          padding: 8,
          fontSize: 12,
          color: theme === 'dark' ? 'white' : 'black',
          align: 'left',
          position: 'insideEnd',
          formatter: (value) =>
            value.data && Number(value.data) > 0 ? `${formatNumber(value.data)}ha` : '',
        },
        data: summaryByYear.months.map((month) => month.area),
      },
    ],
    animation: false,
    height: '85',
    darkMode: isDark,
  };

  return (
    <div className="rounded-lg bg-white py-3 gap-3 items-center flex-col h-full flex dark:bg-darkblack-500 lg:flex-[0.66] shadow-lg">
      <div className="flex justify-between items-center w-full px-3">
        <p className="dark:text-white font-bold">
          {tForestRegistry('header.summaryByYearChart.plantOfMonth')}
        </p>
        {summaryByYear.yearsFilter.length > 0 && (
          <button
            aria-describedby={id}
            className="text-sm w-16 bg-bgray-200 rounded px-3 py-1 flex items-center gap-1"
            onClick={handleClick}
          >
            {loadingSummaryByYearData && !yearSelected ? (
              <Skeleton
                variant="rectangular"
                height={'15px'}
                width={40}
                className="dark:bg-darkblack-400"
              />
            ) : (
              yearSelected
            )}
            <FaChevronDown />
          </button>
        )}
        <BasePopup
          id={id}
          className=" w-16"
          open={open}
          anchor={anchor}
          placement="bottom"
        >
          <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg max-h-48 overflow-y-auto overflow-style-none relative">
            {summaryByYear.yearsFilter.map((year, index) => {
              const isFirst = index === 0;
              const isEnd = index === summaryByYear.yearsFilter.length - 1;
              return (
                <div
                  key={year}
                  className={` cursor-pointer hover:bg-bgray-200 px-2 py-1 ${isFirst ? 'rounded-t-lg' : isEnd ? 'rounded-b-lg' : ''}`}
                  onClick={(event) => {
                    handleYearSelected(year);
                    handleClick(event);
                  }}
                >
                  <p className="text-base dark:text-white">{year}</p>
                </div>
              );
            })}
          </div>
        </BasePopup>
        <div
          onClick={() => setAnchor(null)}
          id="noti-outside"
          className={`fixed left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
      </div>
      <div className="w-full h-[190px] flex-col relative">
        {loadingSummaryByYearData ? (
          <div className="h-full px-5 py-2">
            <Skeleton
              variant="rectangular"
              className="rounded dark:bg-darkblack-400"
              height={'190px'}
              width={'100%'}
            />
          </div>
        ) : summaryByYear.months.length === 0 ? (
          <SurvivalSurveysEmptyState
            description={tForestRegistry('header.summaryByYearChart.emptyData')}
          />
        ) : (
          <EChartComponent
            chartRef={chartRef}
            option={optionConfig}
            style={{
              width: '100%',
              zIndex: 0,
              height: '100%',
            }}
          />
        )}
      </div>
    </div>
  );
});

export default SummaryByYearChart;
