import { formatNumber } from '@/utils/helpers/formaters';
import { get } from 'lodash';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const HeaderInfos = () => {
  const { evaluationDetails: evaluation, loading } = survivalSurveysEvaluationsStore;
  const loadingEvaluationData = loading.evaluationDetails;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const infosShow = [
    {
      field: 'forestRegistry.regiao',
      label: tSurvivalSurveys('details.body.headerInfos.regiao'),
    },
    {
      field: 'forestRegistry.fazenda',
      label: tSurvivalSurveys('details.body.headerInfos.fazenda'),
    },
    {
      field: 'forestRegistry.talhao',
      label: tSurvivalSurveys('details.body.headerInfos.talhao'),
    },
    {
      field: 'area',
      label: tSurvivalSurveys('details.body.headerInfos.area'),
      format: () => {
        return `${formatNumber(
          !loadingEvaluationData && evaluation?.id ? evaluation.forestRegistry.area : 0,
          2,
        )} ha`;
      },
    },
    {
      field: 'forestRegistry.dataPlantio',
      label: tSurvivalSurveys('details.body.headerInfos.datePlant'),
      format: () => {
        return `${moment(evaluation.forestRegistry.dataPlantio).format('DD/MM/YYYY')}`;
      },
    },
    {
      field: 'area',
      label: tSurvivalSurveys('details.body.headerInfos.ageActual'),
      format: () => {
        return `${moment().diff(moment(evaluation.forestRegistry.dataPlantio), 'days')} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'time',
      label: tSurvivalSurveys('details.body.headerInfos.time'),
      format: () => {
        return `${formatNumber(
          !loadingEvaluationData && evaluation?.id ? evaluation.time : 0,
          0,
        )} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'dateTimeStart',
      label: tSurvivalSurveys('details.body.headerInfos.ageInEvaluation'),
      format: () => {
        return `${moment(evaluation.dateTimeStart).diff(
          moment(evaluation.forestRegistry.dataPlantio),
          'days',
        )} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'dateTimeStart',
      label: tSurvivalSurveys('details.body.headerInfos.dateEvaluation'),
      format: () => {
        return moment(evaluation.dateTimeStart).format('DD/MM/YYYY');
      },
    },
    {
      field: 'user.name',
      label: tSurvivalSurveys('details.body.headerInfos.evaluator'),
      format: () => {
        return evaluation.user.name;
      },
    },
    {
      field: 'appVersion',
      label: tSurvivalSurveys('details.body.headerInfos.appVersion'),
      hide: !userStore.user.master,
    },
  ].filter((row) => !row.hide);

  const ItemLoader = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <Skeleton
          variant="rectangular"
          className="mb-1 dark:bg-darkblack-500 "
          width={Math.floor(Math.random() * (130 - 100 + 1)) + 100}
          height={22}
        />
        <Skeleton
          variant="rectangular"
          className={' dark:bg-darkblack-500'}
          width={Math.floor(Math.random() * (80 - 50 + 1)) + 50}
          height={10}
        />
      </div>
    );
  };
  return (
    <div
      className="
      items-center 
      dark:bg-darkblack-500 
      bg-white
      shadow-lg 
      grid 
      4xl:grid-cols-9
      3xl:grid-cols-8
      2xl:grid-cols-7
      xl:grid-cols-6
      lg:grid-cols-5 
      md:grid-cols-4 
      sm:grid-cols-2 
      grid-cols-2
      mb-5 
      gap-3 
      py-3 
      px-3
      rounded-lg"
    >
      {infosShow.map((info, index) => {
        return loadingEvaluationData ? (
          <ItemLoader key={'loaderEvaluationData_' + index} />
        ) : (
          <div className="text-center" key={`itemHeaderInfos${index}`}>
            <p className="sm:text-xl text-base xs:text-lg font-bold dark:text-white">
              {info.format !== undefined ? info.format() : get(evaluation, info.field)}
            </p>
            <p className=" mt-[-4px] text-gray-300 text-sm lg:text-sm xl:text-medium md:text-sm xs:text-sm sm:text-sm">
              {info.label}
            </p>
          </div>
        );
      })}
      {evaluation.observation && (
        <div className="text-start col-span-2 pl-[12.5%] dark:text-white">
          <p className="text-base font-semibold">
            {tSurvivalSurveys('details.body.headerInfos.observations')}:
          </p>
          <p className="text-sm">{evaluation.observation}</p>
        </div>
      )}
    </div>
  );
};
export default observer(HeaderInfos);
