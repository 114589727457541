import moment from 'moment';
import HeaderLoader from './header-loader';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import BadgeStatusApprovals from '../../list/badge-status-approvals';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { BsDownload } from 'react-icons/bs';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import React from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import tokml from 'tokml';
import * as turf from '@turf/turf';
import Colors from '@/utils/colors';
import { useTranslation } from 'react-i18next';
const DetailsHeader = () => {
  const {
    evaluationDetails: evaluation,
    loading,
    handleModal,
  } = survivalSurveysEvaluationsStore;
  const loadingEvaluationData = loading.evaluationDetails;
  const dateTimeStartMoment = moment(evaluation.dateTimeStart);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const exportToKML = () => {
    const features = [];
    const { coordinates, ...forestRegistryWithoutCoordinates } =
      evaluation.forestRegistry;

    const multipolygon = turf.multiPolygon(
      coordinates.map((polygon) => [
        polygon.coordinates.map((coord) => [coord.lng, coord.lat]),
      ]),
      forestRegistryWithoutCoordinates,
    );

    features.push(multipolygon);

    // Create LineString from polyline coordinates if available
    if (evaluation.polyline && evaluation.polyline.length > 0) {
      const polylineCoordinates = evaluation.polyline.map((coord) => [
        coord.lng,
        coord.lat,
      ]);
      const lineString = turf.lineString(polylineCoordinates);
      features.push(lineString); // Ensure LineString is added correctly
    }

    // Create points from survival survey dots
    if (evaluation.survivalSurveyDots && evaluation.survivalSurveyDots.length > 0) {
      const newPoints = evaluation.survivalSurveyDots.map((dot) =>
        turf.point([dot.coordinates.lng, dot.coordinates.lat], {
          ...dot,
          value: !dot.isAlive ? 1 : 0,
          cause: dot.deathCause?.cause || 'V',
          color: dot.deathCause?.color || Colors.primary[200],
        }),
      );
      features.push(...newPoints);
    }

    // Check if there are features to export
    if (features.length === 0) {
      return;
    }

    // Create a FeatureCollection with all the features
    const geojson = turf.featureCollection(features);

    // Convert the GeoJSON to KML
    const kml = tokml(geojson);

    // Create a temporary download link for the KML file
    const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download =
      evaluation.forestRegistry.fazenda.replace(/\s+/g, '_') +
      '__' +
      evaluation.forestRegistry.talhao.replace(/\s+/g, '_') +
      '.kml';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  return (
    <div
      className="
      rounded-lg
      bg-white
      justify-between
      px-6
      py-5
      gap-5
      xs:flex-col
      sm:flex
      dark:bg-darkblack-400
      shadow-lg
      mb-5"
    >
      {loadingEvaluationData ? (
        <HeaderLoader />
      ) : (
        <div className="flex items-center flex-1 justify-between">
          <div className="flex flex-col space-y-2 ">
            <div className="flex-col space-y-2">
              <p className="font-bold text-xl text-nowrap dark:text-gray-200">
                {tSurvivalSurveys('details.header.title', { cod: evaluation.cod })}
              </p>
              <BadgeStatusApprovals evaluation={evaluation} />
            </div>
            <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
              {tSurvivalSurveys('details.header.startIn', {
                date: dateTimeStartMoment.format('DD/MM/YYYY'),
                hours: dateTimeStartMoment.format('HH:mm'),
              })}
            </p>
          </div>
          <SecondaryButton
            text={tCommon('actions.export')}
            onClick={handleClick}
            icon={BsDownload}
          />
          <BasePopup id={id} open={open} anchor={anchor} placement="bottom">
            <ul className="bg-white z-50 dark:bg-darkblack-300 rounded-lg shadow-lg space-y-1 min-w-32 max-h-48 overflow-y-auto overflow-style-none">
              <li
                className="text-base cursor-pointer text-nowrali p-2 hover:bg-bgray-200 rounded-b-lg dark:text-white"
                onClick={(event) => {
                  exportToKML();
                  handleClick(event);
                }}
              >
                {tSurvivalSurveys('details.header.exportGeo')}
              </li>
            </ul>
          </BasePopup>
          <div
            onClick={() => setAnchor(null)}
            id="noti-outside"
            className={`fixed left-0 top-0 z-40 h-full w-full ${anchor ? 'block' : 'hidden'}`}
          ></div>
        </div>
      )}
      {Number(userStore.user.level.id) === Number(evaluation?.levelIdNextAction) &&
        !loading.evaluationDetails && (
          <div className="flex items-center justify-end gap-5 mt-2 md:mt-0">
            <button
              className="gap-1 font-semibold bg-error-200 text-white p-3 justify-center rounded w-full flex items-center"
              onClick={() => handleModal('repprove', true, evaluation)}
            >
              <FaRegTimesCircle color="white" />
              {tCommon('actions.repprove')}
            </button>
            <button
              className="font-semibold bg-success-200 text-white p-3 w-full justify-center rounded flex items-center gap-1"
              onClick={() => handleModal('approve', true, evaluation)}
            >
              <FaRegCheckCircle color="white" />
              {tCommon('actions.approve')}
            </button>
          </div>
        )}
    </div>
  );
};
export default observer(DetailsHeader);
