import Modal from '@/component/modals/modal-default';
import { AddCompanyDataType, AddCompanyModalProps } from './master-companies-types';
import ButtonSubmit from '@/component/forms/button-submit';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import Input from '@/component/forms/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { saveCompany, updateCompany } from './master-companies-services';
import { useMemo, useState } from 'react';
import { Toast } from '@/component/Toast';
import { observer } from 'mobx-react';
import { companyProfileStore } from '@/store';
import { CompanyDataType } from '@/pages/private/config/company-profile/company-profile-types';
import { docCnpjValidation, phoneValidation } from '@/utils/helpers/validations';
import Select from '@/component/forms/Select';
import { acceptedsLanguages } from '@/component/header/LanguageSelect';
import { useTranslation } from 'react-i18next';
const { showSuccess } = Toast;
export const AddCompanyModal = observer(
  ({ isActive, handleClose, company, reloadData = () => {} }: AddCompanyModalProps) => {
    const tMaster = useTranslation('master').t;
    const tCommon = useTranslation('common').t;
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { setCompanyData } = companyProfileStore;
    const validation = useFormik({
      enableReinitialize: true,

      initialValues: {
        id: company?.id,
        name: company?.name || '',
        doc: company?.doc || '',
        contact: company?.contact || '',
        manager: company?.manager || '',
        language: company?.language || 'pt-br',
      },

      validationSchema: Yup.object({
        id: Yup.number(),
        name: Yup.string().required(tMaster('companies.addCompany.errors.nameRequired')),
        doc: docCnpjValidation(),
        contact: phoneValidation(),
        manager: Yup.string().required(
          tMaster('companies.addCompany.errors.managerRequired'),
        ),
      }),

      onSubmit: async (values: AddCompanyDataType) => {
        try {
          setLoadingSubmit(true);
          const result = company?.id
            ? await updateCompany(values)
            : await saveCompany(values);
          if (company?.id) {
            setCompanyData(result.data as CompanyDataType);
          }
          showSuccess(result);
          reloadData();
          handleClose();
          setLoadingSubmit(false);
        } catch (error: any) {
          console.error(error.message);
          setLoadingSubmit(false);
        }
      },
    });

    useMemo(() => {
      if (company?.id) {
        validation.setValues({
          id: company?.id,
          name: company?.name,
          doc: company?.doc,
          contact: company?.contact,
          manager: company?.manager,
          language: company.language,
        });
      } else {
        validation.resetForm();
      }
    }, [company?.id]);
    useMemo(() => {
      if (!isActive) {
        validation.resetForm();
      }
    }, [isActive]);

    return (
      <Modal
        title={`${company?.id ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('company')}`}
        isActive={isActive}
        variant="sm"
        handleClose={handleClose}
        footer={
          <div className="flex w-full justify-end space-x-3">
            <SecondaryButton text={tCommon('actions.cancel')} onClick={handleClose} />
            <ButtonSubmit
              title={company?.id ? tCommon('actions.update') : tCommon('actions.save')}
              disabled={loadingSubmit}
              loading={loadingSubmit}
              onClick={() => validation.handleSubmit()}
            />
          </div>
        }
      >
        <form onSubmit={validation.handleSubmit} className="w-full">
          <Input
            disabled={loadingSubmit}
            value={validation.values.name}
            onChange={validation.handleChange('name')}
            onBlur={validation.handleBlur('name')} // Adicione esta linha
            label={tMaster('companies.addCompany.inputsLabels.companyName')}
            type={'text'}
            error={!!(validation.errors.name && validation.touched.name)}
            errorMessage={validation.errors.name}
          />
          <Input
            disabled={loadingSubmit}
            value={validation.values.doc}
            onChange={validation.handleChange('doc')}
            onBlur={validation.handleBlur('doc')} // Adicione esta linha
            label={tMaster('companies.addCompany.inputsLabels.companyDoc')}
            type={'cnpj'}
            error={!!(validation.errors.doc && validation.touched.doc)}
            errorMessage={validation.errors.doc}
          />
          <Input
            disabled={loadingSubmit}
            value={validation.values.contact}
            onChange={validation.handleChange('contact')}
            onBlur={validation.handleBlur('contact')} // Adicione esta linha
            label={tMaster('companies.addCompany.inputsLabels.contact')}
            type={'tel'}
            error={!!(validation.errors.contact && validation.touched.contact)}
            errorMessage={validation.errors.contact}
          />
          <Input
            disabled={loadingSubmit}
            value={validation.values.manager}
            onChange={validation.handleChange('manager')}
            onBlur={validation.handleBlur('manager')} // Adicione esta linha
            label={tMaster('companies.addCompany.inputsLabels.manager')}
            type={'text'}
            error={!!(validation.errors.manager && validation.touched.manager)}
            errorMessage={validation.errors.manager}
          />
          <Select
            value={validation.values.language}
            label={tMaster('companies.addCompany.inputsLabels.language')}
            data={acceptedsLanguages}
            onChange={(newValue) => validation.setFieldValue('language', newValue)}
            error={!!(validation.errors.language && validation.touched.language)}
            errorMessage={validation.errors.language}
          />
        </form>
      </Modal>
    );
  },
);
