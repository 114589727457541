import CardPlotsRegistereds from './CardPlotsRegistereds';
import CardPlotsAvaliables from './CardPlotsAvaliables';
import CardPlantedArea from './CardPlantedArea';
import CardGraph from './CardGraph';
import { useEffect } from 'react';
import { useFetch } from '@/hooks';
import { forestRegistryStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import SummaryByYearChart from './SummaryByYearChart';

const ForestRegistryHeader = () => {
  const { getSummaryData } = forestRegistryStore;
  const { request } = useFetch(getSummaryData);
  async function requestSummary() {
    await request();
  }
  useEffect(() => {
    requestSummary();
  }, [userStore.companyActive]);

  return (
    <div className="lg:flex w-full xs:flex-col space-x-0 lg:space-x-3 space-y-3 lg:space-y-0 mb-5 ">
      <div className="flex-[0.8] h-[260px]">
        <SummaryByYearChart />
      </div>
      <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-3 text-nowrap ">
        <CardPlotsRegistereds />
        <CardPlantedArea />
        <CardPlotsAvaliables />
        <CardGraph />
      </div>
    </div>
  );
};
export default observer(ForestRegistryHeader);
