import { useEffect, useState } from 'react';
import {
  ModulesType,
  SavePermissionCompanyResponseType,
  ServiceType,
} from './modules-and-services-types';
import {
  deletePermissionCompany,
  getModulesAndServices,
  savePermissionCompany,
} from './modules-and-services-services';
import { useParams } from 'react-router-dom';
import { Toast } from '@/component/Toast';
import { companyProfileStore, userStore } from '@/store';
const { showSuccess } = Toast;
const ModulesAndServicesController = () => {
  const { id } = useParams();
  const [modulesAndServicesData, setSetModulesAndSerciesData] = useState<ModulesType[]>(
    [],
  );
  const [loadingModules, setLoadingModules] = useState(false);
  const [loadingUpdateService, setLoadingUpdateService] = useState({
    loading: false,
    service: '',
  });

  const getData = async () => {
    setLoadingModules(true);
    const response = await getModulesAndServices({ companyId: Number(id) });
    setSetModulesAndSerciesData(response.modules);
    setLoadingModules(false);
  };
  useEffect(() => {
    getData();
  }, [id]);

  const handleService = async ({
    service,
    newValue,
  }: {
    service: ServiceType;
    newValue: boolean;
  }) => {
    setLoadingUpdateService({
      loading: true,
      service: service.service,
    });
    const response: SavePermissionCompanyResponseType = newValue
      ? await savePermissionCompany({
          companyId: Number(id),
          service: service.service,
        })
      : await deletePermissionCompany(Number(service?.permissionId));
    showSuccess(response);
    const newModules: ModulesType[] = modulesAndServicesData.map((module) => {
      return {
        ...module,
        services: module.services.map((serv) => {
          return {
            ...serv,
            permissionId:
              serv.service === service.service
                ? newValue
                  ? Number(response?.data?.id)
                  : null
                : serv.permissionId,
            active: serv.service === service.service ? newValue : serv.active,
          };
        }),
      };
    });
    setSetModulesAndSerciesData(newModules);
    await userStore.myUser();
    await companyProfileStore.getCompanyData(Number(id), false);
    setLoadingUpdateService({
      loading: false,
      service: '',
    });
  };
  return {
    modulesAndServicesData,
    loadingModules,
    loadingUpdateService,
    handleService,
  };
};
export default ModulesAndServicesController;
