import moment from 'moment';
import HeaderLoader from './header-loader';
import { FaRegCheckCircle } from 'react-icons/fa';
import { plantedAreaNoteStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BadgeStatusApprovals from '../../../components/badge-status-approvals';
const PlantedAreaDetailsHeader = () => {
  const { detailsNote: note, loading, handleModal } = plantedAreaNoteStore;
  const loadingEvaluationData = loading.details;

  const tPlantedArea = useTranslation('plantedArea').t;
  const tCommon = useTranslation('common').t;
  return (
    <div
      className="
      rounded-lg
      bg-white
      justify-between
      px-6
      py-5
      gap-5
      xs:flex-col
      sm:flex
      dark:bg-darkblack-400
      shadow-lg
      mb-5"
    >
      {loadingEvaluationData ? (
        <HeaderLoader />
      ) : (
        <div className="flex items-center flex-1 justify-between">
          <div className="flex flex-col space-y-2 ">
            <div className="flex-col space-y-2">
              <p className="font-bold text-xl text-nowrap dark:text-gray-200">
                {tPlantedArea('detailsNote.header.title', { cod: note.cod })}
              </p>
              <BadgeStatusApprovals note={note} />
            </div>
            <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
              {tPlantedArea('detailsNote.header.createdAt', {
                date: moment(note.createdAtMobile).format('DD/MM/YYYY'),
                hours: moment(note.createdAtMobile).format('HH:mm'),
              })}
            </p>
          </div>
        </div>
      )}
      {Number(userStore.user.level.id) === Number(note?.levelIdNextAction) &&
        !loading.details && (
          <div className="flex items-center justify-end gap-5 mt-2 md:mt-0">
            <button
              className="font-semibold bg-success-200 text-white p-3 w-full justify-center rounded flex items-center gap-1"
              onClick={() => handleModal('approve', true, note)}
            >
              <FaRegCheckCircle color="white" />
              {tCommon('actions.approve')}
            </button>
          </div>
        )}
    </div>
  );
};
export default observer(PlantedAreaDetailsHeader);
