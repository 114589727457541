import Table from '@/component/Table';
import ModalAddPlantedAreaGeneticMaterial from './modal-add-genetic-material';
import ModalActions from '@/component/modals/modal-actions';
import { PlantedAreaGeneticMaterialsTableTableControllerType } from './types';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { IoAdd } from 'react-icons/io5';
import { translationI18n } from '@/i18n';
import { useTranslation } from 'react-i18next';
import EmptyState from '@/component/EmptyState';

const PlantedAreaGeneticMaterialsTableTable = ({
  controller,
}: {
  controller: PlantedAreaGeneticMaterialsTableTableControllerType;
}) => {
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  const {
    columns,
    page,
    size,
    totalPages,
    geneticMaterials,
    loadingGeneticMaterials,
    order,
    setOrder,
    setPage,
    setSize,
    options,
    validation,
    modalsState,
    setModalsState,
    onDelete,
    canInsert,
  } = controller;
  return (
    <div className="bg-white w-[100%] h-[100%] px-4 pt-5 mb-[40px]  rounded-lg dark:bg-darkblack-600 shadow-lg">
      <div className="flex justify-end">
        {canInsert && (
          <PrimaryButton
            icon={IoAdd}
            text={translationI18n('common:actions.add')}
            onClick={() =>
              setModalsState({ ...modalsState, add: true, geneticMaterial: undefined })
            }
            loading={false}
          />
        )}
      </div>
      {!loadingGeneticMaterials && geneticMaterials.length === 0 ? (
        <EmptyState
          title={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.emptyState.title',
          )}
          description={translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.emptyState.description',
          )}
        />
      ) : (
        <Table
          options={options}
          showSearch={false}
          columns={columns()}
          page={page}
          size={size}
          totalPages={totalPages}
          data={geneticMaterials}
          setPage={setPage}
          setSize={setSize}
          loading={loadingGeneticMaterials}
          order={order}
          setOrder={setOrder}
        />
      )}
      <ModalAddPlantedAreaGeneticMaterial
        validation={validation}
        setModalState={setModalsState}
        modalState={modalsState}
      />
      <ModalActions
        isActive={modalsState.delete}
        handleClose={() =>
          setModalsState({ ...modalsState, delete: false, geneticMaterial: undefined })
        }
        handleSubmit={onDelete}
        variant={'delete'}
        title={tCompanyProfile(
          'body.screens.params.items.plantedArea.params.geneticMaterials.deleteModal.title',
        )}
        description={tCompanyProfile(
          'body.screens.params.items.plantedArea.params.geneticMaterials.deleteModal.description',
        )}
        submitText={tCommon('actions.delete')}
        cancelText={tCommon('actions.cancel')}
        loading={modalsState.loading}
      />
    </div>
  );
};
export default PlantedAreaGeneticMaterialsTableTable;
