import { dashboardSurvivalSurveysStore } from '@/store';
import Colors from '@/utils/colors';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const LegendSurvivalChart = observer(() => {
  const {
    causesChart,
    handleCausesDeathsSurvivalSurveysChart,
    handleGroupsSurvivalSurveysChart,
    handleDeathCauseHighlight,
    survivalSurveysDataCause,
    survivalSurveysDataChart,
    evaluationsChart,
  } = dashboardSurvivalSurveysStore;
  const color = [
    Colors.primary[200],
    Colors.primary[300],
    Colors.primary[400],
    Colors.warning[400],
    Colors.purple,
  ];

  const tHome = useTranslation('home').t;
  return (
    <div className="gap-2 flex items-center justify-between top-2 px-4 mt-2 max-w-full text-nowrap">
      <div className="flex flex-col lg:flex-row lg:items-center">
        <p className="mr-2 dark:text-white">
          {tHome('survivalSurveys.survivalChart.lenged.evaluation')}
        </p>
        <div className="flex gap-2 flex-1 flex-wrap">
          {survivalSurveysDataChart.map((item, index) => {
            const isVisible = evaluationsChart
              .filter((evaluation) => evaluation.show)
              .map((evaluation) => evaluation.evaluation)
              .includes(
                String(`${item.group} ${tHome('survivalSurveys.survivalChart.days')}`),
              );
            return (
              <button
                onClick={() =>
                  handleGroupsSurvivalSurveysChart(
                    String(
                      `${item.group} ${tHome('survivalSurveys.survivalChart.days')}`,
                    ),
                  )
                }
                key={index}
                // style={{ alignItems: 'center', justifyContent: 'center' }}
                className="flex gap-1 items-center cursor-pointer justify-center"
                onMouseEnter={() => {
                  handleDeathCauseHighlight(
                    `${item.group} ${tHome('survivalSurveys.survivalChart.days')}`,
                  );
                }}
                onMouseOut={() => {
                  handleDeathCauseHighlight('');
                }}
              >
                <div
                  className="w-8 h-4 rounded"
                  style={{
                    backgroundColor: color[index],
                    opacity: !isVisible ? 0.2 : 1,
                  }}
                />
                <span
                  className={`dark:text-bgray-200 text-sm inline-block ${
                    isVisible ? '' : 'line-through opacity-20'
                  }`}
                >
                  {`${item.group} ${tHome('survivalSurveys.survivalChart.days')}`}
                </span>
              </button>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center">
        <p className="mr-2 dark:text-white">
          {tHome('survivalSurveys.survivalChart.lenged.causes')}
        </p>
        <div className="flex gap-2 flex-1 flex-wrap">
          {causesChart.map((item, index) => {
            const causeObj = survivalSurveysDataCause.find(
              (cause) => cause.cause === item.cause,
            );
            const isVisible = causesChart
              .filter((cause) => cause.show)
              .map((cause) => cause.cause)
              .includes(String(item.cause));
            return (
              <button
                onClick={() => handleCausesDeathsSurvivalSurveysChart(String(item.cause))}
                key={index}
                // style={{ alignItems: 'center', justifyContent: 'center' }}
                className="flex gap-1 items-center cursor-pointer justify-center"
                onMouseEnter={() => {
                  handleDeathCauseHighlight(item.cause);
                }}
                onMouseOut={() => {
                  handleDeathCauseHighlight('');
                }}
              >
                <div
                  className="w-8 h-4 rounded"
                  style={{
                    backgroundColor: causeObj?.color,
                    opacity: !isVisible ? 0.2 : 1,
                  }}
                />
                <span
                  className={`dark:text-bgray-200 text-sm inline-block ${
                    isVisible ? '' : 'line-through opacity-20'
                  }`}
                >
                  {item?.cause}
                </span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
});
