import { del, get, post } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  GetModulesResposneType,
  SavePermissionCompanyDatatype,
  SavePermissionCompanyResponseType,
} from './modules-and-services-types';
export const getModulesAndServices = async (data: {
  companyId: number;
}): Promise<GetModulesResposneType> => {
  return await get(url.GET_PERMISSIONS_COMPANY, { params: data });
};

export const savePermissionCompany = async (
  data: SavePermissionCompanyDatatype,
): Promise<SavePermissionCompanyResponseType> => {
  return await post(url.POST_PERMISSIONS_COMPANY, data);
};

export const deletePermissionCompany = async (
  permissionId: number,
): Promise<{
  message: string;
}> => {
  return await del(url.POST_PERMISSIONS_COMPANY + '/' + permissionId);
};
