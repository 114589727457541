import { ForestRegistrySummaryType } from '@/utils/types/ForestRegistryType';
import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { inititalStateSummaryData } from '../../forest-registry/forest-registry-initial-states';
import {
  DashboardFiltersForestRegistryRequestType,
  ForestRegistryPlantedAreaType,
  LoadingDashboardForestRegistryType,
} from './dashboard-forest-registry-types';
import {
  getPlantedArea,
  getServerForestRegistrySummary,
} from './dashboard-forest-registry-services';
import { userStore } from '@/store';
import { getForestRegistryFilters } from './dashboard-forest-registry-services';
import { DashboardFiltersSurvivalSurveysType } from '../survival-surveys/dashboard-survival-surveys-types';
import { inititalStatesDashboardForestRegistry } from './dashboard-forest-registry-initital-states';

export class DashboardForestRegistryStore {
  constructor() {
    makeAutoObservable(this);
    makeObservable(this);
    void makePersistable(this, {
      name: 'DashboardStore',
      properties: [],
    });
  }
  filterForestRegistry: DashboardFiltersForestRegistryRequestType =
    inititalStatesDashboardForestRegistry.filter;
  filtersForestRegistry: DashboardFiltersSurvivalSurveysType =
    inititalStatesDashboardForestRegistry.filters;
  forestRegistrySummary: ForestRegistrySummaryType = inititalStateSummaryData;
  forestRegistryPlantedArea: ForestRegistryPlantedAreaType[] = [];

  loading: LoadingDashboardForestRegistryType =
    inititalStatesDashboardForestRegistry.loading;

  reset = () => {
    this.filterForestRegistry = inititalStatesDashboardForestRegistry.filter;
    this.filtersForestRegistry = inititalStatesDashboardForestRegistry.filters;
    this.forestRegistrySummary = inititalStateSummaryData;
    this.forestRegistryPlantedArea = [];
    this.loading = inititalStatesDashboardForestRegistry.loading;
  };

  handleLoading = (key: keyof LoadingDashboardForestRegistryType, value: boolean) => {
    runInAction(() => {
      this.loading[key] = value;
    });
  };

  getFiltersForestRegistry = async () => {
    this.handleLoading('dashboardFiltersForestRegistry', true);
    try {
      const newFilters = await getForestRegistryFilters({
        ...this.filterForestRegistry,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.filtersForestRegistry = newFilters;
      });
      this.handleLoading('dashboardFiltersForestRegistry', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('dashboardFiltersForestRegistry', false);
    }
  };
  getForetRegistrySummaryData = async () => {
    this.handleLoading('forestRegistrySummary', true);
    try {
      const newSummaryData = await getServerForestRegistrySummary({
        ...this.filterForestRegistry,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.forestRegistrySummary = newSummaryData;
      });
      this.handleLoading('forestRegistrySummary', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('forestRegistrySummary', false);
    }
  };
  getForestRegistryPlantedArea = async () => {
    this.handleLoading('forestReistryPlantedArea', true);
    try {
      const newForestRegistryPlantedArea = await getPlantedArea({
        ...this.filterForestRegistry,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.forestRegistryPlantedArea = newForestRegistryPlantedArea;
      });
      this.handleLoading('forestReistryPlantedArea', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('forestReistryPlantedArea', false);
    }
  };
  handleFiltersForestRegistry = (
    key: keyof DashboardFiltersForestRegistryRequestType,
    value: string[],
  ) => {
    runInAction(() => {
      this.filterForestRegistry = { ...this.filterForestRegistry, [key]: value };
    });
  };
  handleClearFiltersForestRegistry = () => {
    runInAction(() => {
      this.filterForestRegistry = inititalStatesDashboardForestRegistry.filter;
    });
  };
}
