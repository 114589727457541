import { Skeleton } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import { userStore } from '@/store';
import { observer } from 'mobx-react';
import TableHeader from '../modules-and-services-table-header';

const LoaderModulesAndServices = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;
  const rows = [];
  const rowsIndex = [];
  const nRows = 2;
  for (let i = 0; i < nRows; i++) {
    rowsIndex.push(i);
    rows.push(
      <tr className="border-b border-bgray-400 dark:border-darkblack-400" key={i}>
        <td className={`py-3 px-6 w-[40%]`}>
          <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
            <Skeleton
              className="dark:bg-darkblack-400"
              height={21}
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
            />
          </p>
        </td>
        <td className={`py-3 px-6 w-[40%]`}>
          <p className="text-xs font-regular text-bgray-900 dark:text-gray-200">
            <Skeleton
              className="dark:bg-darkblack-400"
              height={18}
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
            />
          </p>
        </td>

        <td className={`py-3 px-6 w-[20%] items-center justify-center`}>
          <PuffLoader
            color={userStore.theme === 'dark' ? 'white' : '#718096'}
            size={26}
          />
        </td>
      </tr>,
    );
  }

  return rowsIndex.map((item) => (
    <div
      key={item}
      className="w-full rounded-lg bg-white mt-5 px-[24px] py-[20px] dark:bg-darkblack-600"
    >
      <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
        <Skeleton
          className="dark:bg-darkblack-400"
          height={24}
          width={`${Math.floor(Math.random() * (40 - 20 + 1)) + 20}%`}
        />
      </h1>
      <div className="w-full mt-3">
        <table className="w-full ">
          <thead>
            <TableHeader />
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  ));
};
export default observer(LoaderModulesAndServices);
