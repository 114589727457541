import Modal from '@/component/modals/modal-default';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import Select from '@/component/forms/Select';
import Input from '@/component/forms/Input';
import { useFormik } from 'formik';
import { survivalSurveysEvaluationsStore } from '@/store';
import * as Yup from 'yup';
import { newApprovationOrReprovationDataType } from './types';
import { useFetch } from '@/hooks';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
const ModalRepprove = () => {
  const { modalStates, handleModal, loading, repproveEvaluation } =
    survivalSurveysEvaluationsStore;
  const { request: requestRepproveEvaluation } = useFetch(repproveEvaluation);

  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  const motivations = [
    tSurvivalSurveys('modals.modalRepprove.motivations.inconsistentData'),
    tSurvivalSurveys('modals.modalRepprove.motivations.nonRepresentativeSampling'),
    tSurvivalSurveys('modals.modalRepprove.motivations.othersReasons'),
  ].map((motivation) => {
    return {
      label: motivation,
      value: motivation,
    };
  });
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      motivation: '',
      description: '',
      approved: false,
      evaluationId: modalStates.evaluation?.id || 0,
    },

    validationSchema: Yup.object({
      motivation: Yup.string().required(
        tSurvivalSurveys('modals.modalRepprove.requiredMotivation'),
      ),
      description: Yup.string().required(
        tSurvivalSurveys('modals.modalRepprove.requiredDescription'),
      ),
    }),

    onSubmit: async (values: newApprovationOrReprovationDataType) => {
      await requestRepproveEvaluation(values);
    },
  });
  return (
    <Modal
      title={tSurvivalSurveys('modals.modalRepprove.title', {
        cod: modalStates.evaluation?.cod,
      })}
      isActive={modalStates.repprove}
      handleClose={() => !loading.modalRepprove && handleModal('repprove', false)}
      variant="sm"
      footer={
        <div className="flex justify-end items-center gap-5">
          <SecondaryButton
            text={tCommon('actions.cancel')}
            onClick={() => !loading.modalRepprove && handleModal('repprove', false)}
          />
          <PrimaryButton
            text={tCommon('actions.repprove')}
            loading={loading.modalRepprove}
            onClick={validation.handleSubmit}
          />
        </div>
      }
    >
      <div className="h-hull w-fulł">
        <h3 className="font-bold text-xldark:text-gray-200">
          {tSurvivalSurveys('modals.modalRepprove.description', {
            cod: modalStates.evaluation?.cod,
          })}
        </h3>
        <p className="text-bgray-600 text-sm my-3">
          {tSurvivalSurveys('modals.modalRepprove.note')}
        </p>
        <Select
          creatable
          value={String(validation.values.motivation)}
          data={motivations}
          loading={loading.modalRepprove}
          onBlur={validation.handleBlur('motivation')} // Adicione esta linha
          onChange={(newValue) => {
            validation.setFieldValue('motivation', newValue);
          }}
        />
        <Input
          disabled={loading.modalRepprove}
          value={validation.values.description}
          onChange={validation.handleChange('description')}
          onBlur={validation.handleBlur('description')} // Adicione esta linha
          label={tSurvivalSurveys('modals.modalRepprove.descriptionLabel')}
          type={'textarea'}
          numRows={5}
          error={!!(validation.errors.description && validation.touched.description)}
          errorMessage={validation.errors.description}
        />
      </div>
    </Modal>
  );
};

export default observer(ModalRepprove);
