import { get } from '@/utils/helpers/api_helper';
import {
  DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CAUSES,
  DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CHART,
  DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_MAP,
  DASHBOARD_GET_SURVIVAL_SURVEYS_FILTERS,
  DASHBOARD_GET_SURVIVAL_SURVEYS_SUMMARY,
} from '@/utils/helpers/url_helper';
import {
  DashboardFiltersSurvivalSurveysType,
  DashboardSurvivalSurveysDataRequestType,
  DashboardSurvivalSurveysDataResponseType,
  DashboardSurvivalSurveysRequestType,
  DashboardSurvivalSurveysSummaryResponseType,
  SurvivalSurveysDataCausesType,
  SurvivalSurveysDataMapType,
} from './dashboard-survival-surveys-types';

export async function getSurvivalSurveysSummary(
  data: DashboardSurvivalSurveysRequestType,
): Promise<DashboardSurvivalSurveysSummaryResponseType> {
  return await get(DASHBOARD_GET_SURVIVAL_SURVEYS_SUMMARY, { params: data });
}

export async function getSurvivalSurveysFilters(
  data: DashboardSurvivalSurveysRequestType,
): Promise<DashboardFiltersSurvivalSurveysType> {
  return await get(DASHBOARD_GET_SURVIVAL_SURVEYS_FILTERS, { params: data });
}

export async function getSurvivalSurveysMap(
  data: DashboardSurvivalSurveysRequestType,
): Promise<SurvivalSurveysDataMapType[]> {
  return await get(DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_MAP, {
    params: data,
  });
}
export async function getSurvivalSurveysCauses(
  data: DashboardSurvivalSurveysRequestType,
): Promise<SurvivalSurveysDataCausesType[]> {
  return await get(DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CAUSES, {
    params: data,
  });
}

export async function getSurvivalSurveysData(
  data: DashboardSurvivalSurveysDataRequestType,
): Promise<DashboardSurvivalSurveysDataResponseType[]> {
  return await get(DASHBOARD_GET_SURVIVAL_SURVEYS_DATA_CHART, { params: data });
}
