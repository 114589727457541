import { useEffect, useMemo, useState } from 'react';
import {
  getParamsCauses,
  putChangeAllCauseCompany,
  putChangeCauseCompany,
} from './survival-surveys-causes-services';
import {
  SurvivalSurveyCauseType,
  SurvivalSurveysCausesTableControllerType,
} from './survival-surveys-causes-types';
import { companyProfileStore, userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import _, { Dictionary } from 'lodash';

const SurvivalSurveysCausesTableController =
  (): SurvivalSurveysCausesTableControllerType => {
    const { companyData, isMyCompany } = companyProfileStore;
    const companyId = companyData.id;
    const [causes, setCauses] = useState<Dictionary<SurvivalSurveyCauseType[]>>();
    const [loadingCauses, setLoadingCauses] = useState(false);
    const [allSelected, setAllSelected] = useState(true);
    const getCauses = async () => {
      setLoadingCauses(true);
      const response: SurvivalSurveyCauseType[] = await getParamsCauses({
        companyId: Number(companyId),
      });
      setCauses(_.groupBy(response, 'origin'));
      setLoadingCauses(false);
    };
    useMemo(() => {
      getCauses();
    }, [companyData.id]);
    useEffect(() => {
      if (causes) {
        // Obtém um array dos valores do objeto causes
        const causesArray = Object.values(causes);

        // Verifica se há algum item desativado em qualquer subarray dentro de causesArray
        const hasDisabledItem = causesArray.some((origin) =>
          origin.some((cause) => !cause.active),
        );

        // Define o estado allSelected com base na verificação
        setAllSelected(!hasDisabledItem);
      }
    }, [causes]);

    const [loadingUpdateCause, setLoadingUpdateCause] = useState('');

    async function onHandleActive(cause: SurvivalSurveyCauseType) {
      setLoadingUpdateCause(cause.cause);
      try {
        const response = await putChangeCauseCompany({
          companyId: companyData.id,
          causeId: cause.id,
        });
        if (causes) {
          const newCauses: Dictionary<SurvivalSurveyCauseType[]> = _.mapValues(
            causes,
            function (ca) {
              return _.map(ca, function (cau) {
                return cau.id === cause.id ? response.data : cau;
              });
            },
          );

          setCauses(newCauses);
        }
        setLoadingUpdateCause('');
      } catch (error: any) {
        console.error(error.message);
        setLoadingUpdateCause('');
      }
    }

    async function onChangeAllCausesOfCompany() {
      try {
        setLoadingUpdateCause('all');
        const response = await putChangeAllCauseCompany({
          companyId: companyData.id,
          active: !allSelected,
        });
        setCauses(_.groupBy(response.data, 'origin'));
        setAllSelected(!allSelected);
        setLoadingUpdateCause('');
      } catch (error: any) {
        console.error(error.message);
        setLoadingUpdateCause('');
      }
    }

    const canInsert =
      (isMyCompany &&
        checkPermission({
          permission: 'hasone',
          service: 'survivalSurveys',
        }) &&
        checkPermission({
          service: 'params',
          permission: 'permissionUpdate',
        })) ||
      userStore.user.master;
    return {
      causes,
      loadingCauses,
      canInsert,
      onHandleActive,
      loadingUpdateCause,
      onChangeAllCausesOfCompany,
      allSelected,
    };
  };
export default SurvivalSurveysCausesTableController;
