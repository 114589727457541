import { observer } from 'mobx-react';
import { paramType } from '../master-params-types';
import servicesParams from './data';
import ParamsItem from './params-item';
const ParamsComponent = ({
  activeParam,
  setActiveParam,
}: {
  activeParam: paramType;

  setActiveParam: (x: paramType) => void;
}) => {
  return servicesParams().map((module) => {
    return (
      <ParamsItem
        module={module}
        key={module.title}
        activeParam={activeParam}
        setActiveParam={setActiveParam}
      />
    );
  });
};
export default observer(ParamsComponent);
