import ButtonSubmit from '@/component/forms/button-submit';
import NewPassordController from './left-side-new-password-controller';
import InputPassword from '@/component/forms/InputPassword';
import { userStore } from '@/store';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LeftSideNewPassword = () => {
  const { validation, loadingNewPassword } = NewPassordController();
  const navigate = useNavigate();
  const tCommon = useTranslation('common').t;
  const tSignin = useTranslation('signin').t;
  return (
    <div className="lg:w-1/2 px-5 xl:pl-12 pt-10">
      <div className="max-w-[450px] m-auto h-screen items-center justify-center flex">
        <div>
          <div className="flex-1">
            <header className="text-center mb-8">
              <p className="text-bgray-900 dark:text-gray-200 text-2xl font-semibold font-poppins mb-2">
                {tSignin('definitivePassword.intro', {
                  name: capitalize(userStore.user.name.split(' ')[0]),
                })}
              </p>
              <p className="font-urbanis text-sm font-medium text-bgray-600 dark:text-bgray-50">
                {tSignin('definitivePassword.description')}
              </p>
            </header>
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <InputPassword
                value={validation.values.newPassword}
                onChange={validation.handleChange('newPassword')}
                disabled={loadingNewPassword}
                error={
                  !!(validation.errors.newPassword && validation.touched.newPassword)
                }
                errorMessage={validation.errors.newPassword}
                onBlur={validation.handleBlur('newPassword')}
                placeholder={tSignin('definitivePassword.newPassword')}
              />
              <InputPassword
                value={validation.values.confirmPassword}
                onChange={validation.handleChange('confirmPassword')}
                disabled={loadingNewPassword}
                error={
                  !!(
                    validation.errors.confirmPassword &&
                    validation.touched.confirmPassword
                  )
                }
                errorMessage={validation.errors.confirmPassword}
                onBlur={validation.handleBlur('confirmPassword')}
                placeholder={tSignin('definitivePassword.confirmPassword')}
              />

              <ButtonSubmit
                wFull
                loading={loadingNewPassword}
                title={tSignin('definitivePassword.submitText')}
              />
            </form>
            <p className="text-base font-medium text-bgray-600 mt-5 dark:text-darkblack-300 mb-7">
              {tSignin('definitivePassword.infos')}
            </p>
            <button
              className="w-full mt-5 items-center jutify-center text-center"
              onClick={() => {
                userStore.logout();
                navigate('/');
              }}
            >
              <p className="text-success-200 font-semibold text-sm">
                {tSignin('definitivePassword.goBackToSignin')}
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="text-center w-full mb-0 ">
        <p className="text-bgray-600 dark:text-gray-200 text-center text-sm mb-6">
          {tCommon('copyriht')}
        </p>
      </div>
    </div>
  );
};
export default LeftSideNewPassword;
