import DetailsSurvivalGraph from './DetailsSurvivalGraph';
import DetailsDeathGraph from './DetailsDeathGraph';
import DetailsSamplingGraph from './DetailsSamplingGraph';
import { survivalSurveysEvaluationType } from '../../../../quality-survival-surveys-types';

const DetailsGraphs = ({ evaluation }: { evaluation: survivalSurveysEvaluationType }) => {
  return (
    <div className="flex flex-1 space-y-0 space-x-3">
      <DetailsSurvivalGraph evaluation={evaluation} />
      <DetailsDeathGraph evaluation={evaluation} />
      <DetailsSamplingGraph evaluation={evaluation} />
    </div>
  );
};
export default DetailsGraphs;
