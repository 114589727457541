import { formatNumber } from '@/utils/helpers/formaters';
import PendencieIcon from './PendencieIcon';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { useFetch } from '@/hooks';
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import SurvivalSurveysEmptyState from '../myApprovals/EmptyState';
import { PendencieType } from '../../../quality-survival-surveys-types';
import { useTranslation } from 'react-i18next';

const HeaderResultsPendencies = () => {
  const { pendencies, loading, getPendencies } = survivalSurveysEvaluationsStore;

  const { request: requestPendencies } = useFetch(getPendencies);
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  async function getListPendencies() {
    await requestPendencies();
  }

  useEffect(() => {
    getListPendencies();
  }, [userStore.companyActive]);

  const PendencieItem = ({ pendencie }: { pendencie: PendencieType }) => {
    const time =
      moment(new Date(pendencie.expectedDate).setHours(0, 0, 0, 0)).diff(
        moment(new Date().setHours(0, 0, 0, 0)),
        'days',
      ) + 1;
    const idade = moment().diff(moment(pendencie.dataPlantio), 'days');
    return (
      <div
        className="bg-bgray-200 dark:bg-darkblack-400 px-3 py-2 rounded"
        key={'row_pendencie' + pendencie.id + pendencie.time}
      >
        <div className="flex justify-between">
          <div className="flex gap-2">
            <PendencieIcon />
            <p className="font-semibold text-lg dark:text-white">{pendencie.talhao}</p>
          </div>
          <p className="text-sm dark:text-bgray-200">
            {time === 0
              ? tSurvivalSurveys(
                  'survivalSurveysList.headerResults.pendencies.todayEvaluation',
                )
              : `${Math.abs(time)} dia${Math.abs(time) === 1 ? '' : 's'} ${time > 0 ? tSurvivalSurveys('survivalSurveysList.headerResults.pendencies.forEvaluation') : tSurvivalSurveys('survivalSurveysList.headerResults.pendencies.ofDelay')}`}
          </p>
        </div>
        <p className="text-base text-gray-600 dark:text-bgray-200">{`${pendencie.fazenda} • ${formatNumber(pendencie.area, 2)} ${tCommon('ha')}`}</p>
        <p className="font-light text-sm dark:text-white">{`${tSurvivalSurveys('survivalSurveysList.headerResults.pendencies.plantedIn')} ${moment(pendencie.dataPlantio).format('DD/MM/YYYY')} (${idade} ${tSurvivalSurveys('survivalSurveysList.days')})`}</p>
        <p className="font-bold dark:text-white">{`${pendencie.time} ${tSurvivalSurveys('survivalSurveysList.days')}`}</p>
      </div>
    );
  };

  return (
    <div className="rounded-lg  px-3  bg-white py-3 items-center h-full flex-col dark:bg-darkblack-600 flex-1 relative shadow-lg">
      <div className="flex items-center">
        <p className="dark:text-white font-bold">
          {tSurvivalSurveys('survivalSurveysList.headerResults.pendencies.pendencies')}
        </p>
        <p className="ml-2 bg-bgray-300 rounded text-sm px-2">
          {formatNumber(pendencies.length, 0)}
        </p>
      </div>
      <div className="overflow-y-auto flex-col relative overflow-style-none space-y-2 h-[260px]">
        {!loading.pendencies ? (
          pendencies.length === 0 ? (
            <SurvivalSurveysEmptyState
              description={tSurvivalSurveys(
                'survivalSurveysList.headerResults.pendencies.emptyData',
              )}
            />
          ) : (
            pendencies.map((pendence) => {
              return (
                <PendencieItem
                  key={`pendencie_item_${pendence.id}_${pendence.time}`}
                  pendencie={pendence}
                />
              );
            })
          )
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={103}
              className="px-3 py-2 rounded dark:bg-darkblack-400"
            />
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={103}
              className="px-3 py-2 rounded dark:bg-darkblack-400"
            />
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={103}
              className="px-3 py-2 rounded dark:bg-darkblack-400"
            />
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={103}
              className="px-3 py-2 rounded dark:bg-darkblack-400"
            />
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={103}
              className="px-3 py-2 rounded dark:bg-darkblack-400"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(HeaderResultsPendencies);
