import DetailsMap from './details-map';
import DetailsCausesGraph from './causes-graph';
import DetailsGraphs from './details-graphs';
import { observer } from 'mobx-react';
import ModalChangeCause from './modal-change-cause';

const DetailsResult = () => {
  return (
    <div className="md:flex-col lg:flex xl:flex w-full gap-3 sm:flex-col lg:flex-row">
      <div className="flex-1 flex-col gap-3">
        <DetailsGraphs />
        <div className="flex flex-1 mt-3">
          <DetailsCausesGraph />
        </div>
      </div>
      <div className="flex flex-1 mt-3 lg:mt-0">
        <DetailsMap />
      </div>
      <ModalChangeCause />
    </div>
  );
};

export default observer(DetailsResult);
