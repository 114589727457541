import { translationI18n } from '@/i18n';

const OriginItemTableHeader = () => {
  return (
    <thead>
      <tr className="border-b border-bgray-400 dark:border-darkblack-400 w-full">
        <th
          className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.causes.columns.cause',
          )}
        </th>
        <th
          className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.causes.columns.description',
          )}
        </th>
        <th
          className={`py-2 text-left text-sm text-gray-500 dark:bg-darkblack-600 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.survivalSurveys.params.causes.columns.enabled',
          )}
        </th>
      </tr>
    </thead>
  );
};

export default OriginItemTableHeader;
