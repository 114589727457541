import { useContext, useEffect, useState } from 'react';
import { ServicesParamsControllerTypes } from './master-params-types';
import servicesParams from './items/data';
import { TitleContext } from '@/component/layout';
import { useTranslation } from 'react-i18next';
import { userStore } from '@/store';

const servicesParamsController = (): ServicesParamsControllerTypes => {
  const [activeParam, setActiveParam] = useState(servicesParams()[0].params[0].param);
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const tMaster = useTranslation('master').t;
  useEffect(() => {
    setTitle(tMaster('paramsPage.paramsPageTitle'));
    setSubTitle(tMaster('paramsPage.paramsPageSubtitle'));
  }, [userStore.user.language, localStorage.getItem('language')]);
  return {
    activeParam,
    setActiveParam,
  };
};
export default servicesParamsController;
