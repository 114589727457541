import { translationI18n } from '@/i18n';

const PlantedAreaReasonItemTableHeader = () => {
  return (
    <thead>
      <tr className="border-b border-bgray-400 dark:border-darkblack-400 w-full">
        <th
          className={`py-2 text-left text-sm text-gray-400 dark:text-white dark:bg-darkblack-500 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.reasons.columns.reason',
          )}
        </th>
        <th
          className={`py-2 text-left text-sm text-gray-400 dark:text-white dark:bg-darkblack-500 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.reasons.columns.description',
          )}
        </th>
        <th
          className={`py-2 text-left text-sm text-gray-400 dark:text-white dark:bg-darkblack-500 bg-white px-6`}
        >
          {translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.reasons.columns.enabled',
          )}
        </th>
      </tr>
    </thead>
  );
};

export default PlantedAreaReasonItemTableHeader;
