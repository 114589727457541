import EChartComponent from '@/component/Echart';
import { Skeleton } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { dashboardSurvivalSurveysStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import LegendChart from './causes-graph-lengend';
import { isArray } from 'lodash';
import { useTranslation } from 'react-i18next';
import EmptyState from '@/component/EmptyState';

const CausesGraph = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const {
    selectedChart,
    handleSelectedChart,
    survivalSurveysDataCause,
    causesDeaths,
    optionsSurvivalChart,
    loading,
    deathCauseHighlight,
  } = dashboardSurvivalSurveysStore;
  const loadingEvaluationData = loading.causes;
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const tHome = useTranslation('home').t;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const { theme } = userStore;
  useMemo(() => {
    handleSelectedChart(selectedChart);
  }, [theme, userStore.user.language]);
  const open = Boolean(anchor);

  const chartsType = [
    {
      label: tHome('survivalSurveys.causesGraph.chartType.pie'),
      value: 'Pie',
    },
    {
      label: tHome('survivalSurveys.causesGraph.chartType.bar'),
      value: 'Bar',
    },
  ];

  useMemo(() => {
    handleSelectedChart(selectedChart);
  }, [survivalSurveysDataCause]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        const option = { ...optionsSurvivalChart };
        if (option.series && isArray(option.series)) {
          const seriesData = option.series[0].data;
          const causesList = causesDeaths
            .filter((cause) => cause.show)
            .map((cause) => cause.cause);
          const emphasizedData = seriesData.map((data) =>
            causesList.includes(String(data.name))
              ? {
                  ...data,
                  value: survivalSurveysDataCause.find((dot) => dot?.cause === data.name)
                    ?.total,
                }
              : { ...data, value: null },
          );
          option.series[0].data = emphasizedData;
          chartInstance.setOption(option);
        }
      }
    }
  }, [causesDeaths, optionsSurvivalChart]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        const emphasizeValue = (name) => {
          const option = chartInstance.getOption();
          if (option.series[0]?.data) {
            const seriesData = option.series[0].data;

            const emphasizedData = seriesData.map((item) => {
              return {
                ...item,
                itemStyle: {
                  ...item.itemStyle,
                  borderWidth: name ? (item.name === name ? 3 : 0) : 0,
                  borderColor: item.itemStyle.color,
                  opacity: name ? (item.name === name ? 1 : 0.2) : 1,
                },
              };
            });

            option.series[0].data = emphasizedData;
            chartInstance.setOption(option);
          }
        };

        emphasizeValue(deathCauseHighlight);
      }
    }
  }, [deathCauseHighlight]);
  if (loadingEvaluationData) {
    return (
      <div className=" shadow-lg dark:bg-darkblack-400 flex flex-col bg-bgray-200 rounded-lg min-h-[260px] h-full flex-1">
        <Skeleton
          variant="rectangular"
          height={'100%'}
          width={'100%'}
          className="dark:bg-darkblack-400"
        />
      </div>
    );
  }

  // if (survivalSurveysDataCause.length === 0) {
  //   return (
  //     <div className="dark:bg-darkblack-500  shadow-lg flex flex-col bg-bgray-100 p-2 rounded-lg h-full flex-1">
  //       <SurvivalSurveysEmptyState
  //         description={tHome('survivalSurveys.causesGraph.emptyData')}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="dark:bg-darkblack-500 flex flex-col bg-white p-3 rounded-lg h-full flex-1  shadow-lg">
      <div className="flex justify-between w-full">
        <p className="font-semibold dark:text-bgray-200 text-xl">
          {tHome('survivalSurveys.causesGraph.title')}
        </p>
        {survivalSurveysDataCause.length > 0 && (
          <button
            className="ignore-me w-16 text-base  dark:text-bgray-200 flex items-center gap-1 bg-bgray-200 dark:bg-darkblack-300 px-2 py-1 rounded-md relative z-20"
            onClick={handleClick}
          >
            {selectedChart.label}
            <FaChevronDown />
          </button>
        )}
        <BasePopup
          open={open}
          anchor={anchor}
          placement="bottom"
          className=" w-16"
          style={{ zIndex: 2 }}
        >
          <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg p-2 max-h-48 overflow-y-auto overflow-style-none">
            {chartsType.map((chart) => {
              return (
                <p
                  className="text-base cursor-pointer dark:text-white"
                  onClick={(event) => {
                    handleSelectedChart(chart);
                    handleClick(event);
                  }}
                  key={chart.value}
                >
                  {chart.label}
                </p>
              );
            })}
          </div>
        </BasePopup>
        <div
          onClick={() => setAnchor(null)}
          id="noti-outside"
          className={`fixed left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
      </div>
      {survivalSurveysDataCause.length > 0 ? (
        <div className="flex-col flex items-center h-full w-full relative">
          {optionsSurvivalChart && (
            <EChartComponent
              chartRef={chartRef}
              option={optionsSurvivalChart}
              style={{
                zIndex: 1,
                display: 'flex',
                flex: 1,
                minHeight: 300,
                width: '100%',
                height: '100%',
              }}
            />
          )}
          <LegendChart />
        </div>
      ) : (
        <EmptyState
          title={tHome('survivalSurveys.causesGraph.emptyData')}
          size="md"
          description={tHome('survivalSurveys.causesGraph.emptyDataDescription')}
        />
      )}
    </div>
  );
};
export default observer(CausesGraph);
