import { formatNumber } from '@/utils/helpers/formaters';
import {
  BarSeriesOption,
  ComposeOption,
  LineSeriesOption,
  TooltipComponentOption,
} from 'echarts';
import _ from 'lodash';
import { ThemeTypes } from '@/store/config/user/types';
import { userStore } from '@/store';
import { SurvivalSurveysDataCausesType } from '../dashboard-survival-surveys-types';
import { translationI18n } from '@/i18n';

export function getOptionsChartsBar(
  deathCauses: SurvivalSurveysDataCausesType[],
  theme: ThemeTypes,
): ComposeOption<BarSeriesOption | TooltipComponentOption> {
  const options: ComposeOption<BarSeriesOption | LineSeriesOption> = {
    darkMode: userStore.theme === 'dark',
    backgroundColor: 'transparent',
    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      valueFormatter: (data: any) => formatNumber(data),
    },
    xAxis: {
      type: 'category',
      data: deathCauses.map((patology) => patology.cause),
      axisLabel: {
        show: true,
        rotate: 45,
        overflow: 'truncate',
      },
    },
    height: '150px',
    yAxis: [
      {
        name: translationI18n('home:survivalSurveys.causesGraph.plants'),
        show: true,
        nameLocation: 'center',
        interval: _.maxBy(deathCauses, 'total'),
        min: 0,
        max: _.maxBy(deathCauses, 'total'),
      },
      {
        name: translationI18n('home:survivalSurveys.causesGraph.pareto'),
        show: true,
        position: 'right',
        nameLocation: 'center',
        interval: 100,
        max: 100,
        min: 0,
      },
    ],
    series: [
      {
        type: 'bar',
        data: deathCauses.map((patology) => ({
          value: patology.total,
          name: patology.cause,
          itemStyle: {
            color: patology.color,
          },
        })),
        label: {
          show: true,
          color: theme === 'dark' ? 'white' : 'black',
          formatter: ({ value }) => {
            const percentage = (Number(value) / _.sumBy(deathCauses, 'total')) * 100;
            return `${formatNumber(percentage, 0)}%`;
          },
          position: 'top',
        },
      },
      {
        type: 'line',
        yAxisIndex: 1,
        lineStyle: {
          color: '#bbb',
        },
        data: deathCauses.map((patology, index) => {
          const value = _.sumBy(
            deathCauses.filter((cause, i) => i <= index),
            (cause) => Number(cause.percetage_quantity),
          );
          return {
            value: value,
            name: patology.cause,
            color: 'red',
            itemStyle: {
              color: '#bbb',
            },
          };
        }),
        label: {
          show: true,
          formatter: ({ value }) => {
            return `${formatNumber(value, 0)}%`;
          },
          position: 'top',
          color: theme === 'dark' ? 'white' : 'black',
        },
      },
    ],
    animation: false,
  };
  return options;
}
