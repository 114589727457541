const FilterIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8889 16.593H4.58813"
        stroke="#22C55E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.699 6.90066H19.9997"
        stroke="#22C55E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.28488 6.84625C9.28488 5.5506 8.22672 4.5 6.92174 4.5C5.61675 4.5 4.55859 5.5506 4.55859 6.84625C4.55859 8.14191 5.61675 9.19251 6.92174 9.19251C8.22672 9.19251 9.28488 8.14191 9.28488 6.84625Z"
        stroke="#22C55E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5586 16.5533C20.5586 15.2576 19.5012 14.207 18.1962 14.207C16.8904 14.207 15.8323 15.2576 15.8323 16.5533C15.8323 17.8489 16.8904 18.8995 18.1962 18.8995C19.5012 18.8995 20.5586 17.8489 20.5586 16.5533Z"
        stroke="#22C55E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default FilterIcon;
