import i18n from '@/i18n';
import { paramType } from '../master-params-types';

export type servicesParamsType = {
  title: string;
  service: string;
  description: string;
  index?: boolean;
  hide?: boolean;
  params: {
    param: paramType;
    title: string;
  }[];
};
const servicesParams = (): servicesParamsType[] => {
  return [
    {
      title: i18n.t('master:paramsPage.params.survivalSurveys.survivalSurveysTitle'),
      service: 'survivalSurveys',
      description: i18n.t(
        'master:paramsPage.params.survivalSurveys.survivalSuveysDescription',
      ),
      index: true,
      params: [
        {
          param: 'survivalSurveysCause' as paramType,
          title: i18n.t('master:paramsPage.params.survivalSurveys.items.causesOfDeath'),
        },
      ],
    },
    {
      title: i18n.t('master:paramsPage.params.plantedArea.title'),
      service: 'plantedArea',
      description: i18n.t('master:paramsPage.params.plantedArea.description'),
      index: true,
      params: [
        {
          param: 'plantedAreaReason' as paramType,
          title: i18n.t('master:paramsPage.params.plantedArea.items.reasonsDiscarded'),
        },
      ],
    },
  ];
};
export default servicesParams;
