import Modal from '@/component/modals/modal-default';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Toast } from '@/component/Toast';
import FileUploadIcon from '@/assets/images/icons/fileUploadIcon';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import FileUploadedIcon from '@/assets/images/icons/fileUploadedIcon';
import { IoCloseSharp } from 'react-icons/io5';
import { FileUploadedExcelInfosType } from './modal-import-excel-types';
import ImportAddRowsIcon from '@/assets/images/icons/ImportAddRowsIcon';
import ImportDesactiveRowsIcon from '@/assets/images/icons/ImportDesactiveRowsIcon';
import ItemFeedback from './item-feedback';
import ImportErrorRowsIcon from '@/assets/images/icons/ImportErrorRowsIcon';
import ImportUpdateRowsIcon from '@/assets/images/icons/ImportUpdateRowsIcon';
import { formatNumber } from '@/utils/helpers/validations';
import { forestRegistryStore } from '@/store';
import {
  downloadExcel,
  generateExcelExemple,
  validExcel,
} from '../../forest-registry-services';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const { showError } = Toast;
const ForestRegistryModalImportExcel = () => {
  const { modalsState, handleModalState, uploadExcel } = forestRegistryStore;
  const [fileUpload, setFileUpload] = useState<File>();
  const [fileInfos, setFileInfos] = useState<FileUploadedExcelInfosType>();
  const [isValiding, setIsValiding] = useState(false);
  const handleClose = () => {
    handleModalState('upload', false);
  };
  const tForestRegistry = useTranslation('forestRegistry').t;
  const tCommon = useTranslation('common').t;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      if (
        file &&
        (file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel')
      ) {
        // Aqui você pode lidar com o arquivo, por exemplo, enviá-lo para o servidor ou processá-lo localmente
        setFileUpload(file);
      } else {
        showError({
          message: tForestRegistry('modals.uploadExcel.errorFile'),
        });
      }
    });
  }, []);

  useEffect(() => {
    fileUpload !== undefined && fileUpload && validFile();
  }, [fileUpload]);

  async function validFile() {
    setIsValiding(true);
    try {
      if (fileUpload === undefined) {
        setIsValiding(false);
        return;
      }
      const response = await validExcel(fileUpload);
      setFileInfos(response.data);
      setIsValiding(false);
    } catch (error: any) {
      console.error(error.message);
      setIsValiding(false);
    }
  }

  const handleSubmit = async () => {
    await uploadExcel(fileUpload as File);
  };
  useEffect(() => {
    setFileUpload(undefined);
  }, [modalsState.forestRegistryItem, modalsState.upload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Modal
      title={tForestRegistry('modals.uploadExcel.title')}
      isActive={modalsState.upload}
      handleClose={() => !(modalsState.loading || isValiding) && handleClose()}
      footer={
        <div className="flex justify-between items-center">
          <div className="flex lg:pl-10">
            <button
              className="text-success-200 text-base underline font-semibold"
              onClick={generateExcelExemple}
            >
              {tForestRegistry('modals.uploadExcel.downloadExample')}
            </button>
          </div>
          <div className="flex items-center justify-end  gap-3">
            <SecondaryButton
              text={tCommon('actions.cancel')}
              disabled={modalsState.loading || isValiding}
              onClick={() => {
                setFileUpload(undefined);
                handleModalState('upload', false);
              }}
            />
            <PrimaryButton
              text={tForestRegistry('modals.uploadExcel.submitText')}
              loading={modalsState.loading || isValiding}
              disabled={!fileUpload || isValiding}
              onClick={handleSubmit}
            />
          </div>
        </div>
      }
    >
      <div className="w-full">
        <div
          className="hover:cursor-pointer mt-5 py-10 dark:bg-darkblack-400 bg-bgray-100 border-dashed border-2 flex items-center justify-center rounded-lg px-10"
          {...getRootProps()}
        >
          <input
            {...getInputProps()}
            type="file"
            disabled={isValiding || modalsState.loading}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
          {isDragActive ? (
            <p className="font-bold my-15 text-3xl text-bgray-600 dark:text-bgray-300">
              {tForestRegistry('modals.uploadExcel.dropFilesHere')}
            </p>
          ) : fileUpload ? (
            <div className="justify-center w-full items-center flex  dark:text-bgray-300">
              <div className="flex flex-1 gap-2 items-center">
                <div className="flex items-center justify-center">
                  <FileUploadedIcon />
                </div>
                <div>
                  <p className="text-sm font-bold">{fileUpload.name}</p>
                  <p className="text-xs font-regular text-bgray-700 dark:text-bgray-200">
                    {isValiding
                      ? tForestRegistry('modals.uploadExcel.processingFile')
                      : `${Number(fileInfos?.totalLines) < 10 ? '0' : ''}${formatNumber(
                          fileInfos?.totalLines,
                          0,
                        )} ${tForestRegistry('modals.uploadExcel.lines')} - ${Intl.NumberFormat(
                          'pt-BR',
                          {
                            maximumFractionDigits: 1,
                            minimumFractionDigits: 1,
                          },
                        ).format(Number(fileUpload.size) / 1000000)}
                    Mb`}
                  </p>
                </div>
              </div>
              <button onClick={() => setFileUpload(undefined)}>
                <IoCloseSharp size={30} />
              </button>
            </div>
          ) : (
            <div className="justify-center items-center flex-col">
              <div className="flex items-center justify-center mb-3">
                <FileUploadIcon />
              </div>
              <p className="text-center  font-bold dark:text-bgray-300">
                {tForestRegistry('modals.uploadExcel.selectOneFile')}
              </p>
              <p className="text-center text-sm font-medium mt-2 text-bgray-600 dark:text-bgray-300">
                {tForestRegistry('modals.uploadExcel.orDragAndDropHere')}
              </p>
            </div>
          )}
        </div>

        {fileUpload?.name && !isValiding && fileInfos?.totalLines && (
          <div className="flex-col space-y-2 mt-5">
            <ItemFeedback
              Icon={<ImportAddRowsIcon />}
              text={
                fileInfos?.createLines
                  ? fileInfos?.createLines > 1
                    ? tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.createLines.textPlural',
                        { lines: fileInfos?.createLines },
                      )
                    : tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.createLines.textSingular',
                      )
                  : tForestRegistry(
                      'modals.uploadExcel.itemsFeedback.createLines.emptyLines',
                    )
              }
              description={tForestRegistry(
                'modals.uploadExcel.itemsFeedback.createLines.description',
              )}
            />
            <ItemFeedback
              Icon={<ImportDesactiveRowsIcon />}
              text={
                fileInfos?.desactiveLines
                  ? fileInfos?.desactiveLines > 1
                    ? tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.desactiveLines.textPlural',
                        { lines: fileInfos?.desactiveLines },
                      )
                    : tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.desactiveLines.textSingular',
                      )
                  : tForestRegistry(
                      'modals.uploadExcel.itemsFeedback.desactiveLines.emptyLines',
                    )
              }
              description={tForestRegistry(
                'modals.uploadExcel.itemsFeedback.desactiveLines.description',
              )}
            />
            <ItemFeedback
              Icon={<ImportUpdateRowsIcon />}
              text={
                fileInfos?.updateLines
                  ? fileInfos?.updateLines > 1
                    ? tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.update.textPlural',
                        { lines: fileInfos?.updateLines },
                      )
                    : tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.update.textSingular',
                      )
                  : tForestRegistry('modals.uploadExcel.itemsFeedback.update.emptyLines')
              }
              description={tForestRegistry(
                'modals.uploadExcel.itemsFeedback.update.description',
              )}
            />
            <ItemFeedback
              Icon={<ImportErrorRowsIcon />}
              text={
                fileInfos?.errors.length
                  ? fileInfos?.errors.length > 1
                    ? tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.error.textPlural',
                        { lines: fileInfos?.errors.length },
                      )
                    : tForestRegistry(
                        'modals.uploadExcel.itemsFeedback.error.textSingular',
                      )
                  : tForestRegistry('modals.uploadExcel.itemsFeedback.error.emptyLines')
              }
              description={tForestRegistry(
                'modals.uploadExcel.itemsFeedback.error.description',
              )}
              rightAction={() =>
                fileInfos?.errors.length &&
                downloadExcel(
                  fileInfos.errors,
                  tForestRegistry('modals.uploadExcel.itemsFeedback.error.errorFileName'),
                )
              }
              rightText={
                fileInfos?.errors.length
                  ? tForestRegistry('modals.uploadExcel.itemsFeedback.error.reportErrors')
                  : ''
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
export default observer(ForestRegistryModalImportExcel);
