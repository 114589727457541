import { useState, useEffect, useRef } from 'react';
import { PaginationSizeType } from './types';

function PaginationSize({ size, setSize }: PaginationSizeType) {
  const [active, setActive] = useState(false);
  const numberOfPages = [10, 50, 100];
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Function to handle clicks outside the component
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  useEffect(() => {
    // Add event listener to detect clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="items-center space-x-4 flex" ref={dropdownRef}>
      <span className="text-xs font-semibold text-bgray-600 dark:text-bgray-50">
        Exibir
      </span>
      <div className="relative">
        <button
          aria-label="none"
          onClick={() => setActive(!active)}
          type="button"
          className="flex items-center space-x-6 rounded-lg border border-bgray-400 px-2.5 py-[5px] dark:border-darkblack-400"
        >
          <span className="text-xs font-semibold text-bgray-900 dark:text-bgray-50">
            {size}
          </span>
          <span>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.03516 6.03271L8.03516 10.0327L12.0352 6.03271"
                stroke="#A0AEC0"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>

        <div
          id="result-filter"
          style={{ display: active ? 'block' : 'none' }}
          className="absolute right-0 top-8 z-10 w-full overflow-hidden rounded-lg bg-white shadow-lg"
        >
          <ul>
            {numberOfPages.map((item) => (
              <li
                key={item}
                onClick={() => {
                  setActive(!active);
                  setSize(item);
                }}
                className="text-bgray-90 cursor-pointer px-5 py-1 text-xs font-medium hover:bg-bgray-100"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <span className="text-xs font-semibold text-bgray-600 dark:text-bgray-50">
        registros
      </span>
    </div>
  );
}

export default PaginationSize;
