import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { NoteBodyInfo } from './infos';
import DetailsMap from './details-map';

export const PlantedAreaDetailsBody = observer(() => {
  const { detailsNote, handleModal } = plantedAreaNoteStore;
  const tPlantedArea = useTranslation('plantedArea').t;
  return (
    <div className=" justify-between items-center flex-col">
      <button
        className="py-2 font-semibold dark:text-bgray-200 text-gray-600"
        style={{ textDecoration: 'underline' }}
        onClick={() => handleModal('history', true, detailsNote)}
      >
        {tPlantedArea('detailsNote.body.viewHistory')}
      </button>
      <div className="flex-col md:flex-row flex gap-2">
        <NoteBodyInfo />
        <div className="flex-1">
          <DetailsMap />
        </div>
      </div>
    </div>
  );
});
