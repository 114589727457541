const ImportDesactiveRowsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_354_4732"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_354_4732)">
        <path
          d="M12.5 21L11.1 19.6L14.65 16.05L11.1 12.5L12.5 11.1L16.05 14.65L19.6 11.1L21 12.5L17.45 16.05L21 19.6L19.6 21L16.05 17.45L12.5 21ZM7 21V19H9V21H7ZM5 5H3C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3V5ZM7 5V3H9V5H7ZM11 5V3H13V5H11ZM15 5V3H17V5H15ZM19 5V3C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5H19ZM5 19V21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19H5ZM3 17V15H5V17H3ZM3 13V11H5V13H3ZM3 9V7H5V9H3ZM19 9V7H21V9H19Z"
          fill="#EA4335"
        />
      </g>
    </svg>
  );
};

export default ImportDesactiveRowsIcon;
