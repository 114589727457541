import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

export class GlobalStore {
  loading: boolean = false;
  modalLanguage: boolean = false;
  constructor() {
    makeAutoObservable(this);
    void makePersistable(this, {
      name: 'GlobalStore',
      properties: [],
    });
  }
  reset = () => {
    runInAction(() => {
      this.loading = false;
    });
  };
  handleLoading = (show: boolean) => {
    runInAction(() => {
      this.loading = show;
    });
  };
  handleModalLanguage = (show: boolean) => {
    runInAction(() => {
      this.modalLanguage = show;
    });
  };
}
