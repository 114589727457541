import Colors from '@/utils/colors';
import { survivalSurveysEvaluationType } from '../../../quality-survival-surveys-types';
import * as echarts from 'echarts';
import SurvivalSurveysIcon from '@/assets/images/icons/survivalSurveysIcon';
import EChartComponent from '@/component/Echart';
import { formatNumber } from '@/utils/helpers/formaters';
import moment from 'moment';
import { FaRegCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { survivalSurveysEvaluationsStore } from '@/store';
import { useTranslation } from 'react-i18next';

const ApprovalItem = ({ evaluation }: { evaluation: survivalSurveysEvaluationType }) => {
  const { handleModal } = survivalSurveysEvaluationsStore;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  const isAlert = evaluation?.percentageSurvival < evaluation.survivalTarget;
  const option: echarts.EChartsOption = {
    series: [
      {
        name: tSurvivalSurveys('survivalSurveysList.headerResults.myApprovals.survival'),
        type: 'pie',
        emphasis: {
          disabled: true,
        },
        label: {
          show: false,
        },
        radius: ['70%', '90%'],
        data: [
          {
            value: evaluation.percentageSurvival,
            name: tSurvivalSurveys(
              'survivalSurveysList.headerResults.myApprovals.survival',
            ),
          },
          {
            value: 100 - evaluation.percentageSurvival,
            name: tSurvivalSurveys(
              'survivalSurveysList.headerResults.myApprovals.deaded',
            ),
          },
        ],
      },
    ],
    animation: false,
    color: [isAlert ? Colors.error[200] : Colors.primary[200], Colors.bgray[300]],
  };
  const navigate = useNavigate();
  return (
    <div className="cursor-pointer flex bg-bgray-200 dark:bg-darkblack-400 px-3 py-2 rounded gap-2">
      <div
        className="flex-col justify-center items-center text-center"
        onClick={() => navigate('/quality/survivalSurveys/' + evaluation.id)}
      >
        <div className="items-center justify-center flex relative">
          <div className="absolute">
            <SurvivalSurveysIcon alert={isAlert} />
          </div>
          <EChartComponent option={option} style={{ height: 60, width: 60 }} />
        </div>
        <p className="font-semibold text-xl dark:text-bgray-200">{`${formatNumber(evaluation.percentageSurvival, 2)}%`}</p>
      </div>
      <div
        className="flex-1 flex-col"
        onClick={() => navigate('/quality/survivalSurveys/' + evaluation.id)}
      >
        <p className="text-lg font-semibold dark:text-bgray-200">
          {evaluation.forestRegistry.talhao}
        </p>
        <p className="text-base text-gray-600 dark:text-bgray-200">{`${evaluation.forestRegistry.fazenda} • ${formatNumber(evaluation.forestRegistry.area, 2)} ${tCommon('ha')}`}</p>
        <p className="text-sm text-gray-600 dark:text-bgray-200">
          {`${tSurvivalSurveys('survivalSurveysList.headerResults.myApprovals.evaluatedIn')} ${moment(evaluation.dateTimeStart).format('DD/MM/YY')}`}
        </p>
        <p className="font-bold dark:text-white">{`${evaluation.time} ${tSurvivalSurveys('survivalSurveysList.days')}`}</p>
      </div>
      <div className="flex items-center justify-center">
        <button
          className="font-semibold bg-success-200 text-white p-2 rounded flex items-center gap-1"
          onClick={() => handleModal('approve', true, evaluation)}
        >
          <FaRegCheckCircle color="white" />
          {tCommon('actions.approve')}
        </button>
      </div>
    </div>
  );
};
export default ApprovalItem;
