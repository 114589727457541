import { ArrowLeftIcon } from '@/assets/images/icons/arrowLeft';
import { ArrowRightIcon } from '@/assets/images/icons/arrowRight';
import { FirstPageIcon } from '@/assets/images/icons/firstPageIcon';
import { LastPageIcon } from '@/assets/images/icons/lastPageIcon';
import { PaginationPagesType } from './types';

function PaginationPages({ page, totalPages, setPage }: PaginationPagesType) {
  const classActive =
    'rounded-lg bg-success-100 px-4 py-1 text-xs font-bold text-success-200 dark:bg-darkblack-500 dark:text-bgray-50 lg:px-6  lg:text-sm';
  const classNormal =
    'rounded-lg px-4 py-1 text-xs font-bold text-bgray-500 transition duration-300 ease-in-out hover:bg-success-100 hover:text-success-200 dark:hover:bg-darkblack-500 lg:px-6  lg:text-sm';

  const hasNextPage = page < totalPages;
  const hasPreviousPage = page > 1;
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  const onNextPage = () => {
    setPage(page + 1);
  };
  const onPreviousPage = () => {
    setPage(page - 1);
  };
  const onPressFirstPage = () => {
    setPage(1);
  };
  const onPressLastPage = () => {
    setPage(totalPages);
  };
  const renderPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 3;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <button
            key={i}
            aria-label={`Page ${i}`}
            type="button"
            className={i === page ? classActive : classNormal}
            onClick={() => setPage(i)}
          >
            {i}
          </button>,
        );
      }
    } else {
      const start = page === totalPages ? totalPages - 2 : Math.max(page - 1, 1);
      const end = Math.min(start + maxVisiblePages - 1, totalPages);

      for (let i = start; i <= end; i++) {
        pages.push(
          <button
            key={i}
            aria-label={`Page ${i}`}
            type="button"
            className={i === page ? classActive : classNormal}
            onClick={() => setPage(i)}
          >
            {i}
          </button>,
        );
      }

      if (end < totalPages - 1) {
        pages.push(
          <span key="end" className="text-xs text-bgray-500">
            ...
          </span>,
        );
        pages.push(
          <button
            key={totalPages}
            aria-label={`Page ${totalPages}`}
            type="button"
            className={totalPages === page ? classActive : classNormal}
            onClick={() => setPage(totalPages)}
          >
            {totalPages}
          </button>,
        );
      }
    }

    return pages;
  };

  return (
    <div className="flex items-center space-x-5 sm:space-x-[35px]">
      {!isFirstPage && (
        <button aria-label="none" type="button" onClick={onPressFirstPage}>
          <span>
            <FirstPageIcon />
          </span>
        </button>
      )}
      {hasPreviousPage && (
        <button aria-label="none" type="button" onClick={onPreviousPage}>
          <span>
            <ArrowLeftIcon />
          </span>
        </button>
      )}
      <div className="flex items-center">
        {/* <button aria-label="none" type="button" className={classActive}>
          1
        </button>
        <button aria-label="none" type="button" className={classNormal}>
          2
        </button>

        {totalPages > 4 && <span className="text-sm text-bgray-500">. . . .</span>}
        {totalPages > 4 && (
          <button aria-label="none" type="button" className={classNormal}>
            {totalPages}
          </button>
        )} */}
        {renderPageNumbers()}
      </div>
      {hasNextPage && (
        <button aria-label="none" type="button" onClick={onNextPage}>
          <span>
            <ArrowRightIcon />
          </span>
        </button>
      )}
      {!isLastPage && totalPages ? (
        <button aria-label="none" type="button" onClick={onPressLastPage}>
          <span>
            <LastPageIcon />
          </span>
        </button>
      ) : null}
    </div>
  );
}

export default PaginationPages;
