import Colors from '@/utils/colors';

const PlotsAvaliableIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.58325 24.5C10.4827 24.5 12.8333 22.1495 12.8333 19.25C12.8333 16.3505 10.4827 14 7.58325 14C4.68376 14 2.33325 16.3505 2.33325 19.25C2.33325 22.1495 4.68376 24.5 7.58325 24.5Z"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1667 24.5C24.0997 24.5 25.6667 22.933 25.6667 21C25.6667 19.067 24.0997 17.5 22.1667 17.5C20.2338 17.5 18.6667 19.067 18.6667 21C18.6667 22.933 20.2338 24.5 22.1667 24.5Z"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33325 12.2494C3.79566 11.1509 5.61344 10.5 7.58325 10.5C12.4157 10.5 16.3333 14.4176 16.3333 19.25C16.3333 19.362 16.3312 19.4734 16.327 19.5844C16.3005 20.2898 16.2872 20.6427 16.4594 20.8214C16.6315 21 16.9495 21 17.5856 21H18.6666"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 10.5L18.7901 11.1794C21.5211 11.6914 22.8867 11.9475 23.6933 12.9196C24.5001 13.8915 24.5001 15.2888 24.5001 18.0833"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3334 14H22.1667"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 14.5833V11.1849C15.1667 10.7307 15.1005 10.279 14.9699 9.84396L13.4167 3.5M4.66675 10.5V3.5"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 3.5H15.1667"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11.0833V9.33333C21 8.04467 22.0446 7 23.3333 7"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.16675 10.5V3.5"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlotsAvaliableIcon;
