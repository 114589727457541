import { formatNumber } from '@/utils/helpers/formaters';
import { useTranslation } from 'react-i18next';

const LegendeMap = ({ survivalTarget }: { survivalTarget: number }) => {
  const tHome = useTranslation('home').t;
  return (
    <div className="absolute z-[2] bg-white p-1 rounded right-2 bottom-2">
      <table>
        <thead>
          <tr>
            <th className="text-sm text-darkblack-400">
              {tHome('survivalSurveys.map.survival')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="flex items-center space-x-1">
              <div className="w-6 h-4 rounded bg-primary-300" />
              <p className="text-xs text-darkblack-400">{`>=${formatNumber(survivalTarget, 1)}%`}</p>
            </td>
          </tr>
          <tr>
            <td className="flex items-center space-x-1">
              <div className="w-6 h-4 rounded bg-primary-200" />
              <p className="text-xs text-darkblack-400">
                {`${formatNumber(survivalTarget, 1)}% a ${formatNumber(survivalTarget - 3, 1)}%`}
              </p>
            </td>
          </tr>
          <tr>
            <td className="flex items-center space-x-1">
              <div className="w-6 h-4 rounded bg-warning-200" />
              <p className="text-xs text-darkblack-400">
                {`${formatNumber(survivalTarget - 3, 1)}% a ${formatNumber(survivalTarget - 6, 1)}%`}
              </p>
            </td>
          </tr>
          <tr>
            <td className="flex items-center space-x-1">
              <div className="w-6 h-4 rounded bg-error-200" />
              <p className="text-xs text-darkblack-400">
                {`${formatNumber(survivalTarget - 6, 1)}% a ${formatNumber(survivalTarget - 9, 1)}%`}
              </p>
            </td>
          </tr>
          <tr>
            <td className="flex items-center space-x-1">
              <div className="w-6 h-4 rounded bg-error-300" />
              <p className="text-xs text-darkblack-400">{`<${formatNumber(survivalTarget - 9, 1)}%`}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LegendeMap;
