import { dashboardSurvivalSurveysStore, userStore } from '@/store';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { observer } from 'mobx-react';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import Colors from '@/utils/colors';
import { formatNumber } from '@/utils/helpers/validations';
import { Skeleton } from '@mui/material';
import SurvivalSurveysEmptyState from '@/pages/private/quality/silviculture/survival-surveys/list/header-results/myApprovals/EmptyState';
import EChartComponent from '@/component/Echart';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import _, { isArray } from 'lodash';
import { sortEchartsOption } from '@/utils/helpers/formaters';
import * as echarts from 'echarts';
import { GroupType } from '../dashboard-survival-surveys-types';
import { LegendSurvivalChart } from './survival-chart-lengend';
import { useTranslation } from 'react-i18next';

const SurvivalChart = observer(
  ({
    survivalSurveysGroup = 'time',
    showButton = false,
    showSlider = false,
  }: {
    survivalSurveysGroup?: GroupType;
    showButton?: boolean;
    showSlider?: boolean;
  }) => {
    const tHome = useTranslation('home').t;
    const chartRef = useRef<HTMLDivElement>(null);
    const {
      filterSurvivalSurveys: filter,
      handleSurvivalSurveyGroup,
      survivalSurveysDataCause,
      loading,
      minSurvivalSurveys,
      evaluationsChart,
      deathCauseHighlight,
      survivalSurveysDataChart,
      getSurvivalSurveysDataChart,
      causesChart,
      groupedCategorysChart,
    } = dashboardSurvivalSurveysStore;

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);
    const isDark = userStore.theme === 'dark';
    const language = localStorage.getItem('language');
    useEffect(() => {
      getSurvivalSurveysDataChart(survivalSurveysGroup);
    }, [filter, survivalSurveysGroup, userStore.companyActive, language]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(anchor ? null : event.currentTarget);
    };

    const open = Boolean(anchor);
    const id = open ? 'simple-popper' : undefined;

    function getCauses() {
      return causesChart
        .filter((item) => item.show)
        .flatMap((cause) => {
          const causeName = cause.cause;
          const color = survivalSurveysDataCause.find(
            (cause) => cause.cause === causeName,
          )?.color;
          return survivalSurveysDataChart.map((group) => {
            const causesWithCategory = groupedCategorysChart.map((category) => {
              const item =
                survivalSurveysGroup === 'time'
                  ? group.values[0]
                  : group.values.find((d) => d[survivalSurveysGroup] === category);
              const survival = item?.mediaPonderada || 0;
              const totalCuasesItem = _.sumBy(item?.causes, 'total') || 0;
              const valueCuase =
                item?.causes.find((c) => c.cause === causeName)?.total || 0;
              const pencentCause =
                ((100 - survival) * (valueCuase / totalCuasesItem)).toFixed(1) || 0;
              return pencentCause;
            });
            return {
              type: 'bar',
              name: `${causeName}`,
              color: color,
              stack: group.group,
              label: {
                show: false,
                rotate: survivalSurveysGroup === 'time' ? 0 : 90,
                fontSize: 12,
                align: 'center',
                position: 'inside',
                formatter: (value: CallbackDataParams) =>
                  (value.data && Number(value.data) > 2) ||
                  (survivalSurveysGroup === 'time' && Number(value.data) > 1)
                    ? `${formatNumber(value.data, 1)}%`
                    : '',
              },
              data: causesWithCategory,
            };
          });
        });
    }
    function getSerieSurvival() {
      return survivalSurveysDataChart
        .filter((data) =>
          evaluationsChart
            .filter((row) => row.show)
            .map((row) => row.evaluation)
            .includes(`${data.group} ${tHome('survivalSurveys.survivalChart.days')}`),
        )
        .map((group) => ({
          type: 'bar',
          name: `${group.group} ${tHome('survivalSurveys.survivalChart.days')}`,
          stack: group.group,
          markLine: {
            symbol: 'circle',
            data: [
              {
                name: tHome('survivalSurveys.survivalChart.meta'),
                yAxis: survivalSurveysDataChart[0]?.values.filter(
                  (ss) => ss.target > 0,
                )[0].target,
                lineStyle: {
                  color: isDark ? Colors.bgray[200] : Colors.darkblack[700],
                  width: 2,
                },
                label: {
                  show: true,
                  fontSize: 10,
                  formatter: '{b}\n{c}%',
                  position: 'end',
                },
              },
            ],
          },
          label: {
            show: true,
            rotate: survivalSurveysGroup === 'time' ? 0 : 90,
            fontSize: 12,
            overflow: 'truncate',
            align: 'center',
            position: 'inside',
            formatter: (value: CallbackDataParams) =>
              value.data && Number(value.data) > 0
                ? `${formatNumber(value.data, 1)}%`
                : '',
          },
          data:
            survivalSurveysGroup === 'time'
              ? group.values.map((val) => val.mediaPonderada)
              : groupedCategorysChart.map(
                  (category) =>
                    group.values.find((d) => d[survivalSurveysGroup] === category)
                      ?.mediaPonderada || 0,
                ),
        }));
    }

    const optionConfigChart: echarts.EChartsOption = useMemo(
      () => ({
        color: [
          Colors.primary[200],
          Colors.primary[300],
          Colors.primary[400],
          Colors.warning[400],
          Colors.purple,
        ],
        grid: {
          left: '2%',
          right: 40,
          bottom: survivalSurveysGroup === 'time' ? 0 : '15%',
          containLabel: true,
        },
        legend: {
          textStyle: {
            color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
            fontSize: 10,
          },
          top: '2%',
          // bottom: 0,
          show: false,
          data: [
            ...survivalSurveysDataChart.map(
              (group) => `${group.group} ${tHome('survivalSurveys.survivalChart.days')}`,
            ),
            ...causesChart,
          ],
        },
        tooltip: {
          trigger: 'item',
          axisPointer: { type: 'shadow' },
          // formatter: function (params) {
          //   return params
          //     ?.filter((a: any) => Number(a?.value) > 0)
          //     ?.map((a: any) => {
          //       return `<div class="flex items-center gap-2">
          //         <div style="background-color:${a.color}" class="h-4 w-4 rounded-full"></div>
          //       ${a?.seriesName}: ${formatNumber(a?.value, 2)}%</div>`;
          //     })
          //     .join('');
          // },
          valueFormatter: (value) =>
            !isNaN(Number(value)) ? `${formatNumber(value, 2)}%` : value,
        },
        xAxis: {
          boundaryGap: true,
          type: 'category',
          data:
            survivalSurveysGroup === 'time'
              ? [tHome('survivalSurveys.survivalChart.options.time')]
              : groupedCategorysChart,
          axisLine: {
            lineStyle: { color: isDark ? Colors.bgray[200] : Colors.darkblack[600] },
          },
          axisLabel: {
            interval: 0,
            show: true,
            fontSize: 10,
            color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
          },
        },
        yAxis: [
          {
            min: minSurvivalSurveys,
            max: 100,
            show: true,
            type: 'value',
            name: '',
            nameLocation: 'middle',
            axisLine: { show: true },
            axisLabel: { show: true },
          },
        ],
        dataZoom: [
          {
            brushSelect: false,
            start: 0,
            end: groupedCategorysChart.length > 30 ? 50 : 100,
            // maxSpan:
            //   (30 / groupedCategorys.length) * 100 > 100
            //     ? 100
            //     : (30 / groupedCategorys.length) * 100,
            type: showSlider ? 'slider' : 'inside', // Adiciona barra de rolagem horizontal
            fillerColor: Colors.primaryOpacity[300],
            borderColor: Colors.primary[400],
            handleStyle: {
              color: Colors.primary[300],
            },
            moveHandleStyle: {
              color: Colors.primary[300],
            },
            dataBackground: {
              areaStyle: {
                color: Colors.primary[100],
              },
              lineStyle: {
                color: Colors.primary[100],
              },
            },
            selectedDataBackground: {
              areaStyle: {
                color: Colors.primary[200],
              },
              lineStyle: {
                color: Colors.primary[400],
              },
            },
          },
        ],
        series: [...getSerieSurvival(), ...getCauses()],
        animation: false,
        darkMode: isDark,
      }),
      [
        isDark,
        causesChart,
        survivalSurveysDataChart,
        survivalSurveysGroup,
        evaluationsChart,
      ],
    );
    const dictionary = {
      time: tHome('survivalSurveys.survivalChart.options.timeLowCase'),
      regiao: tHome('survivalSurveys.survivalChart.options.regiaoLowCase'),
      fazenda: tHome('survivalSurveys.survivalChart.options.fazendaLowCase'),
      talhao: tHome('survivalSurveys.survivalChart.options.talhaoLowCase'),
    };
    const options = [
      { value: 'regiao', label: tHome('survivalSurveys.survivalChart.options.regiao') },
      { value: 'fazenda', label: tHome('survivalSurveys.survivalChart.options.fazenda') },
      { value: 'talhao', label: tHome('survivalSurveys.survivalChart.options.talhao') },
    ];
    useMemo(() => {
      if (chartRef.current) {
        const chartInstance = echarts.getInstanceByDom(chartRef.current);
        if (chartInstance) {
          const emphasizeValue = (name) => {
            const option = chartInstance.getOption();
            if (isArray(option.series)) {
              const seriesData = option.series;

              const emphasizedData = seriesData.map((item) => {
                return {
                  ...item,
                  itemStyle: {
                    ...item.itemStyle,
                    borderWidth: name ? (item.name === name ? 3 : 0) : 0,
                    opacity: name ? (item.name === name ? 1 : 0.2) : 1,
                  },
                };
              });

              option.series = emphasizedData;
              chartInstance.setOption(option);
            }
          };

          emphasizeValue(deathCauseHighlight);
        }
      }
    }, [deathCauseHighlight]);

    return (
      <div className="rounded-lg bg-white px-5 py-5 flex flex-col dark:bg-darkblack-500 flex-1 z-0 shadow-lg">
        <div
          onClick={() => setAnchor(null)}
          className={`fixed left-0 top-0 h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
        <div className="flex flex-1 justify-between items-center mb-2">
          <p className="font-semibold dark:text-bgray-200 text-xl">{`${tHome('survivalSurveys.survivalChart.options.resultPer')} ${dictionary[survivalSurveysGroup]}`}</p>

          {showButton && (
            <button
              onClick={handleClick}
              className="ignore-me flex items-center gap-1 bg-bgray-200 dark:bg-darkblack-300 px-2 py-1 rounded-md relative z-20"
            >
              <p className="text-base dark:text-bgray-200">
                {tHome('survivalSurveys.survivalChart.options.viewPer')}
              </p>
              <p className="text-base font-bold dark:text-bgray-200">
                {dictionary[survivalSurveysGroup]}
              </p>
              <BiChevronDown className="dark:text-bgray-200" />
              <BasePopup
                disablePortal
                className="w-full"
                id={id}
                open={open}
                anchor={anchor}
                placement="bottom"
              >
                <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg py-2 z-20 max-h-48 overflow-y-auto overflow-style-none">
                  {options.map((option) => {
                    return (
                      <div
                        onClick={(event) => {
                          handleSurvivalSurveyGroup(option.value as GroupType);
                          handleClick(event);
                        }}
                        key={option.value}
                        className="text-base px-3 py-1 cursor-pointer text-left w-full dark:text-white hover:bg-bgray-200"
                      >
                        <p>{option.label}</p>
                      </div>
                    );
                  })}
                </div>
              </BasePopup>
            </button>
          )}
        </div>
        <div className="overflow-x-auto">
          {loading.survivalChart ? (
            <Skeleton
              variant="rectangular"
              className="rounded dark:bg-darkblack-400"
              height={'350px'}
              width={'100%'}
            />
          ) : survivalSurveysDataChart.length === 0 ? (
            <SurvivalSurveysEmptyState
              description={tHome('survivalSurveys.survivalChart.emptyData')}
            />
          ) : (
            <>
              <EChartComponent
                chartRef={chartRef}
                option={sortEchartsOption(optionConfigChart)}
                style={{ height: '350px', zIndex: 1 }}
              />
              {survivalSurveysGroup !== 'time' && <LegendSurvivalChart />}
            </>
          )}
        </div>
      </div>
    );
  },
);

export default SurvivalChart;
