import EmptyImageWithArrow from '@/assets/images/illustration/emptyState.svg';
type EmptyStateProps = {
  title: string;
  description: string;
  size?: 'sm' | 'md' | 'lg';
  bg?: string;
  bgDark?: string;
};
const EmptyState = ({
  title,
  description,
  size = 'lg',
  bg = 'white',
  bgDark = 'darkblack-600',
}: EmptyStateProps) => {
  return (
    <div
      className={`rounded-lg w-full ${'bg-' + bg} flex-col flex justify-center items-center ${size === 'sm' ? 'px-[12px] py-[18px]' : 'px-[24px] py-[36px]'} dark:bg-${bgDark}`}
    >
      <p
        className={`font-semibold ${size === 'sm' ? 'text-lg' : size === 'md' ? 'text-xl' : 'text-2xl'} mb-5 dark:text-white`}
      >
        {title}
      </p>
      <img
        loading="lazy"
        src={EmptyImageWithArrow}
        alt=""
        style={{ height: size === 'sm' ? '100px' : size === 'md' ? '200px' : '300px' }}
      />
      <p
        className={`text-center mx-[20%]  ${size === 'sm' ? 'text-sm' : size === 'md' ? 'text-sm' : 'text-md'} text-gray-300`}
      >
        {description}
      </p>
    </div>
  );
};

export default EmptyState;
