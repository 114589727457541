import { Skeleton } from '@mui/material';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const InfosCompany = observer(() => {
  const { companyData, loading } = companyProfileStore;
  type FieldProps = {
    value: string | undefined;
    label?: string;
    loading: boolean;
  };
  const tCompanyProfile = useTranslation('companyProfile').t;
  const Field = ({ value, label, loading }: FieldProps) => {
    if (loading) {
      return <Skeleton className="w-full dark:bg-darkblack-400" height={56} />;
    }
    return (
      <div className=" relative w-full border px-3 rounded py-3 z-10">
        <p className="text-xs text-bgray-600 dark:text-white bg-white dark:bg-darkblack-600 absolute top-[-9px] px-1">
          {label}
        </p>
        <p className="text-sm text-gray-500 dark:text-bgray-200">{value}</p>
      </div>
    );
  };
  return (
    <div className="border border-bgray-400 px-5 py-3 w-full mt-5 rounded-lg lg:mt-0">
      <p className="font-semibold text-base text-black dark:text-gray-100 mb-5 lg:mb-0">
        {tCompanyProfile('header.infoOfCompany')}
      </p>
      <div className="w-full rounded-lg space-y-3 mt-5">
        <div className="md:space-y-0 md:space-x-3 flex flex-col md:flex-row space-x-0 space-y-3">
          <Field
            value={companyData.name}
            label={tCompanyProfile('header.fields.companyName')}
            loading={loading.companyData}
          />
          <Field
            label={tCompanyProfile('header.fields.doc')}
            value={companyData.doc}
            loading={loading.companyData}
          />
        </div>
        <div className="md:space-y-0 md:space-x-3 flex flex-col md:flex-row space-x-0 space-y-3">
          <Field
            value={companyData.contact}
            label={tCompanyProfile('header.fields.companyName')}
            loading={loading.companyData}
          />
          <Field
            label={tCompanyProfile('header.fields.manager')}
            value={companyData.manager}
            loading={loading.companyData}
          />
        </div>
      </div>
    </div>
  );
});

export default InfosCompany;
