import Modal from '@/component/modals/modal-default';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const ModalApprove = observer(() => {
  const { modalStates, handleModal, loading, approveNote } = plantedAreaNoteStore;
  const tPlantedArea = useTranslation('plantedArea').t;
  const tCommon = useTranslation('common').t;
  const { note } = modalStates;
  return (
    <Modal
      title={tPlantedArea('listNotes.modals.modalApprove.title', { cod: note?.cod })}
      isActive={modalStates.approve}
      handleClose={() => !loading.modalApprove && handleModal('approve', false)}
      variant="sm"
      footer={
        <div className="flex justify-end items-center gap-5">
          <SecondaryButton
            text={tCommon('actions.cancel')}
            onClick={() => !loading.modalApprove && handleModal('approve', false)}
          />
          <PrimaryButton
            text={tCommon('actions.approve')}
            loading={loading.modalApprove}
            onClick={approveNote}
          />
        </div>
      }
    >
      <div className="h-hull w-fulł">
        <h3 className="font-bold text-xl dark:text-gray-200">
          {`${tPlantedArea('listNotes.modals.modalApprove.title')} #${note?.cod}?`}
        </h3>
        <p className="text-bgray-600 text-sm my-3">
          {tPlantedArea('listNotes.modals.modalApprove.description')}
        </p>
      </div>
    </Modal>
  );
});
