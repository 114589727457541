import Input from '@/component/forms/Input';
import Modal from '@/component/modals/modal-default';
import {
  AddPlantedAreaReasonsDataType,
  ModalStateType,
} from '../planted-area-reasons-types';
import { FormikContextType } from 'formik';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mui/base/Tabs';
import { Tab, TabPanel, TabsList } from './tabs';
import { languages } from '@/utils/helpers/static_options';

const ModalAddPlantedAreaReason = ({
  validation,
  modalState,
  setModalState,
}: {
  validation: FormikContextType<AddPlantedAreaReasonsDataType>;
  modalState: ModalStateType;

  setModalState: (modalState: ModalStateType) => void;
}) => {
  const { reason } = modalState;
  const tCommon = useTranslation('common').t;
  const tMaster = useTranslation('master').t;
  const handleClose = () => {
    setModalState({ ...modalState, add: false, reason: undefined });
  };

  return (
    <Modal
      title={`${reason?.id ? tCommon('actions.edit') : tCommon('actions.add')} ${tCommon('reason')}`}
      isActive={modalState.add}
      variant="sm"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton text={tCommon('actions.cancel')} onClick={handleClose} />
          <ButtonSubmit
            title={reason?.id ? tCommon('actions.update') : tCommon('actions.save')}
            disabled={modalState.loading}
            loading={modalState.loading}
            onClick={() => validation.handleSubmit()}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <div className="flex items-center gap-2">
          <div className="flex-[0.45]">
            <Input
              type="color"
              label={tMaster(
                'paramsPage.paramsContent.plantedArea.reasons.addReason.labels.colorReason',
              )}
              value={validation.values.color}
              onBlur={validation.handleBlur('color')}
              onChange={validation.handleChange('color')}
              error={!!(validation.errors.color && validation.touched.color)}
              errorMessage={validation.errors.color}
            />
          </div>
        </div>
        <Tabs defaultValue={'pt-br'} className="mt-2">
          <TabsList className="border-b-2" style={{ minWidth: 0 }}>
            <Tab
              value="pt-br"
              className={`bg-primary-200 px-2 py-2 dark:text-white rounded-t-lg ${validation.errors.reason || validation.errors.description ? 'text-red-500' : 'text-darkblack-600 dark:text-white'}`}
            >
              {tCommon('languages.portuguese')}
            </Tab>
            {languages.map((language, index) => {
              return (
                <Tab
                  key={`TAB${language.value}`}
                  value={language.value}
                  className={`bg-primary-200 px-2 py-2 rounded-t-lg ${validation.errors.i18n?.[index] ? 'text-red-500' : 'text-darkblack-600 dark:text-white'}`}
                >
                  {language.label}
                </Tab>
              );
            })}
          </TabsList>
          <TabPanel value="pt-br">
            <Input
              disabled={modalState.loading}
              value={validation.values.reason}
              onChange={validation.handleChange('reason')}
              onBlur={validation.handleBlur('reason')} // Adicione esta linha
              label={tMaster(
                'paramsPage.paramsContent.plantedArea.reasons.addReason.labels.reason',
              )}
              type={'text'}
              error={!!(validation.errors.reason && validation.touched.reason)}
              errorMessage={validation.errors.reason}
            />
            <Input
              disabled={modalState.loading}
              value={validation.values.description}
              onChange={validation.handleChange('description')}
              onBlur={validation.handleBlur('description')} // Adicione esta linha
              label={tMaster(
                'paramsPage.paramsContent.plantedArea.reasons.addReason.labels.descriptionReason',
              )}
              type={'textarea'}
              error={!!(validation.errors.description && validation.touched.description)}
              numRows={5}
              errorMessage={validation.errors.description}
            />
          </TabPanel>
          {languages.map((language, index) => {
            return (
              <TabPanel value={language.value} key={language.value}>
                <Input
                  disabled={modalState.loading}
                  value={validation.values.i18n?.[index]?.reason}
                  onChange={validation.handleChange(`i18n[${index}].reason`)}
                  onBlur={validation.handleBlur(`i18n[${index}].reason`)} // Adicione esta linha
                  label={tMaster(
                    'paramsPage.paramsContent.plantedArea.reasons.addReason.labels.reason',
                  )}
                  type={'text'}
                  error={
                    !!(
                      validation.errors.i18n &&
                      validation.errors.i18n?.[index]?.reason &&
                      validation.touched.i18n?.[index]?.reason
                    )
                  }
                  errorMessage={validation.errors.i18n?.[index]?.reason}
                />
                <Input
                  disabled={modalState.loading}
                  value={validation.values.i18n?.[index]?.description}
                  onChange={validation.handleChange(`i18n[${index}].description`)}
                  onBlur={validation.handleBlur(`i18n[${index}].description`)} // Adicione esta linha
                  label={tMaster(
                    'paramsPage.paramsContent.plantedArea.reasons.addReason.labels.descriptionReason',
                  )}
                  type={'textarea'}
                  error={
                    !!(
                      validation.errors.i18n &&
                      validation.errors.i18n?.[index]?.description &&
                      validation.touched.i18n?.[index]?.description
                    )
                  }
                  numRows={5}
                  errorMessage={validation.errors.i18n?.[index]?.description}
                />
              </TabPanel>
            );
          })}
        </Tabs>
      </form>
    </Modal>
  );
};
export default ModalAddPlantedAreaReason;
