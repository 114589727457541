import React, { useEffect } from 'react';
import { OptionScreenType, ScreensType } from '../types';
import { Skeleton } from '@mui/material';

type SwitchButtonsProps = {
  options: OptionScreenType[];
  setValue: (value: ScreensType) => void;
  value: string | number;
  loading?: boolean;
};

const SwitchButtons = ({
  options,
  value,
  setValue,
  loading = false,
}: SwitchButtonsProps) => {
  const SwitchButton = ({
    text,
    onClick,
    active,
  }: {
    text: string | number;
    onClick: () => void;
    active: boolean;
  }) => {
    useEffect(() => {
      if (options.length && !value) {
        setValue(options[0].value);
      }
    }, [options]);

    return (
      <button
        onClick={onClick}
        className={`py-2 px-5 rounded border w-full md:w-auto text-sm border-bgray-400 dark:text-gray-100 ${
          active ? 'bg-primary-200 text-white  font-bold dark:border-0' : ''
        }`}
      >
        {text}
      </button>
    );
  };
  return (
    <div className="w-full flex-col w-full md:space-x-5 space-y-2 md:space-y-0 ">
      {loading ? (
        <div className="flex gap-5">
          {[1, 2, 3].map((option) => {
            return (
              <Skeleton
                key={option}
                variant="rectangular"
                className={`rounded w-full md:max-w-[150px] min-h-[37px] dark:bg-darkblack-400`}
              />
            );
          })}
        </div>
      ) : (
        options.map((option) => {
          return (
            <SwitchButton
              key={option.value}
              text={option.label}
              active={value === option.value}
              onClick={() => setValue(option.value)}
            />
          );
        })
      )}
    </div>
  );
};

export default SwitchButtons;
