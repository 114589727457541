import { Skeleton } from '@mui/material';
import { observer } from 'mobx-react';
import { IconType } from 'react-icons';
type CardsInfoItemType = {
  title: string;
  aditionalInfo: string;
  value: string;
  percetageValue?: string;
  icon: IconType;
  variant?: 'danger' | 'success' | 'warning' | 'purple';
  variantValue?: 'danger' | 'success' | 'warning' | 'purple';
  loading?: boolean;
};
export const CardsInfoItem = observer(
  ({
    title,
    aditionalInfo,
    value,
    // icon: Icon,
    percetageValue,
    // variant = 'success',
    variantValue = 'success',
    loading = false,
  }: CardsInfoItemType) => {
    if (loading) {
      return (
        <Skeleton
          variant="rectangular"
          height={142}
          className="bg-white shadow dark:bg-darkblack-400 p-5 rounded-lg flex-1"
        />
      );
    }
    return (
      <div className="bg-white shadow-lg dark:bg-darkblack-500 p-5 rounded-lg flex-1">
        <div className="flex gap-2 items-center">
          {/* <Icon
            fontSize={36}
            className={`${variant === 'success' ? 'text-primary-200' : variant === 'danger' ? 'text-error-200' : variant === 'warning' ? 'text-warning-300' : ''}`}
          /> */}
          <p className="dark:text-bgray-200">{title}</p>
        </div>
        <div className="flex items-end justify-start gap-1">
          <div
            className={`text-[36px] font-bold dark:text-bgray-200 ${variantValue === 'success' ? 'text-primary-300' : variantValue === 'danger' ? 'text-error-300' : variantValue === 'warning' ? 'text-warning-400' : variantValue === 'purple' ? 'text-purple' : ''}`}
          >
            {value}
          </div>
          {percetageValue && (
            <div className="text-xl mb-1.5 text-darkblack-300">{percetageValue}</div>
          )}
        </div>
        <div className="flex gap-1 text-bgray-500">
          <p>{aditionalInfo}</p>
        </div>
      </div>
    );
  },
);
