import LevelItem from './level-item';
import LevelLoader from '../loader/level-loader';
import { LevelPermissionsControllerType } from '../levels-permissions-types';

interface LevelProps {
  controller: LevelPermissionsControllerType;
}

const Levels = ({ controller }: LevelProps) => {
  const { loadingLevels, levels, activeLevel, setActiveLevel, options } = controller;
  return loadingLevels ? (
    <LevelLoader />
  ) : (
    levels?.map((level, index) => (
      <LevelItem
        index={index}
        key={`LEVELITEM_${level?.id}`}
        active={level?.id === activeLevel?.id}
        onPressItem={() => setActiveLevel(level)}
        level={level}
        options={options}
      />
    ))
  );
};

export default Levels;
