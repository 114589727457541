import { Skeleton } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import PlantedAreaReasonItemTableHeader from './table/PlantedAreaReasonItemTableHeader';
import { userStore } from '@/store';
import { observer } from 'mobx-react';

const LoaderItems = ({ loading }: { loading: boolean }) => {
  if (!loading) return null;
  const rows = [];
  const rowsIndex = [];
  const nRows = 10;
  for (let i = 0; i < nRows; i++) {
    rowsIndex.push(i);
    rows.push(
      <tr className="border-b border-bgray-400 dark:border-darkblack-400">
        <td className={`py-3 px-6 w-[40%]`}>
          <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
            <Skeleton
              className="dark:bg-darkblack-400"
              height={21}
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
            />
          </p>
        </td>
        <td className={`py-3 px-6 w-[40%]`}>
          <p className="text-xs font-regular text-bgray-900 dark:text-gray-200">
            <Skeleton
              className="dark:bg-darkblack-400"
              height={18}
              width={`${Math.floor(Math.random() * (90 - 50 + 1)) + 50}%`}
            />
          </p>
        </td>

        <td className={`py-3 px-6 w-[20%] items-center justify-center`}>
          <PuffLoader
            color={userStore.theme === 'dark' ? 'white' : '#718096'}
            size={26}
          />
        </td>
      </tr>,
    );
  }

  return (
    <table className="w-full ">
      <PlantedAreaReasonItemTableHeader />
      <tbody>{rows}</tbody>
    </table>
  );
};
export default observer(LoaderItems);
