import ModalActions from '@/component/modals/modal-actions';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const ChangeUserStatusModal = observer(() => {
  const { modalStates, onChangeStatusUser, handleModal } = companyProfileStore;
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  return (
    <ModalActions
      isActive={modalStates.changeStatusUser}
      handleClose={() =>
        handleModal({
          ...modalStates,
          user: undefined,
          changeStatusUser: false,
          loading: false,
        })
      }
      title={
        modalStates.user?.active
          ? tCompanyProfile('body.screens.userList.modals.modalChangeStatus.titleActive')
          : tCompanyProfile(
              'body.screens.userList.modals.modalChangeStatus.titleInactive',
            )
      }
      description={tCompanyProfile(
        'body.screens.userList.modals.modalChangeStatus.description',
      )}
      handleSubmit={onChangeStatusUser}
      variant={modalStates.user?.active ? 'inactive' : 'warning'}
      loading={modalStates.loading}
      submitText={
        modalStates.user?.active ? tCommon('actions.inactive') : tCommon('actions.active')
      }
      cancelText={tCommon('actions.cancel')}
    />
  );
});
