import { permissionsPropsType } from '@/pages/private/config/company-profile/company-profile-body/levels-permissions/levels-permissions-types';
import { CompanyDataType } from '@/pages/private/config/company-profile/company-profile-types';
import { userStore } from '@/store';
import { PermissionsTypes } from '@/utils/types/UsersType';
import { isNumber, round } from 'lodash';
import { ServiceSystemType } from '../types/globalTypes';
import * as Yup from 'yup';
import { cnpj } from 'cpf-cnpj-validator';
import i18n from '@/i18n';
import {
  ValidadePlantDensityPropsInterface,
  ValidadePlantDiscardedPropsInterface,
} from './types';

export const isEmailValid = (email: string) => {
  const usuario = email.substring(0, email.indexOf('@'));
  const dominio = email.substring(email.indexOf('@') + 1, email.length);
  return (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search('@') == -1 &&
    dominio.search('@') == -1 &&
    usuario.search(' ') == -1 &&
    dominio.search(' ') == -1 &&
    dominio.search('.') != -1 &&
    dominio.indexOf('.') >= 1 &&
    dominio.lastIndexOf('.') < dominio.length - 1
  );
};

export const verifyHasOnePermission = (permission: PermissionsTypes) => {
  return (
    permission.permissionApprove ||
    permission.permissionDelete ||
    permission.permissionInsert ||
    permission.permissionRead ||
    permission.permissionUpdate
  );
};

export const checkPermission = ({
  service,
  permission,
}: {
  service: ServiceSystemType;
  permission: permissionsPropsType | 'hasone';
}) => {
  const { user, permissionsCompany } = userStore;
  if (permission === 'hasone') {
    return (
      permissionsCompany.some((modules) =>
        modules.services.some((serv) => serv.service === service && serv.active),
      ) &&
      (user.level.permissions.some(
        (p) => p.serviceName === service && verifyHasOnePermission(p),
      ) ||
        user.keyUser ||
        user.master)
    );
  } else {
    return (
      permissionsCompany.some((modules) =>
        modules.services.some((serv) => serv.service === service),
      ) &&
      (user.level.permissions.some((p) => p.serviceName === service && p[permission]) ||
        user.keyUser ||
        user.master)
    );
  }
};

export const checkPermissionCompany = ({
  companyData,
  service,
}: {
  companyData: CompanyDataType;
  service: ServiceSystemType;
}) => {
  return companyData.permissions?.some((permission) => permission.service === service);
};

export function formatNumber(
  number: number | string | undefined,
  digits: number = 0,
): string {
  const numberFormat = number ? Number(number) : 0;
  return Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: digits,
    minimumFractionDigits: digits,
  }).format(isNumber(numberFormat) ? numberFormat : 0);
}

export const emailValidation = Yup.string()
  .matches(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    i18n.t('common:validations.emailValidation.invalid'),
  )
  .required(i18n.t('common:validations.emailValidation.empty'));

export const passwordValidation = () =>
  Yup.string()
    .min(8, i18n.t('common:validations.passwordValidation.invalid'))
    .required(i18n.t('common:validations.passwordValidation.empty'));

export const passwordConfirmationValidation = () =>
  Yup.string()
    .required(i18n.t('common:validations.passwordValidation.confirmation.empty'))
    .min(8, i18n.t('common:validations.passwordValidation.invalid'))
    .oneOf(
      [Yup.ref('newPassword'), null],
      i18n.t('common:validations.passwordValidation.confirmation.invalid'),
    );

export const phoneValidation = () =>
  Yup.string()
    .required(i18n.t('common:validations.phoneValidation.empty'))
    .matches(
      /^\(\d{2}\) \d \d{4}-\d{4}$/,
      i18n.t('common:validations.phoneValidation.invalid'),
    );
export const docCnpjValidation = () =>
  Yup.string()
    .required(i18n.t('common:validations.docValidation.empty'))
    .test(
      'cnpj-valid',
      i18n.t('common:validations.docValidation.invalid'),
      (value) => value !== undefined && cnpj.isValid(value),
    );
export const emptyStateValidation = () =>
  Yup.string()
    .trim(i18n.t('common:validations.fieldRequired'))
    .required(i18n.t('common:validations.fieldRequired'));

export const codeValidation = Yup.string()
  .length(5, i18n.t('common:validations.codeValidation.invalid'))
  .required(i18n.t('common:validations.codeValidation.empty'));

export const lineSpaceValidation = () =>
  Yup.string()
    .test('is-valid', i18n.t('common:validations.fieldRequired'), (value) => {
      return value?.length > 0 && Number(value) !== 0;
    })
    .required(i18n.t('common:validations.fieldRequired'));

export const stringValidation = () => Yup.string();

export const intValitation = () =>
  Yup.number()
    .required(i18n.t('common:validations.fieldRequired'))
    .integer(i18n.t('common:validations.intValidation'));
export const numberValitation = () =>
  Yup.number().required(i18n.t('common:validations.fieldRequired'));
export const numberQtdValidation = (max: number) =>
  Yup.number()
    .max(
      round(max, 2),
      i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.areaError', {
        value: formatNumber(max, 2),
      }),
    )
    .required(i18n.t('common:validations.fieldRequired'));
export const plantDensityValidation = (min: number, max: number) =>
  Yup.string()
    .required(i18n.t('common:validations.fieldRequired'))
    .test('division-range', '', function () {
      const { path, createError, parent } = this;

      const seedlingsUsed = parent.seedlingsUsed;
      const area = parent.area;

      if (!seedlingsUsed || !area) {
        return createError({
          path,
          message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.area'),
        });
      }
      const used = Number(seedlingsUsed.toString().replace(/\./g, ''));
      const parsedArea = Number(area.toString().replace(/,/g, '.'));

      if (parsedArea === 0) {
        return createError({
          path,
          message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.areaZero'),
        });
      }
      const density = Number((used / parsedArea).toFixed(0));

      if (density < min) {
        return createError({
          path,
          message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.minValue', {
            value: formatNumber(min),
          }),
        });
      }
      if (density > max) {
        return createError({
          path,
          message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.maxValue', {
            value: formatNumber(max),
          }),
        });
      }
      return true;
    });

export const plantDiscardedValidation = (min: number, max: number) =>
  Yup.string()
    .required(i18n.t('common:validations.fieldRequired'))
    .test('division-range', '', function () {
      const { path, createError, parent } = this;

      const seedlingsUsed = parent.seedlingsUsed;
      const seedlingsDiscarded = parent.seedlingsDiscarded;
      const area = parent.area;

      if (!seedlingsDiscarded) {
        return createError({
          path,
          message: i18n.t('common:validations.fieldRequired'),
        });
      }

      const used = Number(seedlingsUsed.toString().replace(/\./g, ''));
      const discarded = Number(seedlingsDiscarded.toString().replace(/\./g, ''));
      const parsedArea = Number(area.toString().replace(/,/g, '.'));

      if (parsedArea === 0) {
        return createError({
          path,
          message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.areaZero'),
        });
      }

      const totalSeedlings = used + discarded;
      const discardPercentage = Number(((discarded / totalSeedlings) * 100).toFixed(2));

      if (discardPercentage < min) {
        return createError({
          path,
          message: i18n.t(
            'plantedArea:listNotes.modals.modalEdit.inputErrors.minDiscardedValue',
            {
              value: formatNumber(min),
            },
          ),
        });
      }
      if (discardPercentage > max) {
        return createError({
          path,
          message: i18n.t(
            'plantedArea:listNotes.modals.modalEdit.inputErrors.maxDiscardedValue',
            {
              value: formatNumber(max),
            },
          ),
        });
      }

      return true;
    });
export const validatePlantDiscarded = ({
  seedlingsUsed,
  seedlingsDiscarded,
  min,
  max,
}: ValidadePlantDiscardedPropsInterface) => {
  if (!seedlingsUsed || !seedlingsDiscarded) {
    return {
      isValid: false,
      message: i18n.t('common:validations.fieldRequired'),
    };
  }

  const totalSeedlings = seedlingsUsed + seedlingsDiscarded;
  const discardPercentage = Number(
    ((seedlingsDiscarded / totalSeedlings) * 100).toFixed(2),
  );

  if (discardPercentage < min) {
    return {
      isValid: false,
      message: i18n.t(
        'plantedArea:listNotes.modals.modalEdit.inputErrors.minDiscardedAlert',
        {
          value: formatNumber(min),
        },
      ),
    };
  }

  if (discardPercentage > max) {
    return {
      isValid: false,
      message: i18n.t(
        'plantedArea:listNotes.modals.modalEdit.inputErrors.maxDiscardedAlert',
        {
          value: formatNumber(max),
        },
      ),
    };
  }

  return {
    isValid: true,
    message: '',
  };
};

export const validatePlantDensity = ({
  seedlingsUsed,
  area,
  min,
  max,
}: ValidadePlantDensityPropsInterface) => {
  if (!seedlingsUsed || !area) {
    return {
      isValid: false,
      message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.area'),
    };
  }

  if (area === 0) {
    return {
      isValid: false,
      message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.areaZero'),
    };
  }

  const density = Number((seedlingsUsed / area).toFixed(0));

  if (density < min) {
    return {
      isValid: false,
      message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.minAlert', {
        value: formatNumber(min),
      }),
    };
  }

  if (density > max) {
    return {
      isValid: false,
      message: i18n.t('plantedArea:listNotes.modals.modalEdit.inputErrors.maxAlert', {
        value: formatNumber(max),
      }),
    };
  }

  return {
    isValid: true,
    message: '',
  };
};
