import SwitchButtons from './switch-buttons';
import UserList from './user-list';
import { Skeleton } from '@mui/material';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import ModulesAndServices from './modules-and-services';
import LevelsPermissions from './levels-permissions';
import ServicesParams from './services-params';

const BodyCompanyProfile = observer(() => {
  const { loading, activeScreen, handleActiveScreen, optionsScreen } =
    companyProfileStore;
  const loadingCompanyData = loading.companyData;

  return (
    <div className="w-full ">
      {optionsScreen.length > 0 && (
        <div className="w-full rounded-lg bg-white px-[24px] py-[20px] dark:bg-darkblack-600 shadow-lg">
          <SwitchButtons
            value={activeScreen}
            setValue={handleActiveScreen}
            options={optionsScreen}
            loading={loadingCompanyData}
          />
        </div>
      )}
      {loadingCompanyData && (
        <div>
          <Skeleton
            variant="rectangular"
            height={91}
            width={'100%'}
            className="rounded-lg mt-5 shadow-lg dark:bg-darkblack-400"
          />
          <Skeleton
            variant="rectangular"
            height={600}
            width={'100%'}
            className="rounded-lg mt-5 shadow-lg dark:bg-darkblack-400"
          />
        </div>
      )}

      {activeScreen === 'modulesAndServices' && <ModulesAndServices />}
      {activeScreen === 'levelsPermissions' && <LevelsPermissions />}
      <UserList />
      {activeScreen === 'params' && <ServicesParams />}
    </div>
  );
});
export default BodyCompanyProfile;
