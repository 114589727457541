import SwitchInput from '@/component/forms/SwitchInput';
import { PuffLoader } from 'react-spinners';
import { userStore } from '@/store';
import { observer } from 'mobx-react';
import { PlantedAreaReasonType } from '../types';

const PlantedAreaReasonItemTableRows = ({
  reasons,
  canInsert,
  onHandleActive,
  loadingUpdateReason,
}: {
  reasons: PlantedAreaReasonType[];
  canInsert: boolean;
  loadingUpdateReason: string;
  onHandleActive: (reason: PlantedAreaReasonType) => void;
}) => {
  return (
    <tbody>
      {reasons?.length > 0 &&
        reasons.map((reason) => {
          return (
            <tr
              key={'tr_sc_' + reason.reason}
              className="border-b border-bgray-400 dark:border-darkblack-400"
            >
              <td className={`py-3 px-6 w-[30%] flex space-x-1`}>
                <div
                  className="min-w-8 h-4 rounded"
                  style={{ backgroundColor: reason.color }}
                ></div>
                <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
                  {reason.reason}
                </p>
              </td>
              <td className={`py-3 px-6 w-[60%]`}>
                <p className="text-xs font-regular text-wrap text-bgray-900 dark:text-gray-200">
                  {reason.description}
                </p>
              </td>

              <td className={`py-3 px-6 w-[10%] items-center justify-center`}>
                {loadingUpdateReason === reason.reason ||
                loadingUpdateReason === 'all' ? (
                  <PuffLoader
                    color={userStore.theme === 'dark' ? 'white' : '#718096'}
                    size={26}
                  />
                ) : (
                  <SwitchInput
                    disabled={!canInsert}
                    checked={reason.active}
                    onChange={() => onHandleActive(reason)}
                    value={reason.active}
                  />
                )}
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
export default observer(PlantedAreaReasonItemTableRows);
