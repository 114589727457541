import EChartComponent from '@/component/Echart';
import { Skeleton } from '@mui/material';
import React, { useEffect, useMemo, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import * as echarts from 'echarts';
import LegendChart from './legend-chart';
import { isArray } from 'lodash';
import EmptyState from '@/component/EmptyState';
import { useTranslation } from 'react-i18next';

const DetailsCausesGraph = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const {
    evaluationDetails: evaluationData,
    loading,
    selectedChart,
    handleSelectedChart,
    optionsSurvivalChart,
    deathCauseHighlight,
    causesDeaths,
    handleDeathCauseHighlight,
  } = survivalSurveysEvaluationsStore;
  const { theme } = userStore;
  const loadingEvaluationData = loading.evaluationDetails;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  if (!evaluationData) return null;
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);

  const chartsType = [
    {
      label: tSurvivalSurveys('details.body.detailsResults.causesGraph.types.pie'),
      value: 'Pie',
    },
    {
      label: tSurvivalSurveys('details.body.detailsResults.causesGraph.types.bar'),
      value: 'Bar',
    },
  ];
  useMemo(() => {
    handleSelectedChart(selectedChart);
  }, [theme]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        chartInstance.on('mouseover', (e) => {
          handleDeathCauseHighlight({
            apply: 'all',
            cause: e?.data?.name as string,
          });
        });
        chartInstance.on('mouseout', () => {
          handleDeathCauseHighlight({
            apply: '',
            cause: '',
          });
        });
      }
    }
  }, [chartRef.current]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        const option = { ...optionsSurvivalChart };
        if (option.series && isArray(option.series)) {
          const seriesData = option.series[0].data;
          const causesList = causesDeaths
            .filter((cause) => cause.show)
            .map((cause) => cause.cause);
          const emphasizedData = seriesData.map((data) =>
            causesList.includes(String(data.name))
              ? {
                  ...data,
                  value: evaluationData.survivalSurveyDots.filter(
                    (dot) => dot.deathCause?.cause === data.name,
                  ).length,
                }
              : { ...data, value: null },
          );
          option.series[0].data = emphasizedData;
          chartInstance.setOption(option);
        }
      }
    }
  }, [causesDeaths, optionsSurvivalChart]);

  useEffect(() => {
    if (chartRef.current) {
      const chartInstance = echarts.getInstanceByDom(chartRef.current);
      if (chartInstance) {
        const emphasizeValue = (name) => {
          const option = chartInstance.getOption();
          if (option.series[0]?.data) {
            const seriesData = option.series[0].data;

            const emphasizedData = seriesData.map((item) => {
              return {
                ...item,
                itemStyle: {
                  ...item.itemStyle,
                  borderWidth: name ? (item.name === name ? 3 : 0) : 0,
                  borderColor: item.itemStyle.color,
                  opacity: name ? (item.name === name ? 1 : 0.2) : 1,
                },
              };
            });

            option.series[0].data = emphasizedData;
            chartInstance.setOption(option);
          }
        };

        if (
          deathCauseHighlight.apply === 'chart' ||
          deathCauseHighlight.apply === 'all'
        ) {
          emphasizeValue(deathCauseHighlight.cause);
        } else {
          emphasizeValue('');
        }
      }
    }
  }, [deathCauseHighlight]);
  if (loadingEvaluationData) {
    return (
      <div className="dark:bg-darkblack-500 flex flex-col  shadow-lg rounded-lg min-h-[356px] flex-1">
        <Skeleton
          variant="rectangular"
          height={'100%'}
          width={'100%'}
          className="dark:bg-darkblack-500 rounded-lg"
        />
      </div>
    );
  }
  const isEmptyState = !(
    isArray(optionsSurvivalChart?.series) &&
    isArray(optionsSurvivalChart?.series[0]?.data) &&
    optionsSurvivalChart?.series[0].data.length > 0
  );
  return (
    <div className="dark:bg-darkblack-500 flex flex-col shadow-lg bg-white p-2 rounded-lg min-h-[356px] flex-1">
      <div className="flex justify-between w-full">
        <p className="text-semibold dark:text-white">
          {tSurvivalSurveys('details.body.detailsResults.causesGraph.title')}
        </p>
        {!isEmptyState && (
          <button
            className="text-sm w-20 justify-between bg-white rounded px-3 py-1 flex items-center gap-1"
            onClick={handleClick}
          >
            {selectedChart.label}
            <FaChevronDown />
          </button>
        )}
        <BasePopup
          open={open}
          anchor={anchor}
          placement="bottom"
          style={{ zIndex: 2 }}
          className="w-20 z-[3]"
        >
          <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg p-2 max-h-48 overflow-y-auto overflow-style-none">
            {chartsType.map((chart) => {
              return (
                <p
                  className="text-base cursor-pointer dark:text-white"
                  onClick={(event) => {
                    handleSelectedChart(chart);
                    handleClick(event);
                  }}
                  key={chart.value}
                >
                  {chart.label}
                </p>
              );
            })}
          </div>
        </BasePopup>
        <div
          onClick={() => setAnchor(null)}
          id="noti-outside"
          className={`fixed z-[2] left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
      </div>
      <div className="flex-col flex justify-center items-center h-full w-full relative">
        {!isEmptyState ? (
          <EChartComponent
            chartRef={chartRef}
            option={optionsSurvivalChart}
            style={{
              zIndex: 1,
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <EmptyState
            title={tSurvivalSurveys('details.body.detailsResults.causesGraph.emptyData')}
            bg={'bgrey-200'}
            bgDark="darkblack-500"
            size="md"
            description={''}
          />
        )}
        <LegendChart />
      </div>
    </div>
  );
};
export default observer(DetailsCausesGraph);
