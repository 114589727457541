import { observer } from 'mobx-react';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import Table from '@/component/Table';
import { AddCompanyModal } from './modal-add-company';
import CompaniesController from './master-companies-controller';
import { IoAdd } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { userStore } from '@/store';
import ModalActions from '@/component/modals/modal-actions';
import { CompanyType } from './master-companies-types';
import { useTranslation } from 'react-i18next';
function Companies() {
  const navigate = useNavigate();
  if (!userStore.user.master) {
    navigate('/');
    return null;
  }
  const {
    totalItems,
    columns,
    page,
    setPage,
    totalPages,
    size,
    setSize,
    loading,
    companies,
    order,
    setOrder,
    query,
    setQuery,
    isActiveModalAddCompany,
    setIsActiveModalAddCompany,
    companyUpdate,
    options,
    reloadData,
    handleCloseModal,
    isActiveModalChangeStatusCompany,
    onChangeStatusCompany,
    setIsActiveModalChangeStatusCompany,
    loadingChangeStatusCompany,
  } = CompaniesController();
  const tCommon = useTranslation('common').t;
  const tMaster = useTranslation('master').t;
  return (
    <div className="w-full min-h-full px-2 py-2 pb-14 md:px-4 md:py-4 relative dark:bg-darkblack-700 ">
      <div className="pl-1 flex mb-5 justify-between items-center">
        <div className="items-center justify-center flex-1 h-full self-end">
          <p className="text-sm font-medium  text-bgray-600 dark:text-bgray-50">
            {totalItems === 1
              ? tCommon('showingRegister', {
                  items: size < totalItems ? size : totalItems,
                })
              : tCommon('showingRegisters', {
                  items: size < totalItems ? size : totalItems,
                  totalItems,
                })}
          </p>
        </div>
        <PrimaryButton
          text={tCommon('actions.add')}
          onClick={() => setIsActiveModalAddCompany(true)}
          icon={IoAdd}
        />
      </div>
      <div className="rounded-lg shadow-lg">
        <Table
          options={options}
          showSearch={true}
          query={query}
          setQuery={setQuery}
          order={order}
          setOrder={setOrder}
          data={companies}
          columns={columns}
          clickable={true}
          page={page}
          totalPages={totalPages}
          size={size}
          setPage={setPage}
          setSize={setSize}
          loading={loading && !query}
          onPressRow={(row: CompanyType) => navigate('/companies/' + row.id)}
        />
      </div>
      <AddCompanyModal
        isActive={isActiveModalAddCompany}
        handleClose={handleCloseModal}
        reloadData={reloadData}
        company={companyUpdate}
      />
      <ModalActions
        isActive={isActiveModalChangeStatusCompany}
        handleClose={() => setIsActiveModalChangeStatusCompany(false)}
        handleSubmit={onChangeStatusCompany}
        variant={companyUpdate?.active ? 'inactive' : 'warning'}
        loading={loadingChangeStatusCompany}
        title={
          companyUpdate?.active
            ? tMaster('companies.changeStatusCompany.titleInactive')
            : tMaster('companies.changeStatusCompany.titleActive')
        }
        description={tMaster('companies.changeStatusCompany.subtitle')}
        submitText={
          companyUpdate?.active ? tCommon('actions.inactive') : tCommon('actions.active')
        }
        cancelText={tCommon('actions.cancel')}
      />
    </div>
  );
}

export default observer(Companies);
