import { SurvivalSurveyCauseType } from '../survival-surveys-causes-types';
import OriginItemTable from './origin-item-table';

const OriginItem = ({
  title,
  origin,
  canInsert,
  onHandleActive,
  loadingUpdateCause,
}: {
  title: string;
  origin: SurvivalSurveyCauseType[];
  canInsert: boolean;
  loadingUpdateCause: string;

  onHandleActive: (cause: SurvivalSurveyCauseType) => void;
}) => {
  return (
    <div className="bg-white w-[100%] px-4 pb-3 pt-5  rounded-lg dark:bg-darkblack-600 shadow-lg overflow-x-auto">
      <h1 className="font-semibold text-dark-300 dark:text-bgray-100">{title}</h1>
      <OriginItemTable
        origin={origin}
        canInsert={canInsert}
        onHandleActive={onHandleActive}
        loadingUpdateCause={loadingUpdateCause}
      />
    </div>
  );
};

export default OriginItem;
