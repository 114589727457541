import { survivalSurveysEvaluationsStore } from '@/store';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';

const LegendChart = observer(() => {
  const {
    optionsSurvivalChart,
    deathCauseHighlight,
    handleDeathCauseHighlight,
    causesDeaths,
    handleCausesDeaths,
  } = survivalSurveysEvaluationsStore;

  return (
    <div className="flex top-2 px-4 flex-wrap gap-3">
      {isArray(optionsSurvivalChart?.series) &&
        optionsSurvivalChart?.series[0] &&
        isArray(optionsSurvivalChart?.series?.[0]?.data) &&
        optionsSurvivalChart?.series?.[0]?.data?.map((item, index) => {
          const isVisible = causesDeaths
            .filter((cause) => cause.show)
            .map((cause) => cause.cause)
            .includes(String(item.name));
          return (
            <button
              onClick={() => handleCausesDeaths(String(item.name))}
              key={index}
              className="flex gap-1 items-center cursor-pointer"
              onMouseEnter={() => {
                handleDeathCauseHighlight({
                  apply: isVisible ? 'all' : '',
                  cause: isVisible ? item.name : '',
                });
              }}
              onMouseOut={() => {
                handleDeathCauseHighlight({
                  apply: '',
                  cause: '',
                });
              }}
            >
              <div
                onMouseEnter={() => {
                  handleDeathCauseHighlight({
                    apply: isVisible ? 'all' : '',
                    cause: isVisible ? item.name : '',
                  });
                }}
                onMouseOut={() => {
                  handleDeathCauseHighlight({
                    apply: '',
                    cause: '',
                  });
                }}
                className="w-8 h-4 rounded"
                style={{
                  backgroundColor: item?.itemStyle?.color,
                  opacity: !isVisible
                    ? 0.2
                    : deathCauseHighlight.cause !== ''
                      ? item.name !== deathCauseHighlight.cause
                        ? 0.2
                        : 1
                      : 1,
                }}
              />
              <span
                className={`dark:text-bgray-200 text-base ${
                  isVisible ? '' : 'line-through opacity-20'
                }`}
              >
                {item?.name}: {item?.value}
              </span>
            </button>
          );
        })}
    </div>
  );
});

export default LegendChart;
