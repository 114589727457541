import FilterIcon from '@/assets/images/icons/FilterIcon';
import Search from '@/component/Table/Search';
import { Collapse } from '@mui/material';
import { useState } from 'react';
import { forestRegistryStore } from '@/store';
import Select from '@/component/forms/Select';
import { observer } from 'mobx-react';
import { FiltersForestRegistryType } from '../types';
import { ForestRegistryFiltersType } from '@/utils/types/ForestRegistryType';
import { useTranslation } from 'react-i18next';

const ForestRegistryFilters = observer(() => {
  const {
    loading: loadingItems,
    filter,
    handleFilters,
    handleClearFilter,
    forestRegistryData,
  } = forestRegistryStore;
  const { filters } = forestRegistryData;
  const [filterOpen, setFilterOpen] = useState(false);
  const tForestRegistry = useTranslation('forestRegistry').t;
  return (
    <div className="rounded-lg px-3 bg-white items-center h-full flex-col dark:bg-darkblack-600 flex-1 mb-3 py-3 shadow-lg">
      <div className="flex gap-3 flex-col sm:flex-row">
        <div className="flex h-[43px] w-full">
          <Search
            value={filter.query}
            setValue={(value) => {
              handleFilters('query', value);
            }}
          />
        </div>
        <div className="flex items-center gap-2 justify-end">
          <button
            className="text-sm text-nowrap text-bgray-700 dark:text-bgray-200"
            onClick={handleClearFilter}
          >
            {tForestRegistry('filters.clearFilters')}
          </button>
          <button
            className="flex items-center gap-1 px-3 bg-primary-100 rounded-md h-[40px] text-success-200 bg-opacity-20 border border-success-200"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <FilterIcon />
            {tForestRegistry('filters.filters')}
          </button>
        </div>
      </div>
      <Collapse in={filterOpen} timeout="auto" unmountOnExit>
        <div
          className="
            grid
            sm:grid-cols-2
            md:grid-cols-3 
            lg:grid-cols-4 
            xl:grid-cols-4 
            2xl:grid-cols-4
            3xl:grid-cols-4 
            gap-x-3"
        >
          {filters &&
            filter &&
            Object.keys(filters).map((chave, index) => {
              const options = filters[chave as keyof ForestRegistryFiltersType];
              let label;
              switch (chave) {
                case 'regiao':
                  label = tForestRegistry('filters.fields.regiao');
                  break;
                case 'fazenda':
                  label = tForestRegistry('filters.fields.fazenda');
                  break;
                case 'talhao':
                  label = tForestRegistry('filters.fields.talhao');
                  break;
                case 'manejo':
                  label = tForestRegistry('filters.fields.manejo');
                  break;
                case 'active':
                  label = tForestRegistry('filters.fields.active');
                  break;
              }
              if (!options.length) {
                return (
                  <Select
                    key={index}
                    loading={loadingItems}
                    multiselect={true}
                    label={label}
                    value={filter[chave as keyof FiltersForestRegistryType]}
                    data={options}
                    onChange={function (value): void {
                      handleFilters(chave, value);
                    }}
                  />
                );
              }
              return (
                <Select
                  key={index}
                  loading={loadingItems}
                  multiselect={true}
                  label={label}
                  value={filter[chave as keyof FiltersForestRegistryType]}
                  data={options}
                  onChange={function (value): void {
                    handleFilters(chave, value);
                  }}
                />
              );
            })}
        </div>
      </Collapse>
    </div>
  );
});
export default ForestRegistryFilters;
