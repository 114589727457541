import Modal from '@/component/modals/modal-default';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import DropZoneFileKML from './modal-dropzone';
import ColumnSelectKML from './select-columns';
import ForestRegistryModalImportKMLController from './forest-registry-import-kml-controller';
import FeedbackValidKML from './feedback-invalid';
import { forestRegistryStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ForestRegistryModalImportKML = () => {
  const { modalsState } = forestRegistryStore;

  const controllerModal = ForestRegistryModalImportKMLController();
  const {
    fileUpload,
    fileInfos,
    isValiding,
    handleClose,
    handleFileSelection,
    handleSubmit,
    columns,
    validFile,
    statusUploadKML,
    handleClearForm,
    handleBackSelectColumns,
  } = controllerModal;
  const tForestRegistry = useTranslation('forestRegistry').t;
  const tCommon = useTranslation('common').t;
  return (
    <Modal
      title={tForestRegistry('modals.uploadKML.title')}
      isActive={modalsState.uploadKML}
      handleClose={() => !(modalsState.loading || isValiding) && handleClose()}
      footer={
        <div className="flex justify-end gap-3">
          <SecondaryButton
            text={
              fileInfos?.totalPolygons > 0
                ? tCommon('actions.back')
                : tCommon('actions.cancel')
            }
            disabled={modalsState.loading || isValiding}
            onClick={() =>
              fileInfos.totalPolygons > 0
                ? handleBackSelectColumns()
                : !fileUpload?.name && fileInfos?.totalPolygons === 0
                  ? handleClose()
                  : handleClearForm()
            }
          />
          <PrimaryButton
            text={
              fileInfos?.totalPolygons &&
              (fileInfos?.createPolygons.length > 0 ||
                fileInfos?.updatePolygons.length > 0)
                ? tForestRegistry('modals.uploadKML.submitText')
                : tForestRegistry('modals.uploadKML.validFile')
            }
            loading={modalsState.loading || isValiding}
            disabled={!fileUpload || isValiding || !columns.fazenda || !columns.talhao}
            onClick={() =>
              fileInfos?.totalPolygons &&
              (fileInfos?.createPolygons.length > 0 ||
                fileInfos.updatePolygons.length > 0)
                ? handleSubmit()
                : validFile()
            }
          />
        </div>
      }
    >
      <div className="w-full">
        {fileUpload ? (
          <ColumnSelectKML controller={controllerModal} />
        ) : (
          <DropZoneFileKML handleFileSelection={handleFileSelection} />
        )}

        <FeedbackValidKML
          fileUpload={fileUpload}
          isValiding={isValiding}
          fileInfos={fileInfos}
          statusUploadKML={statusUploadKML}
        />
      </div>
    </Modal>
  );
};
export default observer(ForestRegistryModalImportKML);
