import moment from 'moment';
import BadgeStatusApprovals from '../../../list/badge-status-approvals';
import { survivalSurveysEvaluationType } from '../../../quality-survival-surveys-types';
import { useTranslation } from 'react-i18next';

const ReportPdfItemHeader = ({
  evaluation,
}: {
  evaluation: survivalSurveysEvaluationType;
}) => {
  const dateTimeStartMoment = moment(evaluation.dateTimeStart);
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex space-x-2">
        <p className="font-bold text-xl dark:text-gray-200">
          {tSurvivalSurveys('details.header.title', { cod: evaluation.cod })}
        </p>
        <BadgeStatusApprovals evaluation={evaluation} />
      </div>
      <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
        {tSurvivalSurveys('details.header.startIn', {
          date: dateTimeStartMoment.format('DD/MM/YYYY'),
          hours: dateTimeStartMoment.format('HH:mm'),
        })}
      </p>
    </div>
  );
};

export default ReportPdfItemHeader;
