import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  CompanyDataType,
  LoadingType,
  ModalStatesType,
  OptionScreenType,
  TableParamsType,
  UserListType,
} from './company-profile-types';
import {
  initialStateLoading,
  initialStateModal,
  initialStateTableParamsUser,
  initialStateUserList,
  inititalStateCompanyData,
} from './company-profile-initial-states';
import {
  ChangeImage,
  changeKeyUser,
  changeMasterUser,
  deleteUser,
  getCompany,
  getUserList,
  updateUser,
} from './company-profile-services';
import { ChangeEvent } from 'react';
import { ScreensType } from './company-profile-body/types';
import { OrderTableTypes } from '@/component/Table/types';
import { Toast } from '@/component/Toast';
import { checkPermission, checkPermissionCompany } from '@/utils/helpers/validations';
import { userStore } from '@/store';
import { LevelType } from './company-profile-body/levels-permissions/levels-permissions-types';
import { getUserLevels } from './company-profile-body/levels-permissions/levels-permissions-services';
import servicesParams from './company-profile-body/services-params/services-params-list/data';
import { translationI18n } from '@/i18n';

const { showSuccess } = Toast;
export class CompanyProfileStore {
  constructor() {
    makeAutoObservable(this);
    makeObservable(this);
    void makePersistable(this, {
      name: 'CompanyProfileStore',
      properties: [],
    });
  }
  isMyCompany: boolean = true;
  optionsScreen: OptionScreenType[] = [];
  companyData: CompanyDataType = inititalStateCompanyData;
  loading: LoadingType = initialStateLoading;
  modalStates: ModalStatesType = initialStateModal;
  activeScreen: ScreensType = '';
  tableParamsUser: TableParamsType = initialStateTableParamsUser;
  userList: UserListType = initialStateUserList;
  levels: LevelType[] = [];

  reset = () => {
    this.isMyCompany = true;
    this.optionsScreen = [];
    this.companyData = inititalStateCompanyData;
    this.loading = initialStateLoading;
    this.modalStates = initialStateModal;
    this.activeScreen = '';
    this.tableParamsUser = initialStateTableParamsUser;
    this.userList = initialStateUserList;
    this.levels = [];
  };

  handleLevels = (newLevels: LevelType[]) => {
    runInAction(() => {
      this.levels = newLevels;
    });
  };
  //FUNCTIONS GET DATA
  getLevelsData = async () => {
    this.handleLoading('levels', true);
    try {
      const responseLevels = await getUserLevels({
        companyId: this.companyData.id,
      });
      if (responseLevels.totalItems > 0) {
        runInAction(() => {
          this.levels = responseLevels.levels;
        });
      }
      this.handleLoading('levels', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('levels', false);
    }
  };
  setCompanyData = (newData: CompanyDataType) => {
    runInAction(() => {
      this.companyData = newData;
    });
  };
  getCompanyData = async (id: number | undefined, withLoading: boolean = true) => {
    this.handleLoading('companyData', withLoading);
    try {
      const result = await getCompany(Number(id));
      runInAction(() => {
        this.companyData = result;
        this.handleLoading('companyData', false);
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('companyData', false);
    }
  };

  getListUser = async () => {
    this.handleLoading('userList', true);
    try {
      const userListResult = await getUserList({
        ...this.tableParamsUser,
        column: this.tableParamsUser.direction.column,
        direction: this.tableParamsUser.direction.direction,
        companyId: this.companyData.id,
      });

      runInAction(() => {
        this.userList = userListResult;
      });
      this.handleLoading('userList', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('userList', false);
    }
  };

  onDelete = async () => {
    try {
      this.handleModal({ ...this.modalStates, loading: true });
      const response = await deleteUser(Number(this.modalStates?.user?.id));
      showSuccess(response);
      this.getListUser();
      this.handleModal({
        ...this.modalStates,
        user: undefined,
        deleteUser: false,
        loading: false,
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModal({
        ...this.modalStates,
        loading: false,
      });
    }
  };

  onChangeStatusUser = async () => {
    this.handleModal({ ...this.modalStates, loading: true });
    const user = this.modalStates.user;
    if (!user) return;
    try {
      const response = await updateUser({
        id: user.id,
        active: !user.active,
      });
      showSuccess(response);
      runInAction(() => {
        this.userList.users = this.userList.users.map((u) =>
          u.id === user.id ? { ...u, active: !u.active } : u,
        );
      });
      this.handleModal({
        ...this.modalStates,
        user: undefined,
        changeStatusUser: false,
        loading: false,
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModal({
        ...this.modalStates,
        user: undefined,
        loading: false,
      });
    }
  };

  onChangeKeyUser = async () => {
    try {
      this.handleModal({ ...this.modalStates, loading: true });
      const user = this.modalStates.user;
      if (!user) return;
      const response = await changeKeyUser({
        id: user.id,
      });
      showSuccess(response);
      runInAction(() => {
        this.userList.users = this.userList.users.map((u) =>
          u.id === user.id ? { ...u, keyUser: !u.keyUser } : u,
        );
      });
      this.handleModal({
        ...this.modalStates,
        user: undefined,
        changeKeyUser: false,
        loading: false,
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModal({
        ...this.modalStates,
        loading: false,
      });
      this.handleModal({ ...this.modalStates, user: undefined, changeKeyUser: false });
    }
  };

  onChangeMasterUser = async () => {
    try {
      this.handleModal({
        ...this.modalStates,
        loading: true,
      });
      const user = this.modalStates.user;
      if (!user) return;
      const response = await changeMasterUser({
        id: user.id,
      });
      showSuccess(response);
      runInAction(() => {
        this.userList.users = this.userList.users.map((u) =>
          u.id === user.id ? { ...u, master: !u.master } : u,
        );
      });
      this.handleModal({
        ...this.modalStates,
        user: undefined,
        changeMasterUser: false,
        loading: false,
      });
    } catch (error: any) {
      console.error(error.message);
      this.handleModal({ ...this.modalStates, loading: false });
    }
  };

  handleChangeImage = async (e: ChangeEvent<HTMLInputElement>) => {
    this.handleLoading('changeImage', true);
    const file = e.target.files && e.target.files[0];
    if (file) {
      // Verificar se o arquivo selecionado é uma imagem
      if (file.type.startsWith('image/')) {
        // Aqui você pode fazer o que quiser com o arquivo selecionado, como enviar para o servidor, etc.
        const response = await ChangeImage({ file: file, id: this.companyData.id });
        runInAction(() => {
          this.companyData = response.data;
          this.handleLoading('companyData', false);
        });
        this.handleLoading('changeImage', false);
      } else {
        this.handleLoading('changeImage', false);
      }
    }
  };

  getOptionsScreen = () => {
    const op: OptionScreenType[] = [
      {
        label: translationI18n('companyProfile:body.screens.modulesAndServices.name'),
        value: 'modulesAndServices',
        hide: this.isMyCompany,
      },
      {
        label: translationI18n('companyProfile:body.screens.levelsPermissions.name'),
        value: 'levelsPermissions',
        hide:
          ((this.isMyCompany &&
            !checkPermission({
              permission: 'hasone',
              service: 'levels',
            })) ||
            !checkPermissionCompany({
              companyData: this.companyData,
              service: 'levels',
            })) &&
          !userStore.user.master,
      },
      {
        label: translationI18n('companyProfile:body.screens.userList.name'),
        value: 'userList',
        hide:
          ((this.isMyCompany &&
            !checkPermission({
              permission: 'hasone',
              service: 'users',
            })) ||
            !checkPermissionCompany({
              companyData: this.companyData,
              service: 'users',
            })) &&
          !userStore.user.master,
      },
      {
        label: translationI18n('companyProfile:body.screens.params.name'),
        value: 'params',
        hide:
          (((this.isMyCompany &&
            !checkPermission({
              permission: 'hasone',
              service: 'params',
            })) ||
            !checkPermissionCompany({
              companyData: this.companyData,
              service: 'params',
            })) &&
            !userStore.user.master) ||
          servicesParams().length === 0,
      },
    ].filter((x) => !x.hide);
    runInAction(() => {
      this.optionsScreen = op;
      if (op.length) {
        this.activeScreen = op[0].value;
      }
    });
  };

  handleLoading = (field: keyof LoadingType, value: boolean) => {
    runInAction(() => {
      this.loading[field] = value;
    });
  };

  handleModal = (modalState: ModalStatesType) => {
    runInAction(() => {
      this.modalStates = modalState;
    });
  };

  handleActiveScreen = (screen: ScreensType) => {
    runInAction(() => {
      this.activeScreen = screen;
    });
  };

  handleTableParamsUser = (
    field: keyof TableParamsType,
    value: number | string | OrderTableTypes,
  ) => {
    runInAction(() => {
      this.tableParamsUser[field] = value;
    });
  };

  handleIsMyCompany = (isMyCompany: boolean) => {
    runInAction(() => {
      this.isMyCompany = isMyCompany;
    });
  };
}
