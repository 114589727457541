import { Navigate, RouteObject } from 'react-router-dom';
import SignIn from '@/pages/public/signin';

const privateNewUserRoutes: RouteObject[] = [
  {
    path: '/resetPassword',
    element: <SignIn />,
  },
  {
    path: '*',
    element: <Navigate to={'/resetPassword'} />,
  },
  {
    path: '/signin',
    element: <Navigate to={'/resetPassword'} />,
  },
];
export default privateNewUserRoutes;
