import {
  DeleteLevelResponseType,
  GetLevelsDataType,
  GetLevelsResponseType,
  GetPermissionsDataType,
  GetPermissionsResponseType,
  SaveAndSavePermissionsResponseType,
  SaveLevelResponseType,
  SavePermissionsDataType,
  UpdateOrderLevelDataType,
  UpdatePermissionsDataType,
} from './levels-permissions-types';
import { del, get, post, put, putArr } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import { AddLevelDataType } from './modal-add-level/types';

export const getLevels = async (
  data: GetLevelsDataType,
): Promise<GetLevelsResponseType> => {
  return await get(url.GET_LEVELS, { params: { ...data, size: 99999 } });
};
export const getUserLevels = async (
  data: GetLevelsDataType,
): Promise<GetLevelsResponseType> => {
  return await get(url.GET_USERS_LEVELS, { params: { ...data, size: 99999 } });
};
export const saveLevel = async (
  data: AddLevelDataType,
): Promise<SaveLevelResponseType> => {
  return await post(url.POST_LEVELS, data);
};
export const updateLevel = async (
  data: AddLevelDataType,
): Promise<SaveLevelResponseType> => {
  return await put(url.POST_LEVELS + '/' + data?.id, data);
};
export const deleteLevel = async (id: number): Promise<DeleteLevelResponseType> => {
  return await del(url.POST_LEVELS + '/' + id);
};
export const getPermissionsByLevelAndCompany = async (
  data: GetPermissionsDataType,
): Promise<GetPermissionsResponseType> => {
  return await get(url.GET_PERMISSIONS_LEVELS, { params: { ...data } });
};

export const savePermissionLevel = async (
  data: SavePermissionsDataType,
): Promise<SaveAndSavePermissionsResponseType> => {
  return (await post(
    url.POST_PERMISSIONS_LEVELS,
    data,
  )) as SaveAndSavePermissionsResponseType;
};

export const updatePermissionLevel = async (
  data: UpdatePermissionsDataType,
): Promise<SaveAndSavePermissionsResponseType> => {
  return await put(url.POST_PERMISSIONS_LEVELS + '/' + data?.id, data);
};

export const updateLevelsOrder = async (
  data: UpdateOrderLevelDataType,
): Promise<SaveAndSavePermissionsResponseType> => {
  return await putArr(url.PUT_ORDER_LEVEL, data);
};
