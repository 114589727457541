import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';

import Gallery from './gallery';
import EmptyState from '@/component/EmptyState';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

const EvaluatuationPhotos = observer(() => {
  const { evaluationDetails, loading: loadingAll } = survivalSurveysEvaluationsStore;
  const { evaluationDetails: loading } = loadingAll;
  const { evaluationPhotos } = evaluationDetails;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  return (
    <div className="dark:bg-darkblack-500 flex flex-col mt-3 lg:mt-0 shadow-lg bg-white p-3 rounded-lg flex-1">
      <p className=" dark:text-white mb-2">
        {tSurvivalSurveys('details.body.evaluationPhotos.title', {
          photos: !loading ? evaluationPhotos?.length || 0 : 0,
        })}
      </p>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={332}
          width={'100%'}
          className="max-h-[334px] min-h-[334px] rounded-lg dark:bg-darkblack-500"
        />
      ) : evaluationPhotos?.length ? (
        <Gallery evaluationPhotos={evaluationPhotos} />
      ) : (
        <EmptyState
          size="md"
          bgDark="darkblack-500"
          bg="white"
          title={tSurvivalSurveys('details.body.evaluationPhotos.emptyData.title')}
          description={tSurvivalSurveys(
            'details.body.evaluationPhotos.emptyData.description',
          )}
        />
      )}
    </div>
  );
});

export default EvaluatuationPhotos;
