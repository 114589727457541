import { get, post } from '@/utils/helpers/api_helper';
import {
  GetMyUserResponseType,
  UserLoginResponseType,
  UserLoginType,
} from '@/utils/types/UsersType';
import * as url from '@/utils/helpers/url_helper';

export const loginRequest = async (
  data: UserLoginType,
): Promise<UserLoginResponseType> => {
  return await post(url.POST_JWT_LOGIN, data);
};

export const getMyUser = async (): Promise<GetMyUserResponseType> => {
  return await get(url.GET_MYUSER);
};
