import Pagination from './Pagination';
import Row from './Row';
import { ColumnsTypes, OrderTableTypes, TableTypes } from './types';
import { BiSortAlt2, BiSortDown, BiSortUp } from 'react-icons/bi';
import Search from './Search';
import LoadingTable from './loading-table';
import FilterIcon from '@/assets/images/icons/FilterIcon';
import { useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import Select from '../forms/Select';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import { OptionFilterType } from '@/utils/types/ForestRegistryType';
import { globalStore } from '@/store';
import { useTranslation } from 'react-i18next';

// Hook personalizado para gerenciar filtros
const useTableFilters = (
  filters: { [x: string]: OptionFilterType[] },
  filter:
    | { [key: string]: string | number | boolean | string[] | number[] | boolean[] }
    | undefined,
  setFilter: {
    (
      filter: string,
      value: string | number | boolean | string[] | number[] | boolean[],
    ): void;
    (arg0: any, arg1: any[]): void;
  },
) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const hasFilters = filter && Object.keys(filters).length > 0;
  const hasFiltered = filter && Object.keys(filter).some((key) => filter[key] !== '');

  const toggleFilterOpen = () => setFilterOpen((prev) => !prev);
  const handleSelectChange = (key: any, option: any) => {
    if (isArray(option)) {
      setFilter(key, option);
    }
  };

  return {
    filterOpen,
    hasFilters,
    hasFiltered,
    toggleFilterOpen,
    handleSelectChange,
  };
};

// Função para renderizar ícone de ordenação
const renderSortIcon = (order: OrderTableTypes, column: ColumnsTypes) => {
  if (order.column === column.field) {
    return order.direction === 'desc' ? (
      <BiSortUp size={16} className="text-bgray-600 dark:text-bgray-50" />
    ) : (
      <BiSortDown size={16} className="text-bgray-600 dark:text-bgray-50" />
    );
  }
  return <BiSortAlt2 size={16} className="text-bgray-600 dark:text-bgray-50" />;
};

const Table = ({
  columns,
  data,
  page,
  size,
  totalPages,
  setPage,
  setSize,
  loading,
  order,
  setOrder,
  query = '',
  setQuery = () => {},
  onPressRow = () => {},
  showSearch = true,
  clickable = false,
  selectable = false,
  selectedRows = [],
  setSelectedRows = () => {},
  options = () => [],
  filters = {},
  filter,
  setFilter = () => {},
  handleClearFilter = () => {},
  disableSelectRow = () => false,
  pt = 5,
  showPagination = true,
}: TableTypes) => {
  const tCommon = useTranslation('common').t;
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);
  const onSortColumn = (field: string) => {
    setOrder({
      column: field,
      direction: field !== order.column || order.direction === 'desc' ? 'asc' : 'desc',
    });
  };

  const { filterOpen, hasFilters, toggleFilterOpen, handleSelectChange } =
    useTableFilters(filters, filter, setFilter);

  const handleSelectAll = () => {
    const newSelectedRows: number[] = [];
    data.forEach((row, index) => {
      !disableSelectRow(row) && newSelectedRows.push(index);
    });
    setSelectedRows(
      selectedRows.length === data.filter((row) => !disableSelectRow(row)).length
        ? []
        : newSelectedRows,
    );
  };
  const handleSelectRow = (index: number, event: KeyboardEvent) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(index, lastSelectedIndex);
      const end = Math.max(index, lastSelectedIndex);
      const newSelectedRows = [...selectedRows];

      for (let i = start; i <= end; i++) {
        if (!newSelectedRows.includes(i)) {
          newSelectedRows.push(i);
        }
      }

      setSelectedRows(newSelectedRows);
    } else {
      const newSelectedRows = [...selectedRows];
      if (newSelectedRows.includes(index)) {
        newSelectedRows.splice(newSelectedRows.indexOf(index), 1);
      } else {
        newSelectedRows.push(index);
      }
      setSelectedRows(newSelectedRows);
      setLastSelectedIndex(index);
    }
  };

  useEffect(() => {
    setLastSelectedIndex(null);
  }, [data]);

  const { handleLoading } = globalStore;

  useEffect(() => {
    if (data.length) {
      handleLoading(loading);
    }
  }, [loading]);
  const disableAllSelected = !data.some((row) => !disableSelectRow(row));
  const hasActions = columns.some((column) => column.type === 'options');
  return (
    <div
      className={`w-full relative rounded-lg bg-white px-5 pb-5 pt-${pt} dark:bg-darkblack-600`}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex gap-2">
          {showSearch && (
            <div className="flex h-[43px] w-full">
              <Search value={query} setValue={setQuery} />
            </div>
          )}
          {hasFilters && (
            <div className="flex items-center gap-2 justify-end">
              <button
                className="text-sm text-nowrap text-bgray-700 dark:text-bgray-200"
                onClick={handleClearFilter}
              >
                {tCommon('components.table.clearFilters')}
              </button>
              <button
                className="flex items-center gap-1 px-3 bg-primary-100 h-[40px] rounded-md py-2 text-success-200 bg-opacity-20 border border-success-200"
                onClick={toggleFilterOpen}
              >
                <FilterIcon />
                {tCommon('components.table.filters')}
              </button>
            </div>
          )}
        </div>
        <Collapse in={filterOpen} timeout="auto" unmountOnExit>
          <div
            className="
            grid 
            sm:grid-cols-2
            md:grid-cols-3 
            lg:grid-cols-4 
            xl:grid-cols-5 
            2xl:grid-cols-5
            3xl:grid-cols-5 
            grid-cols-1 gap-x-3"
          >
            {filters &&
              filter &&
              Object.keys(filters).map((key, index) => (
                <Select
                  key={index}
                  loading={loading}
                  multiselect
                  label={filters[key][0]?.title}
                  value={filter[key]}
                  data={filters[key]}
                  onChange={(option) => handleSelectChange(key, option)}
                />
              ))}
          </div>
        </Collapse>
        <div className="w-full overflow-x-auto">
          <table className={`w-full ${hasActions ? 'tableLast' : ''} relative`}>
            <thead className="fixed-header relative">
              {loading && !data.length ? (
                <LoadingTable columns={columns} nRows={1} />
              ) : (
                <tr className="border-b border-bgray-400 dark:border-darkblack-400">
                  {selectable && (
                    <th
                      className="py-2 dark:bg-darkblack-600 bg-white"
                      onClick={handleSelectAll}
                    >
                      <input
                        disabled={disableAllSelected}
                        className={`cursor-pointer text-primary-200 rounded ${disableAllSelected ? 'opacity-10' : ''}`}
                        type="checkbox"
                        onChange={() => {}}
                        checked={
                          selectedRows.length ===
                            data.filter((row) => !disableSelectRow(row)).length &&
                          data.filter((row) => !disableSelectRow(row)).length > 0
                        }
                      />
                    </th>
                  )}
                  {columns.map((column, index) => (
                    <th
                      key={column.field + index}
                      onClick={() =>
                        column.ordenable !== false &&
                        column.type !== 'options' &&
                        onSortColumn(column.field)
                      }
                      className={`py-2 dark:bg-darkblack-600 bg-white ${['options', 'active'].includes(column.type) ? 'text-center' : ''}`}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        className={`flex items-center space-x-2.5 px-3 ${['options', 'active'].includes(column.type) ? 'text-center' : ''}`}
                      >
                        <span className="text-base font-bold text-bgray-600 dark:text-bgray-50">
                          {column.title}
                        </span>
                        {column.ordenable !== false && column.type !== 'options' && (
                          <span>{renderSortIcon(order, column)}</span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody className="w-full relative">
              {loading && !data.length ? (
                <LoadingTable columns={columns} nRows={size} />
              ) : (
                data.map((row, index) => (
                  <Row
                    key={'table_row' + index}
                    row={row}
                    columns={columns}
                    disableSelectRow={disableSelectRow}
                    onPress={() => onPressRow(row)}
                    clickable={clickable}
                    selectable={selectable}
                    selected={selectedRows.includes(index)}
                    onSelect={(event: KeyboardEvent) => handleSelectRow(index, event)}
                    options={options(row)}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
        {showPagination && (
          <Pagination
            loading={loading}
            setPage={setPage}
            setSize={setSize}
            page={page}
            totalPages={totalPages}
            size={size}
          />
        )}
      </div>
    </div>
  );
};

export default observer(Table);
