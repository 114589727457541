import { userStore } from '@/store';
import { observer } from 'mobx-react';
import Modal from '../modal-default';
import Select from '@/component/forms/Select';
import { useFetch } from '@/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ModalChangeCompany = () => {
  const {
    modalChangeCompany: modalChangeComapny,
    handleModalChangeCompanies,
    getCompanies,
    companiesOptions,
    companyActive,
    handleCompanyAcitve,
  } = userStore;
  const tMaster = useTranslation('master').t;
  const { request, loading } = useFetch(getCompanies);
  const requestAll = async () => {
    await request();
  };
  useEffect(() => {
    userStore.user.master && requestAll();
  }, [modalChangeComapny.show]);

  return (
    <Modal
      isActive={modalChangeComapny.show}
      handleClose={function (): void {
        handleModalChangeCompanies({
          ...modalChangeComapny,
          show: false,
          loading: false,
        });
      }}
      variant="sm"
      title={tMaster('modalChangeCompany.selectCompany')}
    >
      <div className="w-full py-5">
        <Select
          value={companyActive.id}
          data={companiesOptions}
          loading={loading || modalChangeComapny.loading}
          onChange={function (newValue): void {
            handleModalChangeCompanies({
              ...modalChangeComapny,
              show: false,
              loading: false,
            });
            handleCompanyAcitve(newValue as number);
          }}
        />
      </div>
    </Modal>
  );
};
export default observer(ModalChangeCompany);
