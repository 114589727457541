import Colors from '@/utils/colors';

const QualitySurvivalsurveysIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2056_9)">
        <rect
          className="path-1"
          width="24"
          height="24"
          rx="5"
          fill={Colors.primary[400]}
        />
        <path
          className="path-2-stroke"
          d="M7.69231 12C7.69231 7.69229 12 3.0769 12 3.0769C12 3.0769 16.3077 7.69229 16.3077 12C16.3077 15.0769 14.3791 17.5384 12 17.5384C9.62092 17.5384 7.69231 15.0769 7.69231 12Z"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          className="path-2-stroke"
          d="M12 21.2307L12 9.23071"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          className="path-2-stroke"
          d="M17.2308 7.43236C17.9386 6.9803 18.6791 6.58166 19.4462 6.23975C19.4462 6.23975 21.556 11.2444 20.1086 14.8016C19.1935 17.0496 17.1938 18.3927 15.3846 18.1185"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          className="path-2-stroke"
          d="M6.76923 7.43236C6.06144 6.9803 5.32093 6.58166 4.55385 6.23975C4.55385 6.23975 2.44462 11.2444 3.892 14.8016C4.80646 17.0496 6.80615 18.3927 8.61539 18.1185"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          className="path-2-stroke"
          d="M12 14.7692L14.1538 12.9231"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
        <path
          className="path-2-stroke"
          d="M12 12.9231L9.84615 11.0769"
          stroke={Colors.primary[200]}
          strokeWidth="1.5"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2056_9">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default QualitySurvivalsurveysIcon;
