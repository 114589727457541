/* eslint-disable */
import { useState } from 'react';
import {
  FileUploadedKmlInfosType,
  ForestRegistryModalImportKMLControllerType,
  StatusUploadKMLStateType,
} from './modal-import-kml-types';
import * as tj from '@mapbox/togeojson';
import rewind from '@mapbox/geojson-rewind';
import { Toast } from '@/component/Toast';
import { ColumnsStateType } from './select-columns/types';
import { forestRegistryStore, userStore } from '@/store';
import {
  initalStateColumns,
  initialStateFileInfos,
  inititalStateStatusUploadKML,
} from '../../forest-registry-initial-states';
import { validKml, updateListCoordinates } from '../../forest-registry-services';
import { useTranslation } from 'react-i18next';

const { showError, showSuccess } = Toast;
const ForestRegistryModalImportKMLController =
  (): ForestRegistryModalImportKMLControllerType => {
    const tForestRegistry = useTranslation('forestRegistry').t;
    const { handleModalState, handleModalLoading } = forestRegistryStore;
    const [fileUpload, setFileUpload] = useState<File>();
    const [fileInfos, setFileInfos] =
      useState<FileUploadedKmlInfosType>(initialStateFileInfos);
    const [isValiding, setIsValiding] = useState(false);
    const [statusUploadKML, setStatusUploadKML] = useState<StatusUploadKMLStateType>(
      inititalStateStatusUploadKML,
    );
    const [layer, setLayer] = useState(null);
    const [columnsOfKML, setColumnsOfKML] = useState<string[]>([]);
    const [columns, setColumns] = useState<ColumnsStateType>(initalStateColumns);

    function handleClose() {
      handleModalState('uploadKML', false);
      handleClearForm();
    }
    function handleFileSelection(file: File) {
      handleModalLoading();
      setFileUpload(file);
      const name = file.name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      const reader = new FileReader();

      // on load file end, parse the text read
      reader.onloadend = function (event) {
        const text = event.target?.result;
        if (ext === 'kml' || ext === 'kmz') {
          const dom = new DOMParser().parseFromString(String(text), 'text/xml'); // create xml dom object
          const converted = tj.kml(dom); // convert xml dom to geojson
          rewind(converted, false); // correct right hand rule
          setLayer(converted); // save converted geojson to hook state

          // Extrair as colunas do KML
          const columnsArray: string[] = [];
          converted.features.forEach((feature: { properties: any }) => {
            const properties = feature?.properties;
            Object.keys(properties).forEach((key) => {
              if (!columnsArray.includes(key)) {
                columnsArray.push(key);
              }
            });
          });

          // Verificar se foram encontradas colunas
          if (columnsArray.length === 0) {
            showError({
              message: tForestRegistry('modals.uploadKML.emptyColumnsFound'),
            });
            handleModalLoading();
            return;
          }

          // Definir as colunas do KML
          setColumnsOfKML(columnsArray);
        } else {
          // Arquivo GeoJSON
          const json = JSON.parse(String(text));
          rewind(json, false);

          // Extrair as colunas do GeoJSON
          const columnsArray: string[] = [];
          json.features.forEach((feature: { properties: any }) => {
            const properties = feature?.properties;
            Object.keys(properties).forEach((key) => {
              if (!columnsArray.includes(key)) {
                columnsArray.push(key);
              }
            });
          });

          // Verificar se foram encontradas colunas
          if (columnsArray.length === 0) {
            showError({
              message: tForestRegistry('modals.uploadKML.emptyColumnsFound'),
            });
            handleModalLoading();
            return;
          }

          // Definir as colunas do GeoJSON
          setColumnsOfKML(columnsArray);
          setLayer(json);
        }
        handleModalLoading();
      };

      reader.readAsText(file); // start reading file
    }

    function handleClearForm() {
      setFileUpload(undefined);
      setLayer(null);
      setFileInfos(initialStateFileInfos);
      setStatusUploadKML(inititalStateStatusUploadKML);
      setColumns(initalStateColumns);
    }
    function handleBackSelectColumns() {
      setFileInfos(initialStateFileInfos);
      setStatusUploadKML(inititalStateStatusUploadKML);
    }

    async function validFile() {
      setIsValiding(true);
      try {
        if (fileUpload === undefined || !columns.fazenda || !columns.talhao) {
          setIsValiding(false);
          return;
        }
        const response = await validKml(fileUpload, columns, userStore.companyActive.id);
        setFileInfos(response.data);
        setIsValiding(false);
      } catch (error: any) {
        console.error(error.message);
        setIsValiding(false);
      }
    }

    const batchSize = 1000;

    const handleSubmit = async () => {
      handleModalLoading();
      try {
        const polygonsCreate = fileInfos.createPolygons;
        const totalPolygonsCreate = polygonsCreate.length || 0;
        let polygonsCreateProcessed = 0;
        setStatusUploadKML({
          ...statusUploadKML,
          create: tForestRegistry('modals.uploadKML.statusProcces', {
            processed: polygonsCreateProcessed + batchSize,
            totalItems: totalPolygonsCreate,
          }),
          update: tForestRegistry('modals.uploadKML.statusProcces', {
            processed: 0,
            totalItems: fileInfos.updatePolygons.length,
          }),
        });
        while (polygonsCreateProcessed < totalPolygonsCreate) {
          const polygonsBatch =
            polygonsCreate.slice(
              polygonsCreateProcessed,
              polygonsCreateProcessed + batchSize,
            ) || [];

          await updateListCoordinates({
            companyId: userStore.companyActive.id,
            coordinates: polygonsBatch.map((c) => {
              return {
                ...c,
                coordinates: c.coordinates.map((pol) => {
                  return {
                    coordinates: pol.map((latlong) => {
                      const [lat, lng] = latlong;
                      return { lat: Number(lat), lng: Number(lng) };
                    }),
                  };
                }),
              };
            }),
          });

          setStatusUploadKML({
            ...statusUploadKML,
            create: tForestRegistry('modals.uploadKML.statusProcces', {
              processed: polygonsCreateProcessed + batchSize,
              totalItems: totalPolygonsCreate,
            }),
          });
          polygonsCreateProcessed += batchSize;
        }
        const polygonsUpdated = fileInfos.updatePolygons;
        const totalPolygonUpdated = polygonsUpdated.length || 0;
        let polygonsUpdateProcessed = 0;
        while (polygonsUpdateProcessed < totalPolygonUpdated) {
          const polygonsBatch =
            polygonsUpdated.slice(
              polygonsUpdateProcessed,
              polygonsUpdateProcessed + batchSize,
            ) || [];
          await updateListCoordinates({
            companyId: userStore.companyActive.id,
            coordinates: polygonsBatch.map((c) => {
              return {
                ...c,
                coordinates: c.coordinates.map((pol) => {
                  return {
                    coordinates: pol.map((latlong) => {
                      const [lat, lng] = latlong;
                      return { lat: Number(lat), lng: Number(lng) };
                    }),
                  };
                }),
              };
            }),
          });
          setStatusUploadKML({
            ...statusUploadKML,
            update: tForestRegistry('modals.uploadKML.statusProcces', {
              processed: polygonsUpdateProcessed + batchSize,
              totalItems: totalPolygonUpdated,
            }),
          });

          polygonsUpdateProcessed += batchSize;
        }
        showSuccess({ message: tForestRegistry('modals.uploadKML.successallPolygons') });
        handleClose();
        handleModalLoading();
      } catch (error: any) {
        console.error(error.message);
        handleModalLoading();
      }
    };

    return {
      fileUpload,
      setFileUpload,
      fileInfos,
      isValiding,
      layer,
      handleClose,
      handleFileSelection,
      handleSubmit,
      columns,
      setColumns,
      columnsOfKML,
      validFile,
      handleClearForm,
      statusUploadKML,
      setStatusUploadKML,
      handleBackSelectColumns,
    };
  };
export default ForestRegistryModalImportKMLController;
