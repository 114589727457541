import SecondaryButton from '@/component/buttons/SecondaryButton';
import React from 'react';
import { BsUpload } from 'react-icons/bs';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { forestRegistryStore } from '@/store';
import { observer } from 'mobx-react';
import { checkPermission } from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';

const ButtonDropdownImport = () => {
  const { handleModalState } = forestRegistryStore;
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const tCommon = useTranslation('common').t;
  const tForestRegistry = useTranslation('forestRegistry').t;
  if (
    !checkPermission({
      service: 'forestRegistry',
      permission: 'permissionInsert',
    })
  ) {
    return null;
  }
  return (
    <div>
      <SecondaryButton
        text={tCommon('actions.import')}
        icon={BsUpload}
        onClick={handleClick}
        wFull
      />
      <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        offset={1.5}
        placement="bottom"
        style={{ zIndex: 2 }}
      >
        <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg">
          <ul className="">
            <li
              className={`py-2 px-2 dark:text-gray-200 hover:bg-bgray-200 dark:hover:bg-darkblack-400`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAnchor(null);
                handleModalState('upload', true);
              }}
            >
              {tForestRegistry('body.table.header.importOptions.plotsExcel')}
            </li>
            <li
              className={`py-2 px-2 dark:text-gray-200 hover:bg-bgray-200  dark:hover:bg-darkblack-400`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setAnchor(null);
                handleModalState('uploadKML', true);
              }}
            >
              {tForestRegistry('body.table.header.importOptions.gisKml')}
            </li>
          </ul>
        </div>
      </BasePopup>
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        style={{ zIndex: 1 }}
        className={`fixed left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
      ></div>
    </div>
  );
};

export default observer(ButtonDropdownImport);
