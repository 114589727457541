import { useState } from 'react';
import { ServicesParamsControllerTypes } from './services-params-types';
import servicesParams from './services-params-list/data';
import SurvivalSurveysCausesTableController from './services-params-content/quality/silviculture/survival-surveys/survival-surveys-causes/survival-surveys-causes-controller';
import SurvivalSurveysTimesTableController from './services-params-content/quality/silviculture/survival-surveys/survival-surveys-times/survival-surveys-times-controller';
import PlantedAreaReasonsTableController from './services-params-content/control/silvilculture/planted-area/planted-area-reasons/controller';
import PlantedAreaGeneticMaterialsTableController from './services-params-content/control/silvilculture/planted-area/planted-area-genetic-material/controller';

const servicesParamsController = (): ServicesParamsControllerTypes => {
  const [activeParam, setActiveParam] = useState(servicesParams()[0]?.params[0]?.param);
  const CausesController = SurvivalSurveysCausesTableController();
  const TimesController = SurvivalSurveysTimesTableController();
  const ReasonsController = PlantedAreaReasonsTableController();
  const GeneticMaterialsController = PlantedAreaGeneticMaterialsTableController();
  return {
    activeParam,
    setActiveParam,
    CausesController,
    TimesController,
    ReasonsController,
    GeneticMaterialsController,
  };
};
export default servicesParamsController;
