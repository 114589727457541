import Colors from '@/utils/colors';

const PlotsPlantedsIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6666 13.9997C25.6666 7.55635 20.4432 2.33301 13.9999 2.33301C7.55659 2.33301 2.33325 7.55635 2.33325 13.9997C2.33325 20.4429 7.55659 25.6663 13.9999 25.6663C20.4432 25.6663 25.6666 20.4429 25.6666 13.9997Z"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
      />
      <path
        d="M9.33325 14.8747C9.33325 14.8747 11.1999 15.9393 12.1333 17.4997C12.1333 17.4997 14.9333 11.3747 18.6666 9.33301"
        stroke={Colors.primary[200]}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlotsPlantedsIcon;
