import logo from '@/assets/images/logo/logo-color.svg';
import { translationI18n } from '@/i18n';
import { userStore } from '@/store';
const HeaderExport = () => {
  const { companyActive } = userStore;
  return (
    <div className="w-full hidden p-3 items-center relative" id="header-export">
      <div className="w-[150px] h-[50px] bg-success-100">
        <img
          src={logo}
          className="w-full h-full object-contain"
          alt={companyActive.name}
        />
      </div>
      <div className=" flex-1 flex items-center justify-center">
        <p className="text-2xl text-center">
          {translationI18n('home:survivalSurveys.headerExportTitle')}
        </p>
      </div>
      <div className="w-[150px] h-[50px] bg-success-100">
        <img
          src={companyActive.imageUrl}
          className="w-full h-full object-contain"
          alt={companyActive.name}
        />
      </div>
    </div>
  );
};

export default HeaderExport;
