import TermsOfUse from '@/pages/public/terms-of-use';
import SignIn from '../pages/public/signin';
import { Navigate } from 'react-router';
import { Privacy } from '@/pages/public/terms-of-privacy';

const publicRoutes = [
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/signin',
    element: <SignIn />,
  },
  {
    path: '/termsofuse',
    element: <TermsOfUse />,
  },
  {
    path: '/termsofuse/:newLanguage',
    element: <TermsOfUse />,
  },
  {
    path: '/privacy',
    element: <Privacy />,
  },
  {
    path: '/privacy/:newLanguage',
    element: <Privacy />,
  },
  {
    path: '*',
    element: <Navigate to={'/signin'} />,
  },
];
export default publicRoutes;
