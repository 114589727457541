/* eslint-disable */
import { DeathCauseType } from '@/pages/private/quality/silviculture/survival-surveys/quality-survival-surveys-types';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { optionClasses } from '@mui/base/Option';
import { OptionGroupProps } from '@mui/base/OptionGroup';
import * as React from 'react';
import { Select as BaseSelect, SelectProps, SelectRootSlotProps } from '@mui/base/Select';
import { Option as BaseOption } from '@mui/base/Option';
import { OptionGroup as BaseOptionGroup } from '@mui/base/OptionGroup';
import { styled } from '@mui/system';
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded';
import Colors from '@/utils/colors';
import { checkPermission } from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';
const SelectChangeCause = observer(
  ({
    editable,
    handleEditable,
  }: {
    editable: boolean;
    deathCause: DeathCauseType;
    handleEditable: () => void;
  }) => {
    const {
      deathCausesList,
      loading: loadingGlobal,
      deathCauseUpdate,
      handleDeathCauseUpdate,
    } = survivalSurveysEvaluationsStore;
    const { modalChangeCause: loading } = loadingGlobal;
    const tSurvivalSurveys = useTranslation('survivalSurveys').t;
    const tCommon = useTranslation('common').t;
    if (!editable) {
      return (
        <div className="w-full flex items-center dark:text-bgray-200  border rounded px-4 py-2">
          <div className="flex-1">
            <div className="w-full space-x-1 items-center flex">
              <div
                className={`w-6 h-5`}
                style={{ backgroundColor: deathCauseUpdate.color }}
              ></div>
              <div>{deathCauseUpdate.cause}</div>
            </div>
            <div className="text-sm">{deathCauseUpdate.description}</div>
          </div>
          {checkPermission({
            permission: 'permissionUpdate',
            service: 'survivalSurveys',
          }) && (
            <button className="px-3 underline cursor-pointer" onClick={handleEditable}>
              {tCommon('actions.change')}
            </button>
          )}
        </div>
      );
    }
    const Button = React.forwardRef(function Button<
      TValue extends {},
      Multiple extends boolean,
    >(
      props: SelectRootSlotProps<TValue, Multiple>,
      ref: React.ForwardedRef<HTMLButtonElement>,
    ) {
      const { ownerState, ...other } = props;
      return (
        <button
          type="button"
          {...other}
          style={{ width: '100%' }}
          ref={ref}
          className="w-full flex items-center"
        >
          <div className="flex-1">
            <div className="mx-4 w-full space-x-1 items-center flex">
              <div
                className={`w-6 h-5`}
                style={{ backgroundColor: deathCauseUpdate?.color }}
              ></div>
              <div>{deathCauseUpdate?.cause}</div>
            </div>
            <div className="mx-4 text-sm text-wrap text-left">
              {deathCauseUpdate?.description}
            </div>
          </div>
          <div className="px-4">
            <UnfoldMoreRoundedIcon />
          </div>
        </button>
      );
    });

    function Select(props: SelectProps<string, false>) {
      const slots: SelectProps<string, false>['slots'] = {
        root: Button,
        listbox: Listbox,
        popup: Popup,
        ...props.slots,
      };

      return <BaseSelect {...props} slots={slots} />;
    }

    return (
      <div className="w-full flex">
        <div className="w-full flex items-center dark:text-bgray-200  border rounded py-2 z-50 relative">
          <Select value={deathCauseUpdate.id} disabled={loading}>
            {deathCausesList.map((originGroup) => {
              return (
                <OptionGroup
                  label={originGroup.origin}
                  className="bg-white dark:bg-darkblack-500"
                >
                  {originGroup.causes.map((cause) => {
                    return (
                      <Option
                        value={cause.id}
                        onClick={() => handleDeathCauseUpdate(cause)}
                      >
                        <div
                          className={`w-full cursor-pointer flex items-center ${cause.id === deathCauseUpdate.id ? 'bg-bgray-200 dark:bg-darkblack-500' : ''} dark:text-bgray-200  border rounded px-4 py-2`}
                        >
                          <div className="flex-1">
                            <div className="w-full space-x-1 items-center flex">
                              <div
                                className={`w-6 h-5`}
                                style={{ backgroundColor: cause.color }}
                              ></div>
                              <div>{cause.cause}</div>
                            </div>
                            <div className="text-sm text-wrap text-left">
                              {cause.description}
                            </div>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </OptionGroup>
              );
            })}
          </Select>
        </div>
      </div>
    );
  },
);

export default SelectChangeCause;

const OptionGroup = React.forwardRef(function CustomOptionGroup(
  props: OptionGroupProps,
  ref: React.ForwardedRef<any>,
) {
  const slots: OptionGroupProps['slots'] = {
    root: GroupRoot,
    label: GroupHeader,
    list: GroupOptions,
    ...props.slots,
  };

  return <BaseOptionGroup {...props} ref={ref} slots={slots} />;
});

const Listbox = styled('ul')`
  max-height: 300px;
  max-width: 532px;
  overflow: auto;
  margin-top: 1rem;
`;

const Option = styled(BaseOption)`
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  &:last-of-type {
    border-bottom: none;
  }
`;

const GroupRoot = styled('li')`
  list-style: none;
`;

const GroupHeader = styled('span')`
  display: block;
  padding: 15px 0 5px 10px;
  font-size: 0.75em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${Colors.bgray[500]};
`;

const GroupOptions = styled('ul')`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const Popup = styled('div')`
  z-index: 9999;
`;
