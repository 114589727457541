import { ButtonSubmitType } from './types';
import { ScaleLoader } from 'react-spinners';

const ButtonSubmit = ({
  disabled = false,
  loading,
  title,
  wFull = false,
  ...restProps
}: ButtonSubmitType) => {
  return (
    <button
      {...restProps}
      aria-label="none"
      type="submit"
      disabled={disabled}
      id="step-1-next"
      className={`flex ${wFull ? 'w-full' : ''} ${
        disabled ? 'opacity-60' : loading ? 'opacity-80' : ''
      } py-2 min-w-[132px] items-center text-white bg-primary-200 hover:bg-primary-300 transition-all justify-center relative h-[40px] px-[30px] text-sm font-medium rounded-lg`}
    >
      <div className={`w-full h-full flex items-center justify-center absolute`}>
        <ScaleLoader
          loading={loading}
          color={'white'}
          height={18}
          className={`transition-opacity duration-200 ${
            loading ? 'opacity-100' : 'opacity-0'
          }`}
        />
      </div>
      <span className={`${loading ? 'invisible' : 'visible'}`}>{title}</span>
    </button>
  );
};
export default ButtonSubmit;
