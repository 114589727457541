import FilterIcon from '@/assets/images/icons/FilterIcon';
import { Collapse } from '@mui/material';
import { useEffect, useState } from 'react';
import { dashboardSurvivalSurveysStore, userStore } from '@/store';
import Select from '@/component/forms/Select';
import { observer } from 'mobx-react';
import { DashboardFiltersForestRegistryRequestType } from '../../dashboard-forest-registry/dashboard-forest-registry-types';
import Input from '@/component/forms/Input';
import { BsDownload } from 'react-icons/bs';
import { exportAsImage } from '@/utils/downloadImage';
import moment from 'moment';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import { DashboardFiltersSurvivalSurveysType } from '../dashboard-survival-surveys-types';
import { useTranslation } from 'react-i18next';

export const SurvivalSurveysFilters = observer(() => {
  const [loadingExport, setLoadingExport] = useState(false);
  const {
    loading,
    filterSurvivalSurveys,
    filtersSurvivalSurveys,
    handleFiltersSurvivalSurveys,
    handleClearFiltersSurvivalSurveys,
    getFiltersSurvivalSurveys,
    survivalSurveysDataCause,
  } = dashboardSurvivalSurveysStore;
  const tHome = useTranslation('home').t;
  const tCommon = useTranslation('common').t;
  const { data: loadingItems } = loading;
  const [filterOpen, setFilterOpen] = useState(false);
  useEffect(() => {
    getFiltersSurvivalSurveys();
  }, [filterSurvivalSurveys, userStore.companyActive.id]);
  async function handleExport() {
    setLoadingExport(true);
    try {
      const cardGraph = document.getElementById('card_graph');
      if (cardGraph !== null) {
        await exportAsImage(
          cardGraph,
          `${tHome('survivalSurveys.fileNameDownload')} ${moment().format('DD-MM-YY___HH-mm-ss')}`,
        );
      }
      setLoadingExport(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingExport(false);
    }
  }
  const [dateStartState, setDateStartState] = useState(null);
  const [dateEndState, setDateEndState] = useState(null);
  return (
    <div className=" shadow-lg rounded-lg px-3 mb-3 bg-white items-center h-full flex-col dark:bg-darkblack-400 flex-1 py-3">
      <div className="sm:flex xs:flex-col gap-2">
        <div className="flex h-[43px] w-full">
          <div>
            <p className="font-semibold text-xl dark:text-bgray-200">
              {tHome('survivalSurveys.filters.title')}
            </p>
            <p className="font-light text-bgray-600 text-sm">
              {tHome('survivalSurveys.filters.subtitle')}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end">
          <button
            className="text-sm text-nowrap text-bgray-700 dark:text-bgray-200"
            onClick={handleClearFiltersSurvivalSurveys}
          >
            {tHome('survivalSurveys.filters.clearFilters')}
          </button>
          <button
            className="flex items-center gap-1 px-3 bg-primary-100 rounded-md h-[40px] text-success-200 bg-opacity-20 border border-success-200"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <FilterIcon />
            {tHome('survivalSurveys.filters.filters')}
          </button>
          <SecondaryButton
            text={tCommon('actions.export')}
            loading={loadingExport}
            disabled={
              loading.filters ||
              loading.causes ||
              loading.filtersMap ||
              survivalSurveysDataCause.length === 0
            }
            icon={BsDownload}
            onClick={handleExport}
          />
        </div>
      </div>
      <Collapse in={filterOpen} timeout="auto" unmountOnExit>
        <div
          className="
            grid
            sm:grid-cols-2
            md:grid-cols-3 
            lg:grid-cols-4 
            xl:grid-cols-4 
            2xl:grid-cols-4
            3xl:grid-cols-4 
            gap-x-3"
        >
          {filtersSurvivalSurveys &&
            filterSurvivalSurveys &&
            Object.keys(filtersSurvivalSurveys).map((chave, index) => {
              const options =
                filtersSurvivalSurveys[
                  chave as keyof DashboardFiltersSurvivalSurveysType
                ];
              let label;
              switch (chave) {
                case 'regiao':
                  label = tHome('survivalSurveys.filters.labels.regiao');
                  break;
                case 'fazenda':
                  label = tHome('survivalSurveys.filters.labels.fazenda');
                  break;
                case 'talhao':
                  label = tHome('survivalSurveys.filters.labels.talhao');
                  break;
                case 'manejo':
                  label = tHome('survivalSurveys.filters.labels.manejo');
                  break;
                case 'time':
                  label = tHome('survivalSurveys.filters.labels.time');
                  break;
                case 'approved':
                  label = tHome('survivalSurveys.filters.labels.approved');
                  break;
              }
              if (!options?.length) {
                return (
                  <Select
                    key={index}
                    loading={loadingItems}
                    multiselect={true}
                    label={label}
                    value={
                      filterSurvivalSurveys[
                        chave as keyof DashboardFiltersForestRegistryRequestType
                      ]
                    }
                    data={options}
                    onChange={function (value): void {
                      handleFiltersSurvivalSurveys(
                        chave as keyof DashboardFiltersForestRegistryRequestType,
                        value as string[],
                      );
                    }}
                  />
                );
              }
              return (
                <Select
                  key={index}
                  loading={loadingItems}
                  multiselect={true}
                  label={options[0]?.title}
                  value={
                    filterSurvivalSurveys[
                      chave as keyof DashboardFiltersForestRegistryRequestType
                    ]
                  }
                  data={options}
                  onChange={function (value): void {
                    handleFiltersSurvivalSurveys(
                      chave as keyof DashboardFiltersForestRegistryRequestType,
                      value as string[],
                    );
                  }}
                />
              );
            })}
          <Input
            value={dateStartState}
            type={'date'}
            label={tHome('survivalSurveys.filters.labels.dateStart')}
            onChange={(date) => {
              setDateStartState(date);
              const newDate = moment(date);
              if (newDate.isValid() || date === null) {
                handleFiltersSurvivalSurveys('dateStart', date);
              }
            }}
          />
          <Input
            value={dateEndState}
            type={'date'}
            label={tHome('survivalSurveys.filters.labels.dateEnd')}
            onChange={(date) => {
              console.log(date);
              setDateEndState(date);
              const newDate = moment(date, true);
              if (newDate.isValid() || date === null) {
                handleFiltersSurvivalSurveys('dateEnd', date);
              }
            }}
          />
        </div>
      </Collapse>
    </div>
  );
});
