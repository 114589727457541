import Theme from '../../../../tailwind.config';
const { colors } = Theme.theme.extend;
const SurvivalSurveysIcon = ({ alert = false }: { alert: boolean }) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0622 15.5201C14.0622 15.5201 12.8038 17.4647 10.7515 17.8238C9.17496 18.1 7.35059 16.0761 7.35059 16.0761C7.35059 16.0761 8.58014 14.7022 10.1567 14.426C12.0084 14.1021 14.0622 15.5201 14.0622 15.5201Z"
        stroke={alert ? colors.error[200] : colors.primary[200]}
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M3.1499 11.3309C3.1499 11.3309 4.57843 10.1418 6.23638 10.3565C7.51003 10.5214 8.42458 12.5074 8.42458 12.5074C8.42458 12.5074 7.1546 13.2666 5.88043 13.1012C4.3847 12.908 3.1499 11.3309 3.1499 11.3309Z"
        stroke={alert ? colors.error[200] : colors.primary[200]}
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.1555 9.97565C10.1555 9.97565 8.79158 8.49252 8.93543 6.68547C9.04621 5.29737 11.1473 4.20117 11.1473 4.20117C11.1473 4.20117 12.0371 5.53572 11.9264 6.92382C11.7972 8.55447 10.1555 9.97565 10.1555 9.97565Z"
        stroke={alert ? colors.error[200] : colors.primary[200]}
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.3074 9.32178C14.3074 9.32178 13.1173 11.2937 13.69 13.285C14.13 14.8149 16.7335 15.5646 16.7335 15.5646C16.7335 15.5646 17.4265 13.8667 16.9865 12.3374C16.4694 10.5403 14.3074 9.32178 14.3074 9.32178Z"
        stroke={alert ? colors.error[200] : colors.primary[200]}
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17.8503 15.7498C11.0253 15.7498 6.30029 10.4998 6.30029 3.6748"
        stroke={alert ? colors.error[200] : colors.primary[200]}
        strokeWidth="1.26"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};
export default SurvivalSurveysIcon;
