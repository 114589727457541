import { survivalSurveysEvaluationType } from '../../quality-survival-surveys-types';
import ReportPdfItemHeader from './Header';
import HeaderInfos from './HeaderInfos';
import ReportPdfItemResults from './ReportPdfItemResults';

const ReportPdfItem = ({ evaluation }: { evaluation: survivalSurveysEvaluationType }) => {
  return (
    <div className="bg-bgray-50 p-5 rounded-lg report-item page-break">
      <ReportPdfItemHeader evaluation={evaluation} />
      <HeaderInfos evaluation={evaluation} />
      <ReportPdfItemResults evaluation={evaluation} />
    </div>
  );
};

export default ReportPdfItem;
