import { IconType } from 'react-icons';
import { ScaleLoader } from 'react-spinners';

interface DangerButtonProps {
  icon?: IconType;
  text: string;
  onClick: () => void;
  wFull?: boolean;
  loading?: boolean;
}
const DangerButton = ({
  icon: Icon,
  text,
  onClick = () => {},
  wFull = false,
  loading,
}: DangerButtonProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`flex px ${
        wFull ? 'w-full' : ''
      } gap-2 items-center text-sm bg-error-200  h-[40px] hover:bg-error-300 transition-all text-white py-2 px-[30px] font-bold rounded-lg`}
    >
      <ScaleLoader loading={loading} color={'white'} height={18} />
      {!loading && Icon !== undefined ? <Icon size={20} /> : null}
      {!loading && text}
    </button>
  );
};

export default DangerButton;
