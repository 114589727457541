import { IconType } from 'react-icons';
import { ScaleLoader } from 'react-spinners';

interface SmallSecondaryButtonProps {
  icon?: IconType;
  text: string;
  onClick: () => void;
  wFull?: boolean;
  loading?: boolean;
}
const SmallSecondaryButton = ({
  icon: Icon,
  text,
  onClick = () => {},
  loading = false,
  wFull = false,
}: SmallSecondaryButtonProps) => {
  return (
    <button
      onClick={onClick}
      type="button"
      style={{
        textWrap: 'nowrap',
      }}
      className={`flex  ${
        wFull ? 'w-full' : ''
      } px gap-2 text-xs items-center bg-bgray-200 dark:bg-bgray-50 h-[30px] hover:bg-bgray-400 transition-all text-black py-2 px-[30px] font-regular rounded-lg`}
    >
      {loading && (
        <ScaleLoader loading={loading} height={10} className="px-4 fill-bgray-300" />
      )}
      {Icon !== undefined && !loading ? <Icon size={25} /> : null}
      {!loading && text}
    </button>
  );
};

export default SmallSecondaryButton;
