import { forestRegistryStore, userStore } from '@/store';
import CardLoader from './CardLoader';
import { formatNumber } from '@/utils/helpers/formaters';
import EChartComponent from '@/component/Echart';
import Colors from '@/utils/colors';
import { observer } from 'mobx-react';
import { ComposeOption, PieSeriesOption } from 'echarts';
import { CallbackDataParams, TooltipOption } from 'echarts/types/dist/shared';
import { useTranslation } from 'react-i18next';

const CardGraph = observer(() => {
  const { theme } = userStore;
  const { loadingSummaryData, summary } = forestRegistryStore;
  const tForestRegistry = useTranslation('forestRegistry').t;
  if (loadingSummaryData) {
    return <CardLoader />;
  }
  const availableAreaPercentage = (summary.availableArea / summary.totalArea) * 100;
  const plantedAreaPercentage = (summary.plantedArea / summary.totalArea) * 100;
  const data = [
    {
      name: tForestRegistry('header.cardGraph.planted'),
      value: plantedAreaPercentage,
      itemStyle: { color: Colors.warning[200] },
      emphasis: {
        scale: 1.1, // Ajuste o valor para tornar o segmento maior ao ser destacado
      },
    },
    {
      name: tForestRegistry('header.cardGraph.avaliable'),
      value: availableAreaPercentage,
      itemStyle: {
        color: Colors.primary[200],
      },
    },
  ];

  // Opções do gráfico ECharts
  const options: ComposeOption<PieSeriesOption | TooltipOption> = {
    backgroundColor: 'transparent',
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: (params: CallbackDataParams) => {
        return `${params.name}: ${formatNumber(params.value, 2)}%`;
      },
    },
    series: [
      {
        radius: ['40%', '80%'],
        center: ['50%', '50%'],
        type: 'pie',
        startAngle: 0,
        data: data,
        label: {
          show: false,
          formatter: ({ value }) => {
            const percentage =
              (value / data.reduce((acc, cur) => acc + cur.value, 0)) * 100;
            return `${formatNumber(percentage, 0)}%`;
          },
          position: 'inner',
          color: theme === 'dark' ? 'white' : Colors.darkblack[600],
        },
        emphasis: { disabled: false, scaleSize: 4, scale: 3 },
        itemStyle: {
          borderColor: '#fff',
          borderWidth: 1,
          gapWidth: 1,
        },
      },
    ],
    animation: false,
  };
  return (
    <div className="bg-white rounded-lg p-3 flex flex-[0.4] dark:bg-darkblack-500 shadow-lg">
      <div className="flex-[0.8] relative">
        <p className="dark:text-bgray-200">
          {tForestRegistry('header.cardGraph.graphOfUse')}
        </p>
        <div className="flex gap-5 items-center flex-1 pt-3">
          <div className="items-center text-center">
            <p className="text-5xl font-bold dark:text-bgray-200">
              {formatNumber(availableAreaPercentage, 0)}%
            </p>
            <div className="flex items-center mt-[-10px] gap-1">
              <div className="h-[12px] w-[12px] rounded-[20px] bg-primary-200"></div>
              <p className="dark:text-bgray-200 text-base">
                {tForestRegistry('header.cardGraph.avaliable')}
              </p>
            </div>
          </div>
          <div className="items-center text-center">
            <p className="text-5xl font-bold dark:text-bgray-200">
              {formatNumber(plantedAreaPercentage, 0)}%
            </p>
            <div className="flex items-center mt-[-10px] gap-1 ">
              <div className="h-[12px] w-[12px] rounded-[20px] bg-warning-200"></div>
              <p className="dark:text-bgray-200 text-base">
                {tForestRegistry('header.cardGraph.planted')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="items-center flex-1">
        <EChartComponent option={options} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
});

export default CardGraph;
