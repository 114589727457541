import Table from '@/component/Table';
import { ColumnsTypes } from '@/component/Table/types';
import { translationI18n } from '@/i18n';
import { servicesParamsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalEditPlantedAreaParams } from './modal-planted-area-edit-params';

export const PlantedAreaParamsTable = observer(() => {
  const {
    getParamsPlantedArea,
    plantedArea,
    handleModalState,
    loading,
    modalState: modalStateParams,
  } = servicesParamsStore;

  const tCompanyProfile = useTranslation('companyProfile').t;

  useEffect(() => {
    getParamsPlantedArea(userStore.companyActive.id);
  }, [userStore.companyActive.id]);
  const data = [
    {
      id: 'density',
      param: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.paramsName.densityPlant',
      ),
      minError: plantedArea.targetPlantingDensityMinError,
      minAlert: plantedArea.targetPlantingDensityMinAlert,
      target: plantedArea.targetPlantingDensity,
      maxAlert: plantedArea.targetPlantingDensityMaxAlert,
      maxError: plantedArea.targetPlantingDensityMaxError,
    },
    {
      id: 'discarded',
      param: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.paramsName.discardedPlants',
      ),
      minError: plantedArea.targetDiscardedPlantsMinError,
      minAlert: plantedArea.targetDiscardedPlantsMinAlert,
      target: plantedArea.targetDiscardedPlants,
      maxAlert: plantedArea.targetDiscardedPlantsMaxAlert,
      maxError: plantedArea.targetDiscardedPlantsMaxError,
    },
  ];
  const columns: ColumnsTypes[] = [
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.param',
      ),
      type: 'string',
      field: 'param',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.minError',
      ),
      type: 'int',
      field: 'minError',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.minAlert',
      ),
      type: 'int',
      field: 'minAlert',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.target',
      ),
      type: 'int',
      field: 'target',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.maxAlert',
      ),
      type: 'int',
      field: 'maxAlert',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.maxError',
      ),
      type: 'int',
      field: 'maxError',
      ordenable: false,
    },
    {
      title: tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.columns.actions',
      ),
      field: 'options',
      type: 'options',
    },
  ];
  const options = (param: any) => [
    {
      label: translationI18n('common:actions.edit'),
      action: (row: any) => {
        console.log(row);
        handleModalState({ ...modalStateParams, plantedArea: param.id });
      },
    },
  ];
  return (
    <div className="bg-white w-[100%] h-[100%] px-4 pt-5 mb-[40px]  rounded-lg dark:bg-darkblack-600 shadow-lg">
      <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
        {tCompanyProfile('body.screens.params.name')}
      </h1>
      <Table
        options={options}
        columns={columns}
        page={1}
        showPagination={false}
        size={10}
        totalPages={1}
        data={!loading.plantedArea ? data : []}
        showSearch={false}
        setPage={() => {}}
        setSize={() => {}}
        loading={loading.plantedArea}
        order={{ column: 'param', direction: 'asc' }}
        setOrder={() => {}}
      />
      <ModalEditPlantedAreaParams />
    </div>
  );
});
