import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useFetch } from '@/hooks';
import { userStore } from '@/store';
import { FormNewPassordType } from './left-side-new-password-types';
import {
  passwordConfirmationValidation,
  passwordValidation,
} from '@/utils/helpers/validations';

const NewPassordController = () => {
  const { loading: loadingNewPassword, request: requestNewPassword } = useFetch(
    userStore.newPassword,
  );

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: userStore.user.email,
      confirmPassword: '',
      newPassword: '',
    },

    validationSchema: Yup.object({
      newPassword: passwordValidation(),
      confirmPassword: passwordConfirmationValidation(),
    }),

    onSubmit: async (values: FormNewPassordType) => {
      await handleChangePassword(values);
    },
  });

  const handleChangePassword = async (values: FormNewPassordType) => {
    await requestNewPassword(values);
  };

  return {
    handleChangePassword,
    validation,
    loadingNewPassword,
  };
};
export default NewPassordController;
