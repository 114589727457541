import Input from '@/component/forms/Input';
import Modal from '@/component/modals/modal-default';
import {
  AddControlSilviculturePlantedAreaGeneticMaterialDataType,
  ModalStateType,
} from '../types';
import { FormikContextType } from 'formik';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import { translationI18n } from '@/i18n';

const ModalAddPlantedAreaGeneticMaterial = ({
  validation,
  modalState,
  setModalState,
}: {
  validation: FormikContextType<AddControlSilviculturePlantedAreaGeneticMaterialDataType>;
  modalState: ModalStateType;

  setModalState: (modalState: ModalStateType) => void;
}) => {
  const { geneticMaterial } = modalState;
  const handleClose = () => {
    setModalState({ ...modalState, add: false, geneticMaterial: undefined });
  };
  return (
    <Modal
      title={`${geneticMaterial?.id ? translationI18n('common:actions.edit') : translationI18n('common:actions.add')} ${translationI18n('common:parameter')}`}
      isActive={modalState.add}
      variant="sm"
      handleClose={handleClose}
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton
            text={translationI18n('common:actions.cancel')}
            onClick={handleClose}
          />
          <ButtonSubmit
            title={
              geneticMaterial?.id
                ? translationI18n('common:actions.update')
                : translationI18n('common:actions.save')
            }
            disabled={modalState.loading}
            loading={modalState.loading}
            onClick={() => validation.handleSubmit()}
          />
        </div>
      }
    >
      <form onSubmit={validation.handleSubmit} className="w-full">
        <Input
          disabled={modalState.loading}
          value={validation.values.genetic}
          onChange={validation.handleChange('genetic')}
          onBlur={validation.handleBlur('genetic')} // Adicione esta linha
          label={translationI18n(
            'companyProfile:body.screens.params.items.plantedArea.params.geneticMaterials.addLabel',
          )}
          type={'text'}
          error={!!(validation.errors.genetic && validation.touched.genetic)}
          errorMessage={validation.errors.genetic}
        />
      </form>
    </Modal>
  );
};
export default ModalAddPlantedAreaGeneticMaterial;
