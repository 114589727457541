import { userStore } from '@/store';
import Colors from '@/utils/colors';
import { observer } from 'mobx-react';
import { MouseEventHandler } from 'react';
import { IconType } from 'react-icons';
import { ScaleLoader } from 'react-spinners';

// Obtenha o tema no momento da renderização para evitar inconsistências

interface SecondaryButtonProps {
  icon?: IconType;
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  wFull?: boolean;
  bg?: 'white' | 'default';
  loading?: boolean;
  disabled?: boolean;
}

const SecondaryButton = observer(
  ({
    icon: Icon,
    text,
    disabled = false,
    onClick,
    wFull = false,
    bg = 'default',
    loading = false,
  }: SecondaryButtonProps) => {
    const { theme } = userStore;
    return (
      <button
        onClick={onClick}
        type="button"
        disabled={disabled || loading} // Opcional: desabilita o botão durante o loading
        className={`flex ${
          wFull ? 'w-full' : 'w-auto'
        } items-center justify-center gap-2 text-sm h-[40px] px-[30px] py-2 font-regular rounded-lg transition-all
        ${bg === 'white' ? 'bg-white' : 'bg-bgray-200'}
        dark:bg-darkblack-400
        hover:bg-bgray-400
        relative
        text-black dark:text-bgray-200
        ${disabled || loading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}
      >
        <div className={`w-full  h-full flex items-center justify-center absolute`}>
          <ScaleLoader
            loading={loading}
            color={theme === 'dark' ? Colors.bgray[200] : Colors.gray[500]}
            height={18}
            className={`transition-opacity duration-200 ${
              loading ? 'opacity-100' : 'opacity-0'
            }`}
          />
        </div>
        {/* Contêiner para Spinner e Ícone com largura fixa */}
        {Icon && (
          <div className="flex items-center justify-center w-6 h-6 relative">
            {/* Spinner sempre renderizado, mas visível apenas quando loading */}
            {/* Ícone sempre renderizado, mas visível apenas quando não está carregando */}
            <Icon
              size={20}
              className={`transition-opacity duration-200 ${
                loading ? 'opacity-0' : 'opacity-100'
              }`}
            />
          </div>
        )}
        {/* Texto sempre renderizado, mas invisível durante o loading para manter o espaço */}
        <span className={`${loading ? 'invisible' : 'visible'}`}>{text}</span>
      </button>
    );
  },
);

export default SecondaryButton;
