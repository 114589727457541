import Table from '@/component/Table';
import { forestRegistryStore } from '@/store';
import { columns } from '../../forest-registry-initial-states';
import { OptionsType, OrderTableTypes } from '@/component/Table/types';
import { forestRegistryItemType } from '@/utils/types/ForestRegistryType';
import { observer } from 'mobx-react';
import EmptyState from '@/component/EmptyState';
import { checkPermission } from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';

const ForestRegistryTable = observer(() => {
  const {
    filter: filters,
    handleFilters,
    forestRegistryData,
    tableParams,
    handleTableParamas,
    handleModalState,
    loading,
    screenActive,
  } = forestRegistryStore;
  const tCommon = useTranslation('common').t;
  const tForestRegistry = useTranslation('forestRegistry').t;
  if (screenActive !== 'table') {
    return null;
  }
  const options = (forestRegistry: forestRegistryItemType): OptionsType[] =>
    [
      {
        label: tCommon('actions.edit'),
        action: (x: forestRegistryItemType) => handleModalState('add', true, x),
        hide: !checkPermission({
          service: 'forestRegistry',
          permission: 'permissionUpdate',
        }),
      },
      {
        label: tForestRegistry('body.table.editPolygon'),
        action: (x: forestRegistryItemType) => handleModalState('polygonEditor', true, x),
        hide: !checkPermission({
          service: 'forestRegistry',
          permission: 'permissionUpdate',
        }),
      },
      {
        label: forestRegistry.active
          ? tCommon('actions.inactive')
          : tCommon('actions.active'),
        action: (x: forestRegistryItemType) =>
          handleModalState('confirmChangeStatus', true, x),
        variant: forestRegistry.active ? 'danger' : 'success',
        hide: !checkPermission({
          service: 'forestRegistry',
          permission: 'permissionUpdate',
        }),
      },
    ].filter((x) => !x.hide) as OptionsType[];
  const cols = columns();
  if (!loading && forestRegistryData.forestRegisters.length === 0) {
    return (
      <EmptyState
        size="lg"
        title={tForestRegistry('body.table.emptyTable.title')}
        description={tForestRegistry('body.table.emptyTable.description')}
      />
    );
  }
  return (
    <Table
      pt={0}
      showSearch={false}
      options={options}
      filters={forestRegistryData.filters}
      columns={cols}
      page={tableParams.page}
      size={tableParams.size}
      totalPages={forestRegistryData?.totalPages}
      data={forestRegistryData.forestRegisters}
      setPage={function (page: number): void {
        handleTableParamas('page', page);
      }}
      setSize={function (size: number): void {
        handleTableParamas('size', size);
      }}
      query={filters.query}
      setQuery={(query) => handleFilters('query', query)}
      loading={loading}
      order={{
        column: tableParams.column,
        direction: tableParams.direction,
      }}
      setOrder={function (order: OrderTableTypes): void {
        handleTableParamas('column', order.column);
        handleTableParamas('direction', order.direction);
      }}
    />
  );
});
export default ForestRegistryTable;
