import ButtonSubmit from '@/component/forms/button-submit';
import { PasswordResetModalControllerType } from '../../../signin-types';
import BtnClose from '@/component/buttons/BtnClose';
import HeadingLogo from './header-logo';
import Input from '@/component/forms/Input';
import { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

const RequestCode = ({
  passwordResetModalControllers,
}: PasswordResetModalControllerType) => {
  const { loadingSendEmail, onClose, validationEmail } = passwordResetModalControllers;
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    validationEmail;
  const tSignin = useTranslation('signin').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="step-content step-1">
      <div className="relative max-w-[492px] transform overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 p-8 text-left transition-all">
        <div className="absolute top-0 right-0 pr-5 pt-5">
          <BtnClose close={onClose} />
        </div>
        <div>
          <HeadingLogo />
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-gray-200 mb-3">
            {tSignin('resetPasswordModal.enterEmail.title')}
          </h3>
          <p className="text-base font-medium text-bgray-600 dark:text-darkblack-300 mb-7">
            {tSignin('resetPasswordModal.enterEmail.subtitle')}
          </p>
          <form
            action=""
            onSubmit={(event: FormEvent) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <div className="mb-8">
              <Input
                disabled={loadingSendEmail}
                type="text"
                value={values.emailRecoveryPassword}
                onChange={handleChange('emailRecoveryPassword')}
                name="email"
                onBlur={handleBlur('emailRecoveryPassword')}
                className="rounded-lg bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 p-4 border-0 focus:border focus:ring-0 focus:border-success-200 w-full placeholder:font-medium text-base h-14"
                placeholder={tCommon('email')}
                error={!!(errors.emailRecoveryPassword && touched.emailRecoveryPassword)}
                errorMessage={errors.emailRecoveryPassword}
              />
            </div>
            <button
              type="button"
              onClick={onClose}
              className="block text-sm font-bold text-primary-200 mb-8 underline"
            >
              {tSignin('resetPasswordModal.enterEmail.goBackToSignin')}
            </button>
            <ButtonSubmit
              wFull
              disabled={false}
              loading={loadingSendEmail}
              title={tSignin('resetPasswordModal.enterEmail.sendEmail')}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestCode;
