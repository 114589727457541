import SmallPrimaryButton from '@/component/buttons/SmallPrimaryButton';
import { UploadIcon } from '@/assets/images/companies/uploadIcon';
import { useRef } from 'react';
import { companyProfileStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
const LogoCompany = observer(() => {
  const { companyData, loading, handleChangeImage } = companyProfileStore;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const tCompanyProfile = useTranslation('companyProfile').t;
  return (
    <div className=" h-full border border-bgray-400 px-3 py-3 lg:min-w-[300px] rounded-lg">
      <p className="font-semibold text-sm text-black dark:text-gray-200">
        {tCompanyProfile('header.logoOfCompany')}
      </p>
      <div className=" py-5 rounded-lg px-5 items-center justify-center space-y-5 mt-3">
        <div className="w-full items-center flex justify-center ">
          {loading.companyData ? (
            <Skeleton
              variant="rectangular"
              width={65}
              height={65}
              className="rounded dark:bg-darkblack-400"
            />
          ) : companyData.imageUrl ? (
            <img src={companyData.imageUrl} style={{ maxHeight: 65, minHeight: 65 }} />
          ) : (
            <UploadIcon />
          )}
        </div>
        <div className="w-full items-center flex justify-center">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleChangeImage}
            accept="image/*"
          />
          {(userStore.user.keyUser || userStore.user.master) && (
            <SmallPrimaryButton
              text={tCompanyProfile('header.updloadOfImage')}
              disabled={loading.changeImage || loading.companyData}
              loading={loading.changeImage}
              onClick={handleUploadButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
});
export default LogoCompany;
