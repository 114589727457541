import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { observer } from 'mobx-react';
import { userStore } from '@/store';
interface EChartComponentProps {
  option: echarts.EChartsOption;
  style?: React.CSSProperties;
  handleClick?: (params: any) => void;
  chartRef?: React.RefObject<HTMLDivElement>;
}

const EChartComponent: React.FC<EChartComponentProps> = observer(
  ({
    option,
    style,
    handleClick = () => {},
    chartRef = useRef<HTMLDivElement>(null),
  }) => {
    const { theme } = userStore;
    useEffect(() => {
      let chartInstance: echarts.ECharts | null = null;

      const renderChart = () => {
        if (chartRef.current) {
          if (chartInstance) {
            chartInstance.dispose();
          }
          chartInstance = echarts.init(chartRef.current);
          chartInstance.setOption(option);
          // echarts.getInstanceByDom(chartRef.current);
          if (chartInstance) {
            chartInstance.on('click', handleClick);
          }
        }
      };

      // Initial render
      renderChart();

      // Resize event listener
      const handleResize = () => {
        if (chartInstance) {
          chartInstance.resize();
        }
      };

      window.addEventListener('resize', handleResize);

      // Cleanup on component unmount
      return () => {
        if (chartInstance) {
          chartInstance.dispose();
        }
        window.removeEventListener('resize', handleResize);
      };
    }, [option, theme]);

    return <div ref={chartRef} style={style}></div>;
  },
);

export default EChartComponent;
