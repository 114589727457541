import { del, get, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  AddControlSilviculturePlantedAreaGeneticMaterialDataType,
  AddControlSilviculturePlantedAreaGeneticMaterialResponseType,
  DeleteControlSilviculturePlantedAreaGeneticMaterialResponseType,
  GetControlSilviculturePlantedAreaGeneticMaterialResponseType,
  GetControlSilviculturePlantedAreaGeneticMaterialType,
} from './types';

export const getParamsGeneticMaterial = async (
  data: GetControlSilviculturePlantedAreaGeneticMaterialType,
): Promise<GetControlSilviculturePlantedAreaGeneticMaterialResponseType> => {
  const result = (await get(url.GET_CONTROL_PLANTED_AREA_GENETIC_MATERIAL, {
    params: data,
  })) as GetControlSilviculturePlantedAreaGeneticMaterialResponseType;
  return result;
};

export const saveParamsGeneticMaterial = async (
  data: AddControlSilviculturePlantedAreaGeneticMaterialDataType,
): Promise<AddControlSilviculturePlantedAreaGeneticMaterialResponseType> => {
  const resultSave = (await post(
    url.GET_CONTROL_PLANTED_AREA_GENETIC_MATERIAL,
    data,
  )) as AddControlSilviculturePlantedAreaGeneticMaterialResponseType;
  return resultSave;
};
export const udpateParamsGeneticMaterial = async (
  data: Partial<AddControlSilviculturePlantedAreaGeneticMaterialDataType>,
): Promise<AddControlSilviculturePlantedAreaGeneticMaterialResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await put(
    url.GET_CONTROL_PLANTED_AREA_GENETIC_MATERIAL + '/' + data.id,
    data,
  )) as AddControlSilviculturePlantedAreaGeneticMaterialResponseType;
  return resultSave;
};
export const deleteParamsGeneticMaterial = async (
  data: Partial<AddControlSilviculturePlantedAreaGeneticMaterialDataType>,
): Promise<DeleteControlSilviculturePlantedAreaGeneticMaterialResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await del(
    url.GET_CONTROL_PLANTED_AREA_GENETIC_MATERIAL + '/' + data.id,
  )) as DeleteControlSilviculturePlantedAreaGeneticMaterialResponseType;
  return resultSave;
};
