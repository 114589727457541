/* eslint-disable  */
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { Skeleton, FormControlLabel, Checkbox } from '@mui/material';
import Colors from '@/utils/colors';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import * as turf from '@turf/turf';
import { FaLayerGroup } from 'react-icons/fa';
import { createDotsOverlay } from './create-geojson';
import { SurvivalSurveyDot } from '../../../../quality-survival-surveys-types';
import LegendeMap from './details-map-legend';
import { styleMapLibre } from '@/utils/geo';
import {
  Feature,
  GeoJsonProperties,
  MultiPolygon,
  Point,
  LineString,
  Polygon,
  FeatureCollection,
} from 'geojson';
import { initialStateReboleiraHighlight } from '../../../../quality-survival-surveys-initital-states';
import { useTranslation } from 'react-i18next';

const LAYER_IDS = {
  INTERPOLATED_GRID_FILL: 'interpolatedGrid-fill',
  FOREST_REGISTRY_LINE: 'forestRegistry-line',
  WALKLINE_LINE: 'walkline-line',
  WALKLINE_POINT: 'walkline-point',
  WALKLINE_TEXT: 'walkline-text',
  DOTS_ALIVE_POINT: 'dotsAlive-point',
  DOTS_DEATH_POINT: 'dotsDeath-point',
  DOTS_DEATH_SYMBOL: 'dotsDeath-symbol',
  REBOLEIRAS_FILL: 'reboleiras-fill',
  REBOLEIRAS_LINE: 'reboleiras-line',
  REBOLEIRAS_TEXT: 'reboleiras-text',
  PHOTOS_POINT: 'photos-point',
};

const DetailsMap = () => {
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<maplibregl.Map | null>(null);
  const [mapState, setMapState] = useState<{
    center: LngLatLike;
    zoom: number;
    loaded: boolean;
  }>({
    center: [-52.842663, -20.504497],
    zoom: 20,
    loaded: false,
  });

  const {
    evaluationDetails: evaluationData,
    loading,
    handleModal,
    deathCauseHighlight,
    causesDeaths,
    reboleiraHighlight,
    handleReboleiraToZoom,
    reboleiraToZoom,
    activeImage,
    handleDeathCauseHighlight,
    handleImageActiveGallery,
    setModalGalleryIsOpen,
  } = survivalSurveysEvaluationsStore;

  const loadingEvaluationData = loading.evaluationDetails;
  const survivalTarget = evaluationData.survivalTarget;

  const [points, setPoints] = useState<Feature<Point, GeoJsonProperties>[]>([]);
  const [photoPoints, setPhotoPoints] = useState<Feature<Point, GeoJsonProperties>[]>([]);
  const [forestRegistry, setForestRegistry] = useState<
    Feature<MultiPolygon, GeoJsonProperties>
  >(turf.multiPolygon([]));
  const [walkLine, setWalkLine] = useState<Feature<LineString, GeoJsonProperties> | null>(
    null,
  );
  const [walkLineDots, setWalkLineDots] = useState<FeatureCollection<
    Point,
    GeoJsonProperties
  > | null>(null);
  const [reboleirasPolygons, setReboleirasPolygons] = useState<FeatureCollection<
    Polygon,
    GeoJsonProperties
  > | null>(null);
  const [loadedGeometries, setLoadedGeometries] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState({
    survivalSurveyDeathDots: true,
    survivalSurveyLiveDots: false,
    forestRegistry: true,
    survivalSurveyReboleiras: true,
    walkline: true,
    interpolatedGrid: true,
    photos: true,
  });

  const hasPointsInForestRegistry = useMemo(
    () => evaluationData.interpolatedMap?.length > 0,
    [evaluationData.interpolatedMap],
  );

  const generateGeometries = useCallback(() => {
    setLoadedGeometries(false);
    try {
      const { coordinates, ...forestRegistryProps } = evaluationData.forestRegistry;

      const multipolygon = turf.multiPolygon(
        coordinates.map((polygon) => [
          polygon.coordinates.map((coord) => [coord.lng, coord.lat]),
        ]),
        forestRegistryProps,
      );
      setForestRegistry(multipolygon);

      const newPoints = evaluationData.survivalSurveyDots.map((dot) =>
        turf.point([dot.coordinates.lng, dot.coordinates.lat], {
          ...dot,
          value: !dot.isAlive ? 1 : 0,
          cause: dot.deathCause?.cause || 'V',
          color: dot.deathCause?.color || Colors.primary[200],
        }),
      );
      setPoints(newPoints);

      const newPhotosPoints = evaluationData.evaluationPhotos.map((photo) =>
        turf.point([Number(photo.coordinates.lng), Number(photo.coordinates.lat)], {
          ...photo,
        }),
      );
      setPhotoPoints(newPhotosPoints);

      const polylineCoordinates = evaluationData.polyline.map((coord) => [
        coord.lng,
        coord.lat,
      ]);
      const lineString = turf.lineString(polylineCoordinates);
      setWalkLine(lineString);

      const startPoint = turf.point(polylineCoordinates[0], {
        id: 'dotStart',
        text: 'Ínicio',
      });
      const endPoint = turf.point(polylineCoordinates[polylineCoordinates?.length - 1], {
        id: 'dotEnd',
        text: 'Fim',
      });
      const pointsLineWalk = turf.featureCollection([startPoint, endPoint], {
        id: 'walklinePoints',
      });
      setWalkLineDots(pointsLineWalk);

      const reboleirasFeatures: Feature<Polygon, GeoJsonProperties>[] =
        evaluationData.survivalSurveyReboleiras.map((register) => {
          const area = `${(turf.area(turf.polygon([register.coordinates.map((coord) => [coord.lng, coord.lat])])) / 10000).toFixed(2)} ha`;
          return turf.polygon(
            [register.coordinates.map((coord) => [coord.lng, coord.lat])],
            {
              color: register.deathCause.color,
              cause: register.deathCause.cause,
              description: register.deathCause.description,
              origin: register.deathCause.origin,
              id: register.id,
              label: `${register.deathCause.cause}\n${area}`,
            },
          );
        });
      setReboleirasPolygons(turf.featureCollection(reboleirasFeatures));

      setLoadedGeometries(true);
    } catch (error: any) {
      setLoadedGeometries(true);
      console.error('Error generating geometries:', error.message);
    }
  }, [evaluationData]);

  const zoomFeatures = useCallback(
    (map: maplibregl.Map) => {
      const allCoordinates: LngLatLike[] = [
        ...evaluationData.survivalSurveyDots.map((dot) => [
          dot.coordinates.lng,
          dot.coordinates.lat,
        ]),
        ...evaluationData.polyline.map((coord) => [coord.lng, coord.lat]),
        ...evaluationData.forestRegistry.coordinates.flatMap((coordinates) =>
          coordinates.coordinates.map((coord) => [coord.lng, coord.lat] as LngLatLike),
        ),
      ];

      if (allCoordinates?.length > 0) {
        const bounds = new maplibregl.LngLatBounds();
        allCoordinates.forEach((coord) => bounds.extend(coord));
        map.fitBounds(bounds, { padding: 20, animate: false });
      }
    },
    [evaluationData],
  );

  const addInterpolatedGridLayer = useCallback(
    (map: maplibregl.Map) => {
      if (hasPointsInForestRegistry) {
        map.addLayer({
          id: LAYER_IDS.INTERPOLATED_GRID_FILL,
          type: 'fill',
          source: {
            type: 'geojson',
            data: turf.featureCollection(evaluationData.interpolatedMap),
          },
          paint: {
            'fill-color': [
              'step',
              ['get', 'value'],
              Colors.error[300],
              (survivalTarget - 12) / 100,
              Colors.error[300],
              (survivalTarget - 9) / 100,
              Colors.error[200],
              (survivalTarget - 6) / 100,
              Colors.warning[200],
              (survivalTarget - 3) / 100,
              Colors.primary[200],
              survivalTarget / 100,
              Colors.primary[300],
            ],
          },
        });
      }
    },
    [hasPointsInForestRegistry, evaluationData.interpolatedMap, survivalTarget],
  );

  const addForestRegistryLayer = useCallback(
    (map: maplibregl.Map) => {
      if (evaluationData.forestRegistry.coordinates?.length > 0) {
        map.addLayer({
          id: LAYER_IDS.FOREST_REGISTRY_LINE,
          type: 'line',
          source: {
            type: 'geojson',
            data: forestRegistry,
          },
          paint: {
            'line-color': 'red',
            'line-width': 2,
          },
        });
      }
    },
    [evaluationData.forestRegistry.coordinates?.length, forestRegistry],
  );

  const addWalkLineLayers = useCallback(
    (map: maplibregl.Map) => {
      if (evaluationData.polyline?.length > 0 && walkLine && walkLineDots) {
        map.addLayer({
          id: LAYER_IDS.WALKLINE_LINE,
          type: 'line',
          source: {
            type: 'geojson',
            data: walkLine,
          },
          paint: {
            'line-width': 3,
            'line-color': Colors.warning[500],
          },
        });

        // Walkline Points
        map.addLayer({
          id: LAYER_IDS.WALKLINE_POINT,
          type: 'circle',
          source: {
            type: 'geojson',
            data: walkLineDots,
          },
          paint: {
            'circle-color': [
              'case',
              ['==', ['get', 'id'], 'dotStart'],
              '#00ff00',
              '#ff0000',
            ],
            'circle-radius': 5,
          },
          minzoom: 13,
        });

        // Walkline Text
        map.addLayer({
          id: LAYER_IDS.WALKLINE_TEXT,
          type: 'symbol',
          source: {
            type: 'geojson',
            data: walkLineDots,
          },
          layout: {
            'text-field': ['get', 'text'],
            'text-size': 14,
            'text-font': ['Arial-Regular'],
            'text-anchor': 'center',
            'text-offset': [0, 1.5],
          },
          paint: {
            'text-color': [
              'case',
              ['==', ['get', 'id'], 'dotStart'],
              '#00ff00',
              '#ff0000',
            ],
          },
          minzoom: 13,
        });
      }
    },
    [evaluationData.polyline?.length, walkLine, walkLineDots],
  );

  const addSurvivalSurveyLayers = useCallback(
    (map: maplibregl.Map) => {
      if (evaluationData.survivalSurveyDots?.length > 0) {
        const dotsAlive = createDotsOverlay(points, true);
        const dotsDeath = createDotsOverlay(points, false);

        // Dots Alive Layer
        map.addLayer({
          id: LAYER_IDS.DOTS_ALIVE_POINT,
          type: 'circle',
          source: {
            type: 'geojson',
            data: dotsAlive,
          },
          paint: {
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': 'white',
          },
          layout: {
            visibility: 'none',
          },
          minzoom: 13,
        });

        // Dots Death Source and Layer
        if (!map.getSource('dotsDeath-source')) {
          map.addSource('dotsDeath-source', {
            type: 'geojson',
            data: dotsDeath,
          });
        } else {
          map.getSource('dotsDeath-source')!.setData(dotsDeath);
        }

        map.addLayer({
          id: LAYER_IDS.DOTS_DEATH_POINT,
          type: 'circle',
          source: 'dotsDeath-source',
          paint: {
            'circle-color': ['get', 'color'],
            'circle-stroke-width': 1,
            'circle-stroke-color': 'white',
          },
          minzoom: 13,
        });

        // Dots Death Symbol Layer
        map.addLayer({
          id: LAYER_IDS.DOTS_DEATH_SYMBOL,
          type: 'symbol',
          source: 'dotsDeath-source',
          layout: {
            'text-field': ['get', 'cause'],
            'text-size': 12,
            'text-font': ['Arial-Regular'],
            'text-anchor': 'center',
            'text-offset': [0, -1.5],
          },
          paint: {
            'text-color': 'white',
          },
          minzoom: 16,
        });

        // Event Listeners for Dots Death
        const onClick = (e: maplibregl.MapMouseEvent & maplibregl.EventData) => {
          const feature = e.features && e.features[0];
          if (feature?.properties) {
            handleModal(
              'changeCause',
              true,
              evaluationData,
              JSON.parse(JSON.stringify(feature.properties)) as SurvivalSurveyDot,
            );
          }
        };

        const onMouseEnter = (e: maplibregl.MapMouseEvent & maplibregl.EventData) => {
          map.getCanvas().style.cursor = 'pointer';
          const feature = e.features && e.features[0];
          if (feature?.properties) {
            handleDeathCauseHighlight({
              cause: JSON.parse(String(feature.properties.deathCause))?.cause,
              apply: 'chart',
            });
            if (feature.properties.uuid) {
              map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-radius', [
                'case',
                ['==', ['get', 'uuid'], feature.properties.uuid],
                10,
                5,
              ]);
            }
          }
        };

        const onMouseLeave = () => {
          map.getCanvas().style.cursor = '';
          handleDeathCauseHighlight({
            cause: '',
            apply: '',
          });
          map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-radius', 5);
        };

        map.on('click', LAYER_IDS.DOTS_DEATH_POINT, onClick);
        map.on('mouseenter', LAYER_IDS.DOTS_DEATH_POINT, onMouseEnter);
        map.on('mouseleave', LAYER_IDS.DOTS_DEATH_POINT, onMouseLeave);

        // Cleanup event listeners on unmount or layer removal
        return () => {
          map.off('click', LAYER_IDS.DOTS_DEATH_POINT, onClick);
          map.off('mouseenter', LAYER_IDS.DOTS_DEATH_POINT, onMouseEnter);
          map.off('mouseleave', LAYER_IDS.DOTS_DEATH_POINT, onMouseLeave);
        };
      }
    },
    [
      evaluationData.survivalSurveyDots,
      points,
      handleModal,
      evaluationData,
      handleDeathCauseHighlight,
    ],
  );

  const addReboleirasLayers = useCallback(
    (map: maplibregl.Map) => {
      if (evaluationData.survivalSurveyReboleiras?.length > 0 && reboleirasPolygons) {
        if (!map.getSource('reboleiras-source')) {
          map.addSource('reboleiras-source', {
            type: 'geojson',
            data: reboleirasPolygons,
          });
        } else {
          map.getSource('reboleiras-source')!.setData(reboleirasPolygons);
        }

        // Fill Layer
        map.addLayer({
          id: LAYER_IDS.REBOLEIRAS_FILL,
          type: 'fill',
          source: 'reboleiras-source',
          paint: {
            'fill-color': ['get', 'color'],
            'fill-opacity': 0.5,
          },
        });

        // Line Layer
        map.addLayer({
          id: LAYER_IDS.REBOLEIRAS_LINE,
          type: 'line',
          source: 'reboleiras-source',
          paint: {
            'line-color': ['get', 'color'],
            'line-width': 2,
          },
        });

        // Text Layer
        map.addLayer({
          id: LAYER_IDS.REBOLEIRAS_TEXT,
          type: 'symbol',
          source: 'reboleiras-source',
          layout: {
            'text-field': ['get', 'label'],
            'text-size': 14,
            'text-font': ['Arial-Regular'],
            'text-anchor': 'center',
            'text-offset': [0, 0],
          },
          paint: {
            'text-color': 'white',
          },
          minzoom: 17,
        });
      }
    },
    [evaluationData.survivalSurveyReboleiras?.length, reboleirasPolygons],
  );

  const addPhotosLayer = useCallback(
    (map: maplibregl.Map) => {
      if (evaluationData.evaluationPhotos?.length > 0) {
        if (!map.getSource('evaluationPhotos-source')) {
          map.addSource('evaluationPhotos-source', {
            type: 'geojson',
            data: turf.featureCollection(photoPoints),
          });
        } else {
          map
            .getSource('evaluationPhotos-source')!
            .setData(turf.featureCollection(photoPoints));
        }

        map.addLayer({
          id: LAYER_IDS.PHOTOS_POINT,
          type: 'circle',
          source: 'evaluationPhotos-source',
          paint: {
            'circle-color': '#fff',
            'circle-opacity': 0.8,
            'circle-radius': 10,
            'circle-stroke-color': Colors.primary[200],
            'circle-stroke-width': 4,
          },
          minzoom: 14,
        });

        map.on(
          'click',
          LAYER_IDS.PHOTOS_POINT,
          (e: maplibregl.MapMouseEvent & maplibregl.EventData) => {
            const feature = e.features && e.features[0];
            if (feature?.properties) {
              handleImageActiveGallery(
                evaluationData.evaluationPhotos.filter(
                  (row) => row.id === feature?.properties?.id,
                )[0],
              );
              setModalGalleryIsOpen(true);
            }
          },
        );
        // Event Listeners for Photos
        map.on('mouseenter', LAYER_IDS.PHOTOS_POINT, () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', LAYER_IDS.PHOTOS_POINT, () => {
          map.getCanvas().style.cursor = '';
        });
      }
    },
    [evaluationData.evaluationPhotos?.length, photoPoints],
  );

  const addLayers = useCallback(
    (map: maplibregl.Map) => {
      addInterpolatedGridLayer(map);
      addForestRegistryLayer(map);
      addWalkLineLayers(map);
      addSurvivalSurveyLayers(map);
      addReboleirasLayers(map);
      addPhotosLayer(map);
    },
    [
      addInterpolatedGridLayer,
      addForestRegistryLayer,
      addWalkLineLayers,
      addSurvivalSurveyLayers,
      addReboleirasLayers,
      addPhotosLayer,
    ],
  );

  const initializeMap = useCallback(() => {
    if (mapRef.current) {
      updateSources(mapRef.current);
      return;
    }
    try {
      const map = new maplibregl.Map({
        container: mapContainerRef.current as HTMLElement,
        attributionControl: false,
        maxZoom: 18,
        style: styleMapLibre,
        center: mapState.center,
        zoom: mapState.zoom,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        localIdeographFontFamily: 'Noto',
      });

      map.addControl(new maplibregl.NavigationControl(), 'top-left');

      map.on('load', () => {
        addLayers(map);
        if (!mapState.loaded) {
          zoomFeatures(map);
        }

        const updateMapState = () => {
          setMapState({
            center: [map.getCenter().lng, map.getCenter().lat],
            zoom: map.getZoom(),
            loaded: true,
          });
        };

        map.on('zoomend', updateMapState);
        map.on('moveend', updateMapState);
      });

      mapRef.current = map;
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [addLayers, mapState.loaded, zoomFeatures]);

  const updateSources = useCallback(
    (map: maplibregl.Map) => {
      const dotsDeath = createDotsOverlay(points, false);
      const dotsAlive = createDotsOverlay(points, true);

      const dotsDeathSource = map.getSource(
        'dotsDeath-source',
      ) as maplibregl.GeoJSONSource;
      if (dotsDeathSource) {
        dotsDeathSource.setData(dotsDeath);
      }

      const dotsAliveSource = map.getSource(
        LAYER_IDS.DOTS_ALIVE_POINT,
      ) as maplibregl.GeoJSONSource;
      if (dotsAliveSource) {
        dotsAliveSource.setData(dotsAlive);
      }
    },
    [points],
  );

  // Initialize Map when geometries are loaded
  useEffect(() => {
    if (mapContainerRef.current && loadedGeometries) {
      initializeMap();
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [initializeMap, loadedGeometries]);

  // Generate geometries when evaluation data changes
  useEffect(() => {
    if (evaluationData.id) {
      generateGeometries();
    }
  }, [evaluationData, generateGeometries]);

  // Update layer visibility based on selectedLayers
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    const layerVisibilityMap: { [key: string]: string } = {
      [LAYER_IDS.INTERPOLATED_GRID_FILL]: selectedLayers.interpolatedGrid
        ? 'visible'
        : 'none',
      [LAYER_IDS.FOREST_REGISTRY_LINE]: selectedLayers.forestRegistry
        ? 'visible'
        : 'none',
      [LAYER_IDS.WALKLINE_LINE]: selectedLayers.walkline ? 'visible' : 'none',
      [LAYER_IDS.WALKLINE_POINT]: selectedLayers.walkline ? 'visible' : 'none',
      [LAYER_IDS.WALKLINE_TEXT]: selectedLayers.walkline ? 'visible' : 'none',
      [LAYER_IDS.PHOTOS_POINT]: selectedLayers.photos ? 'visible' : 'none',
      [LAYER_IDS.DOTS_ALIVE_POINT]: selectedLayers.survivalSurveyLiveDots
        ? 'visible'
        : 'none',
      [LAYER_IDS.DOTS_DEATH_POINT]: selectedLayers.survivalSurveyDeathDots
        ? 'visible'
        : 'none',
      [LAYER_IDS.DOTS_DEATH_SYMBOL]: selectedLayers.survivalSurveyDeathDots
        ? 'visible'
        : 'none',
      [LAYER_IDS.REBOLEIRAS_LINE]: selectedLayers.survivalSurveyReboleiras
        ? 'visible'
        : 'none',
      [LAYER_IDS.REBOLEIRAS_TEXT]: selectedLayers.survivalSurveyReboleiras
        ? 'visible'
        : 'none',
      [LAYER_IDS.REBOLEIRAS_FILL]: selectedLayers.survivalSurveyReboleiras
        ? 'visible'
        : 'none',
    };

    Object.entries(layerVisibilityMap).forEach(([layerId, visibility]) => {
      if (map.getLayer(layerId)) {
        map.setLayoutProperty(layerId, 'visibility', visibility);
      }
    });
  }, [selectedLayers]);

  // Highlight death causes on the map
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    if (deathCauseHighlight.apply === 'map' || deathCauseHighlight.apply === 'all') {
      map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-opacity', [
        'case',
        ['==', ['get', 'cause'], deathCauseHighlight.cause],
        1,
        0.2,
      ]);
      map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-stroke-opacity', [
        'case',
        ['==', ['get', 'cause'], deathCauseHighlight.cause],
        1,
        0.5,
      ]);
      map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-radius', [
        'case',
        ['==', ['get', 'cause'], deathCauseHighlight.cause],
        10,
        5,
      ]);
    } else {
      map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-radius', 5);
      map.setPaintProperty(LAYER_IDS.DOTS_DEATH_POINT, 'circle-opacity', undefined);
      map.setPaintProperty(
        LAYER_IDS.DOTS_DEATH_POINT,
        'circle-stroke-opacity',
        undefined,
      );
    }
  }, [deathCauseHighlight]);

  // Highlight reboleiras on the map
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    if (reboleiraHighlight.id > 0) {
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_LINE, 'line-width', [
        'case',
        ['==', ['get', 'id'], reboleiraHighlight.id],
        6,
        2,
      ]);
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_LINE, 'line-color', [
        'case',
        ['==', ['get', 'id'], reboleiraHighlight.id],
        'red',
        ['get', 'color'],
      ]);
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_FILL, 'fill-opacity', [
        'case',
        ['==', ['get', 'id'], reboleiraHighlight.id],
        1,
        0.5,
      ]);
      map.setLayoutProperty(LAYER_IDS.REBOLEIRAS_TEXT, 'text-size', [
        'case',
        ['==', ['get', 'id'], reboleiraHighlight.id],
        20,
        14,
      ]);
    } else {
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_LINE, 'line-width', 2);
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_LINE, 'line-color', ['get', 'color']);
      map.setPaintProperty(LAYER_IDS.REBOLEIRAS_FILL, 'fill-opacity', 0.5);
      map.setLayoutProperty(LAYER_IDS.REBOLEIRAS_TEXT, 'text-size', 14);
    }
  }, [reboleiraHighlight]);

  useEffect(() => {
    if (reboleiraToZoom.id > 0 && reboleirasPolygons) {
      const feature = reboleirasPolygons.features.find(
        (f) => f.properties.id === reboleiraToZoom.id,
      );
      if (feature && feature.geometry.type === 'Polygon') {
        const coordinates = (feature.geometry as GeoJSON.Polygon).coordinates;

        // Calcular os limites da reboleira
        const bounds = new maplibregl.LngLatBounds();
        coordinates[0].forEach((coord) => bounds.extend(coord as [number, number]));

        // Ajustar o mapa para os limites calculados com animação
        mapRef.current?.fitBounds(bounds, { padding: 40, animate: true });
        handleReboleiraToZoom(initialStateReboleiraHighlight);
      }
    }
  }, [reboleiraToZoom, reboleirasPolygons]);

  // Update photo point styles based on active image
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    if (activeImage.id) {
      map.setPaintProperty(LAYER_IDS.PHOTOS_POINT, 'circle-stroke-color', [
        'case',
        ['==', ['get', 'id'], activeImage.id],
        Colors.error[300],
        Colors.primary[300],
      ]);
    } else {
      map.setPaintProperty(
        LAYER_IDS.PHOTOS_POINT,
        'circle-stroke-color',
        Colors.primary[300],
      );
    }
  }, [activeImage]);

  // Filter dots based on selected causes
  useEffect(() => {
    const map = mapRef.current;
    if (!map || !map.isStyleLoaded()) return;

    if (causesDeaths?.length) {
      const causesList = causesDeaths
        .filter((cause) => cause.show)
        .map((cause) => cause.cause);

      if (causesList?.length > 0) {
        map.setFilter(LAYER_IDS.DOTS_DEATH_POINT, [
          'in',
          ['get', 'cause'],
          ['literal', causesList],
        ]);
      } else {
        // Define a filter that shows no points
        map.setFilter(LAYER_IDS.DOTS_DEATH_POINT, ['==', ['get', 'cause'], '']);
      }
    }
  }, [causesDeaths]);

  const handleLayerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelectedLayers((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className="relative rounded-lg w-full dark:text-bgray-300 min-h-[548px] h-full shadow-lg">
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        className={`fixed z-[2] left-0 top-0 h-full w-full ${open ? 'block' : 'hidden'}`}
      ></div>
      {selectedLayers.interpolatedGrid && !loadingEvaluationData && loadedGeometries && (
        <LegendeMap survivalTarget={survivalTarget} />
      )}
      {loadingEvaluationData ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height="548px"
          className="rounded-lg dark:bg-darkblack-500"
        />
      ) : (
        <div>
          <button
            className="p-3 rounded shadow absolute top-3 right-3 bg-white text-darkblack-400 z-20"
            onClick={handleClick}
          >
            <FaLayerGroup />
          </button>
          <BasePopup
            id={id}
            open={open}
            anchor={anchor}
            placement="bottom-end"
            offset={10}
            style={{ zIndex: 3 }}
          >
            <div className="bg-white flex flex-col dark:bg-darkblack-300 rounded-lg shadow-lg space-y-1 p-2 mt-[-10px] overflow-y-auto overflow-style-none">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.survivalSurveyDeathDots}
                    onChange={handleLayerChange}
                    color="success"
                    name="survivalSurveyDeathDots"
                  />
                }
                label={tSurvivalSurveys(
                  'details.body.detailsResults.map.control.plantsDead',
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.survivalSurveyLiveDots}
                    onChange={handleLayerChange}
                    color="success"
                    name="survivalSurveyLiveDots"
                  />
                }
                label={tSurvivalSurveys(
                  'details.body.detailsResults.map.control.plantsAlive',
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.photos}
                    onChange={handleLayerChange}
                    color="success"
                    name="photos"
                  />
                }
                label={tSurvivalSurveys(
                  'details.body.detailsResults.map.control.photosEvaluation',
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.survivalSurveyReboleiras}
                    onChange={handleLayerChange}
                    color="success"
                    name="survivalSurveyReboleiras"
                  />
                }
                label={tSurvivalSurveys(
                  'details.body.detailsResults.map.control.reboleiras',
                )}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.walkline}
                    onChange={handleLayerChange}
                    color="success"
                    name="walkline"
                  />
                }
                label={tSurvivalSurveys(
                  'details.body.detailsResults.map.control.polyline',
                )}
              />
              {hasPointsInForestRegistry && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedLayers.interpolatedGrid}
                      onChange={handleLayerChange}
                      color="success"
                      name="interpolatedGrid"
                    />
                  }
                  label={tSurvivalSurveys(
                    'details.body.detailsResults.map.control.mapInterpolated',
                  )}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedLayers.forestRegistry}
                    onChange={handleLayerChange}
                    color="success"
                    name="forestRegistry"
                  />
                }
                label={tSurvivalSurveys('details.body.detailsResults.map.control.talhao')}
              />
            </div>
          </BasePopup>
          <div
            ref={mapContainerRef}
            style={{ height: '548px', zIndex: 1 }}
            className="rounded-lg w-full bg-bgray-200"
          />
        </div>
      )}
    </div>
  );
};

export default observer(DetailsMap);
