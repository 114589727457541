import React from 'react';
import { observer } from 'mobx-react';
import { globalStore, userStore } from '@/store';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { LanguageTypes } from '@/store/config/user/types';
import { SlGlobe } from 'react-icons/sl';

import Modal from '../modals/modal-default';
import Select from '../forms/Select';
import { useTranslation } from 'react-i18next';
import { translationI18n } from '@/i18n';
import { languages } from '@/utils/helpers/static_options';
export const acceptedsLanguages: {
  value: LanguageTypes;
  label: string;
}[] = [
  { value: 'pt-br', label: translationI18n('common:languages.portuguese') },
  ...languages,
];
function LanguageSelect() {
  // you can change default theme here

  const { handleLanguage, user } = userStore;
  const { language } = user;
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const { handleModalLanguage, modalLanguage } = globalStore;
  const tGeneral = useTranslation('general').t;
  return (
    <>
      <button
        aria-label="none"
        type="button"
        id="theme-toggle"
        onClick={handleClick}
        className="relative flex h-[40px] w-[40px] items-center justify-center rounded-[6px] border border-primary-200 dark:border-darkblack-400"
      >
        <SlGlobe className="text-primary-200 dark:text-white" />
      </button>

      <BasePopup id={id} open={open} anchor={anchor} placement="bottom" className="z-50">
        <div className="bg-white dark:bg-darkblack-400 rounded-lg shadow-lg py-2 pb-2 max-h-48 overflow-y-auto overflow-style-none z-50">
          <ul className="">
            {acceptedsLanguages.map((lang) => {
              return (
                <li
                  key={lang.value}
                  className={`${lang.value === language ? 'text-primary-200' : 'dark:text-white'} cursor-pointer py-1 px-3 dark:hover:bg-bgray-100 dark:hover:bg-opacity-55 hover:bg-bgray-200`}
                  onClick={() => {
                    setAnchor(null);
                    handleLanguage(lang.value);
                  }}
                >
                  {lang.label}
                </li>
              );
            })}
          </ul>
        </div>
      </BasePopup>
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        className={`fixed left-0 top-0  h-screen w-screen ${anchor ? 'block' : 'hidden'} z-20`}
      ></div>

      <Modal
        isActive={modalLanguage}
        handleClose={() => handleModalLanguage(false)}
        title={tGeneral('changeLanguage')}
        variant="sm"
      >
        <div className="w-full">
          <Select
            value={language}
            data={acceptedsLanguages}
            onChange={(newLanguage) => {
              handleLanguage(newLanguage);
              handleModalLanguage(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
}

export default observer(LanguageSelect);
