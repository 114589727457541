import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Theme from '../../../../tailwind.config';
import { observer } from 'mobx-react-lite'; // Alterar para mobx-react-lite
import { userStore } from '@/store'; // Importar corretamente o store
import Colors from '@/utils/colors';

// Aplicar diretamente o styled ao Switch
const SwitchInput = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    color={props.checked ? 'success' : 'error'}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    '& + .MuiSwitch-track': {
      backgroundColor:
        userStore.theme === 'dark' // Corrigir para verificar '' ou 'dark'
          ? undefined
          : Colors.bgray[300],
      border: 0,
    },
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Theme.theme.extend.colors.primary[200],
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {},
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: Theme.theme.extend.colors.primary[200],
      border: '6px solid #fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: userStore.theme === '' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: userStore.theme === '' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default observer(SwitchInput);
