import { UpdateMyUserDataType } from '@/component/modals/modal-user/types';
import {
  AddUserDataType,
  AddUserResponseType,
  DeleteUserResponseType,
  GetUserListDataType,
  GetUserListResponseType,
  UpdateUserData,
} from './types';
import { del, get, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';

export const getUserList = async (
  data: GetUserListDataType,
): Promise<GetUserListResponseType> => {
  return await get(url.GET_USERS, { params: data });
};

export const saveUser = async (data: AddUserDataType): Promise<AddUserResponseType> => {
  return await post(url.POST_USERS, data);
};

export const updateUser = async (data: UpdateUserData): Promise<AddUserResponseType> => {
  return await put(url.POST_USERS + '/' + data?.id, data);
};
export const updateMyUser = async (
  data: UpdateMyUserDataType,
): Promise<AddUserResponseType> => {
  return await put(url.POST_USERS + '/' + data.id, data);
};
export const changeKeyUser = async (
  data: UpdateUserData,
): Promise<AddUserResponseType> => {
  return await put(url.PUT_CHANGE_KEY_USER + '/' + data?.id, data);
};
export const changeMasterUser = async (
  data: UpdateUserData,
): Promise<AddUserResponseType> => {
  return await put(url.PUT_CHANGE_MASTER_USER + '/' + data?.id, data);
};

export const deleteUser = async (id: number): Promise<DeleteUserResponseType> => {
  return await del(url.POST_USERS + '/' + id);
};
export const confirmPassword = async (data: {
  password: string;
}): Promise<{ data: { valid: boolean }; message: string }> => {
  return await post(url.CONFIRM_PASSWORD, data);
};
export const newPasswordRequest = async (data: {
  newPassword: string;
  confirmPassword: string;
}) => {
  return await put(url.NEW_PASSWORD_AUTENTICADO, data);
};
