import { makeAutoObservable, makeObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import {
  DashboardFiltersSurvivalSurveysType,
  DashboardSurvivalSurveysDataType,
  DashboardSurvivalSurveysRequestType,
  DashboardSurvivalSurveysSummaryResponseType,
  GroupType,
  LoadingDashboardSurvivalSurveys,
  SurvivalSurveysDataCausesType,
  SurvivalSurveysDataMapType,
} from './dashboard-survival-surveys-types';
import { inititalStateSurvivalSurveys } from './dashboard-survival-surveys-initial-states';
import { EChartsOption } from 'echarts';
import _, { isArray } from 'lodash';
import { getOptionsChartsBar } from './causes-graph/bar-chart-options';
import { userStore } from '@/store';
import { getPathologyData } from './causes-graph/causes-graph-services';
import {
  getSurvivalSurveysCauses,
  getSurvivalSurveysData,
  getSurvivalSurveysFilters,
  getSurvivalSurveysMap,
  getSurvivalSurveysSummary,
} from './dashboard-survival-surveys-services';
import { translationI18n } from '@/i18n';

export class DashboardSurvivalSurveysStore {
  constructor() {
    makeAutoObservable(this);
    makeObservable(this);
    void makePersistable(this, {
      name: 'DashboardSurvivalSurveysStore',
      properties: [],
    });
  }
  summary: DashboardSurvivalSurveysSummaryResponseType =
    inititalStateSurvivalSurveys.summary;
  loading: LoadingDashboardSurvivalSurveys = inititalStateSurvivalSurveys.loading;

  filterSurvivalSurveys: DashboardSurvivalSurveysRequestType =
    inititalStateSurvivalSurveys.filter;
  filtersSurvivalSurveys: DashboardFiltersSurvivalSurveysType =
    inititalStateSurvivalSurveys.filters;

  survivalSurveysGroup: GroupType = 'regiao';

  survivalSurveysDataMap: SurvivalSurveysDataMapType[] = [];
  survivalSurveyEvaluationMapFilter: number = 0;
  survivalSurveysDataCause: SurvivalSurveysDataCausesType[] = [];
  optionsSurvivalChart: EChartsOption = {};
  causesDeaths: {
    cause: string;
    show: boolean;
  }[] = [];
  selectedChart: {
    value: string;
    label: string;
  } = {
    label: translationI18n('home:survivalSurveys.causesGraph.chartType.bar'),
    value: 'Bar',
  };
  deathCauseHighlight: string = '';
  minSurvivalSurveys: number = 0;

  survivalSurveysDataChart: DashboardSurvivalSurveysDataType[] = [];

  causesChart: {
    cause: string;
    show: boolean;
  }[] = [];
  evaluationsChart: {
    evaluation: string;
    show: boolean;
  }[] = [];
  groupedCategorysChart: string[] = [];

  reset = () => {
    this.summary = inititalStateSurvivalSurveys.summary;
    this.loading = inititalStateSurvivalSurveys.loading;

    this.filterSurvivalSurveys = inititalStateSurvivalSurveys.filter;
    this.filtersSurvivalSurveys = inititalStateSurvivalSurveys.filters;

    this.survivalSurveysGroup = 'regiao';

    this.survivalSurveysDataMap = [];
    this.survivalSurveyEvaluationMapFilter = 0;
    this.survivalSurveysDataCause = [];
    this.optionsSurvivalChart = {};
    this.causesDeaths = [];
    this.selectedChart = {
      label: translationI18n('home:survivalSurveys.causesGraph.chartType.bar'),
      value: 'Bar',
    };
    this.deathCauseHighlight = '';
    this.minSurvivalSurveys = 0;

    this.survivalSurveysDataChart = [];

    this.causesChart = [];
    this.evaluationsChart = [];
    this.groupedCategorysChart = [];
  };

  setMinSurvivalSurveys = (min: number) => {
    runInAction(() => {
      this.minSurvivalSurveys = min;
    });
  };

  handleSelectedChart = (newSelectedChart: { label: string; value: string }) => {
    let newOptions: EChartsOption = {};
    if (newSelectedChart.value === 'Pie') {
      newOptions = getPathologyData({
        deathCauses: this.survivalSurveysDataCause,
      });
    }
    if (newSelectedChart.value === 'Bar') {
      newOptions = getOptionsChartsBar(this.survivalSurveysDataCause, userStore.theme);
    }
    let causesDeath = this.causesDeaths;
    if (
      isArray(newOptions.series) &&
      newOptions.series.length > 0 &&
      isArray(newOptions.series[0].data)
    ) {
      causesDeath = newOptions.series[0].data.map((serie) => {
        return {
          cause: serie.name as string,
          show: true,
        };
      });
    }
    runInAction(() => {
      this.selectedChart = newSelectedChart;
      this.optionsSurvivalChart = newOptions;
      this.causesDeaths = causesDeath;
    });
  };
  handleLoading = (key: keyof LoadingDashboardSurvivalSurveys, value: boolean) => {
    runInAction(() => {
      this.loading[key] = value;
    });
  };

  getSurvivalSurveysDataChart = async (survivalSurveysGroup: GroupType) => {
    this.handleLoading('survivalChart', true);
    try {
      const response = await getSurvivalSurveysData({
        ...this.filterSurvivalSurveys,
        companyId: userStore.companyActive.id,
        group: survivalSurveysGroup,
      });
      const groupedDataEvaluation = _.chain(response)
        .groupBy('evaluation')
        .map((group, key) => ({ group: key, values: group }))
        .value() as unknown as DashboardSurvivalSurveysDataType[];

      const causes = response.flatMap((row) => row.causes.flatMap((c) => c.cause));
      const newMin = _.min(response.map((row) => row.mediaPonderada)) || 0;

      const groupedData = _.chain(response)
        .groupBy(survivalSurveysGroup)
        .map((_, key) => key)
        .value();

      const evaluationsData = groupedDataEvaluation.map((row) => ({
        evaluation: `${row.group} ${translationI18n('survivalSurveys:survivalSurveysList.days')}`,
        show: true,
      }));

      runInAction(() => {
        this.survivalSurveysDataChart = groupedDataEvaluation;
        this.groupedCategorysChart = groupedData;
        this.evaluationsChart = evaluationsData;
        this.causesChart = Array.from(new Set(causes.sort())).map((causeName) => ({
          cause: causeName,
          show: true,
        }));
        this.minSurvivalSurveys = (
          (newMin > 0 ? (newMin - 10 < 0 ? 0 : newMin - 3) : 0) || 0
        ).toFixed(0) as unknown as number;
      });
      this.handleLoading('survivalChart', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('survivalChart', false);
    }
  };
  getFiltersSurvivalSurveys = async () => {
    this.handleLoading('filters', true);
    this.handleLoading('filtersMap', true);
    try {
      const newFilters = await getSurvivalSurveysFilters({
        ...this.filterSurvivalSurveys,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        if (
          (this.survivalSurveyEvaluationMapFilter === 0 ||
            (newFilters.time &&
              !newFilters.time
                ?.map((time) => Number(time?.value))
                .includes(this.survivalSurveyEvaluationMapFilter))) &&
          newFilters?.time?.[0]?.value
        ) {
          this.survivalSurveyEvaluationMapFilter = Number(newFilters?.time[0]?.value);
        } else {
          this.handleLoading('filtersMap', false);
        }
        this.filtersSurvivalSurveys = newFilters;
      });
      this.handleLoading('filters', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('filters', false);
    }
  };
  getSuvivalSurveysDataMap = async () => {
    this.handleLoading('filtersMap', true);
    try {
      const response = await getSurvivalSurveysMap({
        ...this.filterSurvivalSurveys,
        companyId: userStore.companyActive.id,
        evaluation: this.survivalSurveyEvaluationMapFilter,
      });
      runInAction(() => {
        this.survivalSurveysDataMap = response;
      });
      this.handleLoading('filtersMap', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('filtersMap', false);
    }
  };
  getSuvivalSurveysDataCause = async () => {
    this.handleLoading('causes', true);
    try {
      const response = await getSurvivalSurveysCauses({
        ...this.filterSurvivalSurveys,
        companyId: userStore.companyActive.id,
        evaluation: this.survivalSurveyEvaluationMapFilter,
      });
      runInAction(() => {
        this.survivalSurveysDataCause = response;
        this.handleSelectedChart({
          label: translationI18n('home:survivalSurveys.causesGraph.chartType.bar'),
          value: 'Bar',
        });
      });
      this.handleLoading('causes', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('causes', false);
    }
  };
  getSuvivalSurveysSummary = async () => {
    this.handleLoading('summary', true);
    try {
      const response = await getSurvivalSurveysSummary({
        ...this.filterSurvivalSurveys,
        companyId: userStore.companyActive.id,
      });
      runInAction(() => {
        this.summary = response;
      });
      this.handleLoading('summary', false);
    } catch (error: any) {
      console.error(error.message);
      this.handleLoading('summary', false);
    }
  };
  handleFiltersSurvivalSurveys = (
    key: keyof DashboardSurvivalSurveysRequestType,
    value: string[] | Date,
  ) => {
    runInAction(() => {
      this.filterSurvivalSurveys = { ...this.filterSurvivalSurveys, [key]: value };
    });
  };
  handleClearFiltersSurvivalSurveys = () => {
    runInAction(() => {
      this.filterSurvivalSurveys = inititalStateSurvivalSurveys.filter;
    });
  };
  handleSurvivalSurveyGroup = (group: GroupType) => {
    runInAction(() => {
      this.survivalSurveysGroup = group;
    });
  };
  handleDeathCauseHighlight = (newDeathCauseHighlight: string) => {
    runInAction(() => {
      this.deathCauseHighlight = newDeathCauseHighlight;
    });
  };
  handleSurvivalSurveysEvaluationTime = (time: number) => {
    runInAction(() => {
      this.survivalSurveyEvaluationMapFilter = time;
    });
  };
  handleCausesDeaths = (cause: string) => {
    runInAction(() => {
      this.causesDeaths = this.causesDeaths.map((item) =>
        cause === item.cause ? { cause: cause, show: !item.show } : item,
      );
    });
  };
  handleCausesDeathsSurvivalSurveysChart = (cause: string) => {
    runInAction(() => {
      this.causesChart = this.causesChart.map((item) =>
        cause === item.cause ? { cause: cause, show: !item.show } : item,
      );
    });
  };
  handleGroupsSurvivalSurveysChart = (evaluation: string) => {
    runInAction(() => {
      this.evaluationsChart = this.evaluationsChart.map((item) =>
        evaluation === item.evaluation
          ? { evaluation: evaluation, show: !item.show }
          : item,
      );
    });
  };
}
