import EChartComponent from '@/component/Echart';
import { Skeleton } from '@mui/material';
import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import LegendChart from './LegendChart';
import { survivalSurveysEvaluationType } from '../../../../quality-survival-surveys-types';
import { filterAndGroupByDeathCause, getPathologyData } from './services';
import { useTranslation } from 'react-i18next';

const DetailsCausesGraph = ({
  evaluation,
}: {
  evaluation: survivalSurveysEvaluationType;
}) => {
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const chartRef = useRef<HTMLDivElement>(null);
  const loadingEvaluationData = false;
  if (!evaluation) return null;
  const selectedChart = 'Pie';

  if (loadingEvaluationData) {
    return (
      <div className="dark:bg-darkblack-400 flex flex-col bg-bgray-200 rounded-lg min-h-[260px] flex-1">
        <Skeleton
          variant="rectangular"
          height={'100%'}
          width={'100%'}
          className=" dark:bg-darkblack-400"
        />
      </div>
    );
  }
  let newOptions = {};
  if (selectedChart === 'Pie') {
    newOptions = getPathologyData({
      deathCauses: filterAndGroupByDeathCause(evaluation),
    });
  }
  // if (selectedChart === 'Bar') {
  //   newOptions = getOptionsChartsBar(this.survivalSurveysDataCause,userStore.theme);
  // }

  return (
    <div className="dark:bg-darkblack-400 flex flex-col bg-bgray-200 p-2 rounded-lg flex-1">
      <div className="flex justify-between w-full">
        <p className="text-semibold dark:text-white">
          {tSurvivalSurveys('details.body.detailsResults.causesGraph.title')}
        </p>
      </div>
      <div className="flex-col flex justify-center items-center w-full relative">
        <EChartComponent
          chartRef={chartRef}
          option={{ ...newOptions, height: '140px', top: '10px' }}
          style={{
            zIndex: 1,
            width: '100%',
            height: '150px',
            padding: 0,
          }}
        />
        <LegendChart optionsSurvivalChart={newOptions} />
      </div>
    </div>
  );
};
export default observer(DetailsCausesGraph);
