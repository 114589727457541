import BtnClose from '@/component/buttons/BtnClose';
import { ModalProps } from './types';
import { IconWarning } from '@/assets/images/icons/iconWarning';
import { IconExclude } from '@/assets/images/icons/iconExclude';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import DangerButton from '@/component/buttons/DangerButton';
import IconAlert from '@/assets/images/icons/IconAlert';
import { translationI18n } from '@/i18n';

const ModalActions = ({
  isActive,
  loading,
  handleClose,
  handleSubmit = () => {},
  submitText = translationI18n('common:actions.send'),
  cancelText = translationI18n('common:actions.cancel'),
  title,
  description,
  variant = 'delete',
}: ModalProps) => {
  return (
    <div
      className={`modal fixed inset-0 z-50 h-full overflow-y-auto flex items-center justify-center ${
        isActive ? '' : 'hidden'
      }`}
      id="multi-step-modal"
    >
      <div className="modal-overlay absolute inset-0 bg-gray-500 opacity-75 dark:bg-bgray-900 dark:opacity-50"></div>
      <div className="modal-content px-4">
        <div className="step-content step-1">
          {/* My Content */}
          <div
            className={`max-w-[381px] min-w-[300px] rounded-lg bg-white dark:bg-darkblack-600 p-6 transition-all relative`}
          >
            <header className="mb-5">
              {variant === 'delete' ? (
                <IconExclude />
              ) : variant === 'alert' ? (
                <IconAlert />
              ) : (
                <IconWarning />
              )}
              <div className="absolute top-0 right-0 pt-5 pr-5">
                <BtnClose close={handleClose} />
              </div>
            </header>
            <h3 className="font-bold text-xl mt-3 dark:text-gray-200">{title}</h3>
            <p className="text-bgray-600 text-sm mt-3">{description}</p>
            <div className="flex space-x-3 items-center justify-end mt-5">
              <SecondaryButton text={cancelText} onClick={handleClose} />
              {variant === 'delete' || variant === 'alert' || variant === 'inactive' ? (
                <DangerButton
                  loading={loading}
                  text={submitText}
                  onClick={handleSubmit}
                />
              ) : (
                <PrimaryButton
                  loading={loading}
                  text={submitText}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalActions;
