import { SurvivalSurveyCauseType } from '../../survival-surveys-causes-types';
import OriginItemTableHeader from './OriginItemTableHeader';
import OriginItemTableRows from './OriginItemTableRows';

const OriginItemTable = ({
  origin,
  canInsert,
  loadingUpdateCause,
  onHandleActive,
}: {
  origin: SurvivalSurveyCauseType[];
  canInsert: boolean;
  loadingUpdateCause: string;

  onHandleActive: (cause: SurvivalSurveyCauseType) => void;
}) => {
  return (
    <div className="w-full mt-3">
      <table className="w-full ">
        <OriginItemTableHeader />
        <OriginItemTableRows
          origin={origin}
          canInsert={canInsert}
          onHandleActive={onHandleActive}
          loadingUpdateCause={loadingUpdateCause}
        />
      </table>
    </div>
  );
};

export default OriginItemTable;
