import { useEffect, useMemo, useState } from 'react';
import {
  deleteLevel,
  getLevels,
  getPermissionsByLevelAndCompany,
  savePermissionLevel,
  updateLevelsOrder,
  updatePermissionLevel,
} from './levels-permissions-services';
import {
  DeleteModalVisbleProps,
  LevelPermissionsControllerType,
  LevelType,
  ModuleAndPermissionType,
  permissionsPropsType,
  PermissionSwitchType,
  PermissionType,
  SavePermissionsDataType,
  UpdatePermissionsDataType,
} from './levels-permissions-types';
import { isNumber } from 'lodash';
import { Toast } from '@/component/Toast';
import { companyProfileStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { OptionsType } from '@/component/Table/types';
import { DropResult } from 'react-beautiful-dnd';
import { translationI18n } from '@/i18n';
const { showSuccess } = Toast;

const LevelsPermissionsController = (): LevelPermissionsControllerType => {
  const {
    companyData,
    isMyCompany,
    levels,
    handleLevels: setLevels,
    activeScreen,
  } = companyProfileStore;
  const companyId = companyData.id;

  //DATAS STATES
  const [modulesAndServicesData, setSetModulesAndServiesData] = useState<
    ModuleAndPermissionType[]
  >([]);
  const [levelUpdate, setLevelUpdate] = useState<LevelType>();
  const [activeLevel, setActiveLevel] = useState<LevelType>();
  const [newOrderLevels, setNewOrderLevels] = useState<LevelType[]>([]);
  //MODAL STATES
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<DeleteModalVisbleProps>({
    show: false,
    levelId: 0,
  });
  const [reoderModalVisbile, setReoderModalVisbile] = useState<boolean>(false);

  //LOADING STATES
  const [loadingLevels, setLoadingLevels] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingPermissionSwitch, setLoadingPermissionSwitch] =
    useState<PermissionSwitchType>({
      service: '',
      permissionField: '',
      loading: false,
    });

  const [reloadDataState, setReloadDataState] = useState(false);
  const reloadData = () => setReloadDataState(true);
  const [loadingSubmitNewOrder, setLoadingSubmitNewOrder] = useState(false);

  //FUNCTIONS GET DATA
  async function getLevelsFuncion() {
    setLoadingLevels(true);
    const responseLevels = await getLevels({
      companyId: companyId,
    });
    if (responseLevels.totalItems > 0) {
      setLevels(responseLevels.levels);
    }
    if (!activeLevel?.id || !responseLevels.levels.includes(activeLevel)) {
      setActiveLevel(responseLevels.levels[0]);
    }
    setReloadDataState(false);
    setLoadingLevels(false);
  }

  async function getPermissions() {
    if (!loadingModules) {
      setLoadingModules(true);
    }
    const response = await getPermissionsByLevelAndCompany({
      levelId: Number(activeLevel?.id),
      companyId: companyId,
    });
    setSetModulesAndServiesData(response.permissions);
    setLoadingModules(false);
  }

  //EFFECTS
  useEffect(() => {
    if (activeScreen === 'levelsPermissions') {
      setActiveLevel(undefined);
      setLevels([]);
      getLevelsFuncion();
    }
  }, [companyData.id, activeScreen]);

  useEffect(() => {
    activeLevel?.id && getPermissions();
  }, [activeLevel]);

  useMemo(() => {
    reloadDataState && getLevelsFuncion();
  }, [reloadDataState]);

  //FUNCTIONS SUBMIT
  const onHandlePermission = async (
    permission: PermissionType,
    permissionField: permissionsPropsType,
  ) => {
    setLoadingPermissionSwitch({
      service: permission.service,
      permissionField: permissionField,
      loading: true,
    });
    const isUpdate =
      permission?.permissionId !== null && isNumber(permission.permissionId);
    let permissionId = permission.permissionId;
    let dataSave: SavePermissionsDataType | UpdatePermissionsDataType;
    try {
      if (isUpdate) {
        dataSave = {
          id: Number(permission.permissionId),
          permissionRead:
            permissionField !== 'permissionRead' && !permission[permissionField]
              ? true
              : permissionField === 'permissionRead'
                ? !permission.permissionRead
                : permission.permissionRead,
          permissionInsert:
            permissionField === 'permissionRead' && permission[permissionField]
              ? false
              : permissionField === 'permissionInsert'
                ? !permission.permissionInsert
                : permission.permissionInsert,
          permissionUpdate:
            permissionField === 'permissionRead' && permission[permissionField]
              ? false
              : permissionField === 'permissionUpdate'
                ? !permission.permissionUpdate
                : permission.permissionUpdate,
          permissionDelete:
            permissionField === 'permissionRead' && permission[permissionField]
              ? false
              : permissionField === 'permissionDelete'
                ? !permission.permissionDelete
                : permission.permissionDelete,
          permissionApprove:
            permissionField === 'permissionRead' && permission[permissionField]
              ? false
              : permissionField === 'permissionApprove'
                ? !permission.permissionApprove
                : permission.permissionApprove,
        };
        const response = await updatePermissionLevel(dataSave);
        showSuccess(response);
      } else {
        dataSave = {
          levelId: Number(activeLevel?.id),
          service: permission.service,
          permissionRead:
            permissionField !== 'permissionRead' && !permission[permissionField]
              ? true
              : permissionField === 'permissionRead',
          permissionInsert: permissionField === 'permissionInsert',
          permissionUpdate: permissionField === 'permissionUpdate',
          permissionDelete: permissionField === 'permissionDelete',
          permissionApprove: permissionField === 'permissionApprove',
        };
        const response = await savePermissionLevel(dataSave);
        permissionId = response.data.id;
        showSuccess(response);
      }
      const newPermissions = modulesAndServicesData.map((module) => {
        return {
          ...module,
          services: module.services.map((serv) => {
            return {
              ...serv,
              permissionId:
                serv.service === permission.service ? permissionId : serv.permissionId,
              permissionRead:
                serv.service === permission.service
                  ? dataSave.permissionRead
                  : serv.permissionRead,
              permissionInsert:
                serv.service === permission.service
                  ? dataSave.permissionInsert
                  : serv.permissionInsert,
              permissionUpdate:
                serv.service === permission.service
                  ? dataSave.permissionUpdate
                  : serv.permissionUpdate,
              permissionDelete:
                serv.service === permission.service
                  ? dataSave.permissionDelete
                  : serv.permissionDelete,
              permissionApprove:
                serv.service === permission.service
                  ? dataSave.permissionApprove
                  : serv.permissionApprove,
            };
          }),
        };
      });
      setSetModulesAndServiesData(newPermissions);
      setLoadingPermissionSwitch({
        service: '',
        permissionField: '',
        loading: false,
      });
    } catch (error: any) {
      console.error(error.message);
      setLoadingPermissionSwitch({
        service: '',
        permissionField: '',
        loading: false,
      });
    }
  };

  const onHandleDeleteLevel = async () => {
    setLoadingDelete(true);
    try {
      const response = await deleteLevel(deleteModalVisible.levelId);
      setLevels(
        levels
          .filter((level) => level.id !== deleteModalVisible.levelId)
          .map((level, index) => {
            return {
              ...level,
              level: index + 1,
            };
          }),
      );
      showSuccess(response);
    } catch (error: any) {
      console.error(error.message);
      /* empty */
    }
    setLoadingDelete(false);
    setDeleteModalVisible({
      show: false,
      levelId: 0,
    });
  };

  const onHandleSubmitNewOrderLevel = async () => {
    setLoadingSubmitNewOrder(true);
    try {
      const resposne = await updateLevelsOrder(
        newOrderLevels.map((lvl) => {
          return {
            id: lvl.id,
            level: lvl.level,
          };
        }),
      );
      showSuccess(resposne);
      setLevels(newOrderLevels);
      setNewOrderLevels([]);
      setReoderModalVisbile(false);
      setLoadingSubmitNewOrder(false);
    } catch (error: any) {
      console.error(error.message);
      setLoadingSubmitNewOrder(false);
    }
  };
  //OTHERS FUNCTIONS
  const addNewLevel = (level: LevelType) => {
    const newLevels = [...levels, level];
    setLevels(newLevels);
    if (!activeLevel?.id || !newLevels.includes(activeLevel)) {
      setActiveLevel(newLevels[0]);
    }
    setAddModalVisible(false);
  };
  const updateLocaLevel = (level: LevelType) => {
    const newLevels = levels.map((lvl) => (lvl.id === level.id ? level : lvl));
    setLevels(newLevels);
    setAddModalVisible(false);
  };

  //MODALS OPEN
  const handleAddLevelModal = () => {
    setLevelUpdate(undefined);
    setAddModalVisible(true);
  };
  const handleUpdateLevelModal = (level: LevelType) => {
    setLevelUpdate(level);
    setAddModalVisible(true);
  };
  const handleReorderLevelsModal = () => {
    if (reoderModalVisbile) {
      setNewOrderLevels([]);
    } else {
      setNewOrderLevels(levels);
    }
    setReoderModalVisbile(!reoderModalVisbile);
  };

  const handleDeleteLevelModal = (level: LevelType) => {
    setDeleteModalVisible({
      show: true,
      levelId: level?.id,
    });
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return; // se soltar fora da área droppable

    const { source, destination } = result;
    if (source.index === destination.index) return; // se não houver mudança de posição

    const reorderedItems = Array.from(newOrderLevels);
    const movedItem = reorderedItems[source.index];
    reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    // Atualizar a ordem dos itens na lista
    const updatedItems = reorderedItems.map((item, index) => {
      return {
        ...item,
        level: index + 1,
      };
    });
    setNewOrderLevels(updatedItems);
  };

  const options: OptionsType[] = [
    {
      label: translationI18n('common:actions.reorder'),
      action: () => handleReorderLevelsModal(),
      hide: !checkPermission({
        permission: 'permissionUpdate',
        service: 'levels',
      }),
    },
    {
      label: translationI18n('common:actions.edit'),
      action: (x: LevelType) => handleUpdateLevelModal(x),
      hide: !checkPermission({
        permission: 'permissionUpdate',
        service: 'levels',
      }),
    },
    {
      label: translationI18n('common:actions.delete'),
      action: (x: LevelType) => handleDeleteLevelModal(x),
      hide: !checkPermission({
        permission: 'permissionDelete',
        service: 'levels',
      }),
      variant: 'danger',
    },
  ].filter((x) => !x.hide);
  return {
    activeLevel,
    setActiveLevel,
    loadingLevels,
    levels,
    modulesAndServicesData,
    loadingModules,
    onHandlePermission,
    loadingPermissionSwitch,
    addModalVisible,
    setAddModalVisible,
    levelUpdate,
    handleAddLevelModal,
    handleUpdateLevelModal,
    addNewLevel,
    updateLocaLevel,
    deleteModalVisible,
    setDeleteModalVisible,
    handleDeleteLevelModal,
    loadingDelete,
    onHandleDeleteLevel,
    reloadData,
    isMyCompany,
    onDragEnd,
    reoderModalVisbile,
    handleReorderLevelsModal,
    onHandleSubmitNewOrderLevel,
    newOrderLevels,
    loadingSubmitNewOrder,
    options,
  };
};
export default LevelsPermissionsController;
