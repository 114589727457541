const ButtonCameraIcon = () => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd_408_20638)">
        <circle cx="26.979" cy="25.0375" r="16.0209" fill="white" />
      </g>
      <path
        d="M24.0706 22.0049C23.3966 22.0069 23.0235 22.023 22.717 22.1517C22.2876 22.3319 21.938 22.6648 21.7337 23.0878C21.567 23.4331 21.5397 23.8755 21.4852 24.7603L21.3996 26.1467C21.2639 28.347 21.1961 29.4471 21.8417 30.1436C22.4874 30.84 23.575 30.84 25.7504 30.84H27.913C30.0884 30.84 31.1761 30.84 31.8217 30.1436C32.4673 29.4471 32.3995 28.347 32.2638 26.1467L32.1782 24.7603C32.1237 23.8755 32.0964 23.4331 31.9297 23.0878C31.7254 22.6648 31.3758 22.3319 30.9464 22.1517C30.6399 22.023 30.2668 22.0069 29.5928 22.0049"
        stroke="#141B34"
        strokeWidth="0.828321"
        strokeLinecap="round"
      />
      <path
        d="M29.5934 22.557L29.1043 21.3341C28.8932 20.8064 28.7096 20.2079 28.1668 19.9392C27.8774 19.7959 27.529 19.7959 26.8324 19.7959C26.1357 19.7959 25.7873 19.7959 25.4979 19.9392C24.9552 20.2079 24.7715 20.8064 24.5605 21.3341L24.0713 22.557"
        stroke="#141B34"
        strokeWidth="0.828321"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.7649 26.422C28.7649 27.4894 27.8996 28.3548 26.8322 28.3548C25.7647 28.3548 24.8994 27.4894 24.8994 26.422C24.8994 25.3546 25.7647 24.4893 26.8322 24.4893C27.8996 24.4893 28.7649 25.3546 28.7649 26.422Z"
        stroke="#141B34"
        strokeWidth="0.828321"
      />
      <path
        d="M26.832 22.0049H26.8359"
        stroke="#141B34"
        strokeWidth="1.10443"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_ddd_408_20638"
          x="0.958008"
          y="0.0166016"
          width="52.042"
          height="52.042"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_408_20638"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_408_20638"
            result="effect2_dropShadow_408_20638"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_408_20638"
            result="effect3_dropShadow_408_20638"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_408_20638"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default ButtonCameraIcon;
