import Modal from '@/component/modals/modal-default';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const ModalApprove = () => {
  const { modalStates, handleModal, loading, approveEvaluation } =
    survivalSurveysEvaluationsStore;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  const { evaluation: evaluationData } = modalStates;
  return (
    <Modal
      title={tSurvivalSurveys('modals.modalApprove.title', { cod: evaluationData?.cod })}
      isActive={modalStates.approve}
      handleClose={() => !loading.modalApprove && handleModal('approve', false)}
      variant="sm"
      footer={
        <div className="flex justify-end items-center gap-5">
          <SecondaryButton
            text={tCommon('actions.cancel')}
            onClick={() => !loading.modalApprove && handleModal('approve', false)}
          />
          <PrimaryButton
            text={tCommon('actions.approve')}
            loading={loading.modalApprove}
            onClick={approveEvaluation}
          />
        </div>
      }
    >
      <div className="h-hull w-fulł">
        <h3 className="font-bold text-xl dark:text-gray-200">
          {`${tSurvivalSurveys('modals.modalApprove.title')} #${evaluationData?.cod}?`}
        </h3>
        <p className="text-bgray-600 text-sm my-3">
          {tSurvivalSurveys('modals.modalApprove.description')}
        </p>
      </div>
    </Modal>
  );
};

export default observer(ModalApprove);
