import ModalActions from '@/component/modals/modal-actions';
import { companyProfileStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const ChangeUserMasterModal = observer(() => {
  const { modalStates, handleModal, onChangeMasterUser } = companyProfileStore;
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  return (
    <ModalActions
      isActive={modalStates.changeMasterUser}
      handleClose={() =>
        handleModal({
          ...modalStates,
          user: undefined,
          changeMasterUser: false,
          loading: false,
        })
      }
      handleSubmit={onChangeMasterUser}
      variant={'warning'}
      loading={modalStates.loading}
      title={
        modalStates.user?.master
          ? tCompanyProfile('body.screens.userList.modals.modalChangeMaster.titleAdd')
          : tCompanyProfile('body.screens.userList.modals.modalChangeMaster.titleRemove')
      }
      description={tCompanyProfile(
        'body.screens.userList.modals.modalChangeMaster.description',
      )}
      submitText={
        modalStates.user?.master
          ? tCompanyProfile('body.screens.userList.modals.modalChangeMaster.remove')
          : tCompanyProfile('body.screens.userList.modals.modalChangeMaster.authorize')
      }
      cancelText={tCommon('actions.cancel')}
    />
  );
});
