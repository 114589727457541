import { del, get, post, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  AddSurvivalSurveysDataType,
  AddSurvivalSurveysResponseType,
  DeleteSurvivalSurveysResponseType,
  GetSurvivalSurveysResponseType,
  GetSurvivalSurveysTimesType,
} from './survival-surveys-times-types';

export const getParamsTimes = async (
  data: GetSurvivalSurveysTimesType,
): Promise<GetSurvivalSurveysResponseType> => {
  const result = (await get(url.GET_SURVIVALSURVEYS_TIMES, {
    params: data,
  })) as GetSurvivalSurveysResponseType;
  return result;
};

export const saveParamsTime = async (
  data: AddSurvivalSurveysDataType,
): Promise<AddSurvivalSurveysResponseType> => {
  const resultSave = (await post(
    url.POST_SURVIVALSURVEYS_TIMES,
    data,
  )) as AddSurvivalSurveysResponseType;
  return resultSave;
};
export const udpateParamsTime = async (
  data: Partial<AddSurvivalSurveysDataType>,
): Promise<AddSurvivalSurveysResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await put(
    url.POST_SURVIVALSURVEYS_TIMES + '/' + data.id,
    data,
  )) as AddSurvivalSurveysResponseType;
  return resultSave;
};
export const deleteParamsTime = async (
  data: Partial<AddSurvivalSurveysDataType>,
): Promise<DeleteSurvivalSurveysResponseType> => {
  if (!data?.id) {
    throw new Error('Coluna id obrigatório');
  }
  const resultSave = (await del(
    url.POST_SURVIVALSURVEYS_TIMES + '/' + data.id,
  )) as DeleteSurvivalSurveysResponseType;
  return resultSave;
};
