import { put, putFile } from '@/utils/helpers/api_helper';
import { LanguageTypes, PutChangeImageResponseType } from './types';
import * as url from '@/utils/helpers/url_helper';
import { ConfigUserType, UserType } from '@/utils/types/UsersType';

export const ChangeImage = async ({
  id,
  file,
}: {
  id: number;
  file: File;
}): Promise<PutChangeImageResponseType> => {
  const formData = new FormData();
  if (!file) {
    throw new Error('file é obrigatório.');
  }
  formData.append('file', file);
  const result = (await putFile(
    url.PUT_USERS_CHANGEIMAGE + '/' + id,
    formData,
  )) as PutChangeImageResponseType;
  return result;
};

export async function removeImage(
  id: number,
): Promise<{ data: UserType; message: string }> {
  return await put(url.PUT_USERS_REMOVE_IMAGE + '/' + id);
}
export async function changeLanguage(data: {
  id: number;
  language: LanguageTypes;
}): Promise<{ data: UserType; message: string }> {
  return await put(url.PUT_CHANGE_LANGUAGE + '/' + data.id, data);
}
export async function changeUserConfig(
  data: Partial<ConfigUserType>,
): Promise<{ data: UserType; message: string }> {
  return await put(url.PUT_CHANGE_CONFIG, data);
}
