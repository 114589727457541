import { IoCloseSharp } from 'react-icons/io5';
import { MapContainer, TileLayer, GeoJSON, Polygon, useMap } from 'react-leaflet';
import { ForestRegistryModalImportKMLControllerType } from '../modal-import-kml-types';
import { useEffect, useRef, useState } from 'react';
import { LatLngExpression } from 'leaflet';

const FitBoundsPolygon = ({ positions }: { positions: LatLngExpression[] }) => {
  const map = useMap();

  useEffect(() => {
    if (positions && positions.length > 0) {
      map.fitBounds(positions);
    }
  }, [map, positions]);

  return null;
};

const MapColumnSelectKML = ({
  controller,
}: {
  controller: ForestRegistryModalImportKMLControllerType;
}) => {
  const { layer, fileInfos, handleClearForm } = controller;
  const [allCoordinates, setAllCoordinates] = useState<L.LatLngExpression[]>([]);
  const mapRef = useRef();

  // Atualiza as coordenadas para todos os polígonos
  function handleAllCoordinates() {
    const newAllCoordinates: LatLngExpression[] = [];
    if (layer?.features) {
      layer.features.forEach(
        (feature: {
          geometry: {
            type: string;
            geometries: { type: string; coordinates: number[][][] }[];
            coordinates: number[][][];
          };
        }) => {
          if (feature.geometry.type === 'GeometryCollection') {
            feature.geometry.geometries.forEach((polygon) => {
              if (polygon.type === 'Polygon') {
                polygon.coordinates.flatMap((coord) => {
                  newAllCoordinates.push([coord[0][1], coord[0][0]]);
                });
              }
            });
          } else if (feature.geometry.type === 'Polygon') {
            feature.geometry.coordinates.flatMap((coord: any[][]) => {
              newAllCoordinates.push([coord[0][1], coord[0][0]]);
            });
          }
        },
      );
    }
    setAllCoordinates(newAllCoordinates);
  }

  useEffect(() => {
    if (mapRef.current) {
      handleAllCoordinates();
    }
  }, [mapRef.current, layer]);

  const hashString = (str: string) => {
    let hash = 0;
    for (let i = 0; i < Math.min(str.length, 255); i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  return (
    <div className="hover:cursor-pointer dark:bg-darkblack-400 bg-bgray-100 border-dashed border-2 flex items-center justify-center rounded-lg">
      <div className="relative w-full dark:text-bgray-300">
        <MapContainer
          ref={mapRef}
          center={[-20.36535, -52.068883]}
          zoom={8}
          className="h-[300px]"
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            minZoom={19}
          />
          <TileLayer
            url={
              'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
            }
            minZoom={0}
            maxZoom={18}
            tileSize={256}
          />
          {/* Renderiza os polígonos de update com zIndex maior */}
          {fileInfos &&
            fileInfos.updatePolygons.map((plot, index) => (
              <Polygon
                key={`update-${index}`}
                positions={plot.coordinates}
                color="green"
                fillColor="green"
                fillOpacity={0.4}
                pane="overlayPane" // Coloca na camada de sobreposição (zIndex padrão)
              />
            ))}
          {/* Renderiza os polígonos de create com zIndex maior */}
          {fileInfos &&
            fileInfos.createPolygons.map((plot, index) => (
              <Polygon
                key={`create-${index}`}
                positions={plot.coordinates}
                color="green"
                fillColor="green"
                fillOpacity={0.4}
                pane="overlayPane" // Coloca na camada de sobreposição (zIndex padrão)
              />
            ))}
          {/* Renderiza o GeoJSON com zIndex menor */}
          {layer && (
            <GeoJSON
              key={hashString(JSON.stringify(layer))}
              data={layer}
              color="red"
              pane="overlayPane" // Coloca na camada de sobreposição (zIndex padrão)
            />
          )}
          {/* Componente para ajustar o zoom para os polígonos */}
          <FitBoundsPolygon positions={allCoordinates} />
        </MapContainer>
        {/* Botão para limpar o formulário */}
        <button
          className="absolute rounded-md bg-opacity-60 right-1 top-1 bg-gray-300 p-1"
          style={{ zIndex: 899999 }}
          onClick={handleClearForm}
        >
          <IoCloseSharp size={20} className="text-gray-600" />
        </button>
      </div>
    </div>
  );
};

export default MapColumnSelectKML;
