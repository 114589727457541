import PlantedAreaReasonItemTableHeader from './PlantedAreaReasonItemTableHeader';
import PlantedAreaReasonItemTableRows from './OriginItemTableRows';
import { PlantedAreaReasonType } from '../types';

const PlantedAreaReasonsItemTable = ({
  reasons,
  canInsert,
  loadingUpdateReason,
  onHandleActive,
}: {
  reasons: PlantedAreaReasonType[];
  canInsert: boolean;
  loadingUpdateReason: string;

  onHandleActive: (reason: PlantedAreaReasonType) => void;
}) => {
  return (
    <div className="w-full rounded-lg pb-4 pt-2 bg-white dark:bg-darkblack-500">
      <table className="w-full ">
        <PlantedAreaReasonItemTableHeader />
        <PlantedAreaReasonItemTableRows
          reasons={reasons}
          canInsert={canInsert}
          onHandleActive={onHandleActive}
          loadingUpdateReason={loadingUpdateReason}
        />
      </table>
    </div>
  );
};

export default PlantedAreaReasonsItemTable;
