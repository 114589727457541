import { ColumnsTypes } from '@/component/Table/types';
import { PlantedAreaReasonType } from './planted-area-reasons-types';
import i18n from '@/i18n';
import { languages } from '@/utils/helpers/static_options';
const translation = i18n.t;
export const columns: ColumnsTypes[] = [
  {
    title: translation('master:paramsPage.paramsContent.plantedArea.reasons.columns.cod'),
    field: 'id',
    type: 'int',
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.color',
    ),
    field: 'color',
    type: 'custom',
    custom: (row: PlantedAreaReasonType) => {
      return (
        <div
          className="w-12 h-5 rounded border-2 border-gray-300"
          style={{
            backgroundColor: `${row.color}`,
          }}
        ></div>
      );
    },
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.reason',
    ),
    field: 'reason',
    type: 'string',
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.description',
    ),
    field: 'description',
    type: 'string',
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.translations',
    ),
    field: 'i18n',
    type: 'custom',
    custom: (row: PlantedAreaReasonType) => {
      return Array.from(
        new Set(
          row.i18n.map((lng) => languages.find((l) => l.value === lng.language)?.label),
        ),
      ).join(',');
    },
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.createdAt',
    ),
    field: 'createdAt',
    type: 'datetime',
  },
  {
    title: translation(
      'master:paramsPage.paramsContent.plantedArea.reasons.columns.actions',
    ),
    field: 'c',
    type: 'options',
    ordenable: false,
  },
];
