import ImportAddRowsIcon from '@/assets/images/icons/ImportAddRowsIcon';

import { FileUploadedKmlInfosType } from '../modal-import-kml-types';
import ItemFeedback from '../item-feedback';
import ImportUpdateRowsIcon from '@/assets/images/icons/ImportUpdateRowsIcon';
import { useTranslation } from 'react-i18next';
const FeedbackValidKML = ({
  fileUpload,
  isValiding,
  fileInfos,
}: {
  fileUpload?: File;
  isValiding: boolean;
  fileInfos: FileUploadedKmlInfosType;
}) => {
  const tForestRegistry = useTranslation('forestRegistry').t;
  return (
    <div>
      {fileUpload?.name && !isValiding && fileInfos?.updatePolygons?.length > 0 && (
        <div className="flex-col space-y-2 mt-5">
          <ItemFeedback
            Icon={<ImportUpdateRowsIcon />}
            text={
              fileInfos?.updatePolygons.length
                ? fileInfos?.updatePolygons.length > 1
                  ? tForestRegistry('modals.uploadKML.itemsFeedback.update.textPlural', {
                      lines: fileInfos?.updatePolygons.length,
                    })
                  : tForestRegistry('modals.uploadKML.itemsFeedback.update.textSingular')
                : tForestRegistry('modals.uploadKML.itemsFeedback.update.emptyLines')
            }
            description={tForestRegistry(
              'modals.uploadKML.itemsFeedback.update.description',
            )}
          />
        </div>
      )}
      {fileUpload?.name && !isValiding && fileInfos?.createPolygons.length > 0 && (
        <div className="flex-col space-y-2 mt-5">
          <ItemFeedback
            Icon={<ImportAddRowsIcon />}
            text={
              fileInfos?.createPolygons.length
                ? fileInfos?.createPolygons.length > 1
                  ? tForestRegistry(
                      'modals.uploadKML.itemsFeedback.createLines.textPlural',
                      { lines: fileInfos?.createPolygons.length },
                    )
                  : tForestRegistry(
                      'modals.uploadKML.itemsFeedback.createLines.textSingular',
                    )
                : tForestRegistry('modals.uploadKML.itemsFeedback.createLines.emptyLines')
            }
            description={tForestRegistry(
              'modals.uploadKML.itemsFeedback.createLines.description',
            )}
          />
        </div>
      )}
    </div>
  );
};
export default FeedbackValidKML;
