import BtnClose from '@/component/buttons/BtnClose';
import { ModalProps } from './types';
import ModalUI from '@mui/material/Modal';
import { useEffect, useState } from 'react';

function Modal({
  isActive,
  handleClose,
  title,
  subTitle,
  children,
  variant = 'md',
  footer = undefined,
  badge = undefined,
}: ModalProps) {
  const [style, setstyle] = useState({
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: window.innerWidth < 750 ? '80%' : undefined, // Ajuste para telas pequenas
    p: 4,
  });

  function handleResize(event: any) {
    setstyle({
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: event.currentTarget?.innerWidth < 750 ? '80%' : undefined, // Ajuste para telas pequenas
      p: 4,
    });
  }
  useEffect(() => {
    window.addEventListener('resize', (event) => handleResize(event));
    return window.removeEventListener('resize', (event) => handleResize(event));
  }, []);
  const getWidthClass = () => {
    switch (variant) {
      case 'sm':
        return 'max-w-sm min-w-[30%]';
      case 'md':
        return 'max-w-md min-w-[40%]';
      case 'lg':
        return 'max-w-lg min-w-[50%]';
      case 'xl':
        return 'max-w-xl min-w-[50%]';
      default:
        return 'max-w-md min-w-[40%]';
    }
  };

  return (
    <ModalUI open={isActive}>
      <div
        className={`bg-white flex flex-col dark:bg-darkblack-600 rounded-lg ${getWidthClass()} sm:mx-auto`}
        style={style}
      >
        <div className="mb-5 flex items-center px-4 pt-4">
          <div className="flex-1">
            <div className="flex sm:items-center gap-2 flex-col sm:flex-row">
              <h3 className="font-bold text-bgray-900 dark:text-white text-2xl mb-1">
                {title}
              </h3>
              {badge && badge}
            </div>
            {subTitle && (
              <p className="text-sm text-bgray-600 dark:text-darkblack-300">{subTitle}</p>
            )}
          </div>
          <div>
            <BtnClose close={handleClose} />
          </div>
        </div>
        <div className="flex max-h-[75vh] w-full overflow-y-auto px-4">{children}</div>
        {footer && <div className="px-4 py-4 flex-1 w-full">{footer}</div>}
      </div>
    </ModalUI>
  );
}

export default Modal;
