import { formatNumber } from '@/utils/helpers/formaters';
import Colors from '@/utils/colors';
import { survivalSurveysEvaluationType } from '../../../../quality-survival-surveys-types';
import { BarSeriesOption, ComposeOption, TooltipComponentOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { filterAndGroupByDeathCause } from './services';
import { ThemeTypes } from '@/store/config/user/types';

export function getOptionsChartsBar(
  evaluationData: survivalSurveysEvaluationType,
  theme: ThemeTypes,
): ComposeOption<BarSeriesOption | TooltipComponentOption> {
  const dotsDeaths = evaluationData?.survivalSurveyDots?.filter((dot) => !dot.isAlive);
  const groupPatologys = filterAndGroupByDeathCause(evaluationData);
  return {
    backgroundColor: 'transparent',
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: (params: CallbackDataParams) => {
        const percentage = (Number(params.value) / (dotsDeaths?.length || 1)) * 100;
        return `${params.name}: ${formatNumber(params.value, 0)}\n(${formatNumber(percentage, 0)}%)`;
      },
    },
    height: '30%',
    xAxis: {
      type: 'category',
      data: groupPatologys.map((patology) => patology.cause),
      axisLabel: {
        show: true,
        rotate: 45,
      },
    },
    yAxis: {},
    series: [
      {
        type: 'bar',
        data: groupPatologys.map((patology) => ({
          value: patology.total,
          name: patology.cause,
          itemStyle: {
            color: patology.color,
          },
        })),
        label: {
          show: true,
          formatter: ({ value }) => {
            const percentage = (value / (dotsDeaths?.length || 1)) * 100;
            return ` ${formatNumber(value, 0)} (${formatNumber(percentage, 0)}%)`;
          },
          position: 'top',
          color: theme === 'dark' ? 'white' : Colors.darkblack[600],
        },
      },
    ],
    animation: false,
  };
}
