import general from './general.json';
import master from './master.json';
import signin from './signin.json';
import common from './common.json';
import layout from './layout.json';
import companyProfile from './companyProfile.json';
import forestRegistry from './forestRegistry.json';
import home from './home.json';
import survivalSurveys from './survivalSurveys.json';
import plantedArea from './plantedArea.json';

export default {
  general,
  master,
  signin,
  common,
  layout,
  companyProfile,
  forestRegistry,
  home,
  survivalSurveys,
  plantedArea,
};
