import MapColumnSelectKML from './Map';
import Columns from './Columns';
import { ForestRegistryModalImportKMLControllerType } from '../modal-import-kml-types';

const ColumnSelectKML = ({
  controller,
}: {
  controller: ForestRegistryModalImportKMLControllerType;
}) => {
  return (
    <div className="w-full">
      <MapColumnSelectKML controller={controller} />
      <Columns controller={controller} />
    </div>
  );
};
export default ColumnSelectKML;
