import { translationI18n } from '@/i18n';

export const monthsEnum = () => ({
  1: translationI18n('common:months.january'),
  2: translationI18n('common:months.february'),
  3: translationI18n('common:months.march'),
  4: translationI18n('common:months.april'),
  5: translationI18n('common:months.may'),
  6: translationI18n('common:months.june'),
  7: translationI18n('common:months.july'),
  8: translationI18n('common:months.august'),
  9: translationI18n('common:months.september'),
  10: translationI18n('common:months.october'),
  11: translationI18n('common:months.november'),
  12: translationI18n('common:months.december'),
});
export const monthsNum = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const monthsEnumSummary = () => ({
  1: translationI18n('common:resumeMonth.jan'),
  2: translationI18n('common:resumeMonth.feb'),
  3: translationI18n('common:resumeMonth.mar'),
  4: translationI18n('common:resumeMonth.apr'),
  5: translationI18n('common:resumeMonth.may'),
  6: translationI18n('common:resumeMonth.jun'),
  7: translationI18n('common:resumeMonth.jul'),
  8: translationI18n('common:resumeMonth.aug'),
  9: translationI18n('common:resumeMonth.sep'),
  10: translationI18n('common:resumeMonth.oct'),
  11: translationI18n('common:resumeMonth.nov'),
  12: translationI18n('common:resumeMonth.dec'),
});

export const getArrayMonthsComplete = () => {
  return monthsNum.map((monthNum) => monthsEnumSummary()[monthNum]);
};

export const months_Enum = () => ({
  '01': translationI18n('common:months.january'),
  '02': translationI18n('common:months.february'),
  '03': translationI18n('common:months.march'),
  '04': translationI18n('common:months.april'),
  '05': translationI18n('common:months.may'),
  '06': translationI18n('common:months.june'),
  '07': translationI18n('common:months.july'),
  '08': translationI18n('common:months.august'),
  '09': translationI18n('common:months.september'),
  '10': translationI18n('common:months.october'),
  '11': translationI18n('common:months.november'),
  '12': translationI18n('common:months.december'),
});
