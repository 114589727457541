import ProtoTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { globalStore, userStore } from '@/store';
import LogoutIcon from '@/assets/images/icons/LogoutIcon';
import { observer } from 'mobx-react';
import { HiOutlineBuildingOffice2 } from 'react-icons/hi2';
import IconsMode from './IconsMode';
import SwitchInput from '../forms/SwitchInput';
import { useTranslation } from 'react-i18next';
import { SlGlobe } from 'react-icons/sl';
function ProfilePopup({
  active,
  handlePopup,
}: {
  active: boolean;
  handlePopup: () => void;
}) {
  const { theme, handleTheme } = userStore;
  const tLayout = useTranslation('layout').t;
  const tCommon = useTranslation('common').t;
  const tGeneral = useTranslation('general').t;
  return (
    <div className="profile-wrapper text-left">
      <div
        style={{
          filter: `drop-shadow(12px 12px 40px rgba(0, 0, 0, 0.08))`,
          display: active ? 'block' : 'none',
          zIndex: 99,
        }}
        className={`profile-box transition-all origin-top absolute right-0 top-[65px] hidden overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 ${
          active ? ' block introAnimation' : 'hidden'
        } `}
      >
        <div className="relative w-full px-3 py-2">
          <div>
            <ul>
              <li className="w-full">
                <Link
                  to="#"
                  onClick={() => {
                    userStore.handleModalUser({ ...userStore.modalMyUser, show: true });
                    handlePopup();
                  }}
                >
                  <div className="flex items-center space-x-2 rounded-lg p-3 text-bgray-600 hover:bg-bgray-100 hover:text-bgray-900 hover:dark:bg-darkblack-500">
                    <div className="w-[20px]">
                      <span>
                        <svg
                          className="stroke-bgray-900 dark:stroke-bgray-50"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.1197 12.7805C12.0497 12.7705 11.9597 12.7705 11.8797 12.7805C10.1197 12.7205 8.71973 11.2805 8.71973 9.51047C8.71973 7.70047 10.1797 6.23047 11.9997 6.23047C13.8097 6.23047 15.2797 7.70047 15.2797 9.51047C15.2697 11.2805 13.8797 12.7205 12.1197 12.7805Z"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M18.7398 19.3796C16.9598 21.0096 14.5998 21.9996 11.9998 21.9996C9.39977 21.9996 7.03977 21.0096 5.25977 19.3796C5.35977 18.4396 5.95977 17.5196 7.02977 16.7996C9.76977 14.9796 14.2498 14.9796 16.9698 16.7996C18.0398 17.5196 18.6398 18.4396 18.7398 19.3796Z"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </div>
                    <div className="flex-1">
                      <span className="text-sm font-semibold text-bgray-900 dark:text-gray-200">
                        {tLayout('header.userPopUp.myProfile')}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="w-full sm:hidden block">
                <div className="flex items-center space-x-2 rounded-lg p-3 text-bgray-600 hover:bg-bgray-100 hover:text-bgray-900 hover:dark:bg-darkblack-500">
                  <div className="w-[20px]">
                    <IconsMode />
                  </div>
                  <div className="flex-1 ml-2">
                    <span className="text-sm font-semibold text-bgray-900 dark:text-gray-200">
                      {theme === 'dark'
                        ? tLayout('header.userPopUp.modeThemeLight')
                        : tLayout('header.userPopUp.modeThemeDark')}
                    </span>
                  </div>
                  <SwitchInput
                    checked={theme === 'dark'}
                    onChange={() => {
                      handleTheme(theme === 'dark' ? 'light' : 'dark');
                    }}
                    value={theme === 'dark'}
                  />
                </div>
              </li>
              <li className="w-full sm:hidden block">
                <Link
                  to="#"
                  onClick={() => {
                    globalStore.handleModalLanguage(true);
                    handlePopup();
                  }}
                >
                  <div className=" text-black dark:text-white flex items-center space-x-2 rounded-lg p-3 hover:bg-bgray-100 hover:text-bgray-900 hover:dark:bg-darkblack-500">
                    <div className="w-[20px]">
                      <SlGlobe size={25} className="text-black dark:text-white" />
                    </div>
                    <div className="flex-1">
                      <span className="text-sm font-semibold text-nowrap">
                        {`${tGeneral('changeLanguage')}`}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              {userStore.user.master && (
                <li className="w-full">
                  <Link
                    to="#"
                    onClick={() => {
                      userStore.handleModalChangeCompanies({
                        ...userStore.modalChangeCompany,
                        show: true,
                      });
                      handlePopup();
                    }}
                  >
                    <div className=" text-black dark:text-white flex items-center space-x-2 rounded-lg p-3 hover:bg-bgray-100 hover:text-bgray-900 hover:dark:bg-darkblack-500">
                      <div className="w-[20px]">
                        <HiOutlineBuildingOffice2 size={25} />
                      </div>
                      <div className="flex-1">
                        <span className="text-sm font-semibold text-nowrap">
                          {userStore.companyActive.name}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              )}
              <li
                className="w-full"
                onClick={() => {
                  userStore.handleModalLogout();
                  handlePopup();
                }}
              >
                <div className="flex items-center space-x-2 rounded-lg p-3 text-error-200 hover:bg-bgray-100  hover:dark:bg-darkblack-500 cursor-pointer">
                  <div className="w-[20px]">
                    <LogoutIcon />
                  </div>
                  <div className="flex-1">
                    <span className="text-sm font-semibold">
                      {tCommon('actions.exit')}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/* <div className="my-[14px] h-[1px] w-full bg-bgray-300"></div>
            <div>
              <ul>
                <li className="w-full">
                  <Link to="/settings">
                    <div className="rounded-lg p-3 text-bgray-600 hover:bg-bgray-100 hover:text-bgray-900 dark:text-bgray-50 dark:hover:bg-darkblack-500">
                      <span className="text-sm font-semibold">Settings</span>
                    </div>
                  </Link>
                </li>
                <li className="w-full">
                  <Link to="/users">
                    <div className="rounded-lg p-3 text-bgray-600 hover:bg-bgray-100 hover:text-bgray-900 dark:text-bgray-50 dark:hover:bg-darkblack-500">
                      <span className="text-sm font-semibold">Users</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </div> */}
        </div>
      </div>
    </div>
  );
}
ProfilePopup.propTypes = {
  active: ProtoTypes.bool,
  handlePopup: ProtoTypes.func,
};

export default observer(ProfilePopup);
