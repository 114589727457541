import PrimaryButton from '@/component/buttons/PrimaryButton';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import { forestRegistryStore } from '@/store';
import { BsDownload } from 'react-icons/bs';
import { IoAdd } from 'react-icons/io5';
import { observer } from 'mobx-react';
import ButtonDropdownImport from './ButtonDropdownImport';
import { checkPermission } from '@/utils/helpers/validations';
import { useTranslation } from 'react-i18next';

const ForestRegistryTableHeader = observer(() => {
  const {
    screenActive,
    getForestRegistryDataExport,
    loadingExport,
    loading,
    handleModalState,
  } = forestRegistryStore;
  const tCommon = useTranslation('common').t;
  if (screenActive !== 'table') {
    return null;
  }
  return (
    <div className=" flex items-center justify-between w-full p-3">
      <div className="flex gap-3 items-center"></div>
      <div className="flex flex-1 sm:justify-end gap-3 sm:items-center flex-col sm:flex-row">
        <SecondaryButton
          text={tCommon('actions.export')}
          loading={loadingExport}
          disabled={loading || loadingExport}
          icon={BsDownload}
          onClick={getForestRegistryDataExport}
        />
        <ButtonDropdownImport />
        {checkPermission({
          service: 'forestRegistry',
          permission: 'permissionInsert',
        }) && (
          <PrimaryButton
            text={tCommon('actions.add')}
            onClick={() => handleModalState('add', true)}
            icon={IoAdd}
          />
        )}
      </div>
    </div>
  );
});

export default ForestRegistryTableHeader;
