import SecondaryButton from '@/component/buttons/SecondaryButton';
import ButtonSubmit from '@/component/forms/button-submit';
import Modal from '@/component/modals/modal-default';
import { servicesParamsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { intValitation, numberValitation } from '@/utils/helpers/validations';
import { useMemo } from 'react';
import Input from '@/component/forms/Input';

export const ModalEditPlantedAreaParams = observer(() => {
  const {
    modalState: modalStateParams,
    handleModalState,
    loading,
    savePlantedAreaParams,
    plantedArea,
  } = servicesParamsStore;
  function onCloseModal() {
    handleModalState({ ...modalStateParams, plantedArea: '' });
  }
  const tCompanyProfile = useTranslation('companyProfile').t;
  const tCommon = useTranslation('common').t;
  let title = '';
  switch (modalStateParams.plantedArea) {
    case 'density':
      title = tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.paramsName.densityPlant',
      );
      break;
    case 'discarded':
      title = tCompanyProfile(
        'body.screens.params.items.plantedArea.params.params.paramsName.discardedPlants',
      );
      break;

    default:
      title = '';
      break;
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      minError: 0,
      minAlert: 0,
      target: 0,
      maxAlert: 0,
      maxError: 0,
    },

    validationSchema: Yup.object({
      minError:
        modalStateParams.plantedArea === 'density' ? intValitation() : numberValitation(),
      minAlert:
        modalStateParams.plantedArea === 'density' ? intValitation() : numberValitation(),
      target:
        modalStateParams.plantedArea === 'density' ? intValitation() : numberValitation(),
      maxAlert:
        modalStateParams.plantedArea === 'density' ? intValitation() : numberValitation(),
      maxError:
        modalStateParams.plantedArea === 'density' ? intValitation() : numberValitation(),
    }),

    onSubmit: async (values: {
      minError: number;
      minAlert: number;
      target: number;
      maxAlert: number;
      maxError: number;
    }) => {
      switch (modalStateParams.plantedArea) {
        case 'density':
          await savePlantedAreaParams({
            companyId: userStore.companyActive.id,
            ...plantedArea,
            targetPlantingDensityMinError: values.minError,
            targetPlantingDensityMinAlert: values.minAlert,
            targetPlantingDensity: values.target,
            targetPlantingDensityMaxAlert: values.maxAlert,
            targetPlantingDensityMaxError: values.maxError,
          });
          break;
        case 'discarded':
          await savePlantedAreaParams({
            companyId: userStore.companyActive.id,
            ...plantedArea,
            targetDiscardedPlantsMinError: values.minError,
            targetDiscardedPlantsMinAlert: values.minAlert,
            targetDiscardedPlants: values.target,
            targetDiscardedPlantsMaxAlert: values.maxAlert,
            targetDiscardedPlantsMaxError: values.maxError,
          });
          break;
        default:
          break;
      }
    },
  });

  useMemo(() => {
    switch (modalStateParams.plantedArea) {
      case 'density':
        validation.setFieldValue('minError', plantedArea.targetPlantingDensityMinError);
        validation.setFieldValue('minAlert', plantedArea.targetPlantingDensityMinAlert);
        validation.setFieldValue('target', plantedArea.targetPlantingDensity);
        validation.setFieldValue('maxAlert', plantedArea.targetPlantingDensityMaxAlert);
        validation.setFieldValue('maxError', plantedArea.targetPlantingDensityMaxError);
        break;
      case 'discarded':
        validation.setFieldValue('minError', plantedArea.targetDiscardedPlantsMinError);
        validation.setFieldValue('minAlert', plantedArea.targetDiscardedPlantsMinAlert);
        validation.setFieldValue('target', plantedArea.targetDiscardedPlants);
        validation.setFieldValue('maxAlert', plantedArea.targetDiscardedPlantsMaxAlert);
        validation.setFieldValue('maxError', plantedArea.targetDiscardedPlantsMaxError);
        break;

      default:
        break;
    }
  }, [modalStateParams.plantedArea]);

  return (
    <Modal
      isActive={modalStateParams.plantedArea !== ''}
      handleClose={onCloseModal}
      title={title}
      variant="sm"
      footer={
        <div className="flex w-full justify-end space-x-3">
          <SecondaryButton text={tCommon('actions.cancel')} onClick={onCloseModal} />
          <ButtonSubmit
            onClick={() => validation.handleSubmit()}
            title={tCommon('actions.save')}
            disabled={loading.plantedArea}
            loading={loading.plantedArea}
          />
        </div>
      }
    >
      <div className="w-full">
        <Input
          label={tCompanyProfile(
            'body.screens.params.items.plantedArea.params.params.columns.minError',
          )}
          value={validation.values.minError}
          type={'number'}
          step={1}
          onChange={validation.handleChange('minError')}
          onBlur={validation.handleBlur('minError')}
          error={!!(validation.errors.minError && validation.touched.minError)}
          errorMessage={validation.errors.minError}
        />
        <Input
          label={tCompanyProfile(
            'body.screens.params.items.plantedArea.params.params.columns.minAlert',
          )}
          value={validation.values.minAlert}
          type={'number'}
          step={1}
          onChange={validation.handleChange('minAlert')}
          onBlur={validation.handleBlur('minAlert')}
          error={!!(validation.errors.minAlert && validation.touched.minAlert)}
          errorMessage={validation.errors.minAlert}
        />
        <Input
          label={tCompanyProfile(
            'body.screens.params.items.plantedArea.params.params.columns.target',
          )}
          value={validation.values.target}
          type={'number'}
          step={1}
          onChange={validation.handleChange('target')}
          onBlur={validation.handleBlur('target')}
          error={!!(validation.errors.target && validation.touched.target)}
          errorMessage={validation.errors.target}
        />
        <Input
          label={tCompanyProfile(
            'body.screens.params.items.plantedArea.params.params.columns.maxAlert',
          )}
          value={validation.values.maxAlert}
          type={'number'}
          step={1}
          onChange={validation.handleChange('maxAlert')}
          onBlur={validation.handleBlur('maxAlert')}
          error={!!(validation.errors.maxAlert && validation.touched.maxAlert)}
          errorMessage={validation.errors.maxAlert}
        />
        <Input
          label={tCompanyProfile(
            'body.screens.params.items.plantedArea.params.params.columns.maxError',
          )}
          value={validation.values.maxError}
          type={'number'}
          step={1}
          onChange={validation.handleChange('maxError')}
          onBlur={validation.handleBlur('maxError')}
          error={!!(validation.errors.maxError && validation.touched.maxError)}
          errorMessage={validation.errors.maxError}
        />
      </div>
    </Modal>
  );
});
