import ModalActions from '@/component/modals/modal-actions';
import { useFetch } from '@/hooks';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FaRegCheckCircle } from 'react-icons/fa';

export const ButtonApprovation = observer(() => {
  const tPlantedArea = useTranslation('plantedArea').t;
  const tCommon = useTranslation('common').t;
  const { selectedRows, loading, approveArrNotes, modalStates, handleModal } =
    plantedAreaNoteStore;
  const { request: requestApprove } = useFetch(approveArrNotes);
  const isPlural = selectedRows.length > 1;
  if (selectedRows.length === 0) {
    return null;
  }
  return (
    <div className="flex px-5 pt-5">
      <button
        className="font-semibold bg-success-200 text-white p-3 rounded flex items-center gap-1"
        onClick={() => handleModal('approveMultiple', true)}
      >
        <FaRegCheckCircle color="white" />
        {isPlural
          ? tPlantedArea('listNotes.modals.modalConfirmMultiApprove.buttonText', {
              notes: selectedRows.length,
            })
          : tPlantedArea('listNotes.modals.modalConfirmMultiApprove.singularButtonText')}
      </button>
      <ModalActions
        isActive={modalStates.approveMultiple}
        handleClose={function (): void {
          handleModal('approveMultiple', false);
        }}
        handleSubmit={requestApprove}
        variant={'warning'}
        title={tPlantedArea('listNotes.modals.modalConfirmMultiApprove.title')}
        description={
          isPlural
            ? tPlantedArea('listNotes.modals.modalConfirmMultiApprove.description', {
                notes: selectedRows.length,
              })
            : tPlantedArea(
                'listNotes.modals.modalConfirmMultiApprove.singularDescription',
                {
                  notes: selectedRows.length,
                },
              )
        }
        submitText={tCommon('actions.approve')}
        cancelText={tCommon('actions.cancel')}
        loading={loading.modalApprove}
      />
    </div>
  );
});
