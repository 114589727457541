import { observer } from 'mobx-react';
import { CardsInfoItem } from './card-infos-item';
import { GiTreeGrowth } from 'react-icons/gi';
import { dashboardSurvivalSurveysStore } from '@/store';
import _ from 'lodash';
import { formatNumber } from '@/utils/helpers/formaters';
import { TbChristmasTreeOff } from 'react-icons/tb';
import { TbLassoPolygon } from 'react-icons/tb';
import { SurvivalIcon } from '@/assets/images/icons/survivalIcon';
import { useTranslation } from 'react-i18next';
export const CardsInfo = observer(() => {
  const { summary, loading } = dashboardSurvivalSurveysStore;
  const { reboleirasInfo, evaluationsInfo } = summary;
  const { summary: loadingSummary } = loading;

  const tHome = useTranslation('home').t;
  const tCommon = useTranslation('common').t;
  const countEvaluations = _.sumBy(evaluationsInfo, 'evaluations');
  const areaEvaluations = _.sumBy(evaluationsInfo, 'somaAreas');
  const areaProductEvaluations = _.sumBy(
    evaluationsInfo,
    (row) => row.mediaPonderada * row.somaAreas,
  );
  const allSurvivals = areaProductEvaluations / areaEvaluations;
  const areaDownEvaluation = _.sumBy(evaluationsInfo, 'areaDownTarget');
  const countDownEvaluation = _.sumBy(evaluationsInfo, 'countDownTarget');

  return (
    <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-3">
      <CardsInfoItem
        loading={loadingSummary}
        title={tHome('survivalSurveys.cardInfos.areaDownEvaluation')}
        aditionalInfo={`${countEvaluations} ${tCommon('talhoes')}`}
        value={`${formatNumber(areaEvaluations, 1)}ha`}
        icon={GiTreeGrowth}
      />
      <CardsInfoItem
        loading={loadingSummary}
        variant="danger"
        variantValue="danger"
        title={tHome('survivalSurveys.cardInfos.evaluationArea')}
        percetageValue={`${formatNumber((areaDownEvaluation / areaEvaluations) * 100, 0)}% ${tHome('survivalSurveys.cardInfos.ofTotal')}`}
        aditionalInfo={`${countDownEvaluation} ${tCommon('talhoes')}`}
        value={`${formatNumber(areaDownEvaluation, 1)}ha`}
        icon={TbChristmasTreeOff}
      />
      <CardsInfoItem
        loading={loadingSummary}
        title={tHome('survivalSurveys.cardInfos.survival')}
        aditionalInfo={`${tHome('survivalSurveys.cardInfos.targetOf')} ${formatNumber(evaluationsInfo[0]?.target, 2)}%`}
        variantValue={allSurvivals < evaluationsInfo[0]?.target ? 'danger' : 'success'}
        percetageValue={`${allSurvivals < evaluationsInfo[0]?.target ? '' : '+'}${formatNumber(allSurvivals - evaluationsInfo[0]?.target, 2)}% ${tHome('survivalSurveys.cardInfos.ofTarget')}`}
        value={`${formatNumber(allSurvivals, 1)}%`}
        icon={SurvivalIcon}
      />
      <CardsInfoItem
        loading={loadingSummary}
        title={tHome('survivalSurveys.cardInfos.reboleiras')}
        variant="warning"
        variantValue="purple"
        aditionalInfo={`${reboleirasInfo?.reboleiras} ${tHome('survivalSurveys.cardInfos.reboleirasLowercase')}`}
        value={`${formatNumber(reboleirasInfo?.area, 2)}${tCommon('ha')}`}
        icon={TbLassoPolygon}
      />
    </div>
  );
});
