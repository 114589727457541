import { IconType } from 'react-icons';
import { ScaleLoader } from 'react-spinners';

interface PrimaryButtonProps extends Partial<HTMLButtonElement> {
  icon?: IconType;
  text: string;
  onClick: () => void;
  wFull?: boolean;
  loading?: boolean;
}
const PrimaryButton = ({
  icon: Icon,
  text,
  onClick = () => {},
  wFull = false,
  disabled = false,
  loading = false,
  ...restProps
}: PrimaryButtonProps) => {
  return (
    <button
      {...restProps}
      onClick={onClick}
      type="button"
      disabled={disabled || loading}
      className={`flex px ${wFull ? 'w-full' : ''} ${
        disabled ? 'opacity-60' : loading ? 'opacity-80' : 'hover:bg-primary-400'
      } py-2 gap-2 min-w-[132px] items-center text-white bg-primary-200 hover:bg-primary-300 transition-all justify-center relative h-[40px] px-[30px] text-sm font-medium rounded-lg`}
    >
      <div className={`w-full h-full flex items-center justify-center absolute`}>
        <ScaleLoader
          loading={loading}
          color={'white'}
          height={18}
          className={`transition-opacity duration-200 ${
            loading ? 'opacity-100' : 'opacity-0'
          }`}
        />
      </div>
      {Icon !== undefined ? (
        <div className=" h-4 w-4 items-center justify-center flex">
          <Icon size={20} className={`${loading ? 'invisible' : 'visible'}`} />
        </div>
      ) : null}
      <span className={`${loading ? 'invisible' : 'visible'} text-nowrap`}>{text}</span>
    </button>
  );
};

export default PrimaryButton;
