import Table from '@/component/Table';
import PlantedAreaReasonsTableController from './planted-area-reasons-controller';
import ModalAddPlantedAreaReason from './modal-add-reason';
import ModalActions from '@/component/modals/modal-actions';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import { BsDownload } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
// import ModalUploadCauses from './ModalUploadCauses';
const PlantedAreaReasonsTable = () => {
  const {
    columns,
    page,
    size,
    totalPages,
    reasons,
    loadingCauses,
    order,
    setOrder,
    setPage,
    setSize,
    options,
    validation,
    modalsState,
    setModalsState,
    onDelete,
    canInsert,
    onDownloadCauses,
    loadingDownload,
  } = PlantedAreaReasonsTableController();
  const tCommon = useTranslation('common').t;
  const tMaster = useTranslation('master').t;
  return (
    <div className="bg-white w-[100%] h-[100%] px-4 pb-3 pt-5  rounded-lg dark:bg-darkblack-600 ">
      <div className="flex justify-end gap-2">
        {canInsert && (
          <SecondaryButton
            text={tCommon('actions.export')}
            loading={loadingDownload}
            icon={BsDownload}
            onClick={onDownloadCauses}
          />
        )}
        {canInsert && (
          <PrimaryButton
            text={tCommon('actions.add')}
            onClick={() =>
              setModalsState({ ...modalsState, add: true, reason: undefined })
            }
            loading={false}
          />
        )}
      </div>
      <Table
        options={options}
        showSearch={false}
        columns={columns}
        page={page}
        size={size}
        totalPages={totalPages}
        data={reasons}
        setPage={setPage}
        setSize={setSize}
        loading={loadingCauses}
        order={order}
        setOrder={setOrder}
      />
      <ModalAddPlantedAreaReason
        validation={validation}
        setModalState={setModalsState}
        modalState={modalsState}
      />
      <ModalActions
        isActive={modalsState.delete}
        handleClose={() =>
          setModalsState({ ...modalsState, delete: false, reason: undefined })
        }
        handleSubmit={onDelete}
        variant={'delete'}
        title={tMaster('paramsPage.paramsContent.plantedArea.reasons.deleteReason.title')}
        description={tMaster(
          'paramsPage.paramsContent.plantedArea.reasons.deleteReason.description',
        )}
        submitText={tCommon('actions.delete')}
        cancelText={tCommon('actions.cancel')}
        loading={modalsState.loading}
      />
    </div>
  );
};
export default PlantedAreaReasonsTable;
