import { formatNumber } from '@/utils/helpers/formaters';
import { get } from 'lodash';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { survivalSurveysEvaluationType } from '../../../quality-survival-surveys-types';

const HeaderInfos = ({ evaluation }: { evaluation: survivalSurveysEvaluationType }) => {
  const loadingEvaluationData = false;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const infosShow = [
    {
      field: 'forestRegistry.regiao',
      label: tSurvivalSurveys('details.body.headerInfos.regiao'),
    },
    {
      field: 'forestRegistry.fazenda',
      label: tSurvivalSurveys('details.body.headerInfos.fazenda'),
    },
    {
      field: 'forestRegistry.talhao',
      label: tSurvivalSurveys('details.body.headerInfos.talhao'),
    },
    {
      field: 'area',
      label: tSurvivalSurveys('details.body.headerInfos.area'),
      format: () => {
        return `${formatNumber(
          !loadingEvaluationData && evaluation?.id ? evaluation.forestRegistry.area : 0,
          2,
        )} ha`;
      },
    },
    {
      field: 'forestRegistry.dataPlantio',
      label: tSurvivalSurveys('details.body.headerInfos.datePlant'),
      format: () => {
        return `${moment(evaluation.forestRegistry.dataPlantio).format('DD/MM/YYYY')}`;
      },
    },
    {
      field: 'area',
      label: tSurvivalSurveys('details.body.headerInfos.ageActual'),
      format: () => {
        return `${moment().diff(moment(evaluation.forestRegistry.dataPlantio), 'days')} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'time',
      label: tSurvivalSurveys('details.body.headerInfos.time'),
      format: () => {
        return `${formatNumber(
          !loadingEvaluationData && evaluation?.id ? evaluation.time : 0,
          0,
        )} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'dateTimeStart',
      label: tSurvivalSurveys('details.body.headerInfos.ageInEvaluation'),
      format: () => {
        return `${moment(evaluation.dateTimeStart).diff(
          moment(evaluation.forestRegistry.dataPlantio),
          'days',
        )} ${tSurvivalSurveys('survivalSurveysList.days')}`;
      },
    },
    {
      field: 'dateTimeStart',
      label: tSurvivalSurveys('details.body.headerInfos.dateEvaluation'),
      format: () => {
        return moment(evaluation.dateTimeStart).format('DD/MM/YYYY');
      },
    },
    {
      field: 'user.name',
      label: tSurvivalSurveys('details.body.headerInfos.evaluator'),
      format: () => {
        return evaluation.user.name;
      },
    },
  ];

  const ItemLoader = () => {
    return (
      <div className="flex flex-col items-center justify-center text-center">
        <Skeleton
          variant="rectangular"
          className="mb-1 dark:bg-darkblack-500 "
          width={Math.floor(Math.random() * (130 - 100 + 1)) + 100}
          height={22}
        />
        <Skeleton
          variant="rectangular"
          className={' dark:bg-darkblack-500'}
          width={Math.floor(Math.random() * (80 - 50 + 1)) + 50}
          height={10}
        />
      </div>
    );
  };
  return (
    <div
      className="
      items-center 
      dark:bg-darkblack-400 
      bg-bgray-200 
      grid 
      grid-cols-3
      mb-5 
      gap-3 
      py-3 
      rounded-lg"
    >
      {infosShow.map((info, index) => {
        return loadingEvaluationData ? (
          <ItemLoader key={'loaderEvaluationData_' + index} />
        ) : (
          <div className="text-center" key={`itemHeaderInfos${index}`}>
            <p className=" text-2xl font-bold dark:text-white">
              {info.format !== undefined ? info.format() : get(evaluation, info.field)}
            </p>
            <p className=" mt-[-8px] text-gray-300 text-xl">{info.label}</p>
          </div>
        );
      })}
      {evaluation.observation && (
        <div className="text-start col-span-2 pl-[12.5%] dark:text-white">
          <p className="text-base font-semibold">
            {tSurvivalSurveys('details.body.headerInfos.observations')}:
          </p>
          <p className="text-sm">{evaluation.observation}</p>
        </div>
      )}
    </div>
  );
};
export default observer(HeaderInfos);
