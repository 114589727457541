import { observer } from 'mobx-react';
import CardGraph from './cards/card-graph';
import CardPlantedArea from './cards/card-planted-area';
import CardPlotsAvaliables from './cards/card-plots-avaliables';
import CardPlotsRegistereds from './cards/card-plots-registered';
import { dashboardForestRegistryStore, userStore } from '@/store';
import PLantedArea from './dashboard-forest-registry-chart';
import ForestRegistryFilters from './filters';
import { useMemo } from 'react';
import { checkPermission, checkPermissionCompany } from '@/utils/helpers/validations';

const ForestRegistryData = observer(() => {
  const {
    getForetRegistrySummaryData: getSummaryData,
    filterForestRegistry: filter,
    getForestRegistryPlantedArea,
  } = dashboardForestRegistryStore;
  const hasPermission =
    checkPermissionCompany({
      companyData: userStore.companyActive,
      service: 'forestRegistry',
    }) && checkPermission({ permission: 'permissionRead', service: 'forestRegistry' });
  useMemo(() => {
    if (hasPermission) {
      getSummaryData();
      getForestRegistryPlantedArea();
    }
  }, [filter, userStore.companyActive, hasPermission]);
  if (!hasPermission) {
    return null;
  }
  return (
    <div className=" rounded-lg">
      <ForestRegistryFilters />
      <div className="flex-col lg:flex-row flex space-x-0 space-y-3 lg:space-x-3 lg:space-y-0">
        <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-3 text-nowrap ">
          <CardPlotsRegistereds />
          <CardPlantedArea />
          <CardPlotsAvaliables />
          <CardGraph />
        </div>
        <div className="flex-1">
          <PLantedArea />
        </div>
      </div>
    </div>
  );
});

export default ForestRegistryData;
