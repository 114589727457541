import { userStore } from '@/store';
import { observer } from 'mobx-react';
interface UserTypes {
  showProfile: (text: string) => void;
}
function User({ showProfile }: UserTypes) {
  const userName = userStore.user.name;
  const partsOfName = userName.split(' ');
  const name = `${partsOfName[0]} ${
    partsOfName.length > 1 ? partsOfName[partsOfName.length - 1][0] + '.' : ''
  }`;
  return (
    <div
      onClick={() => showProfile('profile')}
      className="flex items-center cursor-pointer space-x-0 lg:space-x-3 z-30"
    >
      <div className="h-[40px] w-[40px] rounded-xl border border-bgray-400">
        {userStore.user.imageUrl ? (
          <img
            loading="lazy"
            className="h-[100%] w-[100%] rounded-xl"
            src={userStore.user.imageUrl}
          />
        ) : (
          <div className="h-[100%] w-[100%] items-center justify-center flex text-lg font-bold text-primary-200">
            {partsOfName[0][0] + partsOfName[partsOfName.length - 1][0]}
          </div>
        )}
      </div>
      <div className="flex-col">
        <div className="hidden lg:flex items-center justify-center">
          <div className="flex-col">
            <h3 className="text-sm font-bold text-bgray-900 dark:text-gray-200 text-nowrap">
              {name}
            </h3>
            <p className="text-xs mt-[-5px] font-medium text-bgray-600 dark:text-bgray-50 text-nowrap">
              {userStore.user.master
                ? userStore.companyActive.name
                : userStore.user.keyUser
                  ? 'Key User'
                  : userStore.user.level.levelName}
            </p>
          </div>
          <div>
            <span>
              <svg
                className="stroke-bgray-900 dark:stroke-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 10L12 14L17 10"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(User);
