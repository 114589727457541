import { get, put } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import { GetPlantedAreaReasonsType, PlantedAreaReasonType } from './types';

export const getParamsReasons = async (
  data: GetPlantedAreaReasonsType,
): Promise<PlantedAreaReasonType[]> => {
  const result = (await get(url.PLANTEDAREA_REASONS_OF_COMPANY, {
    params: data,
  })) as PlantedAreaReasonType[];
  return result;
};

type saveCompanyDataType = {
  companyId: number;
  reasonId: number;
};
type updateAllCompanyDataType = {
  companyId: number;
  active: boolean;
};

type updateAllCompanyDataUpdateType = {
  data: PlantedAreaReasonType[];
  message: string;
};
export async function putChangeCauseCompany(
  data: saveCompanyDataType,
): Promise<{ data: PlantedAreaReasonType; message: string }> {
  return await put(url.PLANTEDAREA_REASONS_OF_COMPANY, data);
}
export async function putChangeAllReasonsCompany(
  data: updateAllCompanyDataType,
): Promise<updateAllCompanyDataUpdateType> {
  return await put(url.PLANTEDAREA_REASONS_UPDATE_ALL, data);
}
