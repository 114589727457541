import { useTranslation } from 'react-i18next';

export const BtnClose = ({ close }: { close: () => void }) => {
  const tCommon = useTranslation('common').t;
  return (
    <div className="">
      <button
        aria-label="none"
        type="button"
        onClick={close}
        id="step-1-cancel"
        className="rounded-md bg-transparent focus:outline-none"
      >
        <span className="sr-only">{tCommon('actions.close')}</span>
        {/* Cross Icon  */}
        <svg
          className="stroke-darkblack-300"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6L18 18M6 18L18 6L6 18Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default BtnClose;
