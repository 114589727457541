import { paramType } from '../services-params-types';
import servicesParams from './data';
import ParamsItem from './params-item';
const ParamsComponent = ({
  activeParam,
  setActiveParam,
}: {
  activeParam: paramType;

  setActiveParam: (x: paramType) => void;
}) => {
  return servicesParams().map((module) => {
    return (
      <ParamsItem
        key={module.title}
        module={module}
        activeParam={activeParam}
        setActiveParam={setActiveParam}
      />
    );
  });
};
export default ParamsComponent;
