const ImportAskIcon = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_354_4728"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="17"
        height="18"
      >
        <rect y="0.5" width="17" height="17" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_354_4728)">
        <path
          d="M8.46466 13.2501C8.71258 13.2501 8.92213 13.1645 9.09331 12.9933C9.26449 12.8221 9.35008 12.6126 9.35008 12.3647C9.35008 12.1167 9.26449 11.9072 9.09331 11.736C8.92213 11.5648 8.71258 11.4792 8.46466 11.4792C8.21675 11.4792 8.0072 11.5648 7.83602 11.736C7.66484 11.9072 7.57925 12.1167 7.57925 12.3647C7.57925 12.6126 7.66484 12.8221 7.83602 12.9933C8.0072 13.1645 8.21675 13.2501 8.46466 13.2501ZM7.82717 10.523H9.13758C9.13758 10.1334 9.18185 9.82647 9.27039 9.60217C9.35894 9.37786 9.6098 9.07092 10.023 8.68133C10.3299 8.37439 10.572 8.0822 10.749 7.80477C10.9261 7.52734 11.0147 7.19383 11.0147 6.80425C11.0147 6.14314 10.7727 5.6355 10.2886 5.28133C9.8046 4.92716 9.23203 4.75008 8.57092 4.75008C7.898 4.75008 7.35199 4.92716 6.93289 5.28133C6.5138 5.6355 6.22161 6.0605 6.05633 6.55633L7.22508 7.01675C7.28411 6.80425 7.41692 6.57404 7.62352 6.32612C7.83012 6.07821 8.14592 5.95425 8.57092 5.95425C8.94869 5.95425 9.23203 6.05755 9.42092 6.26414C9.6098 6.47074 9.70425 6.698 9.70425 6.94591C9.70425 7.18203 9.63342 7.40338 9.49175 7.60998C9.35008 7.81658 9.173 8.00842 8.9605 8.1855C8.44105 8.64592 8.1223 8.99418 8.00425 9.23029C7.88619 9.4664 7.82717 9.8973 7.82717 10.523ZM8.50008 16.0834C7.52022 16.0834 6.59939 15.8975 5.73758 15.5256C4.87578 15.1537 4.12612 14.649 3.48862 14.0115C2.85112 13.374 2.34644 12.6244 1.97456 11.7626C1.60269 10.9008 1.41675 9.97994 1.41675 9.00008C1.41675 8.02022 1.60269 7.09939 1.97456 6.23758C2.34644 5.37578 2.85112 4.62612 3.48862 3.98862C4.12612 3.35112 4.87578 2.84644 5.73758 2.47456C6.59939 2.10269 7.52022 1.91675 8.50008 1.91675C9.47994 1.91675 10.4008 2.10269 11.2626 2.47456C12.1244 2.84644 12.874 3.35112 13.5115 3.98862C14.149 4.62612 14.6537 5.37578 15.0256 6.23758C15.3975 7.09939 15.5834 8.02022 15.5834 9.00008C15.5834 9.97994 15.3975 10.9008 15.0256 11.7626C14.6537 12.6244 14.149 13.374 13.5115 14.0115C12.874 14.649 12.1244 15.1537 11.2626 15.5256C10.4008 15.8975 9.47994 16.0834 8.50008 16.0834Z"
          fill="#C1C1C1"
        />
      </g>
    </svg>
  );
};

export default ImportAskIcon;
