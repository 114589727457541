import SwitchInput from '@/component/forms/SwitchInput';
import { SurvivalSurveyCauseType } from '../../survival-surveys-causes-types';
import { PuffLoader } from 'react-spinners';
import { userStore } from '@/store';
import { observer } from 'mobx-react';

const OriginItemTableRows = ({
  origin,
  canInsert,
  onHandleActive,
  loadingUpdateCause,
}: {
  origin: SurvivalSurveyCauseType[];
  canInsert: boolean;
  loadingUpdateCause: string;
  onHandleActive: (cause: SurvivalSurveyCauseType) => void;
}) => {
  return (
    <tbody>
      {origin?.length > 0 &&
        origin.map((cause) => {
          return (
            <tr
              key={'tr_sc_' + cause.cause}
              className="border-b border-bgray-400 dark:border-darkblack-400"
            >
              <td className={`py-3 px-6 w-[30%] flex space-x-1`}>
                <div
                  className="min-w-8 h-4 rounded"
                  style={{ backgroundColor: cause.color }}
                ></div>
                <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
                  {cause.cause}
                </p>
              </td>
              <td className={`py-3 px-6 w-[60%]`}>
                <p className="text-xs font-regular text-wrap text-bgray-900 dark:text-gray-200">
                  {cause.description}
                </p>
              </td>

              <td className={`py-3 px-6 w-[10%] items-center justify-center`}>
                {loadingUpdateCause === cause.cause || loadingUpdateCause === 'all' ? (
                  <PuffLoader
                    color={userStore.theme === 'dark' ? 'white' : '#718096'}
                    size={26}
                  />
                ) : (
                  <SwitchInput
                    disabled={!canInsert}
                    checked={cause.active}
                    onChange={() => onHandleActive(cause)}
                    value={cause.active}
                  />
                )}
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
export default observer(OriginItemTableRows);
