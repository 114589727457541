import PopupOptions from '@/component/PopupOptions';
import { LevelType } from '../levels-permissions-types';
import { OptionsType } from '@/component/Table/types';

const LevelItem = ({
  level,
  active = false,
  onPressItem = () => {},
  options,
}: {
  level: LevelType;
  active?: boolean;
  onPressItem?: () => void;
  options: OptionsType[];
  index: number;
}) => {
  return (
    <div
      key={'item_level' + level?.id}
      className={`${
        active ? 'bg-gray-100 dark:bg-darkblack-400' : ''
      } flex items-center justify-between w-full py-3 px-3 cursor-pointer rounded-lg`}
      onClick={onPressItem}
    >
      <div className="flex items-center">
        <div
          className={`rounded-[100px] w-[48px] h-[48px] min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] mr-3 ${
            active ? 'bg-primary-200' : 'bg-gray-200 dark:bg-darkblack-400'
          } text-white text-3xl items-center justify-center text-center flex`}
        >
          {level.level}
        </div>
        <p
          className={`font-semibold text-lg  ${
            active ? 'text-primary-200' : 'dark:text-gray-200'
          } text-nowrap mr-6`}
        >
          {level?.levelName}
        </p>
      </div>
      <PopupOptions options={options} row={level} />
    </div>
  );
};
export default LevelItem;
