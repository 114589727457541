import {
  LoadingDashboardForestRegistryType,
  DashboardFiltersForestRegistryType,
  DashboardFiltersForestRegistryRequestType,
} from './dashboard-forest-registry-types';

export const inititalStatesDashboardForestRegistry: {
  loading: LoadingDashboardForestRegistryType;
  filters: DashboardFiltersForestRegistryType;
  filter: DashboardFiltersForestRegistryRequestType;
} = {
  filter: {
    regiao: [],
    fazenda: [],
    talhao: [],
    manejo: [],
    companyId: 0,
  },
  filters: {
    regiao: [],
    fazenda: [],
    talhao: [],
    manejo: [],
  },
  loading: {
    forestRegistrySummary: false,
    dashboardFiltersForestRegistry: false,
    forestReistryPlantedArea: false,
  },
};
