import { useTranslation } from 'react-i18next';

const EmptyStateServicesParams = () => {
  const tMaster = useTranslation('master').t;
  return (
    <div className="bg-white w-[100%] h-[100%] px-4 py-3  rounded-lg dark:bg-darkblack-600 items-center justify-center text-center flex">
      <p className="text-3xl font-bold text-bgray-500">
        {tMaster('paramsPage.paramsContent.survivalSurveys.empty')}
      </p>
    </div>
  );
};

export default EmptyStateServicesParams;
