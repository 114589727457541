import { useEffect, useState } from 'react';
import {
  deleteParamsTime,
  getParamsTimes,
  saveParamsTime,
  udpateParamsTime,
} from './survival-surveys-times-services';
import {
  AddSurvivalSurveysDataType,
  GetSurvivalSurveysResponseType,
  ModalStateType,
  SurvivalSurveysTimesTableControllerType,
  SurvivalSurveyTimeType,
} from './survival-surveys-times-types';
import { ColumnsTypes, OptionsType, OrderTableTypes } from '@/component/Table/types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Toast } from '@/component/Toast';
import { companyProfileStore, userStore } from '@/store';
import { checkPermission } from '@/utils/helpers/validations';
import { translationI18n } from '@/i18n';
const { showSuccess } = Toast;
const SurvivalSurveysTimesTableController =
  (): SurvivalSurveysTimesTableControllerType => {
    const { companyData, isMyCompany } = companyProfileStore;
    const companyId = companyData.id;
    const columns: ColumnsTypes[] = [
      {
        title: translationI18n(
          'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.columns.cod',
        ),
        field: 'cod',
        type: 'int',
      },
      {
        title: translationI18n(
          'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.columns.time',
        ),
        field: 'time',
        type: 'int',
      },
      {
        title: translationI18n(
          'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.columns.createdAt',
        ),
        field: 'createdAt',
        type: 'datetime',
      },
      {
        title: translationI18n(
          'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.columns.actions',
        ),
        field: 'c',
        type: 'options',
        ordenable: false,
        hide: !(
          checkPermission({ service: 'params', permission: 'permissionUpdate' }) ||
          checkPermission({ service: 'params', permission: 'permissionDelete' })
        ),
      },
    ].filter((x) => !x.hide);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [size, setSize] = useState(10);
    const [order, setOrder] = useState<OrderTableTypes>({
      column: 'id',
      direction: 'asc',
    });
    const [times, setTimes] = useState<SurvivalSurveyTimeType[]>([]);
    const [loadingTimes, setLoadingTimes] = useState(false);
    const [modalsState, setModalsState] = useState<ModalStateType>({
      add: false,
      delete: false,
      loading: false,
    });
    const getTimes = async () => {
      setLoadingTimes(true);
      const response: GetSurvivalSurveysResponseType = await getParamsTimes({
        page,
        size,
        column: order.column,
        direction: order.direction,
        companyId: Number(companyId),
      });

      setTimes(response.times);
      setTotalPages(response.totalPages);
      setLoadingTimes(false);
    };
    const validation = useFormik({
      enableReinitialize: true,

      initialValues: modalsState.time?.id
        ? {
            id: modalsState.time?.id,
            time: modalsState.time?.time || 1,
          }
        : {
            companyId: Number(companyId),
            time: modalsState.time?.time || 1,
          },

      validationSchema: Yup.object({
        time: Yup.number()
          .required(
            translationI18n(
              'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.fieldRequired',
            ),
          )
          .min(
            1,
            translationI18n(
              'companyProfile:body.screens.params.items.survivalSurveys.params.timeEvaluation.minField',
            ),
          ),
      }),

      onSubmit: async (values: AddSurvivalSurveysDataType) => {
        try {
          setModalsState({ ...modalsState, loading: true });
          const result = modalsState.time?.id
            ? await udpateParamsTime(values)
            : await saveParamsTime(values);
          showSuccess(result);
          if (modalsState.time?.id) {
            setTimes(
              times.map((t) => {
                return modalsState.time?.id && modalsState.time?.id === t.id
                  ? result.data
                  : t;
              }),
            );
          } else {
            getTimes();
          }
          setModalsState({
            ...modalsState,
            loading: false,
            add: false,
            time: undefined,
          });
        } catch (error: any) {
          console.error(error.message);
          setModalsState({
            ...modalsState,
            loading: false,
          });
        }
      },
    });

    const onDelete = async () => {
      setModalsState({ ...modalsState, loading: true });
      try {
        await deleteParamsTime({ id: modalsState.time?.id });
        setTimes(times.filter((t) => t.id !== modalsState.time?.id));
        setModalsState({
          ...modalsState,
          delete: false,
          loading: false,
          time: undefined,
        });
      } catch (error: any) {
        console.error(error.message);
        setModalsState({ ...modalsState, loading: false });
      }
    };
    useEffect(() => {
      if (modalsState.time?.id) {
        validation.setFieldValue('id', modalsState.time?.id);
        validation.setFieldValue('time', modalsState.time?.time);
        validation.setFieldValue('companyId', Number(modalsState.time?.companyId));
      } else {
        validation.resetForm();
      }
    }, [modalsState.time, modalsState.add]);

    useEffect(() => {
      getTimes();
    }, [page, size, order]);

    const options = (time: SurvivalSurveyTimeType): OptionsType[] => {
      return [
        {
          label: translationI18n('common:actions.edit'),
          action: () => setModalsState({ ...modalsState, add: true, time: time }),
          hide:
            isMyCompany &&
            !(
              checkPermission({
                permission: 'hasone',
                service: 'survivalSurveys',
              }) &&
              checkPermission({
                service: 'params',
                permission: 'permissionUpdate',
              })
            ),
        },
        {
          label: translationI18n('common:actions.delete'),
          action: () => setModalsState({ ...modalsState, delete: true, time: time }),
          variant: 'danger',
          hide:
            isMyCompany &&
            !(
              checkPermission({
                permission: 'hasone',
                service: 'survivalSurveys',
              }) &&
              checkPermission({
                service: 'params',
                permission: 'permissionDelete',
              })
            ),
        },
      ].filter((x) => !x.hide);
    };
    const canInsert =
      (isMyCompany &&
        checkPermission({
          permission: 'hasone',
          service: 'survivalSurveys',
        }) &&
        checkPermission({
          service: 'params',
          permission: 'permissionInsert',
        })) ||
      userStore.user.master;
    return {
      columns,
      page,
      size,
      order,
      setOrder,
      setSize,
      setPage,
      modalsState,
      setModalsState,
      validation,
      totalPages,
      times,
      loadingTimes,
      options,
      onDelete,
      canInsert,
    };
  };
export default SurvivalSurveysTimesTableController;
