import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { servicesParamsType } from './data';
import { paramType } from '../services-params-types';
import Colors from '@/utils/colors';
import { observer } from 'mobx-react';
import { userStore } from '@/store';
const ParamsItem = ({
  module,
  activeParam,
  setActiveParam,
}: {
  module: servicesParamsType;
  activeParam: paramType;

  setActiveParam: (x: paramType) => void;
}) => {
  const moduleActive = module.params.some((p) => p.param === activeParam);
  const [expanded, setExpanded] = useState<boolean>(moduleActive);
  const { theme } = userStore;
  return (
    <Accordion
      expanded={expanded}
      className="rounded-lg border-0 border-t-0"
      sx={{
        boxShadow: 'none',
        backgroundColor: moduleActive
          ? theme === 'dark'
            ? Colors.primaryOpacity[200]
            : Colors.primaryOpacity[200]
          : theme === 'dark'
            ? Colors.darkblack[500]
            : Colors.bgray[200],
      }}
      onChange={(event, exp) => setExpanded(exp)}
      id={`panel-${module.service}`}
    >
      <AccordionSummary
        expandIcon={
          <FaChevronDown
            className="dark:text-white"
            color={expanded ? Colors.primary[200] : undefined}
          />
        }
        aria-controls={`panel-${module.service}`}
        id={`panel-${module.service}-header`}
      >
        <div className={`flex-col pr-3`}>
          <p className="font-semibold text-md dark:text-bgray-200">{module.title}</p>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {module.params.map((param) => {
          return (
            <button
              key={param.title}
              onClick={() => setActiveParam(param.param)}
              className={`${
                activeParam === param.param
                  ? 'bg-primary-200 text-white font-semibold'
                  : 'hover:bg-primaryOpacity-200 hover:font-semibold'
              } w-full text-left dark:text-bgray-200 py-2 px-2 rounded`}
            >
              {param.title}
            </button>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
export default observer(ParamsItem);
