import { IoSearch } from 'react-icons/io5';
import { SearchType } from './types';
import { translationI18n } from '@/i18n';

const Search = ({ value, setValue }: SearchType) => {
  return (
    <div className="rounded-lg border border-transparent bg-bgray-100 px-[18px] h-full focus-within:border-success-200 dark:bg-darkblack-500 sm:block sm:w-100 w-full">
      <div className="flex h-full w-full items-center space-x-[15px]">
        <IoSearch size={20} className="text-bgray-600 dark:text-bgray-10" />
        <label htmlFor="listSearch" className="w-full">
          <input
            type="text"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            id="listSearch"
            placeholder={translationI18n('common:actions.search')}
            className="search-input  h-[40px] w-full border-none bg-bgray-100 px-0 text-sm tracking-wide text-bgray-600 placeholder:text-sm placeholder:font-medium placeholder:text-bgray-500 focus:outline-none focus:ring-0 dark:bg-darkblack-500"
          />
        </label>
      </div>
    </div>
  );
};

export default Search;
