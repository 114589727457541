import { ComposeOption, SeriesOption } from 'echarts';
import { XAXisOption } from 'echarts/types/dist/shared';
import { LatLngLiteral } from 'leaflet';
import { isNumber } from 'lodash';

export function formatNumber(number: any, fractionDigits: number = 2) {
  if (isNaN(Number(number)) || !isNumber(number)) {
    return Intl.NumberFormat('pt-BR', {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(0);
  }
  return Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(number);
}
// Função para calcular o centro de um polígono
export const getPolygonCenter = (coordinates: { coordinates: LatLngLiteral[] }[]) => {
  if (coordinates.length === 0) return null;
  let latSum = 0;
  let lngSum = 0;
  coordinates.map((coord) =>
    coord.coordinates.map((c) => {
      latSum += c.lat;
      lngSum += c.lng;
    }),
  );
  return { lat: latSum / coordinates.length, lng: lngSum / coordinates.length };
};
export function sortEchartsOption(option: ComposeOption<XAXisOption | SeriesOption>) {
  // Obter dados do eixo X
  const xAxisData = option?.xAxis?.data;

  // Criar um array de pares [label, index] para referência
  const pairedData = xAxisData.map((label, index) => ({ label, index }));

  // Ordenar os pares alfabeticamente com base no label (primeiro elemento do par)
  pairedData.sort((a, b) => a.label.localeCompare(b.label));

  // Atualizar o eixo X com os rótulos ordenados
  option.xAxis.data = pairedData.map((pair) => pair.label);

  // Atualizar cada série
  option.series.forEach((series) => {
    const originalData = series.data; // Dados originais da série

    // Criar um novo array para os dados ordenados
    const sortedData = pairedData.map((pair) => originalData[pair.index]);

    // Atualizar os dados da série
    series.data = sortedData;
  });

  return option; // Retorna o option ordenado
}
