import { observer } from 'mobx-react';
import { NoteForestRegistry } from './forest-registry-infos';
import { NoteInfos } from './note-infos';
import { OtherInfos } from './other-infos';
import { NoteSeedlings } from './seedlings-infos';

export const NoteBodyInfo = observer(() => {
  return (
    <div className="flex-1 w-full space-y-3">
      <NoteForestRegistry />
      <NoteInfos />
      <NoteSeedlings />
      <OtherInfos />
    </div>
  );
});
