/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { ResponseError } from './../utils/helpers/ResponseErros';
import { useState } from 'react';

export const useFetch = (promise: (x: any) => Promise<any>) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isFinally, setIsFinally] = useState(false);

  const request = async (...params: any): Promise<any> => {
    setLoading(true);
    setIsFinally(false);
    setError('');
    await promise(...params)
      .then((response) => {
        setLoading(false);
        setIsFinally(true);
        return response;
      })
      .catch((error) => {
        const message = new ResponseError(error)?.message;
        setLoading(false);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
        setIsFinally(true);
      });
  };

  return { error, loading, isFinally, request };
};
