import {
  DashboardFiltersSurvivalSurveysType,
  DashboardSurvivalSurveysRequestType,
  DashboardSurvivalSurveysSummaryResponseType,
  LoadingDashboardSurvivalSurveys,
  SurvivalSurveysDataMapType,
} from './dashboard-survival-surveys-types';

export const inititalStateSurvivalSurveys: {
  loading: LoadingDashboardSurvivalSurveys;
  summary: DashboardSurvivalSurveysSummaryResponseType;
  filter: DashboardSurvivalSurveysRequestType;
  filters: DashboardFiltersSurvivalSurveysType;
  map: SurvivalSurveysDataMapType;
} = {
  filters: {
    regiao: [],
    fazenda: [],
    talhao: [],
    manejo: [],
    time: [],
    approved: [],
  },
  filter: {
    regiao: [],
    fazenda: [],
    talhao: [],
    manejo: [],
    time: [],
    approved: [true],
    companyId: 0,
    dateStart: null,
    dateEnd: null,
  },
  summary: {
    evaluationsInfo: [],
    reboleirasInfo: {
      area: 0,
      reboleiras: 0,
    },
  },
  map: {
    percentageSurvival: 0,
    evaluation: 0,
    coordinates: [],
    fazenda: '',
    talhao: '',
    regiao: '',
    interpolated: [],
    survivalTarget: 0,
  },
  loading: {
    data: false,
    filter: false,
    map: false,
    causes: false,
    infos: false,
    filters: false,
    filtersMap: false,
  },
};
