import React, { useEffect } from 'react';
import EChartComponent from '@/component/Echart';
import { formatNumber } from '@/utils/helpers/formaters';
import Colors from '@/utils/colors';
import { FaChevronDown } from 'react-icons/fa';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Skeleton } from '@mui/material';
import { survivalSurveysEvaluationsStore, userStore } from '@/store';
import { observer } from 'mobx-react';
import { useFetch } from '@/hooks';
import SurvivalSurveysEmptyState from '../myApprovals/EmptyState';
import { EChartsOption } from 'echarts';
import { useTranslation } from 'react-i18next';

const HeaderResultsGraph = ({
  hasPermissionApprove,
}: {
  hasPermissionApprove: boolean;
}) => {
  const { dataChartResults, loading, selectedYear, handleSelectedYear, getChartResults } =
    survivalSurveysEvaluationsStore;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  const { request: requestChart } = useFetch(getChartResults);
  async function fetchCharts() {
    await requestChart();
  }
  useEffect(() => {
    fetchCharts();
  }, [selectedYear, userStore.companyActive]);

  const { theme } = userStore;
  const isDark = theme === 'dark';
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const optionConfig: EChartsOption = {
    legend: {
      top: 15,
      textStyle: {
        color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
        fontSize: 10,
      },
      show: true,
      data: [
        tSurvivalSurveys('survivalSurveysList.headerResults.graph.survival'),
        tSurvivalSurveys('survivalSurveysList.headerResults.graph.target'),
        tSurvivalSurveys('survivalSurveysList.headerResults.graph.evaluatedArea'),
      ],
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999',
        },
      },
    },
    xAxis: {
      type: 'category',
      data: dataChartResults.months.map((month) => month.monthName),
      axisLine: {
        lineStyle: { color: isDark ? Colors.bgray[200] : Colors.darkblack[600] },
      },
      axisLabel: {
        show: true,
        fontSize: 10,
        color: isDark ? Colors.bgray[200] : Colors.darkblack[600],
      },
      axisPointer: {
        type: 'shadow',
      },
    },
    yAxis: [
      {
        nameLocation: 'middle',
        nameGap: 5,
        show: false,
        type: 'value',
        name: tSurvivalSurveys('survivalSurveysList.headerResults.graph.survival'),
        axisLine: {
          show: false,
        },
        min: 0,
        max: 100,
        interval: 50,
        axisLabel: {
          show: false,
        },
      },
      {
        nameLocation: 'middle',
        nameGap: 5,
        show: false,
        type: 'value',
        name: tSurvivalSurveys('survivalSurveysList.headerResults.graph.evaluatedArea'),
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        name: tSurvivalSurveys('survivalSurveysList.headerResults.graph.survival'),
        color: Colors.primary[200],
        label: {
          color: theme === 'dark' ? 'white' : 'black',
          show: true,
          formatter: (value) =>
            value.data && Number(value.data) > 0 ? `${formatNumber(value.data)}%` : '',
        },
        data: dataChartResults.months.map((month) => month.weightedAverageSurvival),
      },
      {
        type: 'line',
        name: tSurvivalSurveys('survivalSurveysList.headerResults.graph.target'),
        color: Colors.error[200],
        label: {
          show: false,
          formatter: (value) =>
            value.data && Number(value.data) > 0 ? `${formatNumber(value.data)}%` : '',
        },
        data: dataChartResults.months.map((month) => month.minSurvival),
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: tSurvivalSurveys('survivalSurveysList.headerResults.graph.evaluatedArea'),
        label: {
          show: true,
          formatter: (value) =>
            value.data && Number(value.data) > 0
              ? `${formatNumber(value.data)}${tCommon('ha')}`
              : '',
        },
        data: dataChartResults.months.map((month) => month.area),
      },
    ],
    animation: false,
    height: '160px',
    darkMode: isDark,
  };

  return (
    <div
      className={`rounded-lg bg-white py-3 gap-3 items-center flex-col flex dark:bg-darkblack-600 shadow-lg lg:flex-[${hasPermissionApprove ? '0.66' : '1'}] flex-1`}
    >
      <div className="flex justify-between items-center w-full px-3">
        <p className="dark:text-white font-bold">
          {tSurvivalSurveys('survivalSurveysList.headerResults.title')}
        </p>
        {dataChartResults.yearsFilter.length > 0 && (
          <button
            aria-describedby={id}
            className="text-sm bg-bgray-200 rounded px-3 py-1 w-16 flex items-center gap-1"
            onClick={handleClick}
          >
            {loading.graph && !selectedYear ? (
              <Skeleton
                variant="rectangular"
                height={'15px'}
                width={40}
                className=" dark:bg-darkblack-400"
              />
            ) : (
              selectedYear
            )}
            <FaChevronDown />
          </button>
        )}
        <BasePopup
          id={id}
          className="w-16"
          open={open}
          anchor={anchor}
          placement="bottom"
        >
          <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg max-h-48 overflow-y-auto overflow-style-none">
            {dataChartResults.yearsFilter.map((year, index) => {
              const isFirst = index === 0;
              const isEnd = index === dataChartResults.yearsFilter.length - 1;
              return (
                <div
                  key={year}
                  className={` cursor-pointer dark:text-white hover:bg-bgray-200 px-2 py-1 ${isFirst ? 'rounded-t-lg' : isEnd ? 'rounded-b-lg' : ''}`}
                  onClick={(event) => {
                    handleSelectedYear(year);
                    handleClick(event);
                  }}
                >
                  <p className="text-base">{year}</p>
                </div>
              );
            })}
          </div>
        </BasePopup>
        <div
          onClick={() => setAnchor(null)}
          id="noti-outside"
          className={`fixed left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
        ></div>
      </div>
      <div className="w-full h-[250px] flex-col relative">
        {loading.graph ? (
          <div className="h-full px-5 py-2">
            <Skeleton
              variant="rectangular"
              className="rounded dark:bg-darkblack-400"
              height={'240px'}
              width={'100%'}
            />
          </div>
        ) : dataChartResults.months.length === 0 ? (
          <SurvivalSurveysEmptyState
            description={tSurvivalSurveys('survivalSurveysList.headerResults.emtpyData')}
          />
        ) : (
          <EChartComponent
            option={optionConfig}
            style={{
              width: '100%',
              zIndex: 0,
              height: 250,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(HeaderResultsGraph);
