import {
  DashboardFiltersForestRegistryRequestType,
  DashboardFiltersForestRegistryType,
  ForestRegistryPlantedAreaType,
} from './dashboard-forest-registry-types';
import { get } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import { ForestRegistrySummaryType } from '@/utils/types/ForestRegistryType';

export async function getForestRegistryFilters(
  data: DashboardFiltersForestRegistryRequestType,
): Promise<DashboardFiltersForestRegistryType> {
  return await get(url.DASHBOARD_GET_FOREST_REGISTRY_FILTERS, { params: data });
}
export async function getServerForestRegistrySummary(
  data: DashboardFiltersForestRegistryRequestType,
): Promise<ForestRegistrySummaryType> {
  return await get(url.DASHBOARD_GET_FOREST_REGISTRY_SUMMARY, { params: data });
}
export async function getPlantedArea(
  data: DashboardFiltersForestRegistryRequestType,
): Promise<ForestRegistryPlantedAreaType[]> {
  return await get(url.DASHBOARD_GET_FOREST_REGISTRY_CHART_PLANTED_AREA, {
    params: data,
  });
}
