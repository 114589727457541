import { survivalSurveysEvaluationType } from '../../../../quality-survival-surveys-types';
import { QtdDeathIds } from './types';
import { EChartsOption } from 'echarts';
import _, { isArray } from 'lodash';

export function getPathologyData({
  deathCauses,
}: {
  deathCauses: QtdDeathIds[];
}): EChartsOption {
  const data: EChartsOption = {
    grid: {
      top: '0%',
      containLabel: true,
    },
    animation: false,
    series: [
      {
        type: 'pie',
        radius: ['50%', '75%'],
        center: ['50%', '50%'],
        itemStyle: {
          borderRadius: 5,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 10,
            fontWeight: 'bold',
          },
        },
        data: [],
      },
    ],
  };

  const totalDeaths = deathCauses.reduce((total, cause) => total + cause.total, 0);

  deathCauses.forEach((cause) => {
    const percentage = (cause.total / totalDeaths) * 100;

    if (
      data?.series &&
      isArray(data?.series) &&
      data?.series.length > 0 &&
      isArray(data?.series?.[0]?.data)
    ) {
      data.series[0].data.push({
        value: percentage.toFixed(0),
        name: cause.cause,
        itemStyle: {
          color: cause.color,
        },
        label: {
          show: true,
          formatter: `${percentage.toFixed(0)}% (${cause.total})`,
          color: cause.color,
          fontSize: 10,
          position: 'outside',
        },
      });
    }
  });

  return data;
}
export const filterAndGroupByDeathCause = (
  survivalSurvey: survivalSurveysEvaluationType,
): QtdDeathIds[] => {
  if (!survivalSurvey?.survivalSurveyDots) return [];
  const deathRecords = survivalSurvey.survivalSurveyDots
    .filter((dot) => !dot.isAlive)
    .reverse();

  const deathCauseCounts = deathRecords.reduce((acc: { [key: number]: any }, record) => {
    if (record.deathCauseId) {
      acc[record.deathCauseId] = {
        total: (acc[record.deathCauseId]?.total || 0) + 1,
        cause: record?.deathCause?.cause,
        color: record.deathCause?.color,
      };
    }
    return acc;
  }, {});

  const result = Object.keys(deathCauseCounts).map((deathCauseId) => ({
    deathCauseId: Number(deathCauseId),
    ...deathCauseCounts[Number(deathCauseId)],
  }));
  return _.orderBy(result, 'total', 'desc') as unknown as QtdDeathIds[];
};
