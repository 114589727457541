import { TitleContext } from '@/component/layout';
import { plantedAreaNoteStore } from '@/store';
import { observer } from 'mobx-react';
import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from './header';
import Error from '@/pages/public/error';
import { PlantedAreaDetailsBody } from './body';

export const ControlSilviculturePlantedAreaDetails = observer(() => {
  const { setTitle, setSubTitle } = useContext(TitleContext);
  const { noteNotFound, getNoteDetails, handleNoteNotFound } = plantedAreaNoteStore;
  const { cod } = useParams();
  async function getDetails() {
    await getNoteDetails(Number(cod));
  }
  useEffect(() => {
    getDetails();
    return () => {
      handleNoteNotFound(false);
    };
  }, [cod]);
  const tPlantedArea = useTranslation('plantedArea').t;

  useEffect(() => {
    setTitle(
      noteNotFound
        ? tPlantedArea('detailsNote.titleNotFound')
        : tPlantedArea('detailsNote.title'),
    );
    setSubTitle(noteNotFound ? ' ' : tPlantedArea('detailsNote.subtitle'));
  }, [noteNotFound]);
  if (noteNotFound) {
    return <Error />;
  }
  const detailsBodyRef = useRef(null);

  return (
    <div className="w-full relative  min-h-full px-2 py-2 md:px-4 md:py-4 dark:bg-darkblack-700 ">
      <Header />
      <div ref={detailsBodyRef}>
        <PlantedAreaDetailsBody />
      </div>
    </div>
  );
});
