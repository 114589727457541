import React from 'react';
import { OptionsType } from '../Table/types';
import { SlOptions } from 'react-icons/sl';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { userStore } from '@/store';
import { observer } from 'mobx-react';

const PopupOptions = ({
  options,
  row,
}: {
  options?: OptionsType[];
  row: { [key: string]: string | number | undefined };
}) => {
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  if (!options?.length) {
    return null;
  }
  return (
    <div className="">
      <button
        aria-describedby={id}
        style={{ zIndex: 2 }}
        className="px-3 cursor:pointer py-1"
        onClick={handleClick}
      >
        <SlOptions color={userStore.theme === 'dark' ? 'white' : undefined} />
      </button>
      <BasePopup
        id={id}
        open={open}
        anchor={anchor}
        placement="bottom"
        style={{ zIndex: 2 }}
      >
        <div className="bg-white dark:bg-darkblack-300 rounded-lg shadow-lg p-2 max-h-48 overflow-y-auto overflow-style-none">
          <ul className="">
            {options?.map((option) => (
              <li
                key={option.label}
                className={`pt-1 ${
                  !option.variant || option.variant === 'default'
                    ? `dark:text-gray-200`
                    : option.variant === 'warning'
                      ? 'dark:text-warning-100'
                      : option.variant === 'success'
                        ? 'dark:text-success-100'
                        : 'dark:text-error-100'
                } ${
                  !option.variant || option.variant === 'default'
                    ? ''
                    : option.variant === 'warning'
                      ? 'text-warning-300'
                      : option.variant === 'success'
                        ? 'text-success-200'
                        : 'text-error-200'
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setAnchor(null);
                  option.action(row);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      </BasePopup>
      <div
        onClick={() => setAnchor(null)}
        id="noti-outside"
        className={`fixed left-0 top-0  h-full w-full ${anchor ? 'block' : 'hidden'}`}
      ></div>
    </div>
  );
};
export default observer(PopupOptions);
