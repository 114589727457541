import DetailsSurvivalGraph from './survival-graph';
import DetailsDeathGraph from './death-graph';
import DetailsSamplingGraph from './sampling-graph';

const DetailsGraphs = () => {
  return (
    <div className="lg:flex flex-1 space-y-5 sm:space-y-0 md:space-y-0 lg:space-y-0 sm:space-x-3 md:space-x-3 lg:space-x-3  xs:flex-col sm:flex md:flex">
      <DetailsSurvivalGraph />
      <DetailsDeathGraph />
      <DetailsSamplingGraph />
    </div>
  );
};
export default DetailsGraphs;
