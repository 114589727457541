import { userStore } from '@/store';
import HeaderResultsGraph from './Graph';
import MyApprovals from './myApprovals';
import HeaderResultsPendencies from './Pendencies';
import { observer } from 'mobx-react';

const HeaderResults = observer(() => {
  // HeaderResultsController();
  const hasPermissionApprove = !(
    userStore.user &&
    !userStore.user.level.permissions.some(
      (p) => p.serviceName === 'survivalSurveys' && p.permissionApprove,
    )
  );

  return (
    <div
      className="
      xl:flex
      w-full space-x-0 xl:space-x-3 space-y-3 xl:space-y-0
      mb-5"
    >
      <HeaderResultsGraph hasPermissionApprove={hasPermissionApprove} />
      <div className="md:flex flex-1 items-center space-x-0 md:space-x-3 space-y-3 md:space-y-0">
        <HeaderResultsPendencies />
        <MyApprovals hasPermissionApprove={hasPermissionApprove} />
      </div>
    </div>
  );
});
export default HeaderResults;
