import { translationI18n } from '@/i18n';
import { LanguageTypes } from '@/store/config/user/types';

export const manejoList = [
  {
    value: translationI18n('common:menejoList.implantacao'),
    label: translationI18n('common:menejoList.implantacao'),
  },
  {
    value: translationI18n('common:menejoList.reforma'),
    label: translationI18n('common:menejoList.reforma'),
  },
  {
    value: translationI18n('common:menejoList.conducao'),
    label: translationI18n('common:menejoList.conducao'),
  },
];

export const languages: {
  value: LanguageTypes;
  label: string;
}[] = [{ value: 'es', label: translationI18n('common:languages.spanish') }];
