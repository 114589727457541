import { Link } from 'react-router-dom';
import logoColor from '@/assets/images/logo/logo-color.svg';
import logoWhite from '@/assets/images/logo/logo-white.svg';
const HeadingLogo = () => {
  return (
    <Link to="/signin" className="block mb-7 flex items-center justify-center">
      <img src={logoColor} className="block max-w-[50%] dark:hidden" alt="" />
      <img src={logoWhite} className="hidden max-w-[50%] dark:block" alt="" />
    </Link>
  );
};
export default HeadingLogo;
