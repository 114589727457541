import { get, post } from '@/utils/helpers/api_helper';
import * as url from '@/utils/helpers/url_helper';
import {
  PlantedAreaParamsSaveType,
  PlantedAreaParamsType,
  SuvivalSurveysParamsDataSaveType,
} from './services-params-types';

//SURVIVAL SURVEYS PARAMS
export async function getSurvivalSurveysParams(
  companyId: number,
): Promise<{ samplingTarget: 0; survivalTarget: 0; averageDays: 0 }> {
  return await get(url.GET_SURVIVALSURVEYS_PARAMS + `/` + companyId);
}
export async function saveSurvivalSurveysParams(
  data: SuvivalSurveysParamsDataSaveType,
): Promise<{
  message: string;
  data: {
    samplingTarget: number;
    survivalTarget: number;
    averageDays: number;
  };
}> {
  return await post(url.GET_SURVIVALSURVEYS_PARAMS, data);
}

//PLANTED AREA PARAMS
export async function getPlantedAreaParams(
  companyId: number,
): Promise<PlantedAreaParamsType> {
  return await get(url.GET_CONTROL_SILVICULTURE_PLANTED_AREA_PARAMS + `/` + companyId);
}
export async function savePlantedAreaParams(data: PlantedAreaParamsSaveType): Promise<{
  message: string;
  data: PlantedAreaParamsType;
}> {
  return await post(url.GET_CONTROL_SILVICULTURE_PLANTED_AREA_PARAMS, data);
}
