import { Skeleton } from '@mui/material';
import { SlOptions } from 'react-icons/sl';

const LevelLoader = () => {
  const rows = [];
  for (let i = 0; i <= 5; i++) {
    rows.push(
      <div
        key={`LevelLoader_${i}`}
        className={` flex items-center justify-between w-full py-3 px-3 rounded-lg`}
      >
        <div className="flex items-center">
          <div
            className={`rounded-[100px] w-[48px] h-[48px] min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] mr-3  text-white text-3xl items-center justify-center text-center flex`}
          >
            <Skeleton
              variant="circular"
              height={'100%'}
              width={'100%'}
              className="dark:bg-darkblack-400"
            />
          </div>
          <Skeleton
            height={32}
            width={`${Math.floor(Math.random() * (150 - 110 + 1)) + 110}px`}
            className="dark:bg-darkblack-400"
          />
        </div>
        <button className="px-2 py-2 text-white">
          <SlOptions />
        </button>
      </div>,
    );
  }
  return rows;
};
export default LevelLoader;
