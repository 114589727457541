import FilterIcon from '@/assets/images/icons/FilterIcon';
import Search from '@/component/Table/Search';
import { Collapse } from '@mui/material';
import React, { useState } from 'react';
import { FiltersOptionsType } from '../../quality-survival-surveys-types';
import { survivalSurveysEvaluationsStore } from '@/store';
import Select from '@/component/forms/Select';
import { observer } from 'mobx-react';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import { BsDownload } from 'react-icons/bs';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { useTranslation } from 'react-i18next';

const TableFilters = () => {
  const {
    filter,
    handleFilterEvaluation,
    handleClearFilterEvaluation,
    filters,
    getDataTableExport,
    loading,
  } = survivalSurveysEvaluationsStore;
  const { table: loadingItems } = loading;
  const [filterOpen, setFilterOpen] = useState(false);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };
  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;
  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const tCommon = useTranslation('common').t;
  return (
    <div className="rounded-lg px-3 bg-white items-center h-full flex-col dark:bg-darkblack-600 flex-1 mb-3 py-3 shadow-lg">
      <div className="flex gap-3 flex-col md:flex-row">
        <div className="flex h-[43px] w-full">
          <Search
            value={filter.query}
            setValue={(value) => handleFilterEvaluation('query', value)}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-3 sm:justify-end">
          <div className="flex gap-3 justify-end">
            <button
              className="text-sm text-nowrap text-bgray-700 dark:text-bgray-200"
              onClick={handleClearFilterEvaluation}
            >
              {tSurvivalSurveys('survivalSurveysList.tableFilters.clearFilters')}
            </button>
            <button
              className="flex items-center gap-1 px-3 bg-primary-100 rounded-md h-[40px] text-success-200 bg-opacity-20 border border-success-200"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FilterIcon />
              {tSurvivalSurveys('survivalSurveysList.tableFilters.filters')}
            </button>
          </div>
          <SecondaryButton
            text={tCommon('actions.export')}
            icon={BsDownload}
            disabled={loading.tableExport || loading.table}
            onClick={handleClick}
            loading={loading.tableExport}
          />
          <BasePopup id={id} open={open} anchor={anchor} placement="bottom">
            <ul className="bg-white z-50 dark:bg-darkblack-300 rounded-lg shadow-lg space-y-1 max-h-48 overflow-y-auto overflow-style-none">
              <li
                className="text-base cursor-pointer text-nowrap py-2 px-2 hover:bg-bgray-200"
                onClick={(event) => {
                  window.open('/survivalSurveys/reportPDF', '_blank');
                  handleClick(event);
                }}
              >
                {tSurvivalSurveys('survivalSurveysList.tableFilters.documentPdf')}
              </li>
              <li
                className="text-base cursor-pointer text-nowrap py-2 px-2 dark:text-white hover:bg-bgray-200"
                onClick={(event) => {
                  getDataTableExport();
                  handleClick(event);
                }}
              >
                {tSurvivalSurveys('survivalSurveysList.tableFilters.documentExcel')}
              </li>
            </ul>
          </BasePopup>
        </div>
      </div>
      <Collapse in={filterOpen} timeout="auto" unmountOnExit>
        <div
          className=" 
            grid
            sm:grid-cols-2
            md:grid-cols-3 
            lg:grid-cols-4 
            xl:grid-cols-4 
            2xl:grid-cols-4
            3xl:grid-cols-4 
            gap-x-3"
        >
          {filters &&
            filter &&
            Object.keys(filters).map((chave, index) => {
              const options = filters[chave as keyof FiltersOptionsType];
              let label;
              switch (chave) {
                case 'company':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.company',
                  );
                  break;
                case 'regiao':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.regiao',
                  );
                  break;
                case 'fazenda':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.fazenda',
                  );
                  break;
                case 'talhao':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.talhao',
                  );
                  break;
                case 'user':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.user',
                  );
                  break;
                case 'time':
                  label = tSurvivalSurveys(
                    'survivalSurveysList.tableFilters.labels.time',
                  );
                  break;
              }
              if (!options.length) {
                return (
                  <Select
                    key={index}
                    loading={loadingItems}
                    multiselect={true}
                    label={label}
                    value={filter[chave]}
                    data={options}
                    onChange={function (value): void {
                      handleFilterEvaluation(chave, value);
                    }}
                  />
                );
              }
              return (
                <Select
                  key={index}
                  loading={loadingItems}
                  multiselect={true}
                  label={label}
                  value={filter[chave]}
                  data={options}
                  onChange={function (value): void {
                    handleFilterEvaluation(chave, value);
                  }}
                />
              );
            })}
          <Select
            loading={loadingItems}
            label={tSurvivalSurveys('survivalSurveysList.tableFilters.labels.status')}
            multiselect
            value={filter.active}
            data={[
              { label: tCommon('states.active'), value: true },
              { label: tCommon('states.inactive'), value: false },
            ]}
            onChange={function (value): void {
              handleFilterEvaluation('active', value);
            }}
          />
        </div>
      </Collapse>
    </div>
  );
};
export default observer(TableFilters);
