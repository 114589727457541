import Modal from '@/component/modals/modal-default';
import { LevelPermissionsControllerType } from '../levels-permissions-types';
import LevelItem from './level-item';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import SecondaryButton from '@/component/buttons/SecondaryButton';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';

const ReorderModal = ({ controller }: { controller: LevelPermissionsControllerType }) => {
  const tCompanyProfile = useTranslation('companyProfile').t;
  const {
    handleReorderLevelsModal,
    reoderModalVisbile,
    onDragEnd,
    newOrderLevels,
    onHandleSubmitNewOrderLevel,
    loadingSubmitNewOrder,
  } = controller;

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle,
    index: number,
  ): React.CSSProperties => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    ...draggableStyle,
    background: isDragging ? '#E2E8F0' : '',
    borderRadius: '10px',
    marginLeft: '0px', // Aplique zero para manter a posição
    ...draggableStyle,
    top: `${(index + 1) * 72}px`,
    // margin: '0px',
    // bottom: '0px',
    left: '0px', // Evita que o item vá para fora da área visível do modal
    right: '0px', // Garante que o item fique centralizado
  });
  return (
    <Modal
      isActive={reoderModalVisbile}
      handleClose={handleReorderLevelsModal}
      title={tCompanyProfile('body.screens.levelsPermissions.reorder.title')}
      variant="sm"
      footer={
        <div className={`flex justify-end items-end space-x-3`}>
          <SecondaryButton text={'Cancelar'} onClick={handleReorderLevelsModal} />
          <PrimaryButton
            text={tCompanyProfile('body.screens.levelsPermissions.reorder.submitText')}
            loading={loadingSubmitNewOrder}
            onClick={onHandleSubmitNewOrderLevel}
          />
        </div>
      }
    >
      <div className="flex w-full relative">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="overflow-y-auto w-full relative"
              >
                {newOrderLevels?.length &&
                  newOrderLevels?.map((level, index) => {
                    return (
                      <Draggable
                        isDragDisabled={loadingSubmitNewOrder}
                        key={level.id}
                        draggableId={level.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style as DraggingStyle,
                              index,
                            )}
                          >
                            <LevelItem
                              index={index}
                              key={`LEVELITEM_${level?.id}`}
                              level={level}
                              options={[]}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Modal>
  );
};

export default ReorderModal;
