import ModulesAndServicesController from './modules-and-services-controller';
import SwitchInput from '@/component/forms/SwitchInput';
import { PuffLoader } from 'react-spinners';
import LoaderModulesAndServices from './modules-and-services-loader';
import TableHeader from './modules-and-services-table-header';
import { userStore } from '@/store';
import { observer } from 'mobx-react';
import { translationI18n } from '@/i18n';

const ModulesAndServices = () => {
  const controller = ModulesAndServicesController();
  const { loadingModules, modulesAndServicesData, handleService, loadingUpdateService } =
    controller;

  return (
    <>
      <div className="rounded-lg bg-white mt-5 mb-5 px-6 py-5 justify-between items-center flex dark:bg-darkblack-600 shadow-lg">
        <div>
          <p className="font-bold text-xl dark:text-gray-200">
            {translationI18n('companyProfile:body.screens.modulesAndServices.name')}
          </p>
          <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
            {translationI18n(
              'companyProfile:body.screens.modulesAndServices.description',
            )}
          </p>
        </div>
      </div>
      <LoaderModulesAndServices loading={loadingModules} />
      {modulesAndServicesData?.map((module) => {
        return (
          <div
            key={'module_' + module.module}
            className="w-full relative rounded-lg bg-white mt-5 px-[24px] py-[20px] dark:bg-darkblack-600 shadow-lg"
          >
            <h1 className="font-semibold text-dark-300 dark:text-bgray-100">
              {module.moduleName}
            </h1>
            <div className="w-full mt-3 relative overflow-x-auto">
              <table className="w-full">
                <thead>
                  <TableHeader />
                </thead>
                <tbody>
                  {module.services?.map((service) => {
                    return (
                      <tr
                        key={'tr_s_' + service.service}
                        className="border-b border-bgray-400 dark:border-darkblack-400"
                      >
                        <td className={`py-3 px-6 w-[40%]`}>
                          <p className="text-sm font-regular text-bgray-900 dark:text-gray-200">
                            {service.serviceName}
                          </p>
                        </td>
                        <td className={`py-3 px-6 w-[40%]`}>
                          <p className="text-xs font-regular text-bgray-900 dark:text-gray-200">
                            {service.description}
                          </p>
                        </td>

                        <td className={`py-3 px-6 w-[20%] items-center justify-center`}>
                          {loadingUpdateService.service === service.service &&
                          loadingUpdateService.loading ? (
                            <PuffLoader
                              color={userStore.theme === 'dark' ? 'white' : '#718096'}
                              size={26}
                            />
                          ) : (
                            <SwitchInput
                              checked={service.active}
                              onChange={(event, checked) =>
                                handleService({
                                  service: service,
                                  newValue: checked,
                                })
                              }
                              value={service.active}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default observer(ModulesAndServices);
