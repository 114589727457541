import BtnClose from '@/component/buttons/BtnClose';
import HeadingLogo from './header-logo';
import ButtonSubmit from '@/component/forms/button-submit';
import { PasswordResetModalControllerType } from '../../../signin-types';
import { useTranslation } from 'react-i18next';

const CodeVerify = ({
  passwordResetModalControllers,
}: PasswordResetModalControllerType) => {
  const {
    inputs,
    timer,
    validCodeInputs,
    verifyCode,
    loadingVerifyCode,
    setTimer,
    onClose,
    onSubmitSendEmail,
  } = passwordResetModalControllers;
  const { t: tSignin } = useTranslation('signin');
  const { t: tCommon } = useTranslation('common');
  return (
    <div className="step-content step-2 ">
      <div className="relative max-w-lg transform overflow-hidden rounded-lg bg-white dark:bg-darkblack-600 p-8 text-left transition-all">
        <div className="absolute top-0 right-0 pr-5 pt-5">
          <BtnClose close={onClose} />
        </div>
        <div>
          <HeadingLogo />
          <h3 className="text-2xl font-bold text-bgray-900 dark:text-gray-200 mb-3">
            {tSignin('resetPasswordModal.codeVerify.title')}
          </h3>
          <p className="text-base font-medium text-bgray-600 dark:text-darkblack-300 mb-7">
            {tSignin('resetPasswordModal.codeVerify.subtitle')}
          </p>
          <form onSubmit={verifyCode}>
            <div className="flex w-full space-x-2 md:space-x-4 xl:space-x-6 mb-8">
              <input
                ref={(el) => (inputs.current[0] = el as HTMLInputElement)}
                type="text"
                className=" py-3 rounded-xl text-center text-2xl border border-transparent focus:ring-0 focus:border focus:border-success-200 font-bold text-bgray-900 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 w-full h-14"
                placeholder=""
                maxLength={1}
              />
              <input
                ref={(el) => (inputs.current[1] = el as HTMLInputElement)}
                type="text"
                className=" py-3 rounded-xl text-center text-2xl border border-transparent focus:ring-0 focus:border focus:border-success-200 font-bold text-bgray-900 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 w-full h-14"
                placeholder=""
                maxLength={1}
              />
              <input
                ref={(el) => (inputs.current[2] = el as HTMLInputElement)}
                type="text"
                className=" py-3 rounded-xl text-center text-2xl border border-transparent focus:ring-0 focus:border focus:border-success-200 font-bold text-bgray-900 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 w-full h-14"
                placeholder=""
                maxLength={1}
              />
              <input
                ref={(el) => (inputs.current[3] = el as HTMLInputElement)}
                type="text"
                className=" py-3 rounded-xl text-center text-2xl border border-transparent focus:ring-0 focus:border focus:border-success-200 font-bold text-bgray-900 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 w-full h-14"
                placeholder=""
                maxLength={1}
              />
              <input
                ref={(el) => (inputs.current[4] = el as HTMLInputElement)}
                type="text"
                className=" py-3 rounded-xl text-center text-2xl border border-transparent focus:ring-0 focus:border focus:border-success-200 font-bold text-bgray-900 bg-[#F5F5F5] dark:bg-darkblack-500 dark:text-gray-200 w-full h-14"
                placeholder=""
                maxLength={1}
              />
            </div>
            <button
              type="button"
              disabled={timer > 0}
              aria-label="none"
              onClick={() => {
                onSubmitSendEmail();
                setTimer(60);
              }}
              className="block text-sm font-bold text-primary-200 mb-8"
            >
              {timer > 0
                ? tSignin('resetPasswordModal.codeVerify.sendCodAgainWithSeconds', {
                    timer: timer,
                  })
                : tSignin('resetPasswordModal.codeVerify.sendCodAgain')}
            </button>
            <ButtonSubmit
              wFull
              title={tCommon('actions.verify')}
              disabled={!validCodeInputs}
              loading={loadingVerifyCode}
            />
          </form>
        </div>
      </div>
    </div>
  );
};
export default CodeVerify;
