import Colors from '@/utils/colors';

export const SurvivalIcon = () => {
  return (
    <div className="max-w-9 max-h-9 flex items-center justify-center">
      <svg
        width="95"
        height="95"
        viewBox="0 0 95 95"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.4487 47.5002C30.4487 30.4489 47.5 12.1797 47.5 12.1797C47.5 12.1797 64.5513 30.4489 64.5513 47.5002C64.5513 59.6797 56.9172 69.4233 47.5 69.4233C38.0828 69.4233 30.4487 59.6797 30.4487 47.5002Z"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M47.5 84.0386L47.5 36.5386"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M68.2052 29.4195C71.0068 27.6301 73.938 26.0521 76.9744 24.6987C76.9744 24.6987 85.3259 44.5087 79.5966 58.5894C75.9744 67.4877 68.059 72.8041 60.8975 71.7189"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M26.7949 29.4195C23.9932 27.6301 21.062 26.0521 18.0256 24.6987C18.0256 24.6987 9.67659 44.5087 15.4058 58.5894C19.0256 67.4877 26.941 72.8041 34.1026 71.7189"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
          strokeLinecap="square"
        />
        <path
          d="M47.5 58.4615L56.0256 51.1538"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M47.5 51.1539L38.9744 43.8462"
          stroke={Colors.primary[300]}
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  );
};
