import { IoAdd } from 'react-icons/io5';
import LevelsPermissionsController from './levels-permissions-controller';
import PermissionItem from './permission-item';
import PermissionsLoader from './loader/permissions-loader';
import AddLevelModal from './modal-add-level';
import _ from 'lodash';
import ModalActions from '@/component/modals/modal-actions';
import { checkPermission } from '@/utils/helpers/validations';
import Levels from './levels';
import ReorderModal from './levels/render-modal';
import EmptyState from '@/component/EmptyState';
import { observer } from 'mobx-react';
import { companyProfileStore } from '@/store';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { translationI18n } from '@/i18n';

const LevelsPermissions = observer(() => {
  const { isMyCompany, companyData: company, levels } = companyProfileStore;
  const controller = LevelsPermissionsController();
  const {
    loadingLevels,
    loadingModules,
    modulesAndServicesData,
    onHandlePermission,
    loadingPermissionSwitch,
    addModalVisible,
    setAddModalVisible,
    levelUpdate,
    handleAddLevelModal,
    addNewLevel,
    updateLocaLevel,
    deleteModalVisible,
    setDeleteModalVisible,
    loadingDelete,
    onHandleDeleteLevel,
  } = controller;
  return (
    <div className="w-full">
      <ModalActions
        isActive={deleteModalVisible.show}
        handleClose={() =>
          setDeleteModalVisible({
            levelId: 0,
            show: false,
          })
        }
        handleSubmit={onHandleDeleteLevel}
        variant={'delete'}
        loading={loadingDelete}
        title={translationI18n(
          'companyProfile:body.screens.levelsPermissions.delete.title',
        )}
        description={translationI18n(
          'companyProfile:body.screens.levelsPermissions.delete.description',
        )}
        submitText={translationI18n('common:actions.delete')}
        cancelText={translationI18n('common:actions.back')}
      />
      <AddLevelModal
        isActive={addModalVisible}
        handleClose={() => setAddModalVisible(false)}
        level={levelUpdate}
        addNewLevel={addNewLevel}
        updateLocaLevel={updateLocaLevel}
        newLevel={Number(_.maxBy(levels, 'level')?.level || 0) + 1}
        company={company}
      />
      <ReorderModal controller={controller} />
      <div className="rounded-lg bg-white mt-5 px-6 py-5 sm:justify-between sm:items-center space-y-3 sm:space-y-0 flex sm:flex-row flex-col dark:bg-darkblack-600 shadow-lg">
        <div className="flex flex-col">
          <p className="font-bold text-xl dark:text-gray-200">
            {translationI18n('companyProfile:body.screens.levelsPermissions.name')}
          </p>
          <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
            {translationI18n('companyProfile:body.screens.levelsPermissions.description')}
          </p>
        </div>
        {(!isMyCompany ||
          checkPermission({
            service: 'levels',
            permission: 'permissionInsert',
          })) && (
          <PrimaryButton
            icon={IoAdd}
            text={translationI18n(
              'companyProfile:body.screens.levelsPermissions.addLevel',
            )}
            onClick={handleAddLevelModal}
            loading={false}
          />
        )}
      </div>
      <div className="w-full lg:flex lg:space-x-5 mt-5">
        {!loadingLevels && !loadingModules && levels.length === 0 ? (
          <EmptyState
            size="lg"
            title={translationI18n(
              'companyProfile:body.screens.levelsPermissions.empty.title',
            )}
            description={translationI18n(
              'companyProfile:body.screens.levelsPermissions.empty.description',
            )}
          />
        ) : (
          <>
            <div className="bg-white xl:w-[30%] lg:w-[40%] px-4 py-3   rounded-lg dark:bg-darkblack-600 shadow-lg">
              <Levels controller={controller} />
            </div>
            <div className="xl:w-[70%] lg:w-[60%] lg:mt-0 mt-5 space-y-5">
              {loadingLevels || loadingModules ? (
                <PermissionsLoader />
              ) : (
                modulesAndServicesData.map((module) => (
                  <PermissionItem
                    key={`MODULE_ITEM${module?.module}`}
                    module={module}
                    isMyCompany={isMyCompany}
                    onHandlePermission={onHandlePermission}
                    loading={loadingPermissionSwitch}
                  />
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default LevelsPermissions;
