import Table from '@/component/Table';
import PrimaryButton from '@/component/buttons/PrimaryButton';
import { IoAdd } from 'react-icons/io5';
import { checkPermission } from '@/utils/helpers/validations';
import EmptyState from '@/component/EmptyState';
import { companyProfileStore, userStore } from '@/store';
import { OptionsType } from '@/component/Table/types';
import { columnsUser } from '../../company-profile-initial-states';
import { UserType } from '@/utils/types/UsersType';
import { observer } from 'mobx-react';
import { Toast } from '@/component/Toast';
import LevelsPermissionsController from '../levels-permissions/levels-permissions-controller';
import { useEffect } from 'react';
import { translationI18n } from '@/i18n';
import { DeleteUserModal } from './modals/modal-delete-user';
import { ChangeUserStatusModal } from './modals/modal-change-user-status';
import { ChangeUserKeyUserModal } from './modals/modal-change-user-keyuser';
import { ChangeUserMasterModal } from './modals/modal-change-user-master';
import { AddUserModal } from './modals/modal-user-add';
const { showError } = Toast;
const UserList = observer(() => {
  const {
    activeScreen,
    userList,
    isMyCompany,
    handleModal,
    loading,
    modalStates,
    tableParamsUser,
    handleTableParamsUser,
    getListUser,
    companyData,
    levels,
  } = companyProfileStore;
  LevelsPermissionsController();
  useEffect(() => {
    if (activeScreen === 'userList') {
      getListUser();
    }
  }, [
    companyData,
    isMyCompany,
    activeScreen,
    tableParamsUser.page,
    tableParamsUser.direction,
    tableParamsUser.size,
  ]);

  if (activeScreen !== 'userList') {
    return null;
  }
  const options = (user: UserType): OptionsType[] => {
    return [
      {
        label: translationI18n('common:actions.edit'),
        action: () => handleModal({ ...modalStates, addUser: true, user: user }),
        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionUpdate',
            service: 'users',
          }),
      },
      {
        label: user.active
          ? translationI18n('common:actions.inactive')
          : translationI18n('common:actions.active'),
        variant: user.active ? 'danger' : 'success',
        action: () => handleModal({ ...modalStates, changeStatusUser: true, user: user }),
        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionUpdate',
            service: 'users',
          }),
      },
      {
        label: user.keyUser
          ? translationI18n('companyProfile:body.screens.userList.actions.removeAdmin')
          : translationI18n('companyProfile:body.screens.userList.actions.addAdmin'),
        action: () => handleModal({ ...modalStates, changeKeyUser: true, user: user }),
        hide: isMyCompany && !userStore.user.keyUser,
      },
      {
        label: user.master
          ? translationI18n('companyProfile:body.screens.userList.actions.removeMaster')
          : translationI18n('companyProfile:body.screens.userList.actions.addMaster'),
        action: () => handleModal({ ...modalStates, changeMasterUser: true, user: user }),
        hide: isMyCompany || !userStore.user.master,
      },
      {
        label: translationI18n('common:actions.delete'),
        action: () => handleModal({ ...modalStates, deleteUser: true, user: user }),
        hide:
          isMyCompany &&
          !checkPermission({
            permission: 'permissionDelete',
            service: 'users',
          }),
        variant: 'danger' as 'danger' | 'warning' | 'default' | undefined,
      },
    ].filter((x) => !x.hide);
  };
  return (
    <>
      <div className="space-y-3 pb-14">
        <div className="rounded-lg bg-white mt-5 px-6 py-5 sm:justify-between sm:items-center space-y-3 sm:space-y-0 flex sm:flex-row flex-col dark:bg-darkblack-600 shadow-lg">
          <div>
            <p className="font-bold text-xl dark:text-gray-200">
              {translationI18n('companyProfile:body.screens.userList.name')}
            </p>
            <p className="font-regular text-sm dark:text-gray-300 text-bgray-600">
              {translationI18n('companyProfile:body.screens.userList.description')}
            </p>
          </div>

          {(!isMyCompany ||
            checkPermission({
              service: 'users',
              permission: 'permissionInsert',
            })) && (
            <PrimaryButton
              text={translationI18n('common:actions.add')}
              onClick={() =>
                levels.length === 0
                  ? showError({
                      message: translationI18n(
                        'companyProfile:body.screens.userList.addError',
                      ),
                    })
                  : handleModal({ ...modalStates, addUser: true })
              }
              icon={IoAdd}
            />
          )}
        </div>
        <div className="shadow-lg rounded">
          {!loading.userList && userList.users.length === 0 && !tableParamsUser.query ? (
            <EmptyState
              title={translationI18n('companyProfile:body.screens.userList.empty.title')}
              description={translationI18n(
                'companyProfile:body.screens.userList.empty.description',
              )}
            />
          ) : (
            <Table
              options={options}
              query={tableParamsUser.query}
              setQuery={(query) => {
                handleTableParamsUser('query', query);
              }}
              order={tableParamsUser.direction}
              setOrder={(order) => {
                handleTableParamsUser('direction', order);
              }}
              data={userList.users}
              columns={columnsUser(isMyCompany, userStore.user)}
              page={tableParamsUser.page}
              totalPages={userList.totalPages}
              size={tableParamsUser.size}
              setPage={(page) => {
                handleTableParamsUser('page', page);
              }}
              setSize={(size) => {
                handleTableParamsUser('size', size);
              }}
              loading={loading.userList && !tableParamsUser.query}
              onPressRow={() => {}}
            />
          )}
        </div>
        <AddUserModal
          user={modalStates.user}
          handleClose={() =>
            handleModal({
              ...modalStates,
              user: undefined,
              addUser: false,
              loading: false,
            })
          }
          isActive={modalStates.addUser}
        />
      </div>
      <DeleteUserModal />
      <ChangeUserStatusModal />
      <ChangeUserKeyUserModal />
      <ChangeUserMasterModal />
    </>
  );
});
export default UserList;
