import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import HeaderCompanyProfile from './company-profile-header';
import BodyCompanyProfile from './company-profile-body';
import { companyProfileStore, userStore } from '@/store';
import { TitleContext } from '@/component/layout';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CompanyProfile = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    companyData,
    getCompanyData,
    handleIsMyCompany,
    handleTableParamsUser,
    getOptionsScreen,
    isMyCompany,
  } = companyProfileStore;
  const { id } = useParams();
  const { user, companyActive } = userStore;
  if (!user.master && currentPath !== '/mycompany' && currentPath !== '/mycompany/') {
    navigate('/');
    return null;
  }
  const { setTitle, setSubTitle } = useContext(TitleContext);

  useEffect(() => {
    const isMyCompany = currentPath.includes('mycompany');
    handleIsMyCompany(isMyCompany);
    getCompanyData(isMyCompany ? companyActive.id : Number(id));
  }, [currentPath, companyActive.id]);
  const tCompanyProfile = useTranslation('companyProfile').t;
  useEffect(() => {
    setTitle('');
    setSubTitle('');
    if (companyData.id) {
      setTitle(companyData.name);
      setSubTitle(tCompanyProfile('subTitle'));
      document.title = isMyCompany
        ? tCompanyProfile('documentTitle')
        : tCompanyProfile('documentTitlleMyCompany', { companyName: companyData.name }) +
            companyData.name || '';
    }
  }, [companyData, userStore.user.language, localStorage.getItem('language')]);

  useEffect(() => {
    if (companyData.id && userStore.user.id) {
      getOptionsScreen();
      handleTableParamsUser('companyId', companyData.id);
    }
  }, [companyData, userStore.user]);

  return (
    <div className="w-full min-h-full px-2 py-2 md:px-4 md:py-4  dark:bg-darkblack-700 ">
      <HeaderCompanyProfile />
      <BodyCompanyProfile />
    </div>
  );
});

export default CompanyProfile;
