import EChartComponent from '@/component/Echart';
import { formatNumber } from '@/utils/helpers/formaters';
import { Skeleton } from '@mui/material';
import * as echarts from 'echarts';
import Colors from '@/utils/colors';
import { survivalSurveysEvaluationsStore } from '@/store';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

const DetailsDeathGraph = () => {
  const { evaluationDetails: evaluationData, loading } = survivalSurveysEvaluationsStore;
  const loadingEvaluationData = loading.evaluationDetails;

  const tSurvivalSurveys = useTranslation('survivalSurveys').t;
  const isAlert = evaluationData.percentageSurvival < evaluationData.survivalTarget;
  const optionsSobrevienciaChart: echarts.EChartsOption = {
    series: [
      {
        name: tSurvivalSurveys('details.body.detailsResults.graphs.deaded'),
        type: 'pie',
        radius: ['70%', '90%'],
        center: ['50%', '60%'],
        startAngle: 180,
        endAngle: 360,
        label: {
          show: true,
          position: 'center',
          formatter: (params) => {
            const value = params.value as number;
            if (
              params.name ===
              tSurvivalSurveys('details.body.detailsResults.graphs.deaded')
            ) {
              return `${formatNumber(value, 2)}%`;
            }
            return `${formatNumber(100 - value, 2)}%`;
          },
          fontSize: 22,
          fontWeight: 'bold',
          color: isAlert ? Colors.error[200] : Colors.success[200],
        },
        emphasis: {
          disabled: true,
        },
        data: [
          {
            value: 100 - evaluationData?.percentageSurvival,
            name: tSurvivalSurveys('details.body.detailsResults.graphs.deaded'),
          },
          {
            value: evaluationData?.percentageSurvival,
            name: tSurvivalSurveys('details.body.detailsResults.graphs.survival'),
          },
        ],
      },
    ],
    animation: false,
    color: [isAlert ? Colors.error[200] : Colors.primary[200], Colors.bgray[300]],
  };
  if (loadingEvaluationData || !evaluationData) {
    return (
      <div className="flex flex-col rounded-lg min-h-[180px] flex-[0.5]">
        <Skeleton
          variant="rectangular"
          height={'100%'}
          width={'100%'}
          className="dark:bg-darkblack-500 rounded-lg "
        />
      </div>
    );
  }
  return (
    <div className="flex dark:bg-darkblack-500 flex-col  shadow-lg bg-white p-2 rounded-lg h-[180px] flex-[0.5]">
      <p className="text-semibold dark:text-white">
        {tSurvivalSurveys('details.body.detailsResults.graphs.deaded')}
      </p>
      <div className="flex-col flex justify-center items-center h-full w-full relative">
        <div className="flex-1 h-100%]"></div>
        <div className="w-full flex-1 absolute h-[180px]">
          <EChartComponent
            option={optionsSobrevienciaChart}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
        <div className="flex w-full items-center">
          <div className="flex-1 justify-center flex-col text-center">
            <p className="text-xl font-semibold dark:text-white">
              {formatNumber(evaluationData.deadSeedlings, 0)}
            </p>
            <p className="text-sm dark:text-bgray-200 mt-[-8px] text-nowrap">
              {tSurvivalSurveys('details.body.detailsResults.graphs.plantsDead')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default observer(DetailsDeathGraph);
