import { toast, ToastOptions } from 'react-toastify';
import Theme from '../../../tailwind.config';
interface ToastProps {
  message: string;
  cb?: () => void;
}

interface ToastType {
  showSuccess: (props: ToastProps) => void;

  showError: (props: ToastProps) => void;

  showInfo: (props: ToastProps) => void;
}

export const backgroundColor = {
  success: Theme.theme.extend.colors.primary[200],
  error: Theme.theme.extend.colors.error[200],
  info: '#00A3E0',
};

export const toastConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
  icon: false,
  bodyStyle: {
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    fontWeight: 500,
  },
  style: {
    alignContent: 'center',
    padding: '0px',
  },
  closeButton: false,
};

export const Toast: ToastType = {
  showSuccess: ({ message, cb }) => {
    const newConfig = toastConfig;
    newConfig.style = {
      backgroundColor: backgroundColor.success,
    };
    toast.success(message, {
      onClose: () => {
        if (cb) cb();
      },
      ...newConfig,
    });
  },
  showError: ({ message, cb }) => {
    const newConfig = toastConfig;
    newConfig.style = {
      backgroundColor: backgroundColor.error,
    };
    toast.error(message, {
      onClose: () => {
        if (cb) cb();
      },
      ...newConfig,
    });
  },
  showInfo: ({ message, cb }) => {
    const newConfig = toastConfig;
    newConfig.style = {
      backgroundColor: backgroundColor.info,
    };
    toast.info(message, {
      onClose: () => {
        if (cb) cb();
      },
      ...newConfig,
    });
  },
};
